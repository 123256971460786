import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { A3PageApi } from 'src/connectors/backend';

const HeaderService = {
  // get project header
  getProjectHeaderA3: async (projectId: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetProjectHeaderA3(projectId));
  },

  getMemberList: async (id: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetMembersListA3(id));
  },
};

export default HeaderService;
