import { trackPromise } from 'react-promise-tracker';
import {
  type GetFilterbyXMatrixRequest,
  type GetResourceWorkloadSummaryRequest,
  PersonalDashboardApi,
  authenticatedApi,
} from 'src/config/connectors';
import i18n from '../../i18n';

const PersonalDashboardService = {
  getDeliverablePerStatusChartData: async () => {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetDeliverableSummaryperStatus(currentLanguageCode));
  },

  getDeliverablePerTimeChartData: async () => {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetDeliverableSummaryperTime(currentLanguageCode));
  },

  getPersonalDashboardPhases: async () => {
    try {
      const api = await authenticatedApi(PersonalDashboardApi);

      return trackPromise(api.personalDashboardGetPersonalDashboardPhases());
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  },

  getActivityPerStatusChartData: async () => {
    const currentLng = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetActivitySummaryperStatus(currentLng));
  },

  getActivityPerTimeChartData: async () => {
    const currentLng = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetActivitySummaryperTime(currentLng));
  },

  getKpiSummary: async (xmatrixId: string) => {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetKPISummary(xmatrixId));
  },

  getFeedSummary: async (userId: string) => {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetFeedSummary(userId));
  },

  getXmatrixSummary: async (selectedXmatrixId: string) => {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetXMatrixSummary(selectedXmatrixId));
  },

  getXmatrixFilterChartData: async (payload: GetFilterbyXMatrixRequest) => {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetFilterbyXMatrix(payload));
  },

  getResourceWorkloadData: async (payload: GetResourceWorkloadSummaryRequest) => {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetResourceWorkloadSummary(payload));
  },

  getUsersPicklistPersonalDashboard: async () => {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetUserSelectPersonalDashboard());
  },

  getTeamLeaderPicklistPersonalDashboard: async () => {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetTeamLeaderPicklistPersonalDashboard());
  },
};

export default PersonalDashboardService;
