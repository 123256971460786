import { MinusSquareOutlined } from '@ant-design/icons';
import { Button, Select, Switch } from 'antd';
import type { BaseOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LS_XMATRIX_FILTERS_KEY } from 'src/components/xMatrix/xMatrix';
import type { XMatrixFilters } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import './matrix.scss';
import XMatrixHeader from './xMatrixHeader';
import XMatrixLeftPanel from './xMatrixLeftPanel/xMatrixLeftPanel';
import XmatrixOptions from './xmatrixOptions';

interface FiltersMatrixLocalProps {
  manageFilterApplied: () => void;
  manageFilterReset: () => void;
  xmatrixRootList: any[];
  setFilters: (filters: any) => void;
  reloadMatrix: () => void;
  selectedXmatrixInfo: any;
  isSecondLevelXmatrix: boolean;
  reloadMatrixOnEdit: () => void;
  reloadXmInfo: () => void;
  filters: XMatrixFilters;
  xmatrixData: any;
}

const FiltersMatrixLocal: React.FC<FiltersMatrixLocalProps> = ({
  xmatrixRootList,
  reloadMatrix,
  selectedXmatrixInfo,
  setFilters,
  isSecondLevelXmatrix,
  manageFilterReset,
  reloadMatrixOnEdit,
  reloadXmInfo,
  filters,
  xmatrixData,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [longTermGoalsPicklist, setLongTermGoalsPicklist] = useState<BaseOptionType[]>([]);
  const [annualGoalsPicklist, setAnnualGoalsPicklist] = useState<BaseOptionType[]>([]);
  const [teamLeaderPicklist, setTeamLeaderPicklist] = useState<BaseOptionType[]>([]);
  const [divisionPicklist, setDivisionPicklist] = useState<BaseOptionType[]>([]);
  const [committiePicklist, setCommittiePicklist] = useState<BaseOptionType[]>([]);
  const [projectPicklist, setProjectPicklist] = useState<BaseOptionType[]>([]);
  const [sponsorPicklist, setSponsorPicklist] = useState<BaseOptionType[]>([]);

  useEffect(() => {
    const xMatrixID = id || activeXmatrixInfo?.xMatrixID;

    if (!xMatrixID) return;

    retrieveFilterComponentsData(xMatrixID);
  }, [activeXmatrixInfo, id]);

  const retrieveFilterComponentsData = (xmatrix: any) => {
    retrieveLongTermGoalsPicklist(xmatrix);
    retrieveAnnualGoalsPicklist(xmatrix);
    retrieveDivisions(xmatrix);
    retrieveCommittees(xmatrix);
    retrieveProjects(xmatrix);
    retrieveSponsors();
    retrieveTeamLeaders();
  };

  const retrieveLongTermGoalsPicklist = async (xmatrix: any) => {
    try {
      const response = await MatrixService.getLongTermGoalsXmatrixFilter(xmatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.longTermGoalID,
            label: item.title,
            key: item.longTermGoalID,
          })) || [];
        setLongTermGoalsPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveAnnualGoalsPicklist = async (xMatrix: any) => {
    try {
      const response = await MatrixService.getAnnualGoalsXmatrixFilter(xMatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.annualGoalId,
            label: item.description,
            key: item.annualGoalId,
          })) || [];
        setAnnualGoalsPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveDivisions = async (xMatrix: any) => {
    try {
      const response = await MatrixService.getDivisionXmatrixFilter(xMatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.divisionID,
            label: item.divisionName,
            key: item.divisionID,
          })) || [];
        setDivisionPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveCommittees = async (xMatrix: any) => {
    try {
      const response = await MatrixService.getCommittieXmatrixFilter(xMatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.committeeID,
            label: item.name,
            key: item.committeeID,
          })) || [];
        setCommittiePicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveProjects = async (xmatrix: any) => {
    try {
      const response = await MatrixService.getProjectXmatrixFilter(xmatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.projectID,
            label: item.name,
            key: item.projectID,
          })) || [];
        setProjectPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveTeamLeaders = async () => {
    try {
      const response = await DashboardService.getTeamLaderFilterData();
      if (response.data.success) {
        const respData = response.data.responseObject?.value || [];
        setTeamLeaderPicklist(
          respData?.map((item: any) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveSponsors = async () => {
    try {
      const response = await DashboardService.getSponsorFilterData();
      if (response.data.success) {
        const respData = response.data.responseObject?.value || [];
        setSponsorPicklist(
          respData.map((item: any) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFilterOptionsSelect = (filterName: keyof XMatrixFilters, value: string | string[]) => {
    setFilters({ ...filters, [filterName]: value });
    localStorage.setItem(LS_XMATRIX_FILTERS_KEY, JSON.stringify({ ...filters, [filterName]: value }));
  };

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-shrink-0">
      <div className="tw-flex tw-py-2 tw-px-2 tw-border-b tw-bg-white tw-w-full tw-gap-8 tw-justify-between tw-items-center">
        <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
          <XMatrixLeftPanel
            activeXmatrixInfo={activeXmatrixInfo}
            xmatrixRootList={xmatrixRootList}
            reloadMatrix={reloadMatrix}
          />
          <XMatrixHeader
            reloadMatrix={reloadMatrix}
            selectedXmatrixInfo={selectedXmatrixInfo}
            isSecondLevelXmatrix={isSecondLevelXmatrix}
          />
        </div>

        <div className="tw-flex tw-items-center tw-flex-1 tw-justify-end tw-gap-2">
          <Select
            style={{ width: '100%' }}
            value={filters.longTermGoalId || []}
            showSearch
            mode="multiple"
            id="select_r9"
            maxTagCount="responsive"
            placeholder={t('general.obiettiviLungo')}
            options={longTermGoalsPicklist}
            onChange={(selectedRowKeys) => onFilterOptionsSelect('longTermGoalId', selectedRowKeys)}
          ></Select>
          <Select
            value={filters && filters.annualGoalId ? filters.annualGoalId : []}
            showSearch
            style={{ width: '100%' }}
            mode="multiple"
            options={annualGoalsPicklist}
            id="select_r10"
            maxTagCount="responsive"
            placeholder={t('general.obbietiviAnuali')}
            optionFilterProp="children"
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(selectedRowKeys) => onFilterOptionsSelect('annualGoalId', selectedRowKeys)}
          ></Select>
          <Select
            value={filters && filters.projectID ? filters.projectID : []}
            showSearch
            mode="multiple"
            id="select_r11"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            options={projectPicklist}
            placeholder={t('general.progetti')}
            optionFilterProp="children"
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(selectedRowKeys) => onFilterOptionsSelect('projectID', selectedRowKeys)}
          ></Select>
          <Select
            style={{ width: '100%' }}
            value={filters && filters.committeeId ? filters.committeeId : []}
            showSearch
            mode="multiple"
            id="select_r12"
            maxTagCount="responsive"
            placeholder={t('general.comitato')}
            options={committiePicklist}
            optionFilterProp="children"
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(selectedRowKeys) => onFilterOptionsSelect('committeeId', selectedRowKeys)}
          ></Select>
          <Select
            style={{ width: '100%' }}
            value={filters && filters.divisionId ? filters.divisionId : []}
            showSearch
            mode="multiple"
            id="select_r13"
            maxTagCount="responsive"
            placeholder={t('general.divisione')}
            options={divisionPicklist}
            optionFilterProp="children"
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(selectedRowKeys) => onFilterOptionsSelect('divisionId', selectedRowKeys)}
          ></Select>
          <Select
            style={{ width: '100%' }}
            value={filters && filters.teamLeaderId ? filters.teamLeaderId : []}
            mode="multiple"
            id="teamLeaderSelect"
            maxTagCount="responsive"
            placeholder={t('general.teamLeader')}
            onChange={(selectedRowKeys) => onFilterOptionsSelect('teamLeaderId', selectedRowKeys)}
            options={teamLeaderPicklist}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
          <Select
            style={{ width: '100%' }}
            value={filters && filters.sponsorId ? filters.sponsorId : []}
            mode="multiple"
            id="select_r15"
            maxTagCount="responsive"
            placeholder={t('general.sponsor')}
            onChange={(selectedRowKeys) => onFilterOptionsSelect('sponsorId', selectedRowKeys)}
            options={sponsorPicklist}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />

          <div className="tw-flex tw-gap-2 tw-items-center">
            <span>{t('xMatrixPage.includiSottoprogetti')}</span>
            <Switch
              className="switch"
              checked={filters && filters.includeSubProject ? filters.includeSubProject : false}
              onChange={(checked) => onFilterOptionsSelect('includeSubProject', checked ? 'true' : 'false')}
            />
          </div>

          <div className="tw-flex tw-items-center tw-gap-2">
            {/* <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => handleSubmit()}></Button>*/}
            <Button
              className="tw-mr-4"
              icon={<MinusSquareOutlined />}
              onClick={manageFilterReset}
            ></Button>
            <XmatrixOptions
              reloadMatrix={reloadMatrix}
              selectedXmatrixInfo={selectedXmatrixInfo}
              isSecondLevelXmatrix={isSecondLevelXmatrix}
              reloadMatrixOnEdit={reloadMatrixOnEdit}
              reloadXmInfo={reloadXmInfo}
              xmatrixData={xmatrixData}
            />
          </div>
        </div>
      </div>
      {/* <div className="tw-bg-zinc-100 empty:tw-hidden tw-border-b tw-px-2 tw-py-1">
        {selectedFiltersLabelList.map((item) => (
          <Button
            type="text"
            className="fixed-grey-button"
            key={item.value}>
            {item.label} <CloseOutlined onClick={() => removeSelectedFilter(item.value)} />
          </Button>
        ))}
      </div> */}
    </div>
  );
};

export default FiltersMatrixLocal;
