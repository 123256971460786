import { Button, notification } from 'antd';
import axios, { type AxiosError } from 'axios';
import { createBrowserHistory } from 'history';
import deployment from '../deploymentConfigs';

// Utilizziamo la tipizzazione per TS
const history = createBrowserHistory();

const key = `open${Date.now()}`;

const closeNotificationTab = async (): Promise<void> => {
  history.push('/');
};

const notificationButton = (
  <Button
    type="primary"
    onClick={() => closeNotificationTab()}
  >
    Torna alla home
  </Button>
);

const axiosInstance = axios.create({
  baseURL: deployment.backend.href,
  withCredentials: true,
});

// Configurazione degli headers
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

// Gestore degli errori con tipizzazione
const axiosErrorHander = (error: AxiosError): Promise<never> => {
  console.error('Error:', error);

  if (error.response) {
    if (error.response.status === 401) {
      notification.open({
        duration: 18,
        message: 'NON AUTORIZZATO',
        description: 'Non sei autorizzato ad accedere alla pagina che stai cercando.',
        btn: notificationButton,
        key,
        onClose: closeNotificationTab,
      });
    }
  } else {
    console.error('Other error:', error);
    history.push('/error');
  }
  return Promise.reject(error);
};

// Aggiunta degli interceptor con TS
axios.interceptors.response.use((response) => response, axiosErrorHander);

axiosInstance.interceptors.response.use((response) => response, axiosErrorHander);

export default axiosInstance;
