import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Button, Dropdown, Skeleton, Tooltip, Typography, message } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { projectStatusAsNumber } from 'src/components/shared/utils/constants';
import ObAnnoService from '../../../../../services/matrix/obAnnoService';
import ObKpiService from '../../../../../services/matrix/obKpiService';
import ObProgettiService from '../../../../../services/matrix/obProgettiService';
import { isTeamleader, updateNotificationBell } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import AddObietivoAnnoModal from '../../../data_tables/center/addObAnnoModal';
import AddKpiModal from '../../../data_tables/center/addObKpiModal';
import AddProgettiModal from '../../../data_tables/center/addObProgettiModal';
import QuickAddProgettiModal from '../../../data_tables/center/addQuickProgettiModal';
import '../../../data_tables/center/centerSection.scss';
import ZoomDataModal from '../../../data_tables/center/zoomDataModal';
import '../../../matrix.scss';
import { enumCast } from 'src/config/connectors';
import { AnnualGoalStatus } from 'src/connectors/backend';
import KpiService from 'src/services/pages/kpiService';

const { Text } = Typography;

const optionsMenu = (props) => {
  const items = [
    {
      label: <Text onClick={props.toggleObProgettiModal}>{props.t('xMatrixPage.addProject')}</Text>,
      key: 1,
    },
    {
      label: <Text onClick={props.toggleQuickObProgettiModal}>{props.t('xMatrixPage.quickAddProject')}</Text>,
      key: 2,
    },
  ];
  return { items };
};

const AddOptionsMenu = (props) => (
  <Dropdown
    key="more"
    menu={optionsMenu(props)}
  >
    <Button
      className={`tw-bg-zinc-100  tw-text-rest !tw-rounded-t-none ${props.mode === 'vertical' && 'tw-h-auto !tw-rounded-tl-md !tw-w-10 !tw-rounded-r-none'}`}
      icon={<PlusOutlined />}
    >
      {props.mode === 'vertical' ? props.t('xMatrixPage.iniziative') : props.t('headerMenu.progetti')}
    </Button>
  </Dropdown>
);

const CenterImage = ({ logo }) => {
  if (logo) {
    return (
      <img
        src={`${logo}?${+new Date()}`}
        width="100px"
        className="tw-rounded-lg"
        alt="Logo"
      />
    );
  } else {
    return <Skeleton.Image />;
  }
};

class CenterSectionLevel2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showObAnno: false,
      showObProgetti: false,
      showQuickObProgetti: false,
      showObKpi: false,
      loadingButton: false,
      isSecondaryProject: false,
      selectedZoomModalData: null,
      showZoomModal: false,
    };
  }

  handleDateChange(date) {
    if (date) {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  // Add obittivo lungo anno modal
  addObAnno = (values) => {
    const { selectedXmatrix } = this.props;
    this.setState({ loadingButton: true });
    const payload = values;

    ObAnnoService.addObiettivoAnnoxMartix({
      ...payload,
      status: enumCast(AnnualGoalStatus, values.status) ?? AnnualGoalStatus.Draft,
      xMatrixId: selectedXmatrix,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleObAnnoClose();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleObAnnoModal = () => {
    this.setState({ showObAnno: !this.state.showObAnno });
  };

  handleObAnnoClose = () => {
    this.setState({ showObAnno: false });
    this.setState({ loadingButton: false });
  };

  // Add progetti modal
  addObProgetti = (values) => {
    const { selectedXmatrix } = this.props;
    const { isSecondaryProject } = this.state;

    values['xMatrixID'] = selectedXmatrix;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    values['secondaryProject'] = isSecondaryProject;
    values['fastCreation'] = false;

    const payload = values;

    this.setState({ loadingButton: true });
    ObProgettiService.addObProgetti(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          updateNotificationBell();
          this.handleObProgettiClose();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  addQuickProgetti = (values) => {
    const { selectedXmatrix, selectedXmatrixInfo, isSecondLevelXmatrix } = this.props;
    const { isSecondaryProject } = this.state;

    values['xMatrixID'] = selectedXmatrix;
    values['secondaryProject'] = isSecondaryProject;
    values['status'] = projectStatusAsNumber.draft;
    values['fastCreation'] = true;
    values['hasExternalReview'] = false;
    values['divisionID'] = isSecondLevelXmatrix ? (selectedXmatrixInfo ? selectedXmatrixInfo.divisionID : null) : null;

    const startDateXm = moment(selectedXmatrixInfo.referencePeriod).startOf('year');
    const endDateXm = moment(selectedXmatrixInfo.referencePeriod).endOf('year');
    values['startDate'] = this.handleDateChange(startDateXm);
    values['endDate'] = this.handleDateChange(endDateXm);

    this.setState({ loadingButton: true });
    ObProgettiService.addObProgetti(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleQuickObProgettiClose();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleObProgettiModal = (secondaryProject) => {
    this.setState({ showObProgetti: !this.state.showObProgetti });
    this.setState({ isSecondaryProject: secondaryProject });
  };
  handleObProgettiClose = () => {
    this.setState({ showObProgetti: false });
    this.setState({ loadingButton: false });
  };

  // Add KPI modal
  addObKpi = async (values, isNewKpi) => {
    this.setState({ loadingButton: true });
    const { selectedXmatrix } = this.props;

    try {
      await (isNewKpi
        ? KpiService.createKpi({ ...values, xMatrixId: selectedXmatrix })
        : ObKpiService.addObKpi(selectedXmatrix, values.kpiId));

      void message.success(notifyMessages.addSuccess);
      this.setState({ showObKpi: false });
      this.props.reloadMatrix();
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    this.setState({ loadingButton: false });
  };

  toggleObKpiModal = () => {
    this.setState({ showObKpi: !this.state.showObKpi });
  };
  handleObKpiClose = () => {
    this.setState({ showObKpi: false });
  };

  toggleQuickObProgettiModal = (secondaryProject) => {
    this.setState({ showQuickObProgetti: !this.state.showQuickObProgetti });
    this.setState({ isSecondaryProject: secondaryProject });
  };

  handleQuickObProgettiClose = () => {
    this.setState({ showQuickObProgetti: false });
    this.setState({ loadingButton: false });
  };

  toggleZoomModal = (data, area, label) => {
    this.setState({ showZoomModal: true });
    const dataObj = {};
    dataObj['data'] = data;
    dataObj['area'] = area;
    dataObj['label'] = label;
    this.setState({ selectedZoomModalData: dataObj });
  };

  handleZoomModalClose = () => {
    this.setState({ showZoomModal: false });
  };

  render() {
    const {
      comittieList,
      projectStatusList,
      goalStatusList,
      divisionsList,
      selectedXmatrix,
      selectedXmatrixInfo,
      isSecondLevelXmatrix,
      t,
      annualGoals,
      projects,
      childProjects,
      userData,
      companyData,
    } = this.props;
    const { selectedZoomModalData } = this.state;

    const isRoleTeamLeader = userData && Object.keys(userData).length > 0 ? isTeamleader(userData) : false;

    return (
      <>
        <AddObietivoAnnoModal
          showObAnno={this.state.showObAnno}
          handleObAnnoClose={this.handleObAnnoClose}
          handleObAnnoSave={this.addObAnno}
          loadingButton={this.state.loadingButton}
          statusPickList={goalStatusList}
          t={t}
          annualGoals={annualGoals}
        />

        {selectedXmatrixInfo && (
          <AddProgettiModal
            showObProgetti={this.state.showObProgetti}
            handleObProgettiClose={this.handleObProgettiClose}
            handleObProgettiSave={this.addObProgetti}
            commitiePickList={comittieList}
            divisionsList={divisionsList}
            statusPickList={projectStatusList}
            loadingButton={this.state.loadingButton}
            isSecondLevelXmatrix={isSecondLevelXmatrix}
            selectedXmatrixInfo={selectedXmatrixInfo}
            t={t}
          />
        )}

        {this.state.showObKpi && (
          <AddKpiModal
            showObKpi={this.state.showObKpi}
            handleObKpiClose={this.handleObKpiClose}
            handleObKpiSave={this.addObKpi}
            selectedXmatrix={selectedXmatrix}
            loadingButton={this.state.loadingButton}
            t={t}
            companyData={companyData}
          />
        )}

        {this.state.showQuickObProgetti && (
          <QuickAddProgettiModal
            showQuickObProgetti={this.state.showQuickObProgetti}
            handleModalClose={this.handleQuickObProgettiClose}
            handleSave={this.addQuickProgetti}
            loadingButton={this.state.loadingButton}
            isSecondLevelXmatrix={isSecondLevelXmatrix}
          />
        )}

        {this.state.showZoomModal && (
          <ZoomDataModal
            showZoomModal={this.state.showZoomModal}
            handleZoomModalClose={this.handleZoomModalClose}
            modalData={selectedZoomModalData}
            t={t}
          />
        )}

        <div className="center-section">
          <div className="center-grid-container">
            <div className="center-grid-item horizontal-text"></div>

            <div className="tw-flex tw-justify-center tw-gap-2">
              <Tooltip title={t('xMatrixPage.expandList')}>
                <Button
                  className="tw-bg-zinc-100 tw-text-rest !tw-rounded-t-none"
                  icon={<ZoomInOutlined />}
                  onClick={() =>
                    this.toggleZoomModal(childProjects, 'childProjects', t('xMatrixPage.progettiMiglioramenti'))
                  }
                ></Button>
              </Tooltip>
              {/* <Text className="menu hoverZoomCursor">{t('xMatrixPage.progettiMiglioramenti')}</Text> */}
              <AddOptionsMenu
                mode="horizontal"
                toggleObProgettiModal={() => this.toggleObProgettiModal(true)}
                toggleQuickObProgettiModal={() => this.toggleQuickObProgettiModal(true)}
                t={t}
              />
            </div>

            <div className="center-grid-item horizontal-text"></div>

            <div className="tw-flex vertical-text tw-gap-2">
              <Tooltip title={t('xMatrixPage.expandList')}>
                <Button
                  icon={<ZoomInOutlined />}
                  className="tw-bg-zinc-100 tw-rotate-180 tw-text-rest !tw-rounded-l-none"
                  onClick={() => this.toggleZoomModal(projects, 'projects', t('xMatrixPage.iniziative'))}
                ></Button>
              </Tooltip>
              {/* <Text className="menu hoverZoomCursor">{t('xMatrixPage.iniziative')}</Text> */}
              <AddOptionsMenu
                mode="vertical"
                toggleObProgettiModal={() => this.toggleObProgettiModal(false)}
                toggleQuickObProgettiModal={() => this.toggleQuickObProgettiModal(false)}
                t={t}
              />
            </div>

            <div className="center-grid-item horizontal-text">
              {selectedXmatrixInfo && <CenterImage logo={selectedXmatrixInfo.image} />}
            </div>

            <div className="tw-flex tw-justify-center tw-gap-2 vertical-text">
              {/* <Text className="menu">{t('xMatrixPage.metriche')}</Text> */}
              <Button
                className="tw-rounded-l-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
                onClick={this.toggleObKpiModal}
                icon={<PlusOutlined />}
              >
                {t('xMatrixPage.metriche')}
                <button
                  className="tw-rotate-90 tw-text-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.toggleKpiSection();
                  }}
                >
                  {this.props.isKpiSectionVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </button>
              </Button>

              <Button
                className="tw-rounded-l-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
                type="dashed"
              >
                {t('xMatrixPage.utenti')}
                <button
                  className="tw-rotate-90 tw-text-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.toggleUsersSection();
                  }}
                >
                  {this.props.isUsersSectionVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </button>
              </Button>
            </div>

            <div className="center-grid-item horizontal-text"></div>

            <div className="tw-flex horizontal-text tw-gap-2">
              <Tooltip title={t('xMatrixPage.expandList')}>
                <Button
                  icon={<ZoomInOutlined />}
                  className="tw-bg-zinc-100 tw-text-rest !tw-rounded-b-none"
                  onClick={() => this.toggleZoomModal(annualGoals, 'annualGoal', t('xMatrixPage.objAnno'))}
                >
                  {/* {t('xMatrixPage.objAnno')} */}
                </Button>
              </Tooltip>
              {!isRoleTeamLeader && (
                <Button
                  onClick={this.toggleObAnnoModal}
                  className="tw-rounded-b-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
                  icon={<PlusOutlined />}
                >
                  {t('xMatrixPage.objAnno')}
                </Button>
              )}
            </div>

            <div className="center-grid-item horizontal-text"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
  userData: state.userData.userData,
});

export default connect(mapStateToProps)(CenterSectionLevel2);
