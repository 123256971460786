import { Form } from 'antd';
import React from 'react';
import './obietiviAnno.scss';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, Row, Select, message } from 'antd';
import BraftEditor from 'braft-editor';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../services/commonService';
import ObiettiviAnnoService from '../../services/pages/obiettiviAnnoService';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { richTectEditoControlls, statusPickListCodes } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddNewObiettivoAnno extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      loadingButton: false,
    };
  }

  componentDidMount() {
    this.retrieveStatus();
  }

  handleDateChange(date) {
    if (date) {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addData = (values) => {
    this.setState({ loadingButton: true });
    values.referencePeriod = this.handleDateChange(values.referencePeriod);

    const editorContentToHtml = BraftEditor.createEditorState(values['notes']).toHTML();
    values['notes'] = editorContentToHtml;

    ObiettiviAnnoService.addAnualGoal(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.setState({ loadingButton: false });
          this.props.history.push('/obiettiviannuali');
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  render() {
    const { statusList, loadingButton } = this.state;
    const t = this.props.t;
    const { xmatrixInfo } = this.props;

    return (
      <div className="edit-obietivo-anno-wrapper">
        <>
          <div className="site-card-wrapper"> {t('obiettiviAnnoPage.aggioungiObjAanuali')} </div>
          <Card>
            <Form
              name="add_ob_anno"
              layout="vertical"
              {...formItemLayout}
              onFinish={this.addData}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}
            >
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <div className="button-wrapper">
                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        htmlType="submit"
                        className="tw-float-end"
                        disabled={!this.state.fieldsChanged}
                      >
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row style={{ justifyContent: 'center' }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                >
                  <Card title={t('general.informazioniGenerali')}>
                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                      >
                        <Form.Item
                          label={t('general.nome')}
                          name="description"
                          rules={[{ required: true, message: requiredFields.required }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                      >
                        <Form.Item
                          label={t('general.stato')}
                          name="status"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                          >
                            {statusList &&
                              statusList.map((item) => (
                                <Option
                                  value={item.statusID}
                                  key={item.statusID}
                                >
                                  {item.statusDescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                      >
                        <UserSelectDropDown
                          disabled={false}
                          allowClear={false}
                          fieldLabel={t('general.responsabile')}
                          formName={'owner'}
                          required={false}
                          initValue={null}
                        />

                        {/* <Form.Item
                                                    label={t('general.responsabile')}
                                                    name="owner"
                                                    hasFeedback
                                                >
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        placeholder={t('general.seleziona')}
                                                        options={ownerList}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        loading={!ownerList}
                                                    />
                                                </Form.Item> */}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                      >
                        <Form.Item
                          label={t('obiettiviAnnoPage.periodoRiferimento')}
                          name="referencePeriod"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={
                            xmatrixInfo && xmatrixInfo.referencePeriod
                              ? moment().year(xmatrixInfo.referencePeriod).startOf('year')
                              : moment()
                          }
                        >
                          <DatePicker
                            disabled={true}
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            placeholder={t('general.seleziona')}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                      >
                        <Form.Item
                          label={t('general.commenti')}
                          name="notes"
                        >
                          <BraftEditor
                            //style={{ height: 350, overflowX: 'auto' }}
                            contentFormat="html"
                            language="en"
                            id="editor-text"
                            controls={richTectEditoControlls}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      </div>
    );
  }
}

// export default (withTranslation()(AddNewObiettivoAnno));

const mapStateToProps = (state) => ({
  selectedXmatrix: state.selectedXmatrix.selectedXmatrix,
  xmatrixInfo: state.selectedXmatrix.xmatrixInfo,
});

export default withTranslation()(connect(mapStateToProps)(AddNewObiettivoAnno));
