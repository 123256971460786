import { trackPromise } from 'react-promise-tracker';
import {
  type CreateKpiRequest,
  type InsertUserFavouriteKPIRequest,
  KPIApi,
  type UpdateCommentForKPIRequestDto,
  type UpdateGlobalKPIBowlingChartImportRequestDto,
  type UpdateKPIDetailRequestDto,
  type UpdateSpecificAnnualGoalKPIBowlingRequestDto,
  type UpdateSpecificKPIBowlingChartImportRequestDto,
  XMatrixApi,
  authenticatedApi,
} from 'src/config/connectors';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';

const KpiService = {
  // create kpi (returns kpi dto)
  createKpi: async (payload: CreateKpiRequest) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICreateKpi(payload));
  },

  // get kpi list data
  getKpiList: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetKPIXMAtrixPicklist(xmatrixId));
  },
  //get to populate kpi table
  getKpiListData: async (committeeId?: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIListKpis(committeeId));
  },

  // get kpi data by id
  getKpiByiD: async (id: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIDetail(id));
  },

  // update kpi
  updateKpi: async (updateKpiPayload: UpdateKPIDetailRequestDto) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateKPIDetail(updateKpiPayload));
  },

  // add new kpi
  addKpi: async (payload: CreateKpiRequest) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICreateKpi(payload));
  },

  //delete kpi
  deleteKPI: async (id: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIDeleteKPI(id));
  },

  // check if kpi name exist
  checkIfKpiNameExist: async (name: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICheckDuplicateKPI(name));
  },

  getKpiSpecificoExcelData: async (kPIProjectRelationShipID: string, projectID: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID));
  },

  getKpiGlobaleExcelData: async (kPIDetailID: string, year: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year));
  },

  updateGlobalKpiFromExcel: async (payload: Array<UpdateGlobalKPIBowlingChartImportRequestDto>) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateGlobalKPIBowlingChartImport(payload));
  },

  updateSpecificlKpiFromExcel: async (payload: Array<UpdateSpecificKPIBowlingChartImportRequestDto>) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateSpecificKPIBowlingChartImport(payload));
  },

  getKpiSpecificoExcelDataAnualGoal: async (annualGoalKPIRelationShipID: string, year: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year));
  },

  updateSpecificlKpiFromExcelAnualGoal: async (payload: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(payload));
  },

  // get kpi child Data
  getKpiChildList: async (parentKpiId?: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetRollupKPIChild(parentKpiId));
  },

  // get kpi child Data
  getAnnualGoalList: async (kpiDetailId: string, xmatrixId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetAnnualGoalByKPI(kpiDetailId, xmatrixId));
  },

  // get kpi list data
  getKpiProjectPicklist: async (parentKpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIChildPicklist(parentKpiId));
  },

  insertChildKpi: async (parentKpiId: string, childKpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertKPIChild(parentKpiId, childKpiId));
  },

  setKpiPrefered: async (payload: InsertUserFavouriteKPIRequest) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertUserFavouriteKPI(payload));
  },

  removeKpiPrefered: async (kpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIDeleteUserFavouriteKPI(kpiId));
  },

  getKpiProjectSintesi: async (month: number, projectId: string, xmatrixId: string, year: number) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIDashboardSintesiByProject(month, projectId, xmatrixId, year));
  },

  getKpiAnualGoalSintesi: async (month: number, annualGoalId: string, xmatrixId: string, year: number) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalId, xmatrixId, year));
  },

  getKpiRelatedProjectsBowlingChart: async (kpiId: string, type: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIGerarchicoInfo(kpiId, type));
  },

  getKpiDetailsPastYears: async (filters: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/KPIDetailsPastYears?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiGraph: async (filters: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/DetailsKPI?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiGraphAsInA3: async (filters: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/KPIDetailsGraficoA3?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiComments: async (kpiDetailId: string, projectAnnualGoalId: string, type: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetCommentForKPI(kpiDetailId, projectAnnualGoalId, type));
  },

  onCommentSave: async (payload: UpdateCommentForKPIRequestDto) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateCommentForKPI(payload));
  },

  onKpiChildRemove: async (kpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIRemoveKpiChildParentRelations(kpiId));
  },

  checkKpiYear: async (kpiId: string, type: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICheckKpiTypePlanningStartDate(kpiId, type));
  },

  getKPIPlanningByID: async (projectId: string, annualGoalId: string, year: number, kpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIPlanningByID(projectId, annualGoalId, year, kpiId));
  },
};

export default KpiService;
