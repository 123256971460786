import { type PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { UserDetailsRespose } from 'src/connectors/backend';
import UsersService from '../../services/pages/usersService';
import { retrieveCompanyNotifications } from './companySlice';

interface UserState {
  loading: boolean;
  userData: UserDetailsRespose | null;
}

const initialState: UserState = {
  loading: false,
  userData: null,
};

export const retrieveUserData = createAsyncThunk('user/retrieveUserData', async (_, { dispatch }) => {
  try {
    const response = await UsersService.getSystemUser();
    const { success, responseObject } = response.data;

    if (!success) {
      throw new Error('Failed to fetch company notifications');
    }

    const data = responseObject?.value;

    if (data?.lastLoggedInCompanyID) {
      dispatch(retrieveCompanyNotifications(data.lastLoggedInCompanyID));
    }

    return data;
  } catch (error: any) {
    console.error('Error fetching user data:', error);
    throw error;
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveUserData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.userData = action.payload || null;
      })
      .addCase(retrieveUserData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default userSlice.reducer;
