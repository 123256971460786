import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  DeliverableApi,
  type InsertKPIProjectRequestDto,
  KPIApi,
  LegacyProjectApi,
  type UpdateGlobalKPIBowlingChartRequestDto,
  type UpdateKPIBowlingChartRequestDto,
} from 'src/connectors/backend';
import i18n from '../../../i18n';

const DeliverableService = {
  getProjectDeliverables: async (projectId: string) => {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectDeliverable(projectId, currentLanguageCode));
  },

  getProjectKpi: async (projectId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIByProject(projectId));
  },

  // add new kpi to project
  insertKpiProject: async (payload: InsertKPIProjectRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectInsertKPIProject(payload));
  },

  // get project kpi bowling chart
  getKpiBowlingChart: async (kpiProjectRelationShipId: string, projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetKPIBowlingChart(kpiProjectRelationShipId, projectId));
  },

  getGlobalKpiBowlingChart: async (kpiDetailId: string, year: string, isRollup: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetGlobalKPIBowlingChart(kpiDetailId, year, isRollup));
  },

  updateKpiBowlingChart: async (payload: UpdateKPIBowlingChartRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateKPIBowlingChart(payload));
  },

  updateGlobalKpiBowlingChart: async (payload: UpdateGlobalKPIBowlingChartRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateGlobalKPIBowlingChart(payload));
  },

  getDeliverableProjectPicklist: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetDeliverableProjectPicklist(projectId));
  },

  getParentDeliverableProjectPicklist: async (projectId: string, deliverableId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectDeliverableParentPicklist(projectId, deliverableId));
  },

  // delete a kpi
  removeKpi: async (kpiProjectRelationShipID: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectDeleteKPIRelationshipBowlingChart(kpiProjectRelationShipID));
  },

  // delete deliverable from project
  removeDeliverable: async (deliverableId: string) => {
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableDeleteDeliverable(deliverableId));
  },

  // get bowling chart data
  getBowlingChartListData: async (projectId: string, selectedYear: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIBowlingChartListPage(projectId, selectedYear));
  },

  // get annual goal bowling chart data
  getAnnualBowlingChartListData: async (selectedAnnualGoalId: string, selectedYear: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetAnnualGoalKPIBowlingChartListPage(selectedAnnualGoalId, selectedYear));
  },
};

export default DeliverableService;
