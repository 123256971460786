import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  type UpdateAnnualGoalKPIRelationShipRequestDto,
  type UpdateGoalKPIRelationShipRequestDto,
  type UpdateGoalProjectRelationShipDto,
  type UpdateKPIProjectRelationShipRequestDto,
  type UpdateLongTermAnnualGoalRelationShipDto,
  type UpdateProjectSecondaryProjectRelationShipRequestDto,
  type UpdateProjectUserRelationShipRequestDto,
  XMatrixApi,
} from 'src/connectors/backend';

const RelationsService = {
  // update long term goal and annual goal relation
  updateLongAnnualRelation: async (payload: UpdateLongTermAnnualGoalRelationShipDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateLongTermAnnualGoalRelationShip(payload));
  },

  //update annual goal and projects relation
  updateAnnualProjectRelation: async (payload: UpdateGoalProjectRelationShipDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateGoalProjectRelationShip(payload));
  },

  // update projects and kpi relation
  updateProjectKpiRelation: async (payload: UpdateKPIProjectRelationShipRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateKPIProjectRelationShip(payload));
  },

  // update kpi and long terma goals relation
  updateLongGoalKpiRelation: async (payload: UpdateGoalKPIRelationShipRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateGoalKPIRelationShip(payload));
  },

  // update projects and user relation
  updateProjectUserRelation: async (payload: UpdateProjectUserRelationShipRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateProjectUserRelationShip(payload));
  },

  // delete projects and user relation
  deleteProjectUserRelation: async (projectId: string, userId: string, relation: string, xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteUserRelationshipXMatrix(projectId, userId, relation, xmatrixId));
  },

  // update project child project relation
  updateProjectChildProjectRelation: async (payload: UpdateProjectSecondaryProjectRelationShipRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateProjectSecondaryProjectRelationShip(payload));
  },

  // update annual goal and kpi relation
  updateAnnualGoalKpiRelation: async (payload: UpdateAnnualGoalKPIRelationShipRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateAnnualGoalKPIRelationShip(payload));
  },
};

export default RelationsService;
