import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  type CreateLongTermGoalRequest,
  LongTermGoalApi,
  type UpdateLongTermGoalChecklistRequestDto,
  type UpdateLongTermGoalXRequestDto,
  XMatrixApi,
} from 'src/connectors/backend';

const ObLongService = {
  updateObiettivoLungoxMartix: async (payload: UpdateLongTermGoalXRequestDto) => {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalUpdateLongTermGoalXMatrix(payload));
  },

  addObiettivoLungoxMartix: async (payload: CreateLongTermGoalRequest) => {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalCreateLongTermGoal(payload));
  },

  getAttiva: async (selectedXmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetLongTermGoalChecklist(selectedXmatrixId));
  },

  // update obiettivo lungo in matrix
  updateAttiva: async (payload: UpdateLongTermGoalChecklistRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateLongTermGoalChecklist(payload));
  },
};

export default ObLongService;
