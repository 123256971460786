import {
  CloseCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  MinusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Alert, Button, Card, Col, DatePicker, Row, Space, Table, Tooltip, Typography, Upload, message } from 'antd';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import * as XLSX from 'xlsx';
import KpiService from '../../services/pages/kpiService';
import { capitalizeFirstLetterKpiMonths } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './kpi.scss';

const { Text } = Typography;

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ErrorList = ({ errorList }) => {
  return (
    <Space direction="vertical">
      {errorList.map((item) => (
        <Text> * {item.error}</Text>
      ))}
    </Space>
  );
};

class UploadKpiGlobal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      loadingExcelData: false,
      cols: [],
      rows: [],
      errorMessage: null,
      kpiApiData: null,

      clearFilters: false,
      selectedYear: moment().format('YYYY'),

      rowVlidaionErrors: [],
      showErrorsList: false,
    };
  }

  componentDidMount() {
    this.getExcelData();
  }

  async getExcelData() {
    const { kpiData } = this.props;
    const { selectedYear } = this.state;

    this.setState({ loadingExcelData: true });
    await KpiService.getKpiGlobaleExcelData(kpiData.kpiDetailID, selectedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiApiData: resp.responseObject.value });
          this.setState({ loadingExcelData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingExcelData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingExcelData: false });
      });
  }

  checkFile(file) {
    const { errorMessage } = this.state;
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === 'application/vnd.ms-excel' ||
      file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      this.setState({ errorMessage: 'Upload only excel files' });
    }
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({ errorMessage: 'File must be less than 2 MB! ' });
    }
    return errorMessage;
  }

  fileHandler = (fileList) => {
    const { rowVlidaionErrors } = this.state;
    const fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: 'No files uploaded!',
      });
      return false;
    }

    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: 'Upload only excel files',
      });
      return false;
    }

    const isLt2M = fileObj.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({ errorMessage: 'File must be less than 2 MB! ' });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      const { t } = this.props;

      if (err) {
        console.log(err);
      } else {
        const newRows = [];
        resp.rows.slice(1).map((row, index) => {
          let rowErrorObj = {};

          if (isNaN(row[3])) {
            rowErrorObj['error'] = `${t('kpiPage.rigaActual')} ` + (index + 1) + ` ${t('kpiPage.nonValido')}.`;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row[2] === null || row[2] === undefined) {
            rowErrorObj['error'] = `${t('kpiPage.rigaMese')} ` + (index + 1) + ` ${t('kpiPage.nonValido')}.`;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row && row !== 'undefined') {
            newRows.push({
              key: index + 1,
              kPIDetailID: row[0],
              name: row[1],
              targetMonth: row[2],
              actual: row[3],
              unitOfMeasure: row[4],
            });
          }
        });

        if (newRows.length === 0) {
          this.setState({
            errorMessage: `${t('utentiPage.noDataOnFile')} `,
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    const { rows } = this.state;

    const payloadArray = [];

    rows.forEach((item, index) => {
      const payloadObj = {};

      payloadObj['actual'] = item.actual;
      payloadObj['unitOfMeasure'] = item.unitOfMeasure;
      payloadObj['kPIDetailID'] = item.kPIDetailID;
      payloadObj['referencePeriod'] = item.targetMonth;

      payloadArray.push(payloadObj);
    });

    this.setState({ loadingButton: true });
    KpiService.updateGlobalKpiFromExcel(payloadArray)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ rows: [] });
          this.setState({ loadingButton: false });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });

    //submit to API
    //if successful, banigate and clear the data
    //this.setState({ rows: [] })
  };

  exportToCSV = (kpiApiData) => {
    const { kpiData } = this.props;

    const fileName = `KPI_${kpiData.name}`;

    if (kpiApiData) {
      const ws = XLSX.utils.json_to_sheet(kpiApiData);

      //column formatter
      const wsColsFormatter = [
        { hidden: true }, // hidde first column
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
      ];

      ws['!cols'] = wsColsFormatter;

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  onClearFilter = () => {
    const currentYear = moment().format('YYYY');
    this.setState({ selectedYear: currentYear, clearFilters: true }, () => {
      this.getExcelData();
      this.cancellUpload();
    });
  };

  onYearChange = (year) => {
    const formatedYear = moment(year).format('YYYY');
    this.setState({ selectedYear: formatedYear }, () => {
      this.getExcelData();
      this.cancellUpload();
    });
  };

  disablePreviousYears = (current) => {
    return current && (current > moment().add(10, 'year') || current < moment());
  };

  cancellUpload = async () => {
    this.setState({ rows: [] });
    this.setState({ rowVlidaionErrors: [] });
  };

  showHideErrorList = () => {
    const { showErrorsList } = this.state;
    this.setState({ showErrorsList: !showErrorsList });
  };

  render() {
    const {
      rows,
      kpiApiData,
      loadingExcelData,
      selectedYear,
      errorMessage,
      loadingButton,
      showErrorsList,
      rowVlidaionErrors,
    } = this.state;
    const { t } = this.props;

    const columns = [
      {
        title: '',
        dataIndex: 'key',
      },
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        ellipsis: {
          showTitle: true,
        },
      },
      {
        title: `${t('general.mese')}`,
        dataIndex: 'targetMonth',
        render: (text) => capitalizeFirstLetterKpiMonths(text ? moment(text).format('MMM YYYY') : null),
      },
      {
        title: `${t('general.actual')}`,
        dataIndex: 'actual',
      },
      {
        title: `${t('kpiPage.unitaMisura')}`,
        dataIndex: 'unitOfMeasure',
      },
    ];

    return (
      <>
        {/* {showErrorsModal &&
                <UploadErrorModal
                    showErrorsModal={showErrorsModal}
                    handleCloseModal={this.handleCloseModal}                   
                    loadingButton={loadingButton}
                    errorList={rowVlidaionErrors}
                />
            } */}

        <Card>
          <Row
            gutter={{ lg: 24 }}
            className="mb10"
          >
            <Col
              className="gutter-row"
              xs={{ span: 8 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              {/* <DatePicker allowClear={false} placeholder={t('general.anno')} picker="year" value={moment(selectedYear)} style={{ width: "100%" }} format="YYYY" onChange={(date) => this.onYearChange(date)} disabledDate={(current) => this.disablePreviousYears(current)} /> */}
              <DatePicker
                allowClear={false}
                placeholder={t('general.anno')}
                picker="year"
                value={moment(selectedYear)}
                style={{ width: '100%' }}
                format="YYYY"
                onChange={(date) => this.onYearChange(date)}
              />
            </Col>
            <Col
              xs={{ span: 4 }}
              sm={{ span: 4 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Tooltip title={t('general.clearFilters')}>
                <Button
                  type="dashed"
                  icon={<MinusSquareOutlined />}
                  onClick={() => this.onClearFilter()}
                  disabled={selectedYear ? false : true}
                />
              </Tooltip>
            </Col>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Button
                type="primary"
                loading={loadingExcelData}
                icon={<DownloadOutlined />}
                onClick={() => this.exportToCSV(kpiApiData)}
              >
                {t('buttons.scaricaModello')}{' '}
              </Button>
              <Tooltip
                placement="top"
                title={t('kpiPage.scaricaModelloKpiInfo')}
              >
                <InfoCircleOutlined style={{ color: '#928e8e', marginLeft: 10 }} />
              </Tooltip>
            </Col>
          </Row>

          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="button-wrapper">
                {rows.length > 0 && (
                  <>
                    <Button
                      onClick={this.handleSubmit}
                      type="primary"
                      style={{ marginBottom: 16, marginLeft: 10 }}
                      loading={loadingButton}
                      disabled={rowVlidaionErrors && rowVlidaionErrors.length > 0}
                      className="tw-float-end"
                    >
                      {t('buttons.salva')}
                    </Button>

                    <Button
                      onClick={this.cancellUpload}
                      type="ghost"
                      style={{ marginBottom: 16, marginLeft: 10 }}
                      className="long-term-button remove"
                      icon={<CloseCircleOutlined />}
                    >
                      {t('buttons.cancella')}
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {rowVlidaionErrors && rowVlidaionErrors.length > 0 && (
            <Alert
              message={t('general.alcuniDatiNonValidi')}
              showIcon
              description={showErrorsList ? <ErrorList errorList={rowVlidaionErrors} /> : ''}
              //description={showErrorsList ? "<ErrorList errorList={rowVlidaionErrors}" : ""}
              type="error"
              action={
                <Button
                  danger
                  onClick={this.showHideErrorList}
                >
                  {t('buttons.dettagli')}
                </Button>
              }
            />
          )}

          {rows.length < 1 && (
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Upload
                  name="file"
                  maxCount={1}
                  multiple={false}
                  beforeUpload={this.fileHandler}
                  onRemove={() => this.setState({ rows: [] })}
                >
                  <Button icon={<UploadOutlined />}>{t('buttons.sleionaExcel')}</Button>
                </Upload>
                <p className="upload-text"> * {t('utentiPage.clickFileExcel')} </p>

                {errorMessage && <p className="upload-text-error"> {errorMessage}</p>}
              </Col>
            </Row>
          )}

          {rows.length > 0 && (
            <Row
              gutter={{ lg: 24 }}
              style={{ marginTop: 10 }}
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Table
                  className="table-height"
                  dataSource={rows}
                  columns={columns}
                  bordered={true}
                  scroll={{ x: 'calc(700px + 50%)' }}
                  size="small"
                  pagination={{ pageSize: 50 }}
                  // loading={loadingData}
                />
              </Col>
            </Row>
          )}
        </Card>
      </>
    );
  }
}

export default UploadKpiGlobal;
