import { List, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MatrixService from '../../../../services/matrix/matrixService';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader2 from '../../../shared/components/loader2/loader2';
import XmatrixDropdownFilter from '../../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../../shared/tableLayout';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';

const { Text } = Typography;

const ProjectObjectives = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [longTermGoals, setLongTermGoals] = useState(null);
  const [annualGoals, setAnnualGoals] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);

  useEffect(() => {
    retrieveComponentData();
  }, []);

  useEffect(() => {
    if (activeXmatrixInfo) onXmatrixSelect(activeXmatrixInfo);
  }, [activeXmatrixInfo]);

  const getActiveXmatrix = async () => {
    try {
      const response = await MatrixService.getActiveXmatrix();
      const resp = response.data;
      if (resp.success) {
        setActiveXmatrixInfo(resp.responseObject.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveComponentData = async () => {
    await getActiveXmatrix();
    await retrieveProjectOjectives();
  };

  const retrieveProjectOjectives = async () => {
    const xmatrix = selectedXmatrixValue || activeXmatrixInfo?.xMatrixID;
    const { projectId } = props;

    setIsDataLoading(true);

    try {
      const response = await ProgettiService.getProjectObjectives(projectId, xmatrix);
      const resp = response.data;
      if (resp.success) {
        setLongTermGoals(resp.responseObject.value.longTermGoal);
        setAnnualGoals(resp.responseObject.value.annualGoal);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDataLoading(false);
    }
  };

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
    retrieveProjectOjectives();
  };

  const { t } = useTranslation();

  return (
    <TableLayout title={
      <ProjectSectionTitle title={t('proggetiPage.obiettiviCollegati')} />
      }>
      <TableLayout.Actions>
        {activeXmatrixInfo && (
          <XmatrixDropdownFilter
            onXmatrixSelect={onXmatrixSelect}
            activeXmatrixInfo={activeXmatrixInfo}
            t={t}
          />
        )}
      </TableLayout.Actions>

      <TableLayout.Content>
        {isDataLoading && <Loader2 />}
        {longTermGoals && annualGoals && (
          <>
            <List
              header={<Text>{t('proggetiPage.obiettiviLungo')}</Text>}
              dataSource={longTermGoals}
              renderItem={(item) => (
                <List.Item>
                  <Link to={{ pathname: `/obiettivialungoperiodo/details/id/${item.longTermGoalID}` }}>
                    {item.longTermGoalDesription}
                  </Link>
                </List.Item>
              )}
            />
            <List
              header={<Text>{t('proggetiPage.obbietiviAnuali')}</Text>}
              dataSource={annualGoals}
              renderItem={(item) => (
                <List.Item>
                  <Link to={{ pathname: `/obiettiviannuali/details/id/${item.annualGoalID}` }}>
                    {item.annualGoalDesription}
                  </Link>
                </List.Item>
              )}
            />
          </>
        )}
      </TableLayout.Content>
    </TableLayout>
  );
};

export default ProjectObjectives;
