import classNames from 'classnames';
import { type FC, useEffect, useState } from 'react';

/**
 * A sticky container that will appear when the selected index is out of the viewport 💫
 */
const FixedSelectedAsset: FC<{
  children: React.ReactNode;
  listOfRefs: any;
  selectedIndex: number | null;
  side: 'top' | 'right' | 'bottom' | 'left';
}> = ({ children, side, listOfRefs, selectedIndex }) => {
  const [isStickyVisible, setIsStickyVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (selectedIndex && entry.target === listOfRefs.current[selectedIndex]) {
          setIsStickyVisible(!entry.isIntersecting);
        }
      });
    }, {});

    if (selectedIndex !== null && listOfRefs.current[selectedIndex]) {
      observer.observe(listOfRefs.current[selectedIndex]);
    }

    // Cleanup
    return () => {
      if (selectedIndex !== null && listOfRefs.current[selectedIndex]) {
        observer.unobserve(listOfRefs.current[selectedIndex]);
      }
    };
  }, [selectedIndex]);

  if (!isStickyVisible) {
    return null;
  }

  return (
    <div
      className={classNames(
        'tw-bg-primary [&_*]:tw-truncate tw-text-white tw-gap-2 tw-flex tw-items-center tw-fixed tw-z-50',
        {
          'tw-w-8 tw-h-full tw-top-[117px] tw-right-0 [writing-mode:vertical-rl] tw-py-2': side === 'right',
          'tw-w-8 tw-h-full tw-top-[117px] tw-left-0 [writing-mode:vertical-rl] tw-py-2': side === 'left',
          'tw-h-8 tw-w-full tw-top-[117px] tw-left-0 tw-px-2': side === 'top',
          'tw-h-8 tw-w-full tw-bottom-0 tw-left-0 tw-px-2': side === 'bottom',
        },
      )}
    >
      {children}
    </div>
  );
};

export default FixedSelectedAsset;
