import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import PersonalDashboardService from '../../services/pages/personalDashboardService';
import '../dashboard/dashboard.scss';
import XmatrixDropDownFilter from '../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../shared/tableLayout';
import { isRoleTeamMember } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import './personalDashboard.scss';
import ProjectsPerSponsor from './projectsPerSponsor';
import PersonalDashboardResourceWorklaoad from './resourceWorkloadChart';
import PersonalDashboarXmatrix from './xMatrixChart';
import PersonalDashboardXmatrixFilterChart from './xMatrixFilterChart';

const XmatrixAnalysis = () => {
  const userData = useAppSelector((state) => state.userData.userData);
  const { t } = useTranslation();

  const isTeamMember = userData ? isRoleTeamMember(userData) : null;
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [divisionPicklist, setDivisionPicklist] = useState(null);
  const [teamLeaderPicklist, setTeamLeaderPicklist] = useState(null);
  const [commitiePicklist, setCommitiePicklist] = useState(null);

  useEffect(() => {
    getActiveXmatrix();
    retrieveDivisionPickList();
    retrieveTeamLeaderPickList();
    retrieveCommittiePickList();
  }, []);

  const retrieveDivisionPickList = async () => {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setDivisionPicklist(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveTeamLeaderPickList = async () => {
    await PersonalDashboardService.getTeamLeaderPicklistPersonalDashboard()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const usr = [];
          const respData = resp.responseObject.value;

          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          setTeamLeaderPicklist(usr);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveCommittiePickList = async () => {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setCommitiePicklist(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  return (
    <TableLayout
      title={t('homepage.analisiXmatrix')}
      className="personal-dashboard"
    >
      <TableLayout.Actions>
        {activeXmatrixInfo && (
          <XmatrixDropDownFilter
            onXmatrixSelect={onXmatrixSelect}
            activeXmatrixInfo={activeXmatrixInfo}
            t={t}
          />
        )}
      </TableLayout.Actions>
      <TableLayout.Content>
        {activeXmatrixInfo && (
          <>
            <div>
              <Typography.Title level={4}>{t('bachechaPersonalePage.sommarioXmatrix')}</Typography.Title>
              <PersonalDashboarXmatrix
                activeXmatrixInfo={activeXmatrixInfo}
                selectedXmatrixValue={selectedXmatrixValue}
              />
            </div>

            {!isTeamMember && (
              <div className="tw-mt-8">
                <PersonalDashboardXmatrixFilterChart
                  t={t}
                  userRoleType={'teamleader'}
                  activeXmatrixInfo={activeXmatrixInfo}
                  selectedXmatrixValue={selectedXmatrixValue}
                  divisionPicklist={divisionPicklist}
                  teamLeaderPicklist={teamLeaderPicklist}
                  commitiePicklist={commitiePicklist}
                />
              </div>
            )}

            <div className="tw-mt-8">
              <ProjectsPerSponsor
                t={t}
                userRoleType={'sponsor'}
                activeXmatrixInfo={activeXmatrixInfo}
                selectedXmatrixValue={selectedXmatrixValue}
                divisionPicklist={divisionPicklist}
                teamLeaderPicklist={teamLeaderPicklist}
                commitiePicklist={commitiePicklist}
              />
            </div>

            <div className="tw-mt-8">
              <PersonalDashboardResourceWorklaoad
                t={t}
                divisionPicklist={divisionPicklist}
              />
            </div>
          </>
        )}
      </TableLayout.Content>
    </TableLayout>
  );
};

export default withTranslation()(XmatrixAnalysis);
