import { LogoutOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserProjects from '../../users/userProjects';
import PerStatusProgettiChart from './perStatusChart';

const PersonalDashboardProgetti: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Tabs
      className="no-sticky tw-px-2"
      defaultActiveKey="1"
      tabBarExtraContent={
        <Link to={{ pathname: `/progettihoshinkanri` }}>
          <LogoutOutlined className="hrefIcon" />
        </Link>
      }
      items={[
        {
          label: <>{t('bachechaPersonalePage.perStatus')}</>,
          key: '1',
          children: (
            <>
              <PerStatusProgettiChart />
              <UserProjects
                isCompact={true}
                toFilter={'tmtl'}
              />
            </>
          ),
        },
      ]}
    />
  );
};

export default PersonalDashboardProgetti;
