import { Spin, Statistic } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDashboardService from '../../services/pages/personalDashboardService';

interface XmatrixData {
  totalActiveAnnualGoal?: number;
  totalActiveLongTermGoal?: number;
  totalActiveProject?: number;
  totalDays?: number;
  totalProjectMembers?: number;
}

interface PersonalDashboardXmatrixProps {
  selectedXmatrixValue?: string;
  activeXmatrixInfo?: { xMatrixID: string };
}

const PersonalDashboarXmatrix: React.FC<PersonalDashboardXmatrixProps> = ({
  selectedXmatrixValue,
  activeXmatrixInfo,
}) => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [xMatrixData, setXMatrixData] = useState<XmatrixData | null>(null);

  useEffect(() => {
    if (activeXmatrixInfo) {
      getXmatrixData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedXmatrixValue, activeXmatrixInfo]);

  const getXmatrixData = async () => {
    const xMatrix = selectedXmatrixValue || activeXmatrixInfo?.xMatrixID;

    if (!xMatrix) return;

    setLoadingData(true);

    try {
      const response = await PersonalDashboardService.getXmatrixSummary(xMatrix);
      if (response.data?.success) {
        if (response.data.responseObject?.value !== null) {
          setXMatrixData(response.data.responseObject?.value as XmatrixData);
        }
      } else {
        setXMatrixData(null);
      }
    } catch (error) {
      console.error('Error fetching Xmatrix data:', error);
      setXMatrixData(null);
    } finally {
      setLoadingData(false);
    }
  };

  if (loadingData || !xMatrixData) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <div className="tw-w-full tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-5">
        <Statistic
          className="brand-statistic tw-border-b"
          title={t('bachechaPersonalePage.numObjAnnoAttivi')}
          value={xMatrixData.totalActiveAnnualGoal || 0}
        />
        <Statistic
          className="brand-statistic tw-border-b"
          title={t('bachechaPersonalePage.numObjLungoAttivi')}
          value={xMatrixData.totalActiveLongTermGoal || 0}
        />
        <Statistic
          className="brand-statistic tw-border-b"
          title={t('bachechaPersonalePage.progettiInCorso')}
          value={xMatrixData.totalActiveProject || 0}
        />
        <Statistic
          className="brand-statistic tw-border-b"
          title={t('bachechaPersonalePage.giornateAssegnate')}
          value={xMatrixData.totalDays || 0}
        />
        <Statistic
          className="brand-statistic tw-border-b"
          title={t('bachechaPersonalePage.risorseAssegnate')}
          value={xMatrixData.totalProjectMembers || 0}
        />
      </div>
    </>
  );
};

export default PersonalDashboarXmatrix;
