import { Form, message } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';

import { SelectOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row } from 'antd';
import BraftEditor from 'braft-editor';
import { useTranslation } from 'react-i18next';
import { isTeamleaderOrTeamMemberOrObjectSponsor } from 'src/components/shared/utils/authRolesProvilege/authRolesPrivilege';
import { richTectEditoControlls } from 'src/components/shared/utils/constants';
import type { LongTermObjectiveByIdResponse } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import ObiettiviLongService from 'src/services/pages/obiettiviLongService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

interface EditLongObjectiveModalProps {
  show: boolean;
  handleModalCancel: () => void;
  data: any;
  loadingButton: boolean;
  selectedXmatrix: any;
  handleSave: (values: any, id: any) => void;
}

const EditLongObjectiveModal: React.FC<EditLongObjectiveModalProps> = ({
  show,
  handleModalCancel,
  data,
  loadingButton,
  selectedXmatrix,
  handleSave,
}) => {
  const userData = useAppSelector((state) => state.userData.userData);

  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);
  const [editorValue, setEditorValue] = useState<string>();
  const [isEditorDisabled, setIsEditorDisabled] = useState<boolean>(false);

  const [longTermData, setLongTermData] = useState<LongTermObjectiveByIdResponse | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const getObiettiviLongTermData = () => {
      if (!selectedXmatrix || !data.longTermGoalID) return;

      ObiettiviLongService.getObiettiviLongByiD(data.longTermGoalID, selectedXmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            setLongTermData(resp.responseObject?.value || null);
            setEditorValue(resp.responseObject?.value?.notes || '');
          } else {
            message.error((notifyMessages as any).retrieveFailed);
          }
        })
        .catch(() => {
          message.error((notifyMessages as any).retrieveFailed);
        });
    };

    if (data && selectedXmatrix) {
      getObiettiviLongTermData();
    }
  }, [data, selectedXmatrix]);

  useEffect(() => {
    if (userData && longTermData) {
      const isRoleTeamMemberOrTeamLeader =
        userData && Object.keys(userData).length > 0 && data
          ? isTeamleaderOrTeamMemberOrObjectSponsor(userData, longTermData)
          : false;

      setIsEditorDisabled(isRoleTeamMemberOrTeamLeader);
    }
  }, [userData, longTermData]);

  const updateData = (values: any, id: string) => {
    handleSave(values, id);

    if (editorValue) {
      console.log('🚀 ~ updateData ~ editorValue:', editorValue);
      // convert editorValue in html
      const editorContentToHtml = BraftEditor.createEditorState(editorValue).toHTML();
      ObiettiviLongService.updateCommentOrNameField({
        itemID: id,
        nameOrNote: editorContentToHtml,
        updateCase: 4,
      });
    }
  };

  return (
    <Modal
      className="edit_ob_modal"
      width={800}
      title={`${data.title}`}
      open={show}
      destroyOnClose={true}
      onCancel={handleModalCancel}
      footer={
        <div className="tw-flex tw-justify-between tw-w-full">
          <Button
            href={`/obiettivialungoperiodo/details/id/${data.longTermGoalID}/2/${selectedXmatrix}`}
            type="dashed"
            icon={<SelectOutlined />}
          />

          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleModalCancel}
            formId={'editObLungoXmatrix'}
          />
        </div>
      }
    >
      <Card>
        <Form
          id="editObLungoXmatrix"
          name="edit_ob_lungo"
          layout="vertical"
          onFinish={(values) => updateData(values, data.longTermGoalID)}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}
        >
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
            >
              <Form.Item
                label={t('general.nome')}
                name="title"
                rules={[{ required: true, message: (requiredFields as any).required }]}
                initialValue={data.title}
              >
                <Input />
              </Form.Item>
            </Col>

            {!isEditorDisabled ? (
              <Col xs={{ span: 24 }}>
                <BraftEditor
                  language="en"
                  id="editor-text"
                  controls={richTectEditoControlls as any}
                  value={BraftEditor.createEditorState(editorValue)}
                  onChange={(value) => {
                    setEditorValue(value);
                    setFieldsChanged(true);
                  }}
                  readOnly={isEditorDisabled}
                />
              </Col>
            ) : null}
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditLongObjectiveModal;
