import { Form } from 'antd';
import moment from 'moment';
import React from 'react';
import '../projects/projectForm/projectActivities/activities.scss';

import { Card, Col, DatePicker, Input, Modal, Row, Select, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { activityStatus, formatOfDate, statusPickListCodes } from '../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../shared/utils/functions';
import { requiredFields } from '../shared/utils/notifyMessages';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class UpdateActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      projectPicklist: null,
      xmatrixPicklist: null,
      selectedXmatrixValue: null,
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrieveComponentData();
  }

  retrieveComponentData = async () => {
    await this.xmatrixPicklist();
    await this.retrieveUserProjects();
  };

  getDefaultXmatrix = (xmatrixPicklist) => {
    const defaultXmatrix = xmatrixPicklist
      ? xmatrixPicklist.find((x) => x.status === 1 && x.parentXMatrixID === null).xMatrixID
      : null;
    this.setState({ selectedXmatrixValue: defaultXmatrix });
  };

  async xmatrixPicklist() {
    await MatrixService.getXmatrixPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ xmatrixPicklist: resp.responseObject.value }, () => {
            this.getDefaultXmatrix(resp.responseObject.value);
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveUserProjects() {
    const { selectedXmatrixValue } = this.state;
    const { userData } = this.props;

    if (userData && Object.keys(userData).length > 0) {
      if (selectedXmatrixValue) {
        await CommonService.getUserProjects(selectedXmatrixValue, userData.userId)
          .then((response) => response.data)
          .then((resp) => {
            if (resp.success) {
              this.setState({ projectPicklist: resp.responseObject.value });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  isStatusClosedOrCancel = (status) => {
    if (status) {
      if (status === activityStatus.closed || status === activityStatus.cancelled) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  editData = (values, activityID, deliverableID, projectId) => {
    this.props.handleUpdateActivity(values, activityID, deliverableID, projectId);
  };

  limitDateSelection(current, modalData) {
    if (modalData) {
      if (modalData.parentDeliverableID) {
        const curr = moment(current).endOf('day');
        const startDate = moment(modalData.parentDeliverableStartDate).endOf('day');
        const endDate = moment(modalData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        const curr = moment(current).endOf('day');
        const startDate = moment(modalData.projectStartDate).endOf('day');
        const endDate = moment(modalData.projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    }
  }

  checkValidityBeforeSave = (values, activityID, deliverableID, projectId) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.editData(values, activityID, deliverableID, projectId);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const { showEditModal, handleEditModalClose, loadingButton, modalData, t } = this.props;
    const { statusList, projectPicklist } = this.state;

    const isClosedOrCancelled = this.isStatusClosedOrCancel(modalData.status);

    const isClosed = modalData.status === activityStatus.closed ? true : false;

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('leMieAttivitaPage.aggiornamentoAttivita')}
        open={showEditModal}
        destroyOnClose={true}
        onCancel={handleEditModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleEditModalClose}
            formId={'editMyActivityForm'}
          />,
        ]}
      >
        <Card>
          <Form
            id="editMyActivityForm"
            layout="vertical"
            {...formItemLayout}
            // onFinish={this.checkDatesValidity}
            onFinish={(values) =>
              this.checkValidityBeforeSave(values, modalData.activityID, modalData.deliverableID, modalData.projectId)
            }
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            {isClosed && (
              <Row
                gutter={{ lg: 24 }}
                className="row-margin"
              >
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <div className="button-wrapper">
                    <Text strong>
                      {t('general.dataChiusura')}:{' '}
                      {modalData.actualCloseDate ? moment(modalData.actualCloseDate).format(formatOfDate) : '-'}
                    </Text>
                  </div>
                </Col>
              </Row>
            )}
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('leMieAttivitaPage.soggetto')}
                  name="subject"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.subject}
                >
                  <Input disabled={isClosedOrCancelled} />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <UserSelectDropDown
                  disabled={isClosedOrCancelled}
                  allowClear={false}
                  fieldLabel={t('general.responsabile')}
                  formName={'ownerID'}
                  required={true}
                  initValue={modalData.ownerID}
                />

                {/* <Form.Item className="main-container"
                                    labelAlign="left"
                                    label={t('general.responsabile')}
                                    name="ownerID"
                                    rules={[{ required: true, message: requiredFields.required }]}
                                    initialValue={modalData.ownerID}
                                >
                                    <Select
                                        disabled={isClosedOrCancelled}
                                        showSearch
                                        placeholder={t('general.seleziona')}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        {usersPickList && usersPickList.map(item =>
                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                        )}
                                    </Select>
                                </Form.Item> */}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.status}
                >
                  <Select
                    disabled={isClosedOrCancelled}
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {statusList &&
                      statusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}
                        >
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.progetto')}
                  name="projectID"
                  hasFeedback
                  initialValue={modalData.projectID}
                >
                  <Select
                    disabled={isClosedOrCancelled}
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    //disabled={true}
                  >
                    {projectPicklist &&
                      projectPicklist.map((item) => (
                        <Option
                          value={item.projectID}
                          key={item.projectID}
                        >
                          {item.projectName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.start')}
                  name="startDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.startDate ? moment(modalData.startDate) : modalData.startDate}
                >
                  {/* <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" disabled={isClosedOrCancelled} /> */}
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabled={isClosedOrCancelled}
                    disabledDate={modalData ? (current) => this.limitDateSelection(current, modalData) : null}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.end')}
                  name="endDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.endDate ? moment(modalData.endDate) : modalData.endDate}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabled={isClosedOrCancelled}
                    disabledDate={modalData ? (current) => this.limitDateSelection(current, modalData) : null}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.descrizione')}
                  name="description"
                  initialValue={modalData.description}
                >
                  <TextArea
                    rows={4}
                    disabled={isClosedOrCancelled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(UpdateActivity));
