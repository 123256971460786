import { trackPromise } from 'react-promise-tracker';
import {
  type InsertRiskRequestDto,
  LegacyProjectApi,
  type UpdateRiskRequestDto,
  authenticatedApi,
} from 'src/config/connectors';

const Riskervice = {
  // get project risks and counter measures
  getRiskAndPrecaution: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetRiskPrecaution(projectId));
  },

  addCritic: async (payload: InsertRiskRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectInsertRisk(payload));
  },

  // update company data
  updateCritic: async (payload: UpdateRiskRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateRisk(payload));
  },

  deleteRisk: async (riskId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectDeleteRisk(riskId));
  },

  // delete counter measure
  deletePrecaution: async (precautionId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectDeletePrecaution(precautionId));
  },
};

export default Riskervice;
