import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import { type FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DashboardIcon, GroupIcon, LightbulbIcon, SettingsIcon, TaskIcon } from 'src/assets/icons';
import HelpSection from 'src/components/layout/header/menu/helpSection';
import LanguageSwitcher from 'src/components/layout/header/menu/languageSwitcher';
import NotificationBell from 'src/components/layout/header/menu/notificationBell';
import UserMenuAvatar from 'src/components/layout/header/menu/userAvatar';
import UserNameCompany from 'src/components/layout/header/menu/userNameCompany';
import type { GetMenuItemResponse } from 'src/connectors/backend';
import LayoutService from 'src/services/layoutService';
import { isRoute } from 'src/utils/url-utils';

const { Header } = Layout;

const AdminNavbar: FC = () => {
  const [menuList, setMenuList] = useState<GetMenuItemResponse[] | undefined>(undefined);

  const location = window.location;
  const page = `/${location.pathname.split('/')[1]}`;

  useEffect(() => {
    const getMenuItems = async () => {
      try {
        const response = await LayoutService.getMenuItem();
        if (response.data.success) {
          setMenuList(response.data.responseObject?.value || []);
        }
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };

    getMenuItems();
  }, []);

  const addStaticItemsToMenu = () => [
    {
      key: '789',
      id: 'help-section',
      className: 'userItem',
    },
  ];

  const generateTopMenu = (menuList: any[]) => {
    const items = menuList.map((menu) => {
      if (menu.menuItems.length > 0) {
        if (menu.menuLink === '/configurazione') return null;

        const menuObj: any = {
          label: menu.menuDisplayName,
          key: menu.menuID,
          children: menu.menuItems.map((menuItem: any) => ({
            label: <Link to={{ pathname: menuItem.menuItemLink }}>{menuItem.menuItemDisplayName}</Link>,
            key: menuItem.menuItemLink,
            disabled: menuItem.menuItemIsDisabled,
          })),
        };

        if (menu.menuItems.some((item: any) => item.menuItemLink === '/xmatrix')) {
          menuObj.icon = <LightbulbIcon />;
        }

        if (menu.menuItems.some((item: any) => item.menuItemLink === '/progettihoshinkanri')) {
          menuObj.icon = <TaskIcon />;
        }

        return menuObj;
      } else {
        return {
          children: [],
          label: (
            <Link to={{ pathname: menu.menuLink }}>
              <span className="tw-flex tw-items-center tw-gap-2">
                {menu.menuLink === '/home' && <DashboardIcon className="tw-fill-white" />}
                {menu.menuLink === '/committies' && <GroupIcon />}
                {menu.menuDisplayName}
              </span>
            </Link>
          ),
          key: menu.menuLink,
        };
      }
    });

    return [...items, ...addStaticItemsToMenu()];
  };

  return (
    <Header className="navbar tw-px-4 tw-shrink-0 tw-overflow-hidden">
      <div className="tw-flex tw-relative tw-h-full tw-justify-between tw-items-center">
        <UserNameCompany />
        {menuList ? (
          <Menu
            className="tw-absolute lg:tw-min-w-[50%] tw-left-1/2 -tw-translate-x-1/2 tw-bottom-0 tw-bg-transparent tw-justify-center"
            mode="horizontal"
            selectedKeys={[page]}
            items={generateTopMenu(menuList)}
            overflowedIndicator={<MenuOutlined />}
            expandIcon={<DownOutlined />}
          />
        ) : (
          <div></div>
        )}
        <div className="tw-flex tw-items-center tw-gap-1">
          <LanguageSwitcher />
          <NotificationBell />
          <HelpSection />
          <Button
            href="/settings"
            type="text"
            className={`navbar-button ${isRoute('/settings') ? 'navbar-button-active' : ''}`}
            icon={<SettingsIcon className="tw-fill-white" />}
          />
          <UserMenuAvatar />
        </div>
      </div>
    </Header>
  );
};

export default AdminNavbar;
