import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MatrixService from '../../../services/matrix/matrixService';
import DashboardService from '../../../services/pages/dashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perStatusProgettiChartOptions } from '../chartsConfig';

type PerStatusProgettiChartProps = {};

const appliedFilters = {
  teamLider: null,
  sponsor: null,
  status: null,
  division: null,
  committie: null,
  timeSemaphore: null,
  budgetSemaphore: null,
  qualitySemaphore: null,
  comboSemaphore: null,
  parentProject: null,
  filterCriteria: null,
  projectCode: null,
  showFiltersModal: false,
};

const PerStatusProgettiChart: React.FC<PerStatusProgettiChartProps> = () => {
  const { t } = useTranslation();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [perStatusChart, setPerStatusChart] = useState<any>(perStatusProgettiChartOptions);
  const [activeXmatrix, setActiveXmatrix] = useState<any>(null);

  useEffect(() => {
    retrieveDefaultXmatrix();
  }, []);

  useEffect(() => {
    if (activeXmatrix) {
      getPerStatusProgettiChartData();
    }
  }, [activeXmatrix]);

  const retrieveDefaultXmatrix = async () => {
    try {
      const response = await MatrixService.getXmatrixPicklist();
      const data = response.data;
      if (data.success) {
        const defaultXmatrix = data.responseObject?.value?.find(
          (x: any) => x.status === 1 && x.parentXMatrixID === null,
        );
        if (defaultXmatrix) {
          setActiveXmatrix(defaultXmatrix);
        }
      }
    } catch (error) {
      console.error('Error retrieving Xmatrix', error);
    }
  };

  const getPerStatusProgettiChartData = async () => {
    if (!activeXmatrix) return;

    setLoadingData(true);
    const selectNotRelatedProjects = true;
    try {
      const response = await DashboardService.getDashboardData(
        appliedFilters,
        activeXmatrix.xMatrixID,
        selectNotRelatedProjects,
      );
      const resp = response.data;
      if (resp.success) {
        const data = resp.responseObject.value;

        const seriesObj: any = {
          name: t('bachechaPersonalePage.progetti'),
          data: Object.values(
            data.reduce((acc: any, { status, statusDescription }: any) => {
              acc[status] = acc[status] || { name: statusDescription, y: 0 };
              acc[status].y++;
              return acc;
            }, {}),
          ),
        };

        setPerStatusChart((prevChart: any) => ({ ...prevChart, series: [seriesObj] }));
      }
    } catch (error) {
      console.error('Error retrieving project status data', error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <>
      {loadingData && <Loader2 />}
      {perStatusChart ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>{t('bachechaPersonalePage.noData')}</p>
      )}
    </>
  );
};

export default PerStatusProgettiChart;
