import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { GetCommitteePicklistDto } from 'src/connectors/backend';
import CommonService from 'src/services/commonService';

interface CommitieState {
  commities: GetCommitteePicklistDto[] | null | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: CommitieState = {
  commities: [],
  loading: false,
  error: null,
};

export const fetchCommitteePickList = createAsyncThunk(
  'commities/fetchCommitiePicklist',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CommonService.getCommitiePicklist();
      const { success, responseObject } = response.data;

      if (!success) {
        throw new Error('Failed to fetch company notifications');
      }

      return responseObject?.value;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  },
);

const commitiesSlice = createSlice({
  name: 'commities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommitteePickList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommitteePickList.fulfilled, (state, action) => {
        state.loading = false;
        state.commities = action.payload;
      })
      .addCase(fetchCommitteePickList.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || 'Unknown error';
      });
  },
});

export default commitiesSlice.reducer;
