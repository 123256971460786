import { FC, useEffect, useState } from 'react';
import './bowlingChart.scss';

import {
  FilterOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
  MinusSquareOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import moment, { Moment } from 'moment';
import DashboardService from '../../services/pages/dashboardService';
import DeliverableService from '../../services/pages/projectServices/deliverableServices';
import TableLayout from '../shared/tableLayout';
import { bowlingChartColumsValues, bowlingChartRowName } from '../shared/utils/constants';
import {
  capitalizeFirstLetter,
  checkBowlingChartCellColor,
  convertCamelCaseToNormal,
  numberWithCommas,
} from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import ProjectChildKpisDetailModal from './projectKpiChildDetailsModal';
import { GetProjectPicklistResponseDto, KPIBowlingChartResponse } from 'src/connectors/backend';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { ColumnsType, FilterDropdownProps, Key } from 'antd/es/table/interface';

const { Text } = Typography;
const { Option } = Select;

type TableDataEntry = KPIBowlingChartResponse & { key: string };

const BowlingChartList: FC<{}> = () => {
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const [loadingData, loadingDataSetter] = useState(false);
  const [bowlingChartData, bowlingChartDataSetter] = useState<TableDataEntry[]>([]);
  const [projectPicklist, projectPicklistSetter] = useState<GetProjectPicklistResponseDto[]>([]);
  const [selectedProjectId, selectedProjectIdSetter] = useState<string>();
  const [selectedYear, selectedYearSetter] = useState(moment().format('YYYY'));
  const [showModal, showModalSetter] = useState(false);
  const [modalData, modalDataSetter] = useState<TableDataEntry>();
  const [bowlingChartDataParent, bowlingChartDataParentSetter] = useState<TableDataEntry[]>([]);

  const BowlingChartTable = () => {
    const [form] = Form.useForm();

    const handleSearch = (_: Key[], confirm: FilterDropdownProps['confirm'], _2: any) => {
      confirm();
    };

    const handleReset = (clearFilters: ({ confirm }: { confirm: boolean }) => void) => {
      clearFilters({ confirm: true });
    };

    const checkCellColor = (record: TableDataEntry, monthName: string, monthValue: any) => {
      const kpiData = bowlingChartDataParent;
      const companyKpiTolerance = companyData?.kpiTolerance;
      const companyStartFromCurrentMonth = companyData?.startFromCurrentMonth;

      const targetRow = kpiData.filter(
        (item) =>
          item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID,
      )[0];
      const targetYtdRow = kpiData.filter(
        (item) =>
          item.name === bowlingChartRowName.targetYtd &&
          item.kpiProjectRelationshipID === record.kpiProjectRelationshipID,
      )[0];
      const actualYtdRow = kpiData.filter(
        (item) =>
          item.name === bowlingChartRowName.actualYtd &&
          item.kpiProjectRelationshipID === record.kpiProjectRelationshipID,
      )[0];

      const cellColor = checkBowlingChartCellColor(
        record,
        monthName,
        monthValue,
        kpiData,
        companyKpiTolerance,
        companyStartFromCurrentMonth,
        targetRow,
        targetYtdRow,
        actualYtdRow,
      );

      return cellColor;
    };

    const renderedColumns = () => {
      const columns: ColumnsType<TableDataEntry> = [
        {
          ...(true && {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder="Search"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(selectedKeys, confirm, 'kpiDetailName')}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, 'kpiDetailName')}
                    icon={<SearchOutlined />}
                    style={{ width: 90 }}>
                    Search
                  </Button>
                  <Button
                    onClick={() => clearFilters && handleReset(clearFilters)}
                    style={{ width: 90 }}>
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            filterIcon: (filtered: boolean) => (
              <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
            ),
            onFilter: (value: Key | boolean, record: TableDataEntry) =>
              (record.kpiDetailName ?? '').toLowerCase().includes(`${value}`.toLowerCase()),
          }),
          dataIndex: 'kpiDetailName',
          title: `${t('general.nome')} KPI`,
          fixed: 'left',
          width: '7%',
          ellipsis: {
            showTitle: false,
          },
          render: (text: string, record: TableDataEntry, index: number) => {
            const val = (
              <Space direction="vertical">
                <Tooltip title={text}>{text}</Tooltip>
                <Popover
                  content={
                    <Space direction="vertical">
                      <Text style={{ fontSize: 14 }}>
                        <span className="header-text"> {t('kpiPage.cumulatoPuntuale')}: </span>
                        <u>
                          <b>{record.parentTypeDescription}</b>
                        </u>
                      </Text>
                      <Text style={{ fontSize: 14 }}>
                        <span className="header-text"> {t('kpiPage.tipoCalcolo')}: </span>
                        <u>
                          <b>{record.typeDescription}</b>
                        </u>
                      </Text>
                    </Space>
                  }
                  placement="right"
                  trigger="click">
                  <InfoCircleOutlined style={{ fontSize: 13 }} />
                </Popover>

                {record.isRollupKPI && (
                  <Tag onClick={() => togleKpiDetailModal(record)}>
                    <Text style={{ color: '#16a34a' }}>{t('kpiPage.gerarchicho')}</Text>
                    <FolderOpenOutlined />
                  </Tag>
                )}
              </Space>
            );

            return {
              children: val,
              props: {
                rowSpan: index % 4 === 0 ? 4 : 0,
              },
            };
          },
        },
        {
          dataIndex: 'name',
          title: '',
          width: '6%',
          ellipsis: {
            showTitle: false,
          },
          render(text, record) {
            return {
              props: {
                style: { borderBottom: record.name === bowlingChartColumsValues.targetYTD ? '4px solid #d6d3d1' : '' },
              },
              children: (
                <Tooltip title={convertCamelCaseToNormal(text)}>
                  <Text>{convertCamelCaseToNormal(text)}</Text>
                </Tooltip>
              ),
            };
          },
        },
      ];

      if (bowlingChartDataParent && bowlingChartDataParent.length > 0) {
        const firstRow = bowlingChartDataParent.find(
          (element: TableDataEntry) => element.monthsList && Object.keys(element.monthsList).length > 0,
        );

        for (const item in firstRow?.monthsList) {
          const currentMonth = moment().format('YYYY-MM');
          const monthName = moment(item).format('YYYY-MM');
          const month = moment(item).format('MMM YYYY');

          columns.push({
            align: 'right',
            dataIndex: monthName,
            title: capitalizeFirstLetter(month),
            width: '6%',
            render(_, record: TableDataEntry) {
              return {
                props: {
                  style: {
                    borderBottom: record.name === bowlingChartColumsValues.targetYTD ? '4px solid #d6d3d1' : '',
                    backgroundColor:
                      currentMonth > monthName || currentMonth === monthName
                        ? checkCellColor(record, item, record.monthsList?.[item])?.cellColor
                        : '',
                  },
                },
                children: (
                  <Text
                    style={{
                      color:
                        currentMonth > monthName || currentMonth === monthName
                          ? checkCellColor(record, item, record.monthsList?.[item])?.textColor
                          : '',
                    }}>
                    {numberWithCommas(record.monthsList?.[item])}
                  </Text>
                ),
              };
            },
          });
        }

        return columns;
      }

      return columns;
    };

    return (
      <Form
        form={form}
        component={false}>
        <Table
          bordered
          size="small"
          dataSource={bowlingChartDataParent}
          columns={renderedColumns()}
          pagination={false}
          loading={loadingData}
          rowKey={(obj) => obj.key}
        />
      </Form>
    );
  };

  useEffect(() => {
    void getBowlingChartData();
    retrieveProjectFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveProjectFilterData = async () => {
    try {
      const { data } = await DashboardService.getProjectFilterData();
      if (data.responseObject?.value) {
        projectPicklistSetter(data.responseObject.value);
      }
    } finally {
    }
  };

  const getBowlingChartData = async () => {
    const currentYear = moment().format('YYYY');
    const formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;

    loadingDataSetter(true);

    try {
      const { data } = await DeliverableService.getBowlingChartListData(selectedProjectId!, formatedYear);

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Fetch failed');
      }

      const items = data.responseObject.value
        .map((item, i) => ({
          ...item,
          key: `${i}`,
        }))
        .sort((a, b) => (a.kpiDetailName ?? '').localeCompare(b.kpiDetailName ?? ''));

      const parentData = items.filter((item) => {
        return item.parentKPIProjectRelationshipID === null;
      });

      bowlingChartDataSetter(items);
      bowlingChartDataParentSetter(parentData);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    loadingDataSetter(false);
  };

  const onProjectChange = (option: string) => {
    selectedProjectIdSetter(option);
  };

  const onFilter = () => {
    void getBowlingChartData();
  };

  const onClearFilter = () => {
    selectedYearSetter(moment().format('YYYY'));
    selectedProjectIdSetter(undefined);

    void getBowlingChartData();
  };

  const onYearChange = (year: Moment) => {
    selectedYearSetter(year.format('YYYY'));
  };

  const handleModalClose = () => {
    showModalSetter(false);
  };

  const togleKpiDetailModal = (kpiData: TableDataEntry) => {
    showModalSetter(true);
    modalDataSetter(kpiData);
  };

  return (
    <>
      {showModal && modalData && (
        <ProjectChildKpisDetailModal
          showModal={showModal}
          handleModalClose={handleModalClose}
          kpiModalData={modalData}
          bowlingChartData={bowlingChartData.filter((item) => {
            return item.parentKPIProjectRelationshipID === modalData.kpiProjectRelationshipID;
          })}
          loadingIcon={false}
          loadingData={loadingData}
          t={t}
        />
      )}

      <TableLayout className="tw-mt-4">
        <TableLayout.Actions>
          <DatePicker
            allowClear={false}
            placeholder={t('general.anno')}
            picker="year"
            value={moment(selectedYear, 'YYYY')}
            style={{ width: '100%' }}
            format="YYYY"
            onChange={(date) => onYearChange(date)}
          />

          <Select
            value={selectedProjectId}
            style={{ width: '100%', minWidth: '400px' }}
            allowClear
            showSearch
            optionFilterProp="name"
            placeholder={t('general.progetti')}
            id="filterByProject"
            onChange={(option) => onProjectChange(option)}
            filterOption={(input, option) => option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}>
            {projectPicklist &&
              projectPicklist.map((item) => (
                <Option
                  name={item.name}
                  className={item.isProjectRelatedXMatrix !== 1 ? 'projectOptionColor' : ''}
                  value={item.projectID}
                  key={item.projectID}>
                  <Tooltip
                    placement="left"
                    title={item.name}>
                    {item.name}
                  </Tooltip>
                </Option>
              ))}
          </Select>

          <Tooltip title={t('general.filter')}>
            <Button
              data-testid="filterBtn"
              className="tw-shrink-0"
              type="dashed"
              icon={<FilterOutlined />}
              onClick={() => onFilter()}
              disabled={!selectedYear && !selectedProjectId}
            />
          </Tooltip>
          <Tooltip title={t('general.clearFilters')}>
            <Button
              className="tw-shrink-0"
              type="dashed"
              icon={<MinusSquareOutlined />}
              onClick={() => onClearFilter()}
              disabled={selectedYear || selectedProjectId ? false : true}
            />
          </Tooltip>
        </TableLayout.Actions>

        <TableLayout.Content>
          <BowlingChartTable />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default BowlingChartList;
