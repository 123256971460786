import { Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './kpi.scss';

import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, WarningOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tooltip,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  CheckKpiTypePlanningStartDateResponseDto,
  GetAnnualGoalByKPIResponseDto,
  GetKPIsDetailDto,
  GetRollupKPIChildResponseDto,
  ObjectCodePicklistResponseDto,
  UpdateGlobalKPIBowlingChartRequestDto,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import CommonService from '../../services/commonService';
import KpiService from '../../services/pages/kpiService';
import DeliverableService from '../../services/pages/projectServices/deliverableServices';
import Loader from '../shared/components/loader/loader';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import XmatrixDropDownFilter from '../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import { isUserAdmin, isUserAdminOrSteering } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { bowlingChartRowName, kpiCalculationType, kpiType, objectCodes } from '../shared/utils/constants';
import { checkUserIsKpiResponsabile } from '../shared/utils/functions';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import KpiValoreFattoNonFattoGlobale from './kpiBowlingChart/kpiValoreFattoNonFattoGlobale';
import KpiValoreMassimoGlobal from './kpiBowlingChart/kpiValoreMassimoGlobal';
import KpiValoreMinimoGlobale from './kpiBowlingChart/kpiValoreMinimoGlobale';
import KpiValorePercentualeGlobale from './kpiBowlingChart/kpiValorePercentualeGlobale';
import NewChildKpiModal from './NewChildModalKpiDialog';
import UploadKpiGlobal from './uploadKpiGlobal';

const { TextArea } = Input;
const { Option } = Select;

const NormalJumpOffPoint = (props: { initialValue: number; isRollup: boolean }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={props.initialValue}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${t('kpiPage.jopNumber')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min="0"
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const PercentageJumpOffPoint = (props: { initialValue: number; isRollup: boolean }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={props.initialValue}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${t('kpiPage.jopMax100Number')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min={0}
        max={100}
        formatter={(value) => `${value} %`}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const BooleanJumpOffPoint = (props: { initialValue: number; isRollup: boolean }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('general.jumpOffPoint')}
      name="jumpOffPoint"
      valuePropName="checked"
      initialValue={props.initialValue === 0 || props.initialValue === null ? false : true}>
      <Switch
        checkedChildren={t('kpiPage.fatto')}
        unCheckedChildren={t('kpiPage.nonFatto')}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const EditKpi = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const userData = useAppSelector((state) => state.userData.userData);
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [loadingButton, setLoadingButton] = useState(false);
  const [currentData, setCurrentData] = useState<GetKPIsDetailDto | null>(null);
  const [calculationTypeList, setCalculationTypeList] = useState<ObjectCodePicklistResponseDto[]>([]);
  const [typeList, setTypeList] = useState<ObjectCodePicklistResponseDto[]>([]);
  const [acumulatoPuntuale, setAcumulatoPuntuale] = useState<ObjectCodePicklistResponseDto[]>([]);
  const [showChildKpiModal, setShowChildKpiModal] = useState(false);
  const [childKpiList, setChildKpiList] = useState<GetRollupKPIChildResponseDto[]>([]);
  const [anualGoalList, setAnualGoalList] = useState<GetAnnualGoalByKPIResponseDto[] | []>([]);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState<string | null>(null);
  const [kpiYear, setKpiYear] = useState<CheckKpiTypePlanningStartDateResponseDto | null>(null);
  const [isGlobalKpi, setIsGlobalKpi] = useState(false);

  const child = useRef<any>(null);

  useEffect(() => {
    if (!id) return;

    const init = async () => {
      await getKpiData(id);
      await retrieveCalculationType();
      await retrieveType();
      await retrieveAcumulatoPuntuale();
      await getKpiChildList(id);
      await getAnualGoalList(id);
      await checkKpiValuesYear(id);
    };

    init();
  }, [id]);

  useEffect(() => {
    if (currentData) {
      setIsGlobalKpi(currentData.getKPIDetail?.type === kpiType.globale ? true : false);
    }
  }, [currentData]);

  const checkKpiValuesYear = async (id: string) => {
    await KpiService.checkKpiYear(id, 'KPI')
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const year = (resp.responseObject?.value as any).year.toString();
          setKpiYear(year);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveCalculationType = async () => {
    const objectCode = objectCodes.calculationTypes;
    await CommonService.getCalculationTypeList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setCalculationTypeList(resp.responseObject?.value || []);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveType = async () => {
    const objectCode = objectCodes.kpiType;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setTypeList(resp.responseObject?.value || []);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveAcumulatoPuntuale = async () => {
    const objectCode = objectCodes.acumulatoPuntuale;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setAcumulatoPuntuale(resp.responseObject?.value || []);
        }
      })
      .catch((error) => {});
  };

  const getKpiData = async (id: string) => {
    try {
      const response = await KpiService.getKpiByiD(id);
      const resp = response.data;
      if (resp.success) {
        const respData = resp.responseObject?.value || null;
        setCurrentData(respData);
        console.log('🚀 ~ getKpiData ~ respData:', respData);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const getKpiChildList = async (id: string) => {
    try {
      const response = await KpiService.getKpiChildList(id);
      const resp = response.data;
      if (resp.success) {
        setChildKpiList(resp.responseObject?.value || []);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const updateData = async (values: any, id: string) => {
    try {
      const response = await KpiService.updateKpi({
        kpiDetailID: id,
        jumpOffPoint: values.jumpOffPoint ? 1 : 0,
        isVisible: values.isVisible,
        name: values.name,
        parentType: values.parentType,
        type: values.type,
        isRollupKPI: values.isRollupKPI,
        calculationType: values.calculationType,
      });
      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const removeKPI = async (id: string) => {
    try {
      const response = await KpiService.deleteKPI(id);
      if (response.data.success) {
        setCurrentData(null);
        message.success(notifyMessages.deleteSuccess);
        history.back();
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch (error) {
      message.error(notifyMessages.deleteFailed);
    }
  };

  const getAnualGoalList = async (kpiDetailID: string) => {
    const xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

    if (xmatrix) {
      try {
        const response = await KpiService.getAnnualGoalList(kpiDetailID, xmatrix);
        const resp = response.data;
        if (resp.success) {
          setAnualGoalList(resp.responseObject?.value || []);
        } else {
        }
      } catch (error) {}
    }
  };

  const updateGlobalKpiBowlingChart = (payload: UpdateGlobalKPIBowlingChartRequestDto) => {
    DeliverableService.updateGlobalKpiBowlingChart(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          child.current.getGlobalKpiBowlingChart();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const handleChildKpiModalClose = () => {
    setShowChildKpiModal(false);
    setLoadingButton(false);
  };

  const toggleChildKpiModalClose = () => {
    setShowChildKpiModal(true);
  };

  const addChildKpi = (values: any, parentKpiId: string) => {
    KpiService.insertChildKpi(parentKpiId, values.KPIDetailID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          handleChildKpiModalClose();
          getKpiChildList(parentKpiId);
        } else {
          message.error(notifyMessages.addFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
      });
  };

  const onDataSave = (
    bowlingChartData: any,
    jumpOffPointValue: number,
    calculationType: string,
    finalTarget: number,
    showInA3: boolean,
    definedTarget: boolean,
  ) => {
    const tableData = [];
    const targetRow = bowlingChartData.filter((item: any) => item.Name === bowlingChartRowName.target)[0];
    const actualRow = bowlingChartData.filter((item: any) => item.Name === bowlingChartRowName.actual)[0];
    const actualYtdRow = bowlingChartData.filter((item: any) => item.Name === bowlingChartRowName.actualYtd)[0];
    const targetYtdRow = bowlingChartData.filter((item: any) => item.Name === bowlingChartRowName.targetYtd)[0];

    let isTargetInvalid = false;
    if ((calculationType as unknown) === kpiCalculationType.valorePercentuale) {
      for (var el in targetRow) {
        if (targetRow.hasOwnProperty(el) && (targetRow[el] > 100 || targetRow[el] < 0)) {
          isTargetInvalid = true;
        }
      }
    }

    let isActualInvalid = false;
    if ((calculationType as unknown) === kpiCalculationType.valorePercentuale) {
      for (var act in actualRow) {
        if (actualRow.hasOwnProperty(act) && (actualRow[act] > 100 || actualRow[act] < 0)) {
          isActualInvalid = true;
        }
      }
    }

    let targetYtdInvalid = false;
    if ((calculationType as unknown) === kpiCalculationType.valorePercentuale) {
      for (var targetYtd in targetYtdRow) {
        if (targetYtdRow.hasOwnProperty(targetYtd) && (targetYtdRow[targetYtd] > 100 || targetYtdRow[targetYtd] < 0)) {
          targetYtdInvalid = true;
        }
      }
    }

    let actualYtdInvalid = false;
    if ((calculationType as unknown) === kpiCalculationType.valorePercentuale) {
      for (var actualYtd in actualYtdRow) {
        if (actualYtdRow.hasOwnProperty(actualYtd) && (actualYtdRow[actualYtd] > 100 || actualYtdRow[actualYtd] < 0)) {
          actualYtdInvalid = true;
        }
      }
    }

    if (targetRow || actualRow) {
      if (!isActualInvalid && !isTargetInvalid && !targetYtdInvalid && !actualYtdInvalid) {
        for (const key in targetRow) {
          if (key !== 'Name') {
            const formatedDate = moment(key).format('YYYY-MM-DD');
            const item = `${formatedDate},${actualRow[key]},${targetRow[key]}`;
            tableData.push(item);
          }
        }

        updateGlobalKpiBowlingChart({
          jumpOffPoint: jumpOffPointValue,
          showInA3: showInA3,
          finalTarget: finalTarget,
          kpiDetailID: currentData?.getKPIDetail?.kpiDetailID!,
          tableData: tableData,
          freezeTargetValues: definedTarget,
        });
      }
    }
  };

  const onXmatrixSelect = (selectedXmatrix: { value: string }) => {
    if (!id) return;

    setSelectedXmatrixValue(selectedXmatrix.value);
    getAnualGoalList(id);
  };

  const onChildRemove = (kpiDetailID: string, parentKpiId: string) => {
    KpiService.onKpiChildRemove(kpiDetailID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          getKpiChildList(parentKpiId);
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const longTermGoalColumns: ColumnsType<any> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'longTermGoalDesription',
      width: '40%',
      sorter: (a, b) => {
        a = a.longTermGoalDesription || '';
        b = b.longTermGoalDesription || '';
        return a.localeCompare(b);
      },
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },

    {
      title: `${t('general.sponsor')}`,
      dataIndex: 'fullName',
      sorter: (a, b) => {
        a = a.fullName || '';
        b = b.fullName || '';
        return a.localeCompare(b);
      },
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      dataIndex: 'relationship',
      render: (text, record) => {
        if (record.correlationCoefficient === 1) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationFull"></span>
              </div>
            </Tooltip>
          );
        } else if (record.correlationCoefficient === 2) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationPartial"></span>
              </div>
            </Tooltip>
          );
        } else {
        }
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}`,
            }}>
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const progettiColumns: ColumnsType<any> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      width: '40%',
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },

    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      sorter: (a, b) => {
        a = a.projectCode || '';
        b = b.projectCode || '';
        return a.localeCompare(b);
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (text, record) => (
        <Space>
          <Link to={{ pathname: `/progetti/id/${record.projectID}` }}>
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const childKpiColumns: ColumnsType<any> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        return (
          <Popconfirm
            key="remuve"
            title={t('kpiPage.removeRelation')}
            icon={<WarningOutlined />}
            okText={t('general.si')}
            cancelText={t('general.no')}
            onConfirm={() => onChildRemove(record.kpiDetailID, currentData?.getKPIDetail?.kpiDetailID! || '')}>
            <DeleteOutlined title={t('buttons.rimuovi')} />
          </Popconfirm>
        );
      },
    },
  ];

  const anualGoalColumns: ColumnsType<any> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'annualGoalDesription',
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => {
        a = a.annualGoalDesription || '';
        b = b.annualGoalDesription || '';
        return a.localeCompare(b);
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },

    {
      key: 'action',
      width: '30px',
      render: (text, record) => (
        <Space>
          <Button
            href={`/obiettiviannuali/details/id/${record.longTermGoalID}`}
            icon={<EditOutlined />}
          />
        </Space>
      ),
    },
  ];

  if (!currentData || !currentData.getKPIDetail) {
    return <Loader />;
  }

  return (
    <div className="edit-kpi-wrapper">
      <div className="site-card-wrapper">
        <h3 className="objective-item">
          {currentData.getKPIDetail.name} - {t('general.kpi')}
        </h3>
      </div>
      {showChildKpiModal && (
        <NewChildKpiModal
          showChildKpiModal={showChildKpiModal}
          handleChildKpiModalClose={handleChildKpiModalClose}
          handleChildKpiSave={addChildKpi}
          loadingButton={loadingButton}
          parentKpiId={currentData.getKPIDetail?.kpiDetailID!!}
        />
      )}
      <Tabs
        className="tw-px-4"
        defaultActiveKey="1"
        items={[
          {
            label: <>{t('general.informazioniGenerali')}</>,
            key: '1',
            children: (
              <>
                <Loader />

                <Form
                  name="kpi_Details"
                  layout="vertical"
                  onFinish={(values) => updateData(values, currentData.getKPIDetail?.kpiDetailID!)}>
                  {checkUserIsKpiResponsabile(currentData.getKPIDetail.responsibleID, userData) && (
                    <div className="tw-flex tw-justify-end tw-items-center tw-gap-2">
                      {currentData.getKPIDetail.toBeDeleted === 1 ? (
                        <Form.Item className="!tw-mb-0">
                          <Popconfirm
                            title={t('kpiPage.deleteKpi')}
                            onConfirm={() => removeKPI(currentData.getKPIDetail?.kpiDetailID!)}
                            okText={t('general.si')}
                            cancelText={t('general.no')}
                            className="long-term-button remove">
                            <Button icon={<DeleteOutlined />}>{t('buttons.elimina')}</Button>
                          </Popconfirm>
                        </Form.Item>
                      ) : (
                        <Form.Item className="!tw-mb-0">
                          <Button
                            type="primary"
                            icon={<DeleteOutlined />}
                            disabled={true}
                            onClick={() => removeKPI(currentData.getKPIDetail?.kpiDetailID!)}>
                            {t('buttons.elimina')}
                          </Button>
                        </Form.Item>
                      )}
                      <Form.Item className="!tw-mb-0">
                        <Button
                          loading={loadingButton}
                          icon={<SaveOutlined />}
                          htmlType="submit">
                          {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    </div>
                  )}

                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            hidden={currentData.getKPIDetail.type === kpiType.specifico}
                            label={t('kpiPage.gerarchicho')}
                            name="isRollupKPI"
                            valuePropName="checked"
                            initialValue={currentData.getKPIDetail.isRollupKPI}>
                            <Switch disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            label={t('kpiPage.isVisible')}
                            name="isVisible"
                            valuePropName="checked"
                            initialValue={currentData.getKPIDetail.isVisible}>
                            <Switch disabled={!isUserAdmin(userData)} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={isGlobalKpi ? { span: 12 } : { span: 24 }}
                          lg={isGlobalKpi ? { span: 12 } : { span: 24 }}>
                          <Form.Item
                            className="main-container"
                            label={t('general.nome')}
                            name="name"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentData.getKPIDetail.name}>
                            <Input />
                          </Form.Item>
                        </Col>

                        {isGlobalKpi && (
                          <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <UserSelectDropDown
                              disabled={!isUserAdminOrSteering(userData)}
                              allowClear={true}
                              fieldLabel={t('general.responsabile')}
                              formName={'responsibleID'}
                              required={false}
                              initValue={currentData.getKPIDetail?.responsibleID!}
                            />
                          </Col>
                        )}

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            label={t('kpiPage.tipoCalcolo')}
                            name="calculationType"
                            hasFeedback
                            initialValue={currentData.getKPIDetail.calculationType}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}
                              disabled={true}>
                              {calculationTypeList &&
                                calculationTypeList.map((item) => (
                                  <Option
                                    value={item.objectCodeListID}
                                    key={item.objectCodeListID}>
                                    {item.description}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        {(currentData.getKPIDetail.calculationType === 1 ||
                          currentData.getKPIDetail.calculationType === 2) && (
                          <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}>
                            <NormalJumpOffPoint
                              initialValue={currentData.getKPIDetail.jumpOffPoint ?? 0}
                              isRollup={currentData.getKPIDetail.isRollupKPI ?? false}
                            />
                          </Col>
                        )}

                        {currentData.getKPIDetail.calculationType === 3 && (
                          <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}>
                            <PercentageJumpOffPoint
                              initialValue={currentData.getKPIDetail.jumpOffPoint ?? 0}
                              isRollup={currentData.getKPIDetail.isRollupKPI ?? false}
                            />
                          </Col>
                        )}

                        {currentData.getKPIDetail.calculationType === 4 && (
                          <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}>
                            <BooleanJumpOffPoint
                              initialValue={currentData.getKPIDetail.jumpOffPoint ?? 0}
                              isRollup={currentData.getKPIDetail.isRollupKPI ?? false}
                            />
                          </Col>
                        )}

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            label={t('kpiPage.cumulatoPuntuale')}
                            name="parentType"
                            hasFeedback
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentData.getKPIDetail.parentType}>
                            <Select
                              disabled={true}
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}>
                              {acumulatoPuntuale &&
                                acumulatoPuntuale.map((item) => (
                                  <Option
                                    value={item.objectCodeListID}
                                    key={item.objectCodeListID}>
                                    {item.description}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            label={t('kpiPage.unitaMisura')}
                            name="unitOfMeasure"
                            initialValue={currentData.getKPIDetail.unitOfMeasure}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            label={t('general.actualYtd')}
                            name="actualYTD"
                            initialValue={currentData.getKPIDetail.actualYTD}>
                            <InputNumber
                              min="0"
                              style={{ width: '100%' }}
                              disabled={currentData?.getKPIDetail?.isRollupKPI || false}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            label={t('general.tipo')}
                            name="type"
                            hasFeedback
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentData.getKPIDetail.type}>
                            <Select
                              disabled={true}
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}>
                              {typeList &&
                                typeList.map((item) => (
                                  <Option
                                    value={item.objectCodeListID}
                                    key={item.objectCodeListID}>
                                    {item.description}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Form.Item
                            className="main-container"
                            label={t('general.nota')}
                            name="additionalNote"
                            initialValue={currentData.getKPIDetail.additionalNote}>
                            <TextArea rows={4} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Tabs
                        defaultActiveKey="1"
                        items={[
                          {
                            label: <>{t('general.obiettiviLungo')}</>,
                            key: '1',
                            children: (
                              <Row gutter={{ lg: 24 }}>
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 24 }}
                                  lg={{ span: 24 }}>
                                  <Table
                                    className="table-height"
                                    size="small"
                                    columns={longTermGoalColumns}
                                    dataSource={currentData.getLongTermGoalByKPI || []}
                                    rowKey={(obj) => obj.longTermGoalID!}
                                    scroll={{ x: 'calc(640px + 50%)' }}
                                  />
                                </Col>
                              </Row>
                            ),
                          },
                          {
                            label: <>{t('general.obbietiviAnuali')}</>,
                            key: '2',
                            children: (
                              <Row gutter={{ lg: 24 }}>
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 24 }}
                                  lg={{ span: 24 }}>
                                  <Card
                                    extra={
                                      <>
                                        {activeXmatrixInfo && (
                                          <XmatrixDropDownFilter
                                            onXmatrixSelect={onXmatrixSelect}
                                            activeXmatrixInfo={activeXmatrixInfo}
                                          />
                                        )}
                                      </>
                                    }>
                                    <Table
                                      size="small"
                                      sticky
                                      columns={anualGoalColumns}
                                      dataSource={anualGoalList}
                                      rowKey={(obj) => obj.annualGoalID}
                                    />
                                  </Card>
                                </Col>
                              </Row>
                            ),
                          },
                          {
                            label: <>{t('general.progetti')}</>,
                            key: '3',
                            children: (
                              <Row gutter={{ lg: 24 }}>
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 24 }}
                                  lg={{ span: 24 }}>
                                  <Table
                                    className="table-height"
                                    size="small"
                                    sticky
                                    columns={progettiColumns}
                                    dataSource={currentData.getProjectByKPI || []}
                                    rowKey={(obj) => obj.projectID}
                                  />
                                </Col>
                              </Row>
                            ),
                          },
                          {
                            label: <>{currentData.getKPIDetail.isRollupKPI ? t('kpiPage.kpiFigli') : ''}</>,
                            key: '4',
                            children: (
                              <Row gutter={{ lg: 24 }}>
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 24 }}
                                  lg={{ span: 24 }}>
                                  <Card
                                    extra={
                                      <Button
                                        onClick={toggleChildKpiModalClose}
                                        type="dashed"
                                        icon={<PlusOutlined />}
                                      />
                                    }>
                                    <Table
                                      className="table-height"
                                      size="small"
                                      columns={childKpiColumns}
                                      dataSource={childKpiList}
                                      rowKey={(obj) => obj.kpiDetailID}
                                      scroll={{ x: 'calc(640px + 50%)' }}
                                    />
                                  </Card>
                                </Col>
                              </Row>
                            ),
                          },
                        ]}></Tabs>
                    </Col>
                  </Row>
                </Form>
              </>
            ),
          },
          {
            label: <>{currentData.getKPIDetail.type === kpiType.globale ? t('kpiPage.valoriKpi') : ''}</>,
            key: '2',
            className: 'edit-kpi-modal',
            children: (
              <>
                {currentData.getKPIDetail.type === kpiType.globale && (
                  <>
                    {currentData.getKPIDetail &&
                      currentData.getKPIDetail.calculationType === kpiCalculationType.valoreMassimo && (
                        <KpiValoreMassimoGlobal
                          kpiRowData={currentData.getKPIDetail}
                          projectId={null}
                          onSave={onDataSave}
                          loadingButton={loadingButton}
                          calculationType={currentData.getKPIDetail.calculationType}
                          kpiDetailID={currentData.getKPIDetail?.kpiDetailID!}
                          ref={child}
                          t={t}
                          kpiYear={kpiYear}
                          responsabileID={currentData.getKPIDetail.responsibleID}
                          userData={userData}
                          companyData={companyData}
                        />
                      )}

                    {currentData.getKPIDetail &&
                      currentData.getKPIDetail.calculationType === kpiCalculationType.valoreMinimo && (
                        <KpiValoreMinimoGlobale
                          kpiRowData={currentData.getKPIDetail}
                          projectId={null}
                          onSave={onDataSave}
                          loadingButton={loadingButton}
                          calculationType={currentData.getKPIDetail.calculationType}
                          kpiDetailID={currentData.getKPIDetail?.kpiDetailID!}
                          ref={child}
                          t={t}
                          kpiYear={kpiYear}
                          responsabileID={currentData.getKPIDetail.responsibleID}
                          userData={userData}
                          companyData={companyData}
                        />
                      )}

                    {currentData.getKPIDetail &&
                      currentData.getKPIDetail.calculationType === kpiCalculationType.valorePercentuale && (
                        <KpiValorePercentualeGlobale
                          kpiRowData={currentData.getKPIDetail}
                          projectId={null}
                          onSave={onDataSave}
                          loadingButton={loadingButton}
                          calculationType={currentData.getKPIDetail.calculationType}
                          kpiDetailID={currentData.getKPIDetail?.kpiDetailID!}
                          ref={child}
                          t={t}
                          kpiYear={kpiYear}
                          responsabileID={currentData.getKPIDetail.responsibleID}
                          userData={userData}
                          companyData={companyData}
                        />
                      )}

                    {currentData.getKPIDetail &&
                      currentData.getKPIDetail.calculationType === kpiCalculationType.valoreFatoNonFato && (
                        <KpiValoreFattoNonFattoGlobale
                          kpiRowData={currentData.getKPIDetail}
                          projectId={null}
                          onSave={onDataSave}
                          loadingButton={loadingButton}
                          calculationType={currentData.getKPIDetail.calculationType}
                          kpiDetailID={currentData.getKPIDetail?.kpiDetailID!}
                          ref={child}
                          t={t}
                          kpiYear={kpiYear}
                          responsabileID={currentData.getKPIDetail.responsibleID}
                          userData={userData}
                          companyData={companyData}
                        />
                      )}
                  </>
                )}
              </>
            ),
          },
          {
            label: (
              <>
                {currentData.getKPIDetail.type === kpiType.globale && !currentData.getKPIDetail.isRollupKPI
                  ? t('kpiPage.caricaDatiKpi')
                  : ''}
              </>
            ),
            key: '3',
            children: (
              <>
                {checkUserIsKpiResponsabile(currentData.getKPIDetail.responsibleID, userData) && (
                  <>
                    {currentData.getKPIDetail.type === kpiType.globale && !currentData.getKPIDetail.isRollupKPI && (
                      <UploadKpiGlobal
                        t={t}
                        kpiData={currentData.getKPIDetail}
                      />
                    )}
                  </>
                )}
              </>
            ),
          },
        ]}></Tabs>
    </div>
  );
};

export default EditKpi;
