import { type FC, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type {
  KanBanColumn as KanBanColumnInterface,
  PrsKanBanItem,
} from 'src/components/projects/projectForm/deliverablesAndKpi/kanban-view/kanban-view.utils';
import KanBanColumn from './kanban-column';

interface KanBanProps {
  items: PrsKanBanItem[];
  columns: KanBanColumnInterface[];
  colFn?: any;
  colGroupFn?: any;
  onDropFn?: (item: PrsKanBanItem, columnId: string | number) => void;
}

const KanBan: FC<KanBanProps> = ({ items = [], columns = [], colFn, colGroupFn, onDropFn }) => {
  const [columnItems, setColumnItems] = useState<KanBanColumnInterface[]>([]);

  useEffect(() => {
    if (!colFn) return;

    const columnList: KanBanColumnInterface[] = columns
      .map((column) => ({
        ...column,
        items: items.filter((item) => colFn(item)?.id === column.id),
      }))
      .filter((column) => !(column.hideIfEmpty && column.items?.length === 0));

    setColumnItems(columnList);
  }, [items, columns, colFn]);

  return (
    <DndProvider backend={HTML5Backend}>
      {columnItems.map(({ id, isDndEnabled, canCollapse, title, icon, items }) => (
        <KanBanColumn
          key={id}
          canCollapse={canCollapse ?? true}
          onDropFn={onDropFn}
          isDndEnabled={isDndEnabled ?? false}
          colGroupFn={colGroupFn}
          items={items || []}
          id={id}
          title={title}
          icon={icon}
        />
      ))}
    </DndProvider>
  );
};

export default KanBan;
