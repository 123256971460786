import { DownloadOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Table, Typography, message } from 'antd';
import React from 'react';

import * as FileSaver from 'file-saver';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { history } from '../../../App';
import { notifyMessages } from '../../../components/shared/utils/notifyMessages';
import CompaniesListPageService from '../../../services/pages/companiesListPageServices';
import CompanyService from '../../../services/pages/companyService';
import TableLayout from '../../shared/tableLayout';
import { checkUserIsLenovysAdmin } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { formatOfDate, userRolesCodes } from '../../shared/utils/constants';
import './companies.scss';
import EditCompanies from './viewEditModal';

const { Text } = Typography;

const CompanyUsersList = ({ data, t }) => {
  return (
    <Row gutter={{ lg: 24 }}>
      {data &&
        data.companyCountUsers &&
        data.companyCountUsers.length > 0 &&
        data.companyCountUsers.map((users, index) => {
          return (
            <>
              {users.roleCode === userRolesCodes.steering && (
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <Text>
                    Steering:{' '}
                    <Text
                      className="ml"
                      strong
                    >
                      {users.usersCount ? users.usersCount : '0'}
                    </Text>
                  </Text>
                </Col>
              )}

              {users.roleCode === userRolesCodes.teamLeader && (
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <Text>
                    Team Leaders:{' '}
                    <Text
                      className="ml"
                      strong
                    >
                      {users.usersCount ? users.usersCount : '0'}
                    </Text>
                  </Text>
                </Col>
              )}
              {users.roleCode === userRolesCodes.teamMember && (
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <Text>
                    Team Members:{' '}
                    <Text
                      className="ml"
                      strong
                    >
                      {users.usersCount ? users.usersCount : '0'}
                    </Text>
                  </Text>
                </Col>
              )}

              {users.roleCode === userRolesCodes.companyAdmin && (
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <Text>
                    Company Admin:{' '}
                    <Text
                      className="ml"
                      strong
                    >
                      {users.usersCount ? users.usersCount : '0'}
                    </Text>
                  </Text>
                </Col>
              )}

              {users.roleCode === userRolesCodes.externalReviewer && (
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <Text>
                    External Reviewers:{' '}
                    <Text
                      className="ml"
                      strong
                    >
                      {users.usersCount ? users.usersCount : '0'}
                    </Text>
                  </Text>
                </Col>
              )}

              {users.roleCode === 'TotalUsers' && (
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <Text>
                    {t('utentiPage.altriUtenti')}:{' '}
                    <Text
                      className="ml"
                      strong
                    >
                      {users.usersCount ? users.usersCount : '0'}
                    </Text>
                  </Text>
                </Col>
              )}
            </>
          );
        })}
    </Row>
  );
};

class CompaniesListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      companiesListData: null,
      showEditCompanyModal: false,
      loadingButton: false,
      selectedCompanyData: null,
      loadingExport: false,
    };
  }

  componentDidMount() {
    this.retrieveCompaniesListPage();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  onNew = () => {
    history.push('/companies/new');
  };

  async retrieveCompaniesListPage() {
    await CompaniesListPageService.getCompaniesList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ companiesListData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  handleEditCompanyClose = () => {
    this.setState({ showEditCompanyModal: false });
    this.setState({ loadingButton: false });
  };

  toggleEditCompanyModal = (rowData) => {
    this.setState({ showEditCompanyModal: true });
    this.setState({ selectedCompanyData: rowData });
  };

  handleDateChange(date) {
    if (date) {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  updateCompany = (values, companyId, fromForm) => {
    const payload = {};

    payload['companyID'] = companyId;
    payload['startDate'] = this.handleDateChange(values.startDate);
    payload['endDate'] = this.handleDateChange(values.endDate);
    payload['activationDate'] = this.handleDateChange(values.activationDate);
    payload['deactivationDate'] = this.handleDateChange(values.deactivationDate);
    payload['licenseType'] = values.licenseType;
    payload['deactivateLicense'] = false;

    // if (fromForm === 1) {
    //   payload["deactivateLicense"] = true;
    //   payload["deactivationDate"] = moment().format('YYYY-MM-DD');
    // }
    // else {
    //   payload["deactivateLicense"] = false;
    // }

    this.setState({ loadingButton: true });
    CompanyService.updateCompanyLicense(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.handleEditCompanyClose();
          this.retrieveCompaniesListPage();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  exportCompanies = (companies) => {
    this.setState({ loadingExport: true });
    const t = this.props.t;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = t('societaPage.societa');
    const formatedData = [];

    companies.map((item) => {
      let companiesUsers = '';
      if (item && item.companyCountUsers.length > 0) {
        item.companyCountUsers.map((userRoles) => {
          if (userRoles.roleCode !== userRolesCodes.techAdmin && userRoles.roleCode !== userRolesCodes.lenovysAdmin) {
            companiesUsers = companiesUsers.concat(`${userRoles.roleCode}` + ':' + `${userRoles.usersCount}` + ' ; ');
          }
        });
      }

      formatedData.push({
        companyName: item.companyName,
        startDate: item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : '',
        endDate: item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : '',
        activationDate: item.activationDate ? moment(item.activationDate).format('DD/MM/YYYY') : '',
        deactivationDate: item.deactivationDate ? moment(item.deactivationDate).format('DD/MM/YYYY') : '',
        statusDescription: item.statusDescription,
        licenseTypeDescription: item.licenseTypeDescription,
        companiesUsers: companiesUsers,
      });
    });

    if (companies && companies.length > 0) {
      const headers = [
        [
          'Nome',
          'Data Inizio',
          'Data Fine',
          'Data di attivazione',
          'Data disattivazione',
          'Stato',
          'Licenza',
          'Utenti',
        ],
      ];

      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(ws, headers);

      const wb = { Sheets: { Società: ws }, SheetNames: ['Società'] };

      const wsColsWidthFormatter = [];
      for (let i = 0; i < 30; i++) {
        wsColsWidthFormatter.push({ wpx: 120 });
      }
      ws['!cols'] = wsColsWidthFormatter;

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, fileName + fileExtension);
      this.setState({ loadingExport: false });
    } else {
      this.setState({ loadingExport: false });
      message.warn(t('general.noDataFound'));
    }
  };

  render() {
    const { companiesListData, selectedCompanyData, loadingButton, showEditCompanyModal, loadingExport } = this.state;
    const { userData } = this.props;
    const t = this.props.t;

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'companyName',
        width: '20%',
        sorter: (a, b) => {
          a = a.companyName || '';
          b = b.companyName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('companyName'),
      },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        width: '20%',
        sorter: (a, b) => {
          a = a.startDate || '';
          b = b.startDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => (text ? moment(text).format(formatOfDate) : ''),
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endDate',
        width: '20%',
        sorter: (a, b) => {
          a = a.endDate || '';
          b = b.endDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => (text ? moment(text).format(formatOfDate) : ''),
      },
      {
        title: `${t('societaPage.dataAttivazione')}`,
        dataIndex: 'activationDate',
        width: '20%',
        sorter: (a, b) => {
          a = a.activationDate || '';
          b = b.activationDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => (text ? moment(text).format(formatOfDate) : ''),
      },
      {
        title: `${t('societaPage.dataDisattivazione')}`,
        dataIndex: 'deactivationDate',
        width: '20%',
        sorter: (a, b) => {
          a = a.deactivationDate || '';
          b = b.deactivationDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => (text ? moment(text).format(formatOfDate) : ''),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        width: '20%',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
      },
      {
        title: `${t('societaPage.licenza')}`,
        dataIndex: 'licenseTypeDescription',
        width: '20%',
        sorter: (a, b) => {
          a = a.licenseTypeDescription || '';
          b = b.licenseTypeDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
      },
      {
        key: 'action',
        width: '30px',
        render: (text, record) => (
          <Button
            icon={<EditOutlined />}
            onClick={() => this.toggleEditCompanyModal(record)}
          ></Button>
        ),
      },
    ];

    return (
      <TableLayout title={t('societaPage.societa')}>
        <TableLayout.Actions>
          {showEditCompanyModal && (
            <EditCompanies
              showEditCompanyModal={showEditCompanyModal}
              handleEditCompanyClose={this.handleEditCompanyClose}
              companyData={selectedCompanyData}
              loadingButton={loadingButton}
              updateCompany={this.updateCompany}
            />
          )}

          {checkUserIsLenovysAdmin(userData) && (
            <Button
              loading={loadingExport}
              onClick={() => this.exportCompanies(companiesListData)}
              icon={<DownloadOutlined />}
            >
              {t('general.export')}
            </Button>
          )}

          <Button
            htmlType="submit"
            type="primary"
            onClick={this.onNew}
          >
            <PlusOutlined />
            {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            size="small"
            columns={columns}
            dataSource={companiesListData}
            rowKey={(obj) => obj.companyID}
            expandable={{
              expandedRowRender: (record) => (
                <CompanyUsersList
                  data={record}
                  t={t}
                />
              ),
            }}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(CompaniesListPage));
