import { DatePicker, Form, Modal } from 'antd';
import type moment from 'moment';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../shared/utils/notifyMessages';
import './matrix.scss';

interface AddNewXmatrixProps {
  handleModalXmatrixClose: () => void;
  handleModalXmatrixSave: (values: any) => void;
  showModalXmatrix: boolean;
  loadingButton: boolean;
  lastXmatrixYear: string;
  xMatrixYearsList: string[];
}

const AddNewXmatrix: React.FC<AddNewXmatrixProps> = ({
  showModalXmatrix,
  handleModalXmatrixClose,
  handleModalXmatrixSave,
  loadingButton,
  xMatrixYearsList,
}) => {
  const { t } = useTranslation();
  const [fieldsChanged, setFieldsChanged] = useState(false);

  const disablePreviousYears = (current: moment.Moment | null) => {
    if (!current) return false;
    const currYear = current.year().toString();
    return xMatrixYearsList.includes(currYear);
  };

  const addData = (values: any) => {
    handleModalXmatrixSave(values);
  };

  return (
    <Modal
      className="mew_xmatrix_modal"
      width={800}
      title={t('xMatrixPage.confermaChiusuraXmCrezioneNuova')}
      open={showModalXmatrix}
      destroyOnClose
      onCancel={handleModalXmatrixClose}
      footer={[
        <ModalFooterButtons
          key="footer-buttons"
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleModalXmatrixClose}
          formId={'newXmatrix'}
        />,
      ]}
    >
      <Form
        id="newXmatrix"
        name="add_xmatrix"
        layout="vertical"
        onFinish={addData}
        onValuesChange={() => setFieldsChanged(true)}
      >
        <Form.Item
          label={t('general.anno')}
          name="year"
          hasFeedback
          rules={[{ required: true, message: requiredFields.required }]}
        >
          <DatePicker
            placeholder={t('general.seleziona')}
            picker="year"
            style={{ width: '100%' }}
            format="YYYY"
            disabledDate={disablePreviousYears as any}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewXmatrix;
