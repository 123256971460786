import { Table, Tabs, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { GetDeliverableListPageResponseDto } from 'src/connectors/backend';
import DeliverablePageService from '../../../services/pages/deliverableService';
import { formatOfDate } from '../../shared/utils/constants';
import PerStatusDeliverableChart from './perStatusChart';
import PerTimeDeliverableChart from './perTimeChart';

const PersonalDashboardDeliverables: React.FC = () => {
  const { t } = useTranslation();

  const [deliverableList, setDeliverableList] = useState<GetDeliverableListPageResponseDto[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);

  useEffect(() => {
    retrieveDeliverableList();
  }, []);

  const retrieveDeliverableList = async () => {
    setLoadingData(true);
    try {
      const response = await DeliverablePageService.getDeliverableList();
      const resp = response.data;
      if (resp.success) {
        setDeliverableList(resp.responseObject?.value || []);
      }
    } catch (error) {
      console.error('Error fetching deliverables:', error);
    } finally {
      setLoadingData(false);
    }
  };

  const deliverablesColumns = [
    {
      title: t('general.nome'),
      dataIndex: 'deliverableName',
      sorter: (a: any, b: any) => a.deliverableName.localeCompare(b.deliverableName),
      ellipsis: {
        showTitle: true,
      },
      showSorterTooltip: true,
    },
    {
      title: t('general.stato'),
      dataIndex: 'statusDescription',
      render: (text: string) => <Tag>{text}</Tag>,
    },
    {
      title: t('general.end'),
      dataIndex: 'endDate',
      render: (text: string) => (text ? moment(text).format(formatOfDate) : ''),
    },
  ];

  return (
    <>
      <Tabs
        className="tw-px-2 no-sticky"
        defaultActiveKey="1"
        items={[
          {
            label: t('bachechaPersonalePage.perStatus'),
            key: '1',
            children: <PerStatusDeliverableChart />,
          },
          {
            label: t('bachechaPersonalePage.inTempoRitardo'),
            key: '2',
            children: <PerTimeDeliverableChart />,
          },
        ]}
      />
      <Table
        columns={deliverablesColumns}
        dataSource={deliverableList}
        rowKey={(record, i) => record.deliverableID + i?.toString()}
        loading={loadingData}
        size="small"
        pagination={{ defaultPageSize: 5, pageSizeOptions: ['5', '10', '20', '50'] }}
      />
    </>
  );
};

export default PersonalDashboardDeliverables;
