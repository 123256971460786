import { useEffect, useState } from 'react';

import {
  ContainerOutlined,
  DownOutlined,
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Switch, Table, Tooltip, message } from 'antd';
import type { FilterDropdownProps, Key } from 'antd/es/table/interface';
import Search from 'antd/es/transfer/search';
import type { MenuProps } from 'antd/lib';
import type { ColumnType } from 'antd/lib/table';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TreeXmatrixFilter from 'src/components/shared/components/treeXmatrixFilter/treeXmatrixFilter';
import {
  type CreateProjectRequest,
  type GetProjectListPageResponseDto,
  type GetXMatrixInfoResponseDto,
  type GetXMatrixPicklistResponseDto,
  type ProjectDto,
  ProjectParentship,
  ProjectStatus,
  ProjectType,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import DashboardService from 'src/services/pages/dashboardService';
import * as XLSX from 'xlsx';
import MatrixService from '../../../services/matrix/matrixService';
import ObProgettiService from '../../../services/matrix/obProgettiService';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils';
import TableLayout from '../../shared/tableLayout';
import { formatOfDate } from '../../shared/utils/constants';
import { capitalizeFirstLetter, isTeamMember } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import QuickAddProgettiModal from '../../xMatrix/data_tables/center/addQuickProgettiModal';
import ProjectsTimeline from '../projectsTimelineViewPage/projectsTimeline';

enum ViewType {
  timeline = 'timeline',
  kanban = 'kanban',
  list = 'default',
}

const ProjectListIndex = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const userData = useAppSelector((state) => state.userData.userData);

  const [view, setView] = useState<ViewType>((getQueryParam('list') || ViewType.timeline) as ViewType);
  const [loadingData, setLoadingData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [projectList, setProjectList] = useState<ProjectDto[]>([]);
  const [showProjectsWithoutRelations, setShowProjectsWithoutRelations] = useState(true);
  const [showNonOperationalProjects, setShowNonOperationalProjects] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [showQuickProjectModal, setShowQuickProjectModal] = useState(false);
  const [loadingQuickSave, setLoadingQuickSave] = useState(false);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState<GetXMatrixInfoResponseDto | null>(null);
  const [selectedXmatrixIds, setSelectedXmatrixIds] = useState<string[]>([]);
  const [xmatrixPicklist, setXmatrixPicklist] = useState<GetXMatrixPicklistResponseDto[]>([]);
  const [searchByName, setSearchByName] = useState('');
  const [filteredProjectList, setFilteredProjectList] = useState<ProjectDto[]>([]);

  const isRoleTeamMember = userData && Object.keys(userData).length > 0 && isTeamMember(userData);
  const activeXmatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  useEffect(() => {
    if (activeXmatrix?.xMatrixID) {
      setActiveXmatrixInfo(activeXmatrix);
      setSelectedXmatrixIds([activeXmatrix?.xMatrixID]);
    }

    void fetchXmatrixPicklist();
  }, [activeXmatrix]);

  useEffect(() => {
    if (selectedXmatrixIds.length > 0) {
      void fetchProjects(selectedXmatrixIds);
    }
  }, [selectedXmatrixIds, showProjectsWithoutRelations, showNonOperationalProjects]);

  useEffect(() => {
    setFilteredProjectList(projectList.filter((project) => !!project.name?.toLowerCase().includes(searchByName ?? '')));
  }, [searchByName, projectList]);

  const fetchXmatrixPicklist = async () => {
    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success) {
      const respData = resp.responseObject?.value as GetXMatrixPicklistResponseDto[];
      setXmatrixPicklist(respData);
    }
  };

  const fetchProjects = async (xMatrixIds: string[]) => {
    setLoadingData(true);

    try {
      const { data } = await DashboardService.getDashboardProjects({
        xMatrixIds,
        projectParentship: ProjectParentship.All,
        selectNotRelatedProjects: showProjectsWithoutRelations,
        type: showNonOperationalProjects ? ProjectType.NonOperational : ProjectType.Operational,
      });
      setProjectList(data);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    setLoadingData(false);
  };

  const handleSearch = (selectedKeys: Key[], dataIndex: string) => {
    setSearchText(selectedKeys[0] as string);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: FilterDropdownProps['clearFilters']) => {
    if (clearFilters !== undefined) {
      clearFilters();
    }

    setSearchText('');
  };

  const onNew = () => {
    if (activeXmatrixInfo?.xMatrixID) {
      history.push(`/progetti/new/${activeXmatrixInfo?.xMatrixID}`);
    }
  };

  const handleQuickProgettiClose = () => {
    setShowQuickProjectModal(false);
    setLoadingQuickSave(false);
  };

  const toggleCreateQuickProjectModal = () => {
    setShowQuickProjectModal(!showQuickProjectModal);
  };

  const addQuickProject = async (
    values: Partial<CreateProjectRequest> & {
      name: string;
      code: string;
    },
  ) => {
    const xmatrixStartDate = moment(activeXmatrixInfo?.referencePeriod).startOf('year');
    const xmatrixEndDate = moment(activeXmatrixInfo?.referencePeriod).endOf('year');

    setLoadingQuickSave(true);

    try {
      const { data } = await ObProgettiService.addObProgetti({
        ...values,
        status: values.status ?? ProjectStatus.Draft,
        secondaryProject: false,
        fastCreation: true,
        hasExternalReview: false,
        xMatrixId: activeXmatrixInfo?.xMatrixID as string,
        startDate: xmatrixStartDate.format('YYYY-MM-DD'),
        endDate: xmatrixEndDate.format('YYYY-MM-DD'),
      });

      if (!data.success) {
        throw new Error('Creation failed');
      }

      void message.success(notifyMessages.addSuccess);
      handleQuickProgettiClose();
      await fetchProjects(selectedXmatrixIds); // update project list
    } catch {
      void message.error(notifyMessages.addFailed);
    } finally {
      setLoadingQuickSave(false);
    }
  };

  const viewOptions: MenuProps = {
    items: [
      {
        label: (
          <li
            onClick={() => {
              addQueryParam('list', ViewType.list);
              setView(ViewType.list);
            }}
          >
            Lista
          </li>
        ),
        key: 'list',
      },
      {
        label: (
          <li
            onClick={() => {
              addQueryParam('list', ViewType.timeline);
              setView(ViewType.timeline);
            }}
          >
            Timeline
          </li>
        ),
        key: 'timeline',
      },
      {
        label: <li>Kanban</li>,
        disabled: true,
        key: 'kanban',
      },
    ],
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
    ),
    onFilter: (value: any, record: Record<string, any>) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const toggleNonOperationalProjects = () => {
    setShowNonOperationalProjects(!showNonOperationalProjects);
  };

  const columns: ColumnType<ProjectDto>[] = [
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      ...getColumnSearchProps('projectCode'),
      showSorterTooltip: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
      fixed: 'left',
      width: '400px',
      showSorterTooltip: false,
      ...getColumnSearchProps('name'),
    },
    {
      title: `${t('proggetiPage.inizio')}`,
      dataIndex: 'startDate',
      sorter: (a, b) => (a.startDate || '').localeCompare(b.startDate || ''),
      showSorterTooltip: false,
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('proggetiPage.fine')}`,
      dataIndex: 'endDate',
      sorter: (a, b) => (a.endDate || '').localeCompare(b.endDate || ''),
      showSorterTooltip: false,
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      sorter: (a, b) => (a.statusDescription || '').localeCompare(b.statusDescription || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('statusDescription'),
    },
    {
      title: `${t('general.comitato')}`,
      dataIndex: ['committee', 'name'],
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => (a.committee?.name || '').localeCompare(b.committee?.name || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('committeeName'),
    },
    {
      title: `${t('general.teamLeader')}`,
      dataIndex: ['teamLeader', 'fullName'],
      sorter: (a, b) => (a.teamLeader?.fullName || '').localeCompare(b.teamLeader?.fullName || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('teamLeaderFullName'),
    },
    {
      title: `${t('general.sponsor')}`,
      dataIndex: ['sponsor', 'fullName'],
      sorter: (a, b) => (a.sponsor?.fullName || '').localeCompare(b.sponsor?.fullName || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('sponsorFullName'),
    },
    {
      title: `${t('general.divisione')}`,
      dataIndex: ['division', 'name'],
      sorter: (a, b) => (a.division?.name || '').localeCompare(b.division?.name || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('divisionName'),
    },
    {
      key: 'action',
      width: '50px',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-justify-end tw-flex-row tw-gap-2">
              {record.projectRepositoryLink && (
                <Tooltip title={t('a3Page.repositoriProgetto')}>
                  <Button
                    type="text"
                    icon={<ContainerOutlined />}
                    href={record.projectRepositoryLink}
                    target="_blank"
                    rel="noreferrer"
                  ></Button>
                </Tooltip>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const exportProjectList = (projectList: GetProjectListPageResponseDto[]) => {
    if (!projectList?.length) {
      message.warning(t('general.noDataFound'));
      return;
    }

    setLoadingExport(true);

    const formattedData = projectList.map((item) => ({
      projectCode: item.projectCode,
      name: item.name,
      parentProjectCode: item.parentProjectCode,
      parentName: item.parentName,
      committeeName: item.committeeName,
      divisionName: item.divisionName,
      statusDescription: item.statusDescription,
      startDate: item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : '',
      endDate: item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : '',
      teamLeaderFullName: item.teamLeaderFullName,
      teamLeaderEmailAddress: item.teamLeaderEmailAddress,
      sponsorFullName: item.sponsorFullName,
      sponsorEmailAddress: item.sponsorEmailAddress,
      teamMembersName: item.teamMembersName,
      teamMembersEmail: item.teamMembersEmail,
    }));

    const headers = [
      [
        'Codice',
        'Nome',
        'Codice Padre',
        'Nome Padre',
        'Comitato',
        'Divisione',
        'Stato',
        'Data Inizio',
        'Data Fine',
        'Team Leader',
        'Team Leader Email',
        'Sponsor',
        'Sponsor Email',
        'Nome Team Members',
        'Email Team Members',
      ],
    ];

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = t('general.progetti');

    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, formattedData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, headers);
    ws['!cols'] = Array.from({ length: 30 }, () => ({ wpx: 120 }));

    const wb = { Sheets: { Progetti: ws }, SheetNames: ['Progetti'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
    setLoadingExport(false);
  };

  type NewProjectDropdownProps = {
    disabled: boolean;
    onNew: () => void;
    onFastNew: () => void;
  };

  const optionsMenu = (props: NewProjectDropdownProps) => {
    const items = [
      {
        label: <li onClick={props.onNew}>{t('proggetiPage.aggiungiNuovoProgetto')}</li>,
        key: 1,
      },
      {
        label: <li onClick={props.onFastNew}>{t('xMatrixPage.quickAddProject')}</li>,
        key: 2,
      },
    ];
    return { items };
  };
  const NewProjectOptions = (props: NewProjectDropdownProps) => (
    <Dropdown
      key="more"
      disabled={props.disabled}
      overlayClassName="new-project-dropdown"
      menu={optionsMenu(props)}
      className="matrixComponentsPrint"
    >
      <Button
        icon={<PlusOutlined />}
        type="primary"
      >
        {t('buttons.aggiungiNuovo')}
      </Button>
    </Dropdown>
  );

  const toggleProjectsWithoutRelations = async () => {
    setShowProjectsWithoutRelations(!showProjectsWithoutRelations);
  };

  const onTreeXmatrixChange = (value: string[]) => {
    setSelectedXmatrixIds(value);
  };

  return (
    <>
      <QuickAddProgettiModal
        showQuickObProgetti={showQuickProjectModal}
        handleModalClose={handleQuickProgettiClose}
        handleSave={addQuickProject}
        loadingButton={loadingQuickSave}
      />

      <TableLayout
        title={t('headerMenu.progetti')}
        className="[&_.ant-select]:tw-h-[40px] tw-p-6 tw-flex tw-flex-col tw-h-full tw-overflow-hidden [&_.ant-select-selector]:!tw-rounded-lg"
      >
        <TableLayout.Actions>
          {/* change type of view */}
          <Dropdown
            key="more"
            placement="bottomLeft"
            menu={viewOptions}
          >
            <Button
              data-testid="setViewBtn"
              className="tw-border-0 tw-shadow-none"
            >
              <TableOutlined />
              {view === ViewType.list ? 'Lista' : capitalizeFirstLetter(view)}
              <DownOutlined />
            </Button>
          </Dropdown>

          {xmatrixPicklist.length > 0 && (
            <TreeXmatrixFilter
              selectedXmatrixIds={selectedXmatrixIds}
              xmatrixPicklist={xmatrixPicklist}
              onTreeXmatrixChange={onTreeXmatrixChange}
            />
          )}

          {view === ViewType.list && (
            <>
              <div className="tw-flex tw-items-center tw-gap-1">
                <Switch
                  loading={loadingData}
                  onClick={() => toggleProjectsWithoutRelations()}
                />
                <span className="tw-w-60">{t('proggetiPage.progettiSenzaRelazione')}</span>
              </div>

              <div className="tw-flex tw-items-center tw-gap-1">
                <Switch
                  defaultChecked={showNonOperationalProjects}
                  loading={loadingData}
                  onClick={() => toggleNonOperationalProjects()}
                />
                <span className="tw-w-60">{t('proggetiPage.showNonOperationalProjects')}</span>
              </div>
            </>
          )}

          {/* export */}
          {projectList.length > 0 && (
            <Button
              loading={loadingExport}
              onClick={() => exportProjectList(projectList)}
              icon={<DownloadOutlined />}
            >
              {t('proggetiPage.exportProjectList')}
            </Button>
          )}

          {/* search box */}
          {view === ViewType.list && (
            <Search
              value={searchByName}
              onChange={(e) => {
                setSearchByName(e.currentTarget.value.trim().toLowerCase());
              }}
              placeholder={t('general.search')}
            />
          )}

          {/* add new project button */}
          {!isRoleTeamMember && (
            <NewProjectOptions
              disabled={!activeXmatrixInfo?.xMatrixID}
              onNew={onNew}
              onFastNew={toggleCreateQuickProjectModal}
            />
          )}
        </TableLayout.Actions>
        <TableLayout.Content>
          {view === ViewType.timeline && <ProjectsTimeline selectedXmatrixIds={selectedXmatrixIds} />}

          {view === ViewType.list && (
            <Table
              sticky
              onRow={(r) => ({
                onClick: () => history.push(`/progetti/id/${r.id}`),
                className: 'tw-cursor-pointer',
              })}
              size="small"
              columns={columns}
              data-test="projects-table"
              dataSource={filteredProjectList}
              rowKey={(obj) => obj.id}
              scroll={{ x: 'calc(600px + 50%)' }}
              loading={loadingData}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          )}
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default ProjectListIndex;
