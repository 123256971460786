import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Select, Spin, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCollapseDiagonal2Fill, RiExpandDiagonal2Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import {
  type GetXMatrixPicklistResponseDto,
  type ProjectDto,
  ProjectParentship,
  ProjectStatus,
  ProjectType,
  type QueryProjectFilters,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService from 'src/services/matrix/matrixService';
import { getQueryParam } from 'src/utils/url-utils';
import DashboardService from '../../services/pages/dashboardService';
import TreeXmatrixFilter from '../shared/components/treeXmatrixFilter/treeXmatrixFilter';
import TableLayout from '../shared/tableLayout';
import './dashboard.scss';
import DashboardFiltersModal from './dashboardFiltersModal';
import ProjectCard from './projectCard';

const { Option } = Select;

interface SortField {
  label: string;
  value: string;
}

export const initialFilterState: QueryProjectFilters = {
  xMatrixIds: [],
  selectNotRelatedProjects: true,
  projectStatuses: [ProjectStatus.Active, ProjectStatus.Draft],
  projectParentship: ProjectParentship.All,
  type: ProjectType.Operational,
};

const ProjectsDashboard = () => {
  const { t } = useTranslation();
  const activeXmatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [sortField, setSortField] = useState<keyof ProjectDto>('code');
  const [sortType, setSortType] = useState<'Sort A to Z' | 'Sort Z to A'>('Sort A to Z');
  const [dashboardData, setDashboardData] = useState<ProjectDto[]>([]);
  const [sortFields] = useState<SortField[]>([
    { label: `${t('proggetiPage.nomeProgetto')}`, value: 'name' },
    { label: `${t('proggetiPage.codiceProgetto')}`, value: 'code' },
    { label: `${t('general.start')}`, value: 'startDate' },
    { label: `${t('general.end')}`, value: 'endDate' },
  ]);
  const [sortTypes] = useState<string[]>(['Sort A to Z', 'Sort Z to A']);
  const [allExpanded, setAllExpanded] = useState<boolean>(false);
  const [dashboardFilters, setDashboardFilters] = useState<QueryProjectFilters>(initialFilterState);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [xmatrixPicklist, setXmatrixPicklist] = useState<GetXMatrixPicklistResponseDto[]>([]);

  const params = useParams();

  useEffect(() => {
    void fetchXmatrixPicklist();
  }, []);

  useEffect(() => {
    const preSelectedComittie = params.committee;

    if (preSelectedComittie) {
      setDashboardFilters((prev) => ({ ...prev, commiteeIds: [preSelectedComittie] }));
    }

    const xMatrix = getQueryParam('xMatrix') || activeXmatrix?.xMatrixID;

    if (xMatrix && !dashboardFilters.xMatrixIds.length) {
      setDashboardFilters((prev) => ({ ...prev, xMatrixIds: [xMatrix] }));
    }
  }, [activeXmatrix]);

  useEffect(() => {
    void retrieveDashboardData();
  }, [dashboardFilters]);

  const fetchXmatrixPicklist = async () => {
    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success) {
      const respData = resp.responseObject?.value;
      setXmatrixPicklist(respData ?? []);
    }
  };

  const retrieveDashboardData = async () => {
    try {
      setIsLoading(true);
      const response = await DashboardService.getDashboardProjects(dashboardFilters);

      const data = response.data;
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSortedDashboard = () => {
    const tempDashboardData = [...(dashboardData || [])] as ProjectDto[];

    return tempDashboardData.sort((a, b) => {
      if (sortType === 'Sort A to Z') {
        return a && a[sortField] && b && b[sortField] ? (a[sortField] > b[sortField] ? 1 : -1) : 0;
      } else {
        return a && a[sortField] && b && b[sortField] ? (a[sortField] > b[sortField] ? -1 : 1) : 0;
      }
    });
  };

  const handleModalFiltersClose = () => {
    setIsFilterModalVisible(false);
  };

  const toggleFiltersModal = () => {
    setIsFilterModalVisible(true);
  };

  const handleToggle = () => {
    setAllExpanded(!allExpanded);
  };

  const sortedDashboardData = (dashboardData?.length || 0) > 0 ? getSortedDashboard() : [];

  return (
    <Card
      className="!tw-shadow-none projects-dashboard"
      bordered={false}
    >
      <DashboardFiltersModal
        isVisible={isFilterModalVisible}
        handleModalFiltersClose={handleModalFiltersClose}
        onChange={(filters) => {
          setDashboardFilters(filters);
        }}
        dashboardFilters={dashboardFilters}
      />

      <TableLayout
        className="[&_.ant-select]:tw-h-[38px] [&_.ant-select-selector]:!tw-rounded-lg"
        title={
          <div className="tw-flex tw-items-center tw-gap-4">
            <h2 className="tw-text-2xl tw-flex-1 tw-font-medium">{t('dashboardProgettiPage.dashboardProgetti')}</h2>
          </div>
        }
      >
        <TableLayout.Actions>


          <div className="tw-flex tw-items-center tw-gap-1">
            <Switch
              loading={isLoading}
              onClick={(checked) => {
                setDashboardFilters((prev) => ({
                  ...prev,
                  type: checked ? ProjectType.NonOperational : ProjectType.Operational,
                }));
              }}
            />
            <span className="tw-w-60">{t('proggetiPage.showNonOperationalProjects')}</span>
          </div>

          <TreeXmatrixFilter
            selectedXmatrixIds={dashboardFilters.xMatrixIds}
            xmatrixPicklist={xmatrixPicklist}
            onTreeXmatrixChange={(xmatrixIds) => {
              setDashboardFilters((prev) => ({
                ...prev,
                xMatrixIds: xmatrixIds,
              }));
            }}
          />

          <Select
            value={sortField}
            placeholder={t('dashboardProgettiPage.ordinaPer')}
            onChange={(val) => setSortField(val)}
          >
            {sortFields.map((item) => (
              <Option
                value={item.value}
                key={item.value}
              >
                {item.label}
              </Option>
            ))}
          </Select>

          <Select
            value={sortType}
            placeholder={t('dashboardProgettiPage.tipoOrdinamento')}
            onChange={(val) => setSortType(val)}
          >
            {sortTypes.map((item) => (
              <Option
                value={item}
                key={item}
              >
                {item}
              </Option>
            ))}
          </Select>

          <Button
            icon={<FilterOutlined />}
            onClick={() => toggleFiltersModal()}
          >
            {t('dashboardProgettiPage.dashboardFilter')}
          </Button>

          <Button
            icon={!allExpanded ? <RiExpandDiagonal2Fill /> : <RiCollapseDiagonal2Fill />}
            onClick={handleToggle}
          >
            {!allExpanded ? t('buttons.expandAll') : t('buttons.collapseAll')}
          </Button>
        </TableLayout.Actions>

        <TableLayout.SubHeader>
          <div className="empty:tw-hidden tw-gap-2 tw-flex tw-items-center">
            {dashboardFilters.teamLeaderIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, teamLeaderIds: [] }))}
              >
                {t('dashboardProgettiPage.teamLeader')}
              </Button>
            ) : null}

            {dashboardFilters.projectStatuses?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, projectStatuses: [] }))}
              >
                {t('dashboardProgettiPage.statoProgetto')}
              </Button>
            ) : null}

            {dashboardFilters.projectCodes?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, projectCodes: [] }))}
              >
                {t('general.projectCode')}
              </Button>
            ) : null}

            {dashboardFilters.sponsorIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, sponsorIds: [] }))}
              >
                {t('general.sponsor')}
              </Button>
            ) : null}

            {dashboardFilters.comboSemaphores?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, comboSemaphores: [] }))}
              >
                {t('dashboardProgettiPage.analisiAllarmi')}
              </Button>
            ) : null}

            {dashboardFilters.commiteeIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, commiteeIds: [] }))}
              >
                {t('general.comitato')}
              </Button>
            ) : null}

            {dashboardFilters.divisionIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, divisionIds: [] }))}
              >
                {t('dashboardProgettiPage.divisioniReparti')}
              </Button>
            ) : null}
          </div>
        </TableLayout.SubHeader>

        <TableLayout.Content>
          {!dashboardData && null}

          {isLoading ? (
            <div className="tw-flex tw-justify-center tw-w-full">
              <Spin />
            </div>
          ) : dashboardData.length ? (
            <div className="tw-grid tw-mt-4 tw-gap-x-4 tw-gap-y-6 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
              {sortedDashboardData.map((project, index) => {
                return (
                  <ProjectCard
                    project={project}
                    key={index}
                    expanded={allExpanded}
                  />
                );
              })}
            </div>
          ) : (
            <div className="tw-flex tw-justify-center tw-w-full">
              <Empty />
            </div>
          )}
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default ProjectsDashboard;
