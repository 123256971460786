import { Form } from 'antd';
import React from 'react';
import './deliverable.scss';

import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Statistic, Switch, Table, message } from 'antd';
import moment from 'moment';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import { userIsProjectTeamLeaderOrSponsorOrAdmin } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { bowlingChartRowName, kpiPuntualeAcumulato, kpiType } from '../../../shared/utils/constants';
import {
  AddSingelSpace,
  alignCurrentMonthCellOnKpiValuesGrid,
  capitalizeFirstLetter,
  checkUserIsKpiResponsabileOrProjectTM,
} from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import {
  actualYtdRow,
  currentMonthOfActualYtd,
  currentMonthOfTargetYtd,
  firstMonthOfActualYtd,
  firstMonthOfTargetYtd,
  lastMonthOfTarget,
  previousMonthOfActualYtd,
  previousMonthOfTargetYtd,
  targetYtdRow,
} from './calculations';
const { Option } = Select;

class KpiValoreFattoNonFatto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parentType: props.kpiRowData.parentType,
      jumpOffPointValue: props.kpiRowData.jumpOffPoint,
      kpiProjectRelationShipID: props.kpiRowData.kpiProjectRelationShipID,
      finalTargetValue: '',
      targetYtdValue: '',
      actualYtdValue: '',
      showInA3: props.kpiRowData.showInA3,
      definedTarget: props.kpiRowData.freezeTargetValues,
      fieldsChanged: false,
      loadingButton: false,
      kpiList: null,
      kpiData: null,
      bowlingChartData: null,
      loadingData: false,
      kpiFieldsChanged: false,
      selectedResponsabile: props.kpiRowData.responsibleID,
    };
  }

  componentDidMount() {
    this.getKpiBowlingChart();
  }

  calculateKpiTargetsAndActuals = (bowlingChartData) => {
    if (bowlingChartData) {
      this.calculateFinalTarget(this.state.jumpOffPointValue);
      //this.calculateTargetYtdRow();
    }
  };

  async getKpiBowlingChart() {
    const { projectId, kpiRowData } = this.props;
    this.setState({ loadingData: true });
    await DeliverableService.getKpiBowlingChart(kpiRowData.kpiProjectRelationShipID, projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ bowlingChartData: resp.responseObject.value }, () => {
            this.calculateKpiTargetsAndActuals(this.state.bowlingChartData);
            this.calculateTargetYTD(this.state.bowlingChartData);
            this.calculateActualYTD(this.state.bowlingChartData);
          });
          this.setState({ loadingData: false });

          alignCurrentMonthCellOnKpiValuesGrid();
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  onConfirm = () => {
    const finalTarget = 1;
    const {
      bowlingChartData,
      jumpOffPointValue,
      kpiProjectRelationShipID,
      showInA3,
      definedTarget,
      selectedResponsabile,
    } = this.state;
    const { calculationType } = this.props;
    this.props.onSave(
      bowlingChartData,
      jumpOffPointValue,
      kpiProjectRelationShipID,
      calculationType,
      finalTarget,
      showInA3,
      definedTarget,
      selectedResponsabile,
    );
  };

  renderTargetColumns = (tableData) => {
    const { kpiRowData } = this.props;
    const { definedTarget } = this.state;

    if (tableData && tableData.length > 0) {
      const columns = [];

      const firstRow = tableData[0];

      for (const col in firstRow) {
        if (col === 'Name') {
          columns.push({
            dataIndex: 'Name',
            title: '',
            //width: 10,
            render: (text) => AddSingelSpace(text, 6).join(' '),
          });
        } else {
          const currentMonth = moment().format('YYYY-MM');
          const month = moment(col).format('MMM YYYY');
          columns.push({
            dataIndex: col,
            className: col === currentMonth ? 'currentMonthBorderLineIndicatorTarget' : '',
            //width: 20,
            // title: moment(col).format("MMM YYYY"),
            title: capitalizeFirstLetter(month),

            render: (text, record, index) => {
              if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                return record.Name === bowlingChartRowName.target ? (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetChangePuntuale(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                  />
                ) : (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetYtdChange(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={true}
                  />
                );
              } else {
                return record.Name === bowlingChartRowName.target ? (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetChange(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                  />
                ) : (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetYtdChange(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                  />
                );
              }
            },
          });
        }
      }

      return columns;
    }
  };

  renderActualColumns = (tableData) => {
    const { kpiRowData } = this.props;
    if (tableData) {
      const columns = [];

      if (tableData.length > 0) {
        const firstRow = tableData[0];

        for (const col in firstRow) {
          if (col === 'Name') {
            columns.push({
              dataIndex: 'Name',
              title: '',
              //width: 10,
              render: (text) => AddSingelSpace(text, 6).join(' '),
            });
          } else {
            const currentMonth = moment().format('YYYY-MM');
            const month = moment(col).format('MMM YYYY');
            columns.push({
              className: col === currentMonth ? 'currentMonthBorderLineIndicatorActual' : '',
              dataIndex: col,
              title: capitalizeFirstLetter(month),
              render: (text, record, index) => {
                if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                  return record.Name === bowlingChartRowName.actual ? (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualChangePuntuale(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={kpiRowData.isRollupKPI}
                    />
                  ) : (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualYtdChange(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={true}
                    />
                  );
                } else {
                  return record.Name === bowlingChartRowName.actual ? (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualChange(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={kpiRowData.isRollupKPI}
                    />
                  ) : (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualYtdChange(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={kpiRowData.isRollupKPI}
                    />
                  );
                }
              },
            });
          }
        }
      }

      return columns;
    }
  };

  onTargetChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    const { companyData, kpiRowData } = this.props;
    this.setState({ kpiFieldsChanged: true });

    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
    const isResetGlobalKPI =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;
    const selectedYear = moment(selectedMonth).year();

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in targetRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          targetRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            // do nothing
          } else {
            targetRow[key] = newValue ? 1 : 1;
          }
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            // do nothing
          } else {
            targetRow[key] = 0;
          }
        }
      }
    }
    this.setState(bowlingChartData);
  };

  onTargetChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    targetRow[selectedMonth] = newValue ? 1 : 0;
    this.setState(bowlingChartData);
  };

  onTargetYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in targetYtdRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          targetYtdRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          targetYtdRow[key] = newValue ? 1 : 1;
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          targetYtdRow[key] = 0;
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateTargetYTD();
    });
  };

  onActualChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    const { companyData, kpiRowData } = this.props;
    this.setState({ kpiFieldsChanged: true });

    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    const isResetGlobalKPI =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;
    const selectedYear = moment(selectedMonth).year();

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in actualRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          actualRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            // do nothing
          } else {
            actualRow[key] = newValue ? 1 : 1;
          }
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            // do nothing
          } else {
            actualRow[key] = 0;
          }
        }
      }
    }
    this.setState(bowlingChartData);
  };

  onActualChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    this.setState({ kpiFieldsChanged: true });

    const { bowlingChartData } = this.state;
    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    actualRow[selectedMonth] = newValue ? 1 : 0;
    this.setState(bowlingChartData);
  };

  onActualYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in actualYtdRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          actualYtdRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          actualYtdRow[key] = newValue ? 1 : 1;
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          actualYtdRow[key] = 0;
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  handleShowInA3Change = (value) => {
    this.setState({ kpiFieldsChanged: true });
    this.setState({ showInA3: value });
  };

  calculateFinalTarget = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      const lastMonth = lastMonthOfTarget(bowlingChartData);
      this.setState({ finalTargetValue: lastMonth.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      // let targetTotalMonths = getTotalTargetMonths(bowlingChartData)
      // this.setState({ finalTargetValue: (jumpOffPointValue + targetTotalMonths) });

      const lastMonth = lastMonthOfTarget(bowlingChartData);
      this.setState({ finalTargetValue: lastMonth.monthValue });
    } else {
      return;
    }
  };

  calculateTargetYTD = () => {
    const { bowlingChartData } = this.state;
    const currentMonth = moment().format('YYYY-MM');

    const previousMonth = currentMonthOfTargetYtd(bowlingChartData, currentMonth);

    this.setState({ targetYtdValue: previousMonth.monthValue });
  };

  calculateActualYTD = () => {
    const { bowlingChartData } = this.state;
    const currentMonth = moment().format('YYYY-MM');

    const previousMonth = currentMonthOfActualYtd(bowlingChartData, currentMonth);

    this.setState({ actualYtdValue: previousMonth.monthValue });
  };

  calculateTargetYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    const targetYtdFirstMonth = firstMonthOfTargetYtd(bowlingChartData);

    const targetYtdRowData = targetYtdRow(bowlingChartData);

    for (const key in targetYtdRowData) {
      if (key !== 'Name') {
        if (key === targetYtdFirstMonth.monthName) {
          const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
          const targetValue = targetRow[key];

          const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
          targetYtdRow[key] = jumpOffPointValue + targetValue;
        } else {
          const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
          const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];

          const previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
          const previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];

          const targetValue = targetRow[key];

          targetYtdRow[key] = previousTargetYtdMonthValue + targetValue;
        }
      }
    }
  };

  calculateActualYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    const actualYtdFirstMonth = firstMonthOfActualYtd(bowlingChartData);

    const actualYtdRowData = actualYtdRow(bowlingChartData);

    for (const key in actualYtdRowData) {
      if (key !== 'Name') {
        if (key === actualYtdFirstMonth.monthName) {
          const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
          const actualValue = actualRow[key];

          const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
          actualYtdRow[key] = jumpOffPointValue + actualValue;
        } else {
          const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
          const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];

          const previousActualYtdMonth = previousMonthOfActualYtd(bowlingChartData, key);
          const previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];

          const actualValue = actualRow[key];

          actualYtdRow[key] = previousActualYtdMonthValue + actualValue;
        }
      }
    }
  };

  handleDefinedTargetChange = (value) => {
    this.setState({ kpiFieldsChanged: true });
    this.setState({ definedTarget: value });
  };

  handleResponsabileSelect = (option) => {
    this.setState({ kpiFieldsChanged: true });
    if (option) {
      this.setState({ selectedResponsabile: option });
    } else {
      this.setState({ selectedResponsabile: null });
    }
  };

  render() {
    const { loadingButton, kpiRowData, t, projectTeamMembers, userData, currentProject } = this.props;
    const { bowlingChartData, loadingData, kpiFieldsChanged, definedTarget, selectedResponsabile } = this.state;

    let targetRow = [];
    let actualRow = [];

    if (bowlingChartData) {
      targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
      actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    }

    return (
      <>
        {bowlingChartData && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="button-wrapper">
                {checkUserIsKpiResponsabileOrProjectTM(
                  kpiRowData.responsibleID,
                  userData,
                  currentProject.teamLeaderID,
                  currentProject.sponsorID,
                ) && (
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      onClick={() => this.onConfirm()}
                      icon={<SaveOutlined />}
                      disabled={!targetRow || !actualRow || !kpiFieldsChanged}
                    >
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                )}
              </div>
            </Col>
          </Row>
        )}

        <Row
          gutter={{ lg: 24 }}
          className="row-margin"
        >
          {kpiRowData.parentType !== kpiPuntualeAcumulato.puntuale && (
            <>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 5 }}
              >
                {this.state.finalTargetValue === 1 ? (
                  <Statistic
                    title={t('kpiPage.finalTarget')}
                    prefix={<CheckOutlined />}
                    value={t('kpiPage.fatto')}
                  />
                ) : (
                  <Statistic
                    title={t('kpiPage.finalTarget')}
                    prefix={<CloseOutlined />}
                    value={t('kpiPage.nonFatto')}
                  />
                )}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 4 }}
              >
                {this.state.targetYtdValue ? (
                  <Statistic
                    title={t('kpiPage.targetYearDate')}
                    prefix={<CheckOutlined />}
                    value={t('kpiPage.fatto')}
                  />
                ) : (
                  <Statistic
                    title={t('kpiPage.targetYearDate')}
                    prefix={<CloseOutlined />}
                    value={t('kpiPage.nonFatto')}
                  />
                )}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 4 }}
              >
                {this.state.actualYtdValue ? (
                  <Statistic
                    title={t('kpiPage.actualYearDate')}
                    prefix={<CheckOutlined />}
                    value={t('kpiPage.fatto')}
                  />
                ) : (
                  <Statistic
                    title={t('kpiPage.actualYearDate')}
                    prefix={<CloseOutlined />}
                    value={t('kpiPage.nonFatto')}
                  />
                )}
              </Col>
            </>
          )}

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 3 }}
          >
            <Form.Item label={t('kpiPage.showInA3')}>
              <Switch
                defaultChecked={this.state.showInA3}
                onChange={(value) => this.handleShowInA3Change(value)}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 3 }}
          >
            <Form.Item label={t('kpiPage.definedTarget')}>
              <Switch
                defaultChecked={definedTarget}
                onChange={(value) => this.handleDefinedTargetChange(value)}
              />
            </Form.Item>
          </Col>

          {kpiRowData.kpiType === kpiType.specifico && (
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 4 }}
            >
              <Form.Item
                className="main-container"
                label={t('general.responsabile')}
              >
                <Select
                  showSearch
                  value={selectedResponsabile}
                  placeholder={t('general.seleziona')}
                  allowClear
                  style={{ width: '100%' }}
                  onChange={(value) => this.handleResponsabileSelect(value)}
                  options={projectTeamMembers}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={!projectTeamMembers}
                  disabled={
                    !userIsProjectTeamLeaderOrSponsorOrAdmin(
                      userData,
                      currentProject.teamLeaderID,
                      currentProject.sponsorID,
                    )
                  }
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {bowlingChartData && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderTargetColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Target';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Target';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    loading={loadingData}
                    scroll={{ x: 'max-content' }}
                  />
                </div>
              </Col>
            </Row>

            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderActualColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Actual';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Actual';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    loading={loadingData}
                    scroll={{ x: 'max-content' }}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        {bowlingChartData && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="button-wrapper">
                {/* {checkUserIsKpiResponsabile(kpiRowData.responsibleID, userData) && ( */}
                {checkUserIsKpiResponsabileOrProjectTM(
                  kpiRowData.responsibleID,
                  userData,
                  currentProject.teamLeaderID,
                  currentProject.sponsorID,
                ) && (
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      onClick={() => this.onConfirm()}
                      icon={<SaveOutlined />}
                      disabled={!targetRow || !actualRow || !kpiFieldsChanged}
                    >
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                )}
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default KpiValoreFattoNonFatto;
