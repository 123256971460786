import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { DatePicker as AntDatePicker, Card, Col, Input, InputNumber, Modal, Row, Select, TimePicker } from 'antd';
import moment from 'moment';
import CommonService from '../../../../services/commonService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import {
  formItemLayout,
  formatOfDate,
  formatOfHHMM,
  sessionStatus,
  statusPickListCodes,
} from '../../../shared/utils/constants';
import { handleDateConvert, handleTimeConvert } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';

const { Option } = Select;

const NewSessionModal = ({
  loadingSave,
  showModal,
  handleModalClose,
  t,
  saveNewSession,
  committeeID,
  xMatrixID,
  comitieDetails,
  sessions,
}) => {
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [sessionStatusList, setSessionStatusList] = useState(null);

  useEffect(() => {
    retrieveStatus();
  }, []);

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.sessionStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setSessionStatusList(respData);
          // const updatedStatusArray = respData.map(status => {
          // if (status.statusDescription === 'Suspended') {
          //     return { ...status, statusDescription: 'Cancelled' };
          // }
          // return status;
          // });
          // setSessionStatusList(updatedStatusArray)
        } else {
        }
      })
      .catch((error) => {});
  };

  function findMaxProgressiveNumber(sessions) {
    let maxProgressiveNumber = 0;

    sessions.forEach((session) => {
      const codice = session.codice;

      if (codice) {
        const regex = /ID (\d+)/;
        const match = codice.match(regex);

        if (match && match[1]) {
          const progressiveNumber = Number.parseInt(match[1], 10);

          if (!isNaN(progressiveNumber) && progressiveNumber > maxProgressiveNumber) {
            maxProgressiveNumber = progressiveNumber;
          }
        }
      }
    });

    return maxProgressiveNumber + 1;
  }

  const saveData = (values) => {
    values.date = handleDateConvert(values.date);
    values.startTime = handleTimeConvert(values.startTime);
    // values.startTime = handleTimeConvert(convertSelectedTimeToMoment(values.startTime))
    values['endTime'] = handleTimeConvert(endTime);
    values['committeeID'] = committeeID;
    values['sessionID'] = null;
    values['XMatrixID'] = xMatrixID;
    saveNewSession(values);
  };

  const onDurationChange = (value, startTime) => {
    if (value && startTime) {
      const endTimePick = moment(startTime, formatOfHHMM).add(value, 'hours');
      setEndTime(endTimePick);
      setStartTime(startTime);
      setDuration(value);
    } else if (value && !startTime) {
      setDuration(value);
    } else {
      return;
    }
  };

  const ontmch = (option, duration) => {
    const cc = option.getTime();
    const bb = moment(option);
  };

  const onStartTimeChange = (value, duration) => {
    if (value && duration) {
      const endTimePick = moment(value, formatOfHHMM).add(duration, 'hours');
      setEndTime(endTimePick);
      setStartTime(value);
      setDuration(duration);
    } else if (value && !duration) {
      setEndTime(value);
      setStartTime(value);
    } else {
      return;
    }
  };

  const convertSelectedTimeToMoment = (selectedTime) => {
    const currentDate = moment().format('YYYY-MM-DD');
    const concatDateAndTime = currentDate + ' ' + selectedTime;
    const date = moment(concatDateAndTime);
    return date;
  };

  return (
    <>
      <Modal
        width={800}
        title={t('comitatiPage.newSession')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingSave}
            modalClose={handleModalClose}
            formId={'newSession'}
            key="newSession"
          />,
        ]}
      >
        <Card>
          <Form
            id="newSession"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => saveData(values)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  label={t('proggetiPage.codice')}
                  name="codice"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={`${comitieDetails.name} ID ${findMaxProgressiveNumber(sessions)}`}
                >
                  <Input value={`${comitieDetails.name} ID ${findMaxProgressiveNumber(sessions)}`} />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label={t('comitatiPage.date')}
                  name="date"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <AntDatePicker
                    style={{ width: '100%' }}
                    format={formatOfDate}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  labelAlign="left"
                  label={t('general.stato')}
                  name="state"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={sessionStatus.toBeConfirmed}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {sessionStatusList &&
                      sessionStatusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}
                        >
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  label={t('comitatiPage.startTime')}
                  name="startTime"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <TimePicker
                    placeholder={t('comitatiPage.startTime')}
                    // minuteStep={15}
                    style={{ width: '100%' }}
                    format={formatOfHHMM}
                    value={startTime}
                    onChange={(value) => onStartTimeChange(value, duration)}
                  />

                  {/* <DatePicker
                                    id='l'
                                        // selected={startTime}
                                        onChange={(date) => ontmch(date, duration)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                    /> */}

                  {/* <Select
                                        value={startTime}
                                        onChange={(option) => onStartTimeChange(option, duration)}
                                        options={[
                                            {
                                                label: '10:00',
                                                value: '10:00',       
                                            },
                                            {
                                                label: '10:15',
                                                value: '10:15',
                                            },
                                            {
                                                label: '10:30',
                                                value: '10:30',
                                            },
                                            {
                                                label: '10:45',
                                                value: '10:45',
                                            }
                                        
                                        ]}
                                    /> */}
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  label={t('proggetiPage.durata') + ' (h/min)'}
                  name="duration"
                  initialValue="0"
                  // rules={[
                  //     { required: true, message: requiredFields.required }
                  // ]}
                >
                  <InputNumber
                    disabled={true}
                    value={duration}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item label={t('comitatiPage.endTime')}>
                  <TimePicker
                    value={endTime}
                    placeholder={t('comitatiPage.endTime')}
                    style={{ width: '100%' }}
                    format={formatOfHHMM}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </>
  );
};
export default NewSessionModal;
