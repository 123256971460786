import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../../services/commonService';
import ActivityService from '../../../services/pages/activitiesServices';
import '../../projects/project.scss';
import Loader from '../../shared/components/loader/loader';
import { activityStatus, formatOfDate, statusPickListCodes } from '../../shared/utils/constants';
import { ResponsiblePopoverContent, projectTypeColumnValues } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import NewActivity from './newActivity';
import UpdateActivity from './updateActivity';

const { Text } = Typography;

class DeliverableActivities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingActivities: false,
      deliverableActivityList: null,
      deliverableActivityListFiltered: null,
      showEditModal: false,
      showCreateModal: false,
      modalData: null,
      startDate: null,
      endDate: null,
      clearFilters: false,
      statusListFilter: [],
      // filteredInfo: ["Draft", "Attivo"]
      filteredInfo: [`${props.t('status.draft')}`, `${props.t('status.attivo')}`],
    };
  }

  componentWillMount() {
    this.retrieveDeliverableActivityList();
    this.retrieveStatusList();
  }

  async retrieveStatusList() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          this.setState({ statusListFilter: obj });
        } else {
        }
      })
      .catch((error) => {});
  }

  componentWillUnmount() {
    this.setState({ loadingActivities: false });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)}>Reset</Button>
          {/* <Button
                    type="link"
                    
                    onClick={() => {
                      confirm({ closeDropdown: false });
                      this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                      });
                    }}
                  >
                    Filter
                  </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   }
    // },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  toggleEditAtivityModal = (rowData) => {
    this.setState({ showEditModal: true });
    this.setState({ modalData: rowData });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
    this.setState({ loadingButton: false });
  };

  handleUpdateActivity = (values, activityID, deliverableId, projectId) => {
    values['activityID'] = activityID;
    values['deliverableId'] = deliverableId;
    values['projectId'] = projectId;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveDeliverableActivityList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleCreateAtivityModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleCreateModalClose = () => {
    this.setState({ showCreateModal: false });
    this.setState({ loadingButton: false });
  };

  handleSaveActivity = (values, deliverableId) => {
    values['deliverableID'] = deliverableId;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    this.setState({ loadingButton: true });
    ActivityService.insertActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleCreateModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveDeliverableActivityList();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch(() => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  async retrieveDeliverableActivityList() {
    const { startDate, endDate, clearFilters } = this.state;
    const { deliverableId } = this.props;

    let formatedStartDate = this.handleDateChange(startDate);
    let formatedEndDate = this.handleDateChange(endDate);

    if (clearFilters) {
      this.setState({ startDate: null });
      this.setState({ endDate: null });
      formatedStartDate = null;
      formatedEndDate = null;
    }

    this.setState({ loadingActivities: true });
    await ActivityService.getDeliverableActivities(deliverableId, formatedStartDate, formatedEndDate)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          this.setState({ deliverableActivityList: respData });
          this.setState({ deliverableActivityListFiltered: respData });
          this.setState({ loadingActivities: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingActivities: false });
        }
      })
      .catch(() => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingActivities: false });
      });
  }

  handleDateChange(date) {
    if (date) {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.retrieveDeliverableActivityList();
    });
  };

  onClearFilter = () => {
    this.setState({ clearFilters: true }, () => {
      this.retrieveDeliverableActivityList();
    });
  };

  onRemoveActivity = (id) => {
    ActivityService.removeActivity(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveDeliverableActivityList();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  dateFilters = () => {
    const { startDate, endDate } = this.state;
    const { t } = this.props;
    return (
      <div className="tw-flex tw-items-center tw-gap-4">
        <DatePicker
          placeholder={t('general.start')}
          value={startDate}
          format="DD/MM/YYYY"
          onChange={(date) => this.onStartDateChange(date)}
        />

        <DatePicker
          placeholder={t('general.end')}
          value={endDate}
          format="DD/MM/YYYY"
          onChange={(date) => this.onEndDateChange(date)}
        />

        <div className="tw-flex tw-gap-2 tw-items-center">
          <Tooltip title={t('general.filter')}>
            <Button
              icon={<FilterOutlined />}
              onClick={() => this.onFilter()}
              disabled={!startDate && !endDate}
            />
          </Tooltip>
          <Tooltip title={t('general.clearFilters')}>
            <Button
              icon={<MinusSquareOutlined />}
              onClick={() => this.onClearFilter()}
            />
          </Tooltip>
        </div>

        <Space direction="horizontal">
          <Button onClick={() => this.filterActivities('activities')}>{t('proggetiPage.attivitaFilter')}</Button>
          <Button onClick={() => this.filterActivities('prs')}>{t('proggetiPage.prsFilter')}</Button>
          <Button onClick={() => this.filterActivities('all')}>{t('proggetiPage.allFilter')}</Button>
        </Space>
      </div>
    );
  };

  filterActivities = (type) => {
    const { deliverableActivityList } = this.state;
    if (type === 'activities') {
      const filteredData =
        deliverableActivityList && deliverableActivityList.filter((obj) => obj.activityType === 'activity');
      this.setState({ deliverableActivityListFiltered: filteredData });
    } else if (type === 'prs') {
      const filteredData =
        deliverableActivityList &&
        deliverableActivityList.filter(
          (obj) => obj.activityType === 'deliverable' || obj.activityType === 'macroActivity',
        );
      this.setState({ deliverableActivityListFiltered: filteredData });
    } else {
      this.setState({ deliverableActivityListFiltered: deliverableActivityList });
    }
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters.statusDescription,
    });
  };

  handleFastCloseActivity = (record) => {
    this.setState({ loadingButton: true });
    ActivityService.updateActivity({
      activityID: record.activityID,
      deliverableID: record.deliverableID,
      description: record.description,
      endDate: this.handleDateChange(record.endDate),
      ownerID: record.ownerID,
      projectID: record.projectID,
      startDate: this.handleDateChange(record.startDate),
      status: activityStatus.closed,
      subject: record.subject,
    })
      .then(({ data }) => {
        if (data.success) {
          // this.handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveDeliverableActivityList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  render() {
    const {
      deliverableActivityList,
      deliverableActivityListFiltered,
      loadingActivities,
      loadingButton,
      showCreateModal,
      showEditModal,
      modalData,
      statusListFilter,
      filteredInfo,
    } = this.state;
    const { deliverableId, deliverableName, deliverableData, t } = this.props;

    const deliverableActivitiesColumns = [
      {
        title: `${t('general.tipo')}`,
        dataIndex: 'activityType',
        width: '10%',
        showSorterTooltip: [false],
        render(title, record) {
          return <Text>{projectTypeColumnValues(record, t)}</Text>;
        },
      },
      {
        title: `${t('general.descrizione')}`,
        dataIndex: 'subject',
        showSorterTooltip: [true],
        sorter: (a, b) => {
          return a.subject.localeCompare(b.subject);
        },
      },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endDate',
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        // filters: statusListFilter,
        // filteredValue: filteredInfo || null,
        // onFilter: (value, record) => record.statusDescription ? record.statusDescription.includes(value) : '',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        filters: statusListFilter,
        filteredValue: filteredInfo || null,
        onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
        render(text, record) {
          return {
            children: <Tag>{text}</Tag>,
          };
        },
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'fullName',
        render: (title, record) => (
          <Popover
            content={
              <ResponsiblePopoverContent
                data={record}
                t={t}
              />
            }
            title={title}
          >
            <span>{title}</span>
          </Popover>
        ),
      },
      {
        title: `${t('general.dataChiusura')}`,
        dataIndex: 'actualCloseDate',
        render: (text) => (text ? moment(text).format(formatOfDate) : '-'),
      },
      {
        key: 'action',
        width: '30px',
        render: (_, record) => <EditOutlined onClick={() => this.toggleEditAtivityModal(record)} />,
      },
      {
        key: 'action',
        width: '30px',
        render: (_, record) => {
          const isClosed = record.status === activityStatus.closed ? true : false;
          if (isClosed) {
            return;
          } else {
            return (
              <Popconfirm
                title={t('buttons.rimuoveAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => this.onRemoveActivity(record.activityID)}
              >
                <DeleteOutlined />
              </Popconfirm>
            );
          }
        },
      },
      {
        key: 'action',
        width: '30px',
        render: (_, record) => {
          const quickClose =
            record.status === activityStatus.active || record.status === activityStatus.draft ? true : false;
          if (!quickClose) {
            return;
          } else {
            return (
              <Popconfirm
                key="close"
                placement="topRight"
                title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => this.handleFastCloseActivity(record)}
              >
                <Tooltip title={t('buttons.chiusuraRapida')}>
                  <CheckOutlined />
                </Tooltip>
              </Popconfirm>
            );
          }
        },
      },
    ];

    return (
      <>
        {showCreateModal && (
          <NewActivity
            showCreateModal={showCreateModal}
            handleCreateModalClose={this.handleCreateModalClose}
            handleSaveActivity={this.handleSaveActivity}
            loadingButton={loadingButton}
            deliverableId={deliverableId}
            deliverableName={deliverableName}
            deliverableData={deliverableData}
            t={t}
          />
        )}

        {showEditModal && (
          <UpdateActivity
            showEditModal={showEditModal}
            handleEditModalClose={this.handleEditModalClose}
            handleUpdateActivity={this.handleUpdateActivity}
            loadingButton={loadingButton}
            deliverableId={deliverableId}
            modalData={modalData}
            deliverableData={deliverableData}
            t={t}
          />
        )}

        <Row>
          <Col xs={{ span: 24 }}>
            <Card
              className="!tw-shadow-none"
              styles={{ header: { padding: 0 }, body: { padding: 0 } }}
              bordered={false}
              title={this.dateFilters()}
              extra={
                <Button
                  data-testid="add_deliverable_todo_btn"
                  type="primary"
                  onClick={() => this.toggleCreateAtivityModal()}
                  icon={<PlusOutlined />}
                >
                  {t('buttons.aggiungiNuovo')}
                </Button>
              }
            >
              {loadingActivities && <Loader />}
              <Table
                columns={deliverableActivitiesColumns}
                dataSource={deliverableActivityListFiltered}
                rowKey={(obj) => obj.activityID}
                onChange={this.handleChange}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(DeliverableActivities));
