import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import './loader.scss';

export const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    promiseInProgress && (
      <div className="tw-size-full tw-flex tw-justify-center tw-items-center">
        <Spin size="large" />
      </div>
    )
  );
};

export default Loader;
