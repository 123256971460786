import moment from 'moment';
import {
  projectCostColumNames,
  projectWorkloadColumNames,
  workloadTotaleProgetto,
} from '../../../shared/utils/constants';

export const getTotalBudgetProgettoYTD = (totalProjectWorkloadData) => {
  let totalMonths = 0;
  if (totalProjectWorkloadData && totalProjectWorkloadData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const budgetProgetto = totalProjectWorkloadData.filter((obj) => {
      return obj.Name === workloadTotaleProgetto.budgetProgetto;
    })[0];

    for (const item in budgetProgetto) {
      if (
        item !== projectWorkloadColumNames.name &&
        item !== projectWorkloadColumNames.fullName &&
        item !== projectWorkloadColumNames.projectUserRelationshipID &&
        item !== projectWorkloadColumNames.total &&
        item !== projectWorkloadColumNames.type &&
        item !== projectWorkloadColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += budgetProgetto[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};

export const getTotalAllocatoProgettoYTD = (totalProjectWorkloadData) => {
  let totalMonths = 0;

  if (totalProjectWorkloadData && totalProjectWorkloadData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const allocatoProgetto = totalProjectWorkloadData.filter((obj) => {
      return obj.Name === workloadTotaleProgetto.allocatoProgetto;
    })[0];

    for (const item in allocatoProgetto) {
      if (
        item !== projectWorkloadColumNames.name &&
        item !== projectWorkloadColumNames.fullName &&
        item !== projectWorkloadColumNames.projectUserRelationshipID &&
        item !== projectWorkloadColumNames.total &&
        item !== projectWorkloadColumNames.type &&
        item !== projectWorkloadColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += allocatoProgetto[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};

export const getTotalNonAllocatoProgettoYTD = (totalProjectWorkloadData) => {
  let totalMonths = 0;

  if (totalProjectWorkloadData && totalProjectWorkloadData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const nonAllocatoProgetto = totalProjectWorkloadData.filter((obj) => {
      return obj.Name === workloadTotaleProgetto.nonAllocato;
    })[0];

    for (const item in nonAllocatoProgetto) {
      if (
        item !== projectWorkloadColumNames.name &&
        item !== projectWorkloadColumNames.fullName &&
        item !== projectWorkloadColumNames.projectUserRelationshipID &&
        item !== projectWorkloadColumNames.total &&
        item !== projectWorkloadColumNames.type &&
        item !== projectWorkloadColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += nonAllocatoProgetto[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};

export const getTotalConsutivoYTD = (totalProjectWorkloadData) => {
  let totalMonths = 0;

  if (totalProjectWorkloadData && totalProjectWorkloadData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const consutivo = totalProjectWorkloadData.filter((obj) => {
      return obj.Name === workloadTotaleProgetto.consuntivo;
    })[0];

    for (const item in consutivo) {
      if (
        item !== projectWorkloadColumNames.name &&
        item !== projectWorkloadColumNames.fullName &&
        item !== projectWorkloadColumNames.projectUserRelationshipID &&
        item !== projectWorkloadColumNames.total &&
        item !== projectWorkloadColumNames.type &&
        item !== projectWorkloadColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += consutivo[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};

export const getTotalCostBudgetProgettoYTD = (totalProjectCostData) => {
  let totalMonths = 0;
  if (totalProjectCostData && totalProjectCostData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const budgetProgetto = totalProjectCostData.filter((obj) => {
      return obj.Name === projectCostColumNames.budgetProgetto;
    })[0];

    for (const item in budgetProgetto) {
      if (
        item !== projectCostColumNames.name &&
        item !== projectCostColumNames.fullName &&
        item !== projectCostColumNames.projectID &&
        item !== projectCostColumNames.total &&
        item !== projectCostColumNames.type &&
        item !== projectCostColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += budgetProgetto[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};

export const getTotalCostAllocatoProgettoYTD = (totalProjectCostData) => {
  let totalMonths = 0;

  if (totalProjectCostData && totalProjectCostData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const allocatoProgetto = totalProjectCostData.filter((obj) => {
      return obj.Name === projectCostColumNames.allocatoProgetto;
    })[0];

    for (const item in allocatoProgetto) {
      if (
        item !== projectCostColumNames.name &&
        item !== projectCostColumNames.fullName &&
        item !== projectCostColumNames.projectID &&
        item !== projectCostColumNames.total &&
        item !== projectCostColumNames.type &&
        item !== projectCostColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += allocatoProgetto[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};

export const getTotalCostNonAllocatoProgettoYTD = (totalProjectCostData) => {
  let totalMonths = 0;

  if (totalProjectCostData && totalProjectCostData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const nonAllocatoProgetto = totalProjectCostData.filter((obj) => {
      return obj.Name === projectCostColumNames.nonAllocato;
    })[0];

    for (const item in nonAllocatoProgetto) {
      if (
        item !== projectCostColumNames.name &&
        item !== projectCostColumNames.fullName &&
        item !== projectCostColumNames.projectID &&
        item !== projectCostColumNames.total &&
        item !== projectCostColumNames.type &&
        item !== projectCostColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += nonAllocatoProgetto[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};

export const getTotalCostConsutivoYTD = (totalProjectCostData) => {
  let totalMonths = 0;

  if (totalProjectCostData && totalProjectCostData.length > 0) {
    const currentMonth = moment().format('YYYY-MM');

    const consutivo = totalProjectCostData.filter((obj) => {
      return obj.Name === projectCostColumNames.consuntivo;
    })[0];

    for (const item in consutivo) {
      if (
        item !== projectCostColumNames.name &&
        item !== projectCostColumNames.fullName &&
        item !== projectCostColumNames.projectID &&
        item !== projectCostColumNames.total &&
        item !== projectCostColumNames.type &&
        item !== projectCostColumNames.key
      ) {
        const formatItem = moment(item, 'YYYY-MM');
        if (formatItem <= moment(currentMonth, 'YYYY-MM')) {
          totalMonths += consutivo[item];
        }
      }
    }
    return totalMonths;
  }
  return totalMonths;
};
