import { type FC, useState } from 'react';

import { Form, Input, Modal } from 'antd';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import type { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import type { CreateProjectRequest } from 'src/config/connectors';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

type FormData = {
  code: CreateProjectRequest['code'];
  name: CreateProjectRequest['name'];
};

const QuickAddProgettiModal: FC<{
  showQuickObProgetti: boolean;
  handleModalClose: () => void;
  handleSave: (values: FormData) => Promise<void>;
  loadingButton: boolean;
}> = ({ showQuickObProgetti, handleModalClose, handleSave, loadingButton }) => {
  const { t } = useTranslation();
  const [fieldsChanged, setFieldsChanged] = useState(false);

  return (
    <Modal
      className="add_ob_modal"
      width={800}
      title={t('xMatrixPage.quickAddProject')}
      open={showQuickObProgetti}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleModalClose}
      footer={[
        <ModalFooterButtons
          key={'modalButtons'}
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleModalClose}
          formId={'newProgettoXmatrix'}
        />,
      ]}
    >
      <Form<FormData>
        id="newProgettoXmatrix"
        name="add_ob_project"
        layout="vertical"
        {...formItemLayout}
        onFinish={handleSave}
        onValuesChange={() => {
          setFieldsChanged(true);
        }}
      >
        <Form.Item<FormData>
          label={t('proggetiPage.nomeProgetto')}
          name="name"
          rules={[{ required: true, message: requiredFields.required }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FormData>
          extra={t('proggetiPage.codiceLungeza')}
          label={t('proggetiPage.codiceProgetto')}
          name="code"
          rules={[
            {
              required: true,
              message: requiredFields.required,
            },
            { max: 10, message: `${t('proggetiPage.codiceLungeza')}` },
            {
              validator: async (_: Rule, value: string, callback: (error?: string) => void) => {
                try {
                  const { data } = await ProgettiService.checkIfProjectCodeExist(value);
                  if (data.success && data.responseObject?.value) {
                    callback(requiredFields.validateProjectCode);

                    return;
                  }
                } catch {}

                callback();
              },
            },
          ]}
        >
          <Input maxLength={10} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QuickAddProgettiModal;
