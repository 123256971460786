import { CheckOutlined, ClockCircleOutlined, CloseOutlined, EuroOutlined, LineChartOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Radio, Row, Select, Space, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialFilterState } from 'src/components/dashboard/projects-dashboard';
import { objectCodes } from 'src/components/shared/utils/constants';
import { ProjectParentship, ProjectSemaphore, ProjectStatus, type QueryProjectFilters } from 'src/connectors/backend';
import CommonService from 'src/services/commonService';
import DashboardService from 'src/services/pages/dashboardService';
import './dashboard.scss';

const { Option } = Select;

interface OptionType {
  value: string | number;
  label: string;
  key?: string;
  disabled?: boolean;
}

interface PickLists {
  sponsorList?: Array<OptionType> | null;
  teamLeaderList?: Array<OptionType> | null;
  projectStatusesList?: Array<OptionType> | null;
  projectCodeList?: Array<OptionType> | null;
  divisionList?: Array<OptionType> | null;
  committeeList?: Array<OptionType> | null;
  semaphore?: Array<OptionType> | null;
  parentShip?: Array<OptionType> | null;
}

interface DashboardFiltersModalProps {
  isVisible: boolean;
  dashboardFilters: QueryProjectFilters;
  handleModalFiltersClose: () => void;
  onChange: (filters: QueryProjectFilters) => void;
}

const DashboardFiltersModal: React.FC<DashboardFiltersModalProps> = ({
  isVisible,
  dashboardFilters,
  onChange,
  handleModalFiltersClose,
}) => {
  const { t } = useTranslation();

  const [filterIsChanged, setFilterIsChanged] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState<QueryProjectFilters>({
    xMatrixIds: [],
    projectParentship: ProjectParentship.All,
    selectNotRelatedProjects: false,
  });

  const [filters, setFilters] = useState<PickLists>({
    sponsorList: [],
    teamLeaderList: [],
    projectStatusesList: [],
    projectCodeList: [],
    divisionList: [],
    committeeList: [],
    semaphore: [],
    parentShip: [],
  });

  useEffect(() => {
    retrieveTeamLeaders();
    retrieveSponsor();
    retrieveProjectCode();
    retrieveStatus();
    retrieveDivisions();
    retrieveSemaphores();
    retrieveCommittees();
    retrieveProjectsOption();
    // retrieveProjectCodes();
  }, []);

  useEffect(() => {
    setUpdatedFilters(dashboardFilters);
  }, [dashboardFilters]);

  const onSubmit = () => {
    setFilterIsChanged(false);
    onChange({ ...updatedFilters });
    handleModalFiltersClose();
  };

  const onClear = () => {
    setFilterIsChanged(false);
    onChange({ ...initialFilterState });
    handleModalFiltersClose();
  };

  const onFilterChange = (key: keyof QueryProjectFilters, value: any) => {
    setFilterIsChanged(true);
    setUpdatedFilters((prev) => ({ ...prev, [key]: value }));
  };

  const retrieveTeamLeaders = async () => {
    await DashboardService.getTeamLaderFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (!resp.responseObject?.value) return;

          const usr = [
            { value: '', label: `${t('general.seleziona')}...`, key: 'teamLiderNan', disabled: true },
            ...resp.responseObject?.value.map((item) => ({
              value: item.userID,
              label: item.fullName,
              key: item.userID,
              disabled: item.isDeleted,
            })),
          ] as OptionType[];

          setFilters((prevFilters) => ({ ...prevFilters, teamLeaderList: usr }));
        }
      });
  };

  const retrieveSponsor = async () => {
    await DashboardService.getSponsorFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (!resp.responseObject?.value) return;

          const usr = [
            { value: '', label: `${t('general.seleziona')}...`, key: 'sponsorNan', disabled: true },
            ...resp.responseObject?.value.map((item) => ({
              value: item.userID,
              label: item.fullName,
              key: item.userID,
              disabled: item.isDeleted,
            })),
          ] as OptionType[];

          setFilters((prevFilters) => ({ ...prevFilters, sponsorList: usr }));
        }
      });
  };

  const retrieveProjectCode = async () => {
    await DashboardService.getProjectCodeData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (!resp.responseObject?.value) return;

          const options = resp.responseObject.value.map((item) => ({
            value: item.projectCode,
            label: item.projectCode,
          })) as OptionType[];

          setFilters((prevFilters) => ({ ...prevFilters, projectCodeList: options }));
        }
      });
  };

  const retrieveDivisions = async () => {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (!resp.responseObject?.value) return;

          const options = resp.responseObject.value.map((item) => ({
            value: item.divisionID,
            label: item.divisionName,
          })) as OptionType[];

          setFilters((prevFilters) => ({ ...prevFilters, divisionList: options }));
        }
      });
  };

  const retrieveCommittees = async () => {
    await DashboardService.getCommittiesFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (!resp.responseObject?.value) return;

          const options = resp.responseObject.value.map((item) => ({
            value: item.committeeID,
            label: item.name,
            disabled: !item.hasAccess,
          })) as OptionType[];

          setFilters((prevFilters) => ({ ...prevFilters, committeeList: options }));
        }
      });
  };

  const retrieveStatus = async () => {
    const options = [
      {
        label: t('status.attivo'),
        value: ProjectStatus.Active,
      },
      {
        label: t('status.sospeso'),
        value: ProjectStatus.Suspended,
      },
      {
        label: t('status.draft'),
        value: ProjectStatus.Draft,
      },
      {
        label: t('status.completato'),
        value: ProjectStatus.Completed,
      },
    ] as OptionType[];

    setFilters((prevFilters) => ({ ...prevFilters, projectStatusesList: options }));
  };

  const retrieveSemaphores = async () => {
    const options = [
      {
        label: t('general.semaphore.notSet'),
        value: ProjectSemaphore.NotSet,
      },
      {
        label: t('general.semaphore.onTime'),
        value: ProjectSemaphore.OnTime,
      },
      {
        label: t('general.semaphore.warning'),
        value: ProjectSemaphore.Warning,
      },
      {
        label: t('general.semaphore.critical'),
        value: ProjectSemaphore.Critical,
      },
    ];

    setFilters((prevFilters) => ({ ...prevFilters, semaphore: options }));
  };

  // const retrieveParentProject = async (xMatrixId?: string) => {
  //   const xmId = xMatrixId || activeXmatrixInfo?.xMatrixID;
  //   await DashboardService.getParentProjectFilterData(xmId)
  //     .then((response) => response.data)
  //     .then((resp) => {
  //       if (resp.success) {
  //         setFilters((prevFilters) => ({ ...prevFilters, parentProject: resp.responseObject?.value }));
  //       }
  //     })
  //     .catch(() => {});
  // };

  const retrieveProjectsOption = async () => {
    const objectCode = objectCodes.projectsOption;
    await CommonService.getProjectsOptionData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (!resp.responseObject?.value) return;

          const options = resp.responseObject.value.map((item) => ({
            value: item.objectCodeListID,
            label: item.description,
          })) as OptionType[];

          setFilters((prevFilters) => ({ ...prevFilters, parentShip: options }));
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('dashboardProgettiPage.dashboardFilter')}
        open={isVisible}
        onCancel={handleModalFiltersClose}
        footer={
          <div className="tw-flex tw-items-center tw-justify-between">
            {/* <span>
              <CalendarOutlined style={{ color: 'black' }} />
              <span style={{ color: 'black' }}>
                {t('dashboardProgettiPage.settimanaCorrente')} {moment(moment(), 'MMDDYYYY').isoWeek()}
              </span>
            </span> */}
            <Button onClick={onClear}>{t('general.clearFilters')}</Button>
            <div className="tw-flex tw-gap-2">
              <Button
                disabled={!filterIsChanged}
                type="primary"
                onClick={onSubmit}
              >
                {t('general.filter')}
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
      >
        <Row
          gutter={{
            xs: 8,
            sm: 16,
          }}
        >
          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.teamLeader')}</span>
            <Select
              id="team_leader_filter"
              value={updatedFilters.teamLeaderIds || []}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              onChange={(val, option: any) => onFilterChange('teamLeaderIds', val)}
              options={filters.teamLeaderList || []}
              showSearch
              mode="multiple"
              filterOption={(input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>

          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.statoProgetto')}</span>
            <Select
              value={updatedFilters.projectStatuses || []}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              mode="multiple"
              options={filters.projectStatusesList || []}
              optionFilterProp="children"
              onChange={(val, option: any) => onFilterChange('projectStatuses', val)}
            ></Select>
          </Col>

          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('proggetiPage.codiceProgetto')}</span>
            <Select
              mode="multiple"
              value={updatedFilters.projectCodes || []}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              options={filters.projectCodeList || []}
              onChange={(val, option: any) => onFilterChange('projectCodes', val)}
            ></Select>
          </Col>

          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.divisioniReparti')}</span>
            <Select
              mode="multiple"
              value={updatedFilters.divisionIds || []}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(val, option: any) => onFilterChange('divisionIds', val)}
              options={filters.divisionList || []}
              filterOption={(input, option: any) => option.toLowerCase().includes(input.toLowerCase())}
            ></Select>
          </Col>

          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('general.commitato')}</span>
            <Select
              value={updatedFilters.commiteeIds || []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              mode="multiple"
              options={filters.committeeList || []}
              onChange={(val, option: any) => onFilterChange('commiteeIds', val)}
              filterOption={(input, option: any) => (option?.children).toLowerCase().includes(input.toLowerCase())}
            ></Select>
          </Col>

          <Col
            className="tw-my-2"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.analisiAllarmi')}</span>
            <Select
              value={updatedFilters.comboSemaphores || []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              mode="multiple"
              options={filters.semaphore || []}
              onChange={(val, option: any) => onFilterChange('comboSemaphores', val)}
              filterOption={(input, option: any) => (option?.children).toLowerCase().includes(input.toLowerCase())}
            ></Select>
          </Col>

          <Col
            className="tw-my-4"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <span>
              <ClockCircleOutlined style={{ fontSize: '17px', color: 'black' }} />
            </span>
            <Radio.Group
              style={{ width: '100%' }}
              defaultValue={updatedFilters.timeSemaphores || null}
              onChange={(e) => onFilterChange('timeSemaphores', [e.target.value])}
            >
              <Space direction="vertical">
                {filters.semaphore?.map((item) => (
                  <Radio
                    value={item.value}
                    key={item.value}
                  >
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Col>

          <Col
            className="tw-my-4"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <span>
              <EuroOutlined style={{ fontSize: '17px', color: 'black' }} />
            </span>
            <Radio.Group
              style={{ width: '100%' }}
              onChange={(e) => onFilterChange('budgetSemaphores', [e.target.value])}
              defaultValue={updatedFilters.budgetSemaphores || null}
            >
              <Space direction="vertical">
                {filters.semaphore?.map((item) => (
                  <Radio
                    value={item.value}
                    key={item.value}
                  >
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Col>

          <Col
            className="tw-my-4"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <span>
              <LineChartOutlined style={{ fontSize: '18px', color: 'black' }} />
            </span>
            <Radio.Group
              style={{ width: '100%' }}
              onChange={(e) => onFilterChange('qualitySemaphores', [e.target.value])}
              defaultValue={updatedFilters.qualitySemaphores || null}
            >
              <Space direction="vertical">
                {filters.semaphore?.map((item) => (
                  <Radio
                    value={item.value}
                    key={item.value}
                  >
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Col>

          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.tipologiaProgetti')}</span>
            <Select
              value={updatedFilters.projectParentship || []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(val, option: any) => onFilterChange('projectParentship', val)}
              filterOption={(input, option: any) =>
                (option?.children as any).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option
                value=""
                key="filterCriteriaNan"
                style={{ color: '#afaeae' }}
              >
                {t('general.seleziona')}...
              </Option>
              {filters.parentShip?.map((item) => (
                <Option
                  value={item.value}
                  key={item.value}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col>

          {/* <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('general.progetto')}</span>
            <Select
              value={updatedFilters.projectParentship || []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(val, option: any) =>
                onSubmit('parentProject', option, { value: 'parentProject', label: t('general.progetto') })
              }
              filterOption={(input, option: any) => (option?.children as any).toLowerCase().includes(input.toLowerCase())}>
              <Option
                value=""
                key="parentProjectNan"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.parentProject.map((item) => (
                <Option
                  value={item.value}
                  key={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col> */}

          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('general.sponsor')}</span>
            <Select
              value={updatedFilters.sponsorIds || []}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              onChange={(val, option: any) => onFilterChange('sponsorIds', val)}
              options={filters.sponsorList || []}
              showSearch
              filterOption={(input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>

          <Col
            className="tw-my-2"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('proggetiPage.progettiSenzaRelazione')}</span>
            <div className="tw-h-full tw-mt-2">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={updatedFilters.selectNotRelatedProjects}
                onChange={(checked) => {
                  onFilterChange('selectNotRelatedProjects', checked);
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default DashboardFiltersModal;
