import { Form } from 'antd';
import { type FC, useEffect, useState } from 'react';
import './deliverable.scss';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Input, InputNumber, Modal, Popover, Row, Select, Switch, message } from 'antd';
import type { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import {
  type CreateKpiRequest,
  KpiDetailCalculationType,
  KpiDetailParentType,
  KpiDetailType,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import KpiService from '../../../../services/pages/kpiService';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { kpiPuntualeAcumulato, kpiType } from '../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';

type KpiOption = {
  value: string;
  key: string;
  name: string;
  calculationType: string;
  parentType: string;
  isRollupKpi: boolean;
};

const NewProjectKpi: FC<{
  handleNewKpiModalClose: () => void;
  handleKpiModalSave: (values: CreateKpiRequest | { kpiId: string }, projectId: string) => void;
  loadingButton: boolean;
  projectId: string;
  showNewKpiModal: boolean;
}> = ({ handleNewKpiModalClose, handleKpiModalSave, loadingButton, projectId, showNewKpiModal }) => {
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const NormalJumpOffPoint = () => {
    return (
      <Form.Item<CreateKpiRequest>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        initialValue={0}
        rules={[
          { required: true, message: requiredFields.required },
          {
            validator: async (_, jumpOffPoint) => {
              if (typeof jumpOffPoint !== 'number') {
                return Promise.reject(new Error(`${t('kpiPage.jopNumber')}`));
              }
            },
          },
        ]}>
        <InputNumber
          style={{ width: '100%' }}
          min="0"
        />
      </Form.Item>
    );
  };

  const PercentageJumpOffPoint = () => {
    return (
      <Form.Item<CreateKpiRequest>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        initialValue={0}
        rules={[
          { required: true, message: requiredFields.required },
          {
            validator: async (_, jumpOffPoint) => {
              if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
                return Promise.reject(new Error(`${t('kpiPage.jopMax100Number')}`));
              }
            },
          },
        ]}>
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          max={100}
          formatter={(value) => `${value} %`}
        />
      </Form.Item>
    );
  };

  const BooleanJumpOffPoint = () => {
    return (
      <Form.Item<CreateKpiRequest>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        valuePropName="checked"
        initialValue={false}>
        <Switch
          checkedChildren={t('kpiPage.fatto')}
          unCheckedChildren={t('kpiPage.nonFatto')}
        />
      </Form.Item>
    );
  };

  const DisabledJumpOfPoint = () => {
    return (
      <Form.Item<CreateKpiRequest>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        initialValue={null}>
        <InputNumber
          value={null}
          style={{ width: '100%' }}
          min="0"
          disabled={true}
        />
      </Form.Item>
    );
  };

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [fieldsChangedNewKpi, setFieldsChangedNewKpi] = useState(false);
  const [loadingKpiButton, setLoadingKpiButton] = useState(false);
  const [kpiList, setKpiList] = useState<KpiOption[]>([]);
  const [jumpOffPointComponent, setJumpOffPointComponent] = useState(<NormalJumpOffPoint />);
  const [newKpiForm, setNewKpiForm] = useState(false);
  const [disableJumpOfPointResetGlobalKpi, setDisableJumpOfPointResetGlobalKpi] = useState(false);
  const [selectedType, setSelectedType] = useState<number>();
  const [selectedCumulatoPuntuale, setSelectedCumulatoPuntuale] = useState<number>();

  useEffect(() => {
    void retrieveKpiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Retrieve KPI list
  const retrieveKpiList = async () => {
    try {
      const { data } = await ProgettiService.getKpiProjectPicklist(projectId);
      if (!data.success || !data.responseObject?.value) {
        throw new Error('Fetch failed');
      }

      setKpiList(
        data.responseObject.value.map((item) => ({
          value: item.kpiDetailID as string,
          key: item.kpiDetailID as string,
          name: item.name as string,
          calculationType: item.globaleType as string,
          parentType: item.puntualeType as string,
          isRollupKpi: item.isRollupKPI === true,
        })),
      );
    } catch {}
  };

  const _validateKpiName = async (_rule: Rule, value: string, callback: (error?: string) => void) => {
    try {
      const { data } = await KpiService.checkIfKpiNameExist(value);
      if (!data.success) {
        throw new Error('Check failed');
      }

      if (data.responseObject?.value) {
        callback(requiredFields.validateKpiName);
      }

      callback();
    } catch {
      void message.error(notifyMessages.errorAccured);
    }
  };

  const onCalculationTypeChange = (type: KpiDetailCalculationType) => {
    if (type === KpiDetailCalculationType.IsCompleted) {
      setJumpOffPointComponent(<BooleanJumpOffPoint />);
    } else if (type === KpiDetailCalculationType.CompletionPercentage) {
      setJumpOffPointComponent(<PercentageJumpOffPoint />);
    } else {
      setJumpOffPointComponent(<NormalJumpOffPoint />);
    }
  };

  const createKpi = async (values: CreateKpiRequest, projectId: string) => {
    if ('kpiId' in values) {
      return;
    }

    if (disableJumpOfPointResetGlobalKpi) {
      values.jumpOffPoint = 0;
    } else {
      values.jumpOffPoint = +values.jumpOffPoint;
    }

    values.isRollupKpi = false;

    setLoadingKpiButton(true);

    try {
      const { data } = await KpiService.addKpi(values);

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Creation failed');
      }

      const kpi = data.responseObject.value;

      handleKpiModalSave({ kpiId: kpi.kpiDetail!.id }, projectId);
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    setLoadingKpiButton(false);
  };

  const showKpiForm = () => {
    setNewKpiForm(!newKpiForm);
  };

  const checkDisableJumpOfPointResetGlobale = () => {
    if (
      companyData?.resetGlobalKPI &&
      selectedCumulatoPuntuale === kpiPuntualeAcumulato.acumulato &&
      selectedType === kpiType.globale
    ) {
      setDisableJumpOfPointResetGlobalKpi(true);
    } else {
      setDisableJumpOfPointResetGlobalKpi(false);
    }
  };

  const onCumulatoPuntualeChange = (value: number) => {
    setSelectedCumulatoPuntuale(value);
    checkDisableJumpOfPointResetGlobale();
  };

  const onTypeChange = (value: number) => {
    setSelectedType(value);
    checkDisableJumpOfPointResetGlobale();
  };

  return (
    <Modal
      className="add-kpi-modal"
      width={800}
      title={t('kpiPage.aggiungiKpi')}
      open={showNewKpiModal}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleNewKpiModalClose}
      footer={[
        !newKpiForm ? (
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleNewKpiModalClose}
            formId={'newProjectKpi'}
          />
        ) : (
          ''
        ),
      ]}>
      <Row gutter={{ lg: 24 }}>
        {!newKpiForm && (
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ textAlign: 'right' }}>
            <Button
              className="create-kpi"
              onClick={showKpiForm}
              type="text">
              <PlusOutlined /> {t('buttons.creaNewKpi')}
            </Button>
          </Col>
        )}

        {newKpiForm && (
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ textAlign: 'right' }}>
            <Button
              className="create-kpi"
              onClick={showKpiForm}
              type="text">
              <PlusOutlined /> {t('kpiPage.aggiungiKpi')}
            </Button>
          </Col>
        )}
      </Row>

      {!newKpiForm && (
        <Card>
          <Form<CreateKpiRequest>
            id="newProjectKpi"
            layout="vertical"
            onFinish={(values) => handleKpiModalSave(values, projectId)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item<{ kpiId: string }>
                  className="main-container"
                  label={t('kpiPage.listaKpi')}
                  name="kpiId"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (typeof option?.children?.[0] === 'string') {
                        const value = option?.children?.[0] as unknown as string;

                        return value.toLowerCase().includes(input.trim().toLowerCase());
                      }

                      return false;
                    }}>
                    {kpiList.map((item) => (
                      <Select.Option
                        value={item.value}
                        key={item.key}>
                        {item.name}
                        <Popover
                          content={
                            <div>
                              <p>
                                {' '}
                                <b>{t('kpiPage.tipoCalcolo')}: </b> {item.calculationType}
                              </p>
                              <p>
                                {' '}
                                <b>{t('kpiPage.cumulatoPuntuale')}: </b> {item.parentType}
                              </p>
                              <p>
                                {' '}
                                <b>{t('kpiPage.gerarchicho')}: </b>{' '}
                                {item.isRollupKpi ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                              </p>
                            </div>
                          }
                          title={t('xMatrixPage.kpiInfo')}>
                          <InfoCircleOutlined style={{ fontSize: 12, color: '#1f85e4', marginLeft: '15px' }} />
                        </Popover>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}

      {newKpiForm && (
        <Row
          gutter={{ lg: 24 }}
          className="row-margin">
          <Form<CreateKpiRequest>
            className="main-container"
            name="new_kpi"
            layout="vertical"
            onFinish={(values) => createKpi(values, projectId)}
            onValuesChange={() => {
              setFieldsChangedNewKpi(true);
            }}>
            <Row
              gutter={{ lg: 24 }}
              style={{ marginTop: 20 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card style={{ background: '#f6fbff' }}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <div className="button-wrapper">
                        <Form.Item
                          wrapperCol={{ span: 24 }}
                          className="main-container">
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!fieldsChangedNewKpi}
                            loading={loadingKpiButton}>
                            <SaveOutlined />
                            {t('buttons.salvaKpi')}
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item<CreateKpiRequest>
                        className="main-container"
                        label={t('general.nome')}
                        name="name"
                        rules={[
                          { required: true, message: requiredFields.required },
                          {
                            validator: _validateKpiName,
                          },
                        ]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}>
                      <Form.Item<CreateKpiRequest>
                        className="main-container"
                        label={t('kpiPage.tipoCalcolo')}
                        name="calculationType"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onChange={onCalculationTypeChange}>
                          {Object.values(KpiDetailCalculationType).map((value) => (
                            <Select.Option
                              value={value}
                              key={value}>
                              {t(`kpi.calculationTypes.${value}`)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}>
                      {jumpOffPointComponent && !disableJumpOfPointResetGlobalKpi && jumpOffPointComponent}

                      {disableJumpOfPointResetGlobalKpi && <DisabledJumpOfPoint />}
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}>
                      <Form.Item<CreateKpiRequest>
                        className="main-container"
                        label={t('kpiPage.cumulatoPuntuale')}
                        name="parentType"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onChange={onCumulatoPuntualeChange}>
                          {Object.values(KpiDetailParentType).map((value) => (
                            <Select.Option
                              value={value}
                              key={value}>
                              {t(`kpi.parentTypes.${value}`)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}>
                      <Form.Item<CreateKpiRequest>
                        className="main-container"
                        label={t('kpiPage.unitaMisura')}
                        name="unitOfMeasure">
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}>
                      <Form.Item<CreateKpiRequest>
                        className="main-container"
                        label={t('general.actualYtd')}
                        name="actualYTD">
                        <InputNumber
                          min="0"
                          style={{ width: '100%' }}
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}>
                      <Form.Item<CreateKpiRequest>
                        className="main-container"
                        label={t('general.tipo')}
                        name="type"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={KpiDetailType.Specific}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onChange={onTypeChange}>
                          {Object.values(KpiDetailType).map((value) => (
                            <Select.Option
                              value={value}
                              key={value}>
                              {t(`kpi.types.${value}`)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Row>
      )}
    </Modal>
  );
};

// export default NewProjectKpi

// const mapStateToProps = (state) => ({
//   userData: state.userData.userData,
//   companyData: state.companyData.companyData,
// });

export default NewProjectKpi;
