import { trackPromise } from 'react-promise-tracker';
import {
  type InsertProjectCostDetailRequestDto,
  LegacyProjectApi,
  type UpdatePlaningProjectRequest,
  type UpdateProjectCostDetailRequestDto,
  type UpdateProjectCostRequest,
  type UpdateProjectTotalCostRequest,
  type UpdateUserPlanning,
  type UpdateUserTimePlanning,
  WorkloadContollerApi,
  authenticatedApi,
} from 'src/config/connectors';

const ResourceWorkloadService = {
  // get project resource workload
  getProjectWorkloadData: async (projectId: string) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetTeamPlanningProject(projectId));
  },

  // get project resource workload
  getTotalProjectWorkloadData: async (projectId: string) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetTotalTeamPlanningProject(projectId));
  },

  updateProjectResourceWorkloadData: async (payload: UpdatePlaningProjectRequest) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerUpdateTeamPlanningProject(payload));
  },

  // get  resource workload
  getResourceWorkloadData: async (selectedYear: string) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetResourceWorkload(selectedYear));
  },

  // get  resource workload
  getTotalResourceWorkloadData: async (selectedYear: string) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetTotalResourceWorkload(selectedYear));
  },

  // get user resource workload
  getUserWorkloadData: async (userID: string, selectedYear: string) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetUserPlanningProject(userID, selectedYear));
  },

  updateUserPlanning: async (payload: UpdateUserPlanning) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerUpdateUserPlanningProject(payload));
  },

  getUserTimePlanning: async (selectedYear: string, userId: string, XMatrixID: string) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetUserTimePlanning(selectedYear, userId, XMatrixID));
  },

  getProjectTimePlanning: async (
    selectedYear: string,
    userId: string,
    porjectID: string,
    getProjectTimePlanning: string,
  ) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(
      api.workloadContollerGetProjectTimePlanning(selectedYear, userId, porjectID, getProjectTimePlanning),
    );
  },

  updateTimePlanning: async (payload: UpdateUserTimePlanning) => {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerUpdateUserTimePlanning(payload));
  },

  getProjectFilterData: async (userId: string, xmatrixID: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetTeamLeaderProject(userId, xmatrixID));
  },

  getProjectCostData: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectCostChart(projectId));
  },

  getTotalProjectCost: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetTotalCostProjectChart(projectId));
  },

  getTeamProjectCost: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetTotalTeamCostProjectChart(projectId));
  },

  getProjectCostDetails: async (projectCostDetailID: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectCostDetail(projectCostDetailID));
  },

  insertProjectCost: async (payload: InsertProjectCostDetailRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectInsertProjectCostDetail(payload));
  },

  updateProjectCost: async (payload: UpdateProjectCostRequest) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateProjectCost(payload));
  },

  updateProjectCostDetails: async (payload: UpdateProjectCostDetailRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateProjectCostDetail(payload));
  },

  updateProjectTotalCost: async (payload: UpdateProjectTotalCostRequest) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateProjectTotalCost(payload));
  },

  removeProjectCost: async (id: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectDeleteProjectCost(id));
  },
};

export default ResourceWorkloadService;
