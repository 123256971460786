import { Button, message } from 'antd';
import BraftEditor, { type EditorState } from 'braft-editor';
import 'braft-editor/dist/index.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { richTectEditoControlls } from '../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';

import ProgettiService from '../../../services/pages/projectServices/progettiService';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import { getProjectById } from 'src/redux/slices/projectSlice';
import { useAppSelector } from 'src/redux/store';

interface ProjectGoalEditorProps {
  isPresentationMode: boolean;
  isEditable: boolean;
}

const ProjectGoalEditor: React.FC<ProjectGoalEditorProps> = ({ isPresentationMode, isEditable }) => {
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.project.currentProject);
  const dispatch = useDispatch();

  const [isTouched, setIsTouched] = useState(false);
  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>();

  useEffect(() => {
    if (currentProject) {
      setEditorState(BraftEditor.createEditorState(currentProject.projectGoal));
    }
  }, [currentProject]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editorState || editorState.isEmpty()) {
      message.error(requiredFields.required);
      return;
    }

    const values = { projectGoal: editorState.toHTML() };
    updateFieldToObject(values);
    setIsTouched(false);
  };

  const updateFieldToObject = (values: Record<string, string>) => {
    setLoadingEditorButton(true);

    const fieldName = Object.keys(values)[0];
    const editorContentToHtml = BraftEditor.createEditorState(values[fieldName]).toHTML();

    if (!currentProject?.projectID) {
      return;
    }

    ProgettiService.updateEditorField({
      projectID: currentProject.projectID,
      projectFieldName: fieldName,
      projectFieldValue: editorContentToHtml,
    })
      .then(() => {
        setLoadingEditorButton(false);

        dispatch(getProjectById({ projectId: currentProject?.projectID! }));
        message.success(notifyMessages.updateSuccess);
      })
      .catch(() => {
        setLoadingEditorButton(false);
        message.error(notifyMessages.updateFailed);
      });
  };

  return (
    <div className="tw-h-full">
      {currentProject && (
        <form
          className="tw-h-full tw-flex tw-flex-col"
          data-testid="expectedResultForm"
          id="projectEditorForm"
          onSubmit={handleSubmit}>
          {isPresentationMode ? (
            <ProjectSectionTitle
              title={t('proggetiPage.expectedResult')}
              isPresentationMode={isPresentationMode}
            />
          ) : (
            <h1
              className={classNames('tw-text-xl tw-mb-2 tw-font-semibold', {
                '!tw-text-3xl !tw-mb-4': isPresentationMode,
              })}>
              {t('proggetiPage.risultatoAtteso')}
            </h1>
          )}

          <div className="tw-rounded-lg tw-grow tw-overflow-hidden">
            <BraftEditor
              className={classNames({
                '[&_.bf-content]:tw-h-full tw-min-h-full [&_.bf-content]:tw-text-3xl': isPresentationMode,
              })}
              readOnly={isPresentationMode || !isEditable}
              language="en"
              id="goal-editor-text"
              value={editorState}
              onChange={(newState) => {
                setEditorState(newState);
                setIsTouched(true);
              }}
              controls={isPresentationMode ? [] : (richTectEditoControlls as any)}
            />
          </div>

          {!isPresentationMode && (
            <div
              key="buttons"
              className="tw-ml-auto tw-w-fit tw-mt-4">
              <Button
                type="primary"
                htmlType="submit"
                key="submit"
                disabled={!isTouched}
                loading={loadingEditorButton}>
                {t('buttons.salva')}
              </Button>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default ProjectGoalEditor;
