import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  type InsertGoalCategoryRequestDto,
  LongTermGoalApi,
  type UpdateGoalCategoryRequestDto,
} from 'src/connectors/backend';
import i18n from '../../i18n';

const CategoryService = {
  // get category list
  getCategoryList: async () => {
    const api = await authenticatedApi(LongTermGoalApi);
    const currentLang = i18n.language;

    return trackPromise(api.longTermGoalGetGoalCategoryList(currentLang));
  },

  // get kpi data by id
  getCategoryByiD: async (id: string) => {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalGetGoalCategoryByID(id));
  },

  updateCategory: async (payload: UpdateGoalCategoryRequestDto) => {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalUpdateGoalCategory(payload));
  },

  addCategory: async (payload: InsertGoalCategoryRequestDto) => {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalInsertGoalCategory(payload));
  },

  // get icon list
  getIconList: async () => {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalCategoryIconlist());
  },
};

export default CategoryService;
