import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'src/contexts/ConfirmationContext';

interface EndDateConfirmationModalProps {
  showModal: boolean;
  handleConfirmResponse: (values: any, confirm: boolean) => void;
  handleDeclineResponse: (values: any, confirm: boolean) => void;
  deliverableFormValues: any;
}

const EndDateConfirmationModal: FC<EndDateConfirmationModalProps> = ({
  showModal,
  handleConfirmResponse,
  handleDeclineResponse,
  deliverableFormValues,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  useEffect(() => {
    const showConfirmation = async () => {
      if (showModal) {
        const confirmation = await confirm({
          message: t('deliverablePage.endDateGreaterThanWarning'),
          okText: t('deliverablePage.extendDate'),
          cancelText: t('general.no'),
        });

        if (confirmation) {
          handleConfirmResponse(deliverableFormValues, true);
        } else {
          handleDeclineResponse(deliverableFormValues, false);
        }
      }
    };

    showConfirmation();
  }, [showModal]);

  return null;
};

export default EndDateConfirmationModal;
