import { ClockCircleOutlined, EuroOutlined, LineChartOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { semaphoreStatus } from '../../shared/utils/constants';
import { checkCompanyBudgetAlert } from '../utils/functions';
import './semaphores.scss';

const { Text } = Typography;

export const checkSemaphoreColor = (status) => {
  if (status === semaphoreStatus.s1) {
    return 'greenColorIndicator';
  } else if (status === semaphoreStatus.s2) {
    return 'yellowColorIndicator';
  } else if (status === semaphoreStatus.s3) {
    return 'redColorIndicator';
  } else {
    return 'greyColorIndicator';
  }
};

// semaphores tooltips
export const TimeTooltip = ({ data, t }) => {
  return (
    <Space direction="vertical">
      <Text style={{ color: '#ffffff' }}>
        {data.timeSemaphoreNumerator ? data.timeSemaphoreNumerator : '0'} {t('proggetiPage.semTimeChiudere')}
      </Text>
      <Text style={{ color: '#ffffff' }}>
        {data.timeSemaphoreDenominator ? data.timeSemaphoreDenominator : '0'} {t('proggetiPage.semTimeDaChiudere')}
      </Text>
    </Space>
  );
};

export const BudgetTooltip = ({ data, t, companyData }) => {
  return (
    <Space direction="vertical">
      <Text style={{ color: '#ffffff' }}>
        {data.budgetSemaphoreNumerator ? data.budgetSemaphoreNumerator : '0'} {checkCompanyBudgetAlert(companyData)}{' '}
        {t('proggetiPage.semCostConsutivati')}
      </Text>
      <Text style={{ color: '#ffffff' }}>
        {data.budgetSemaphoreDenominator ? data.budgetSemaphoreDenominator : '0'} {checkCompanyBudgetAlert(companyData)}{' '}
        {t('proggetiPage.semCostBudget')}
      </Text>
    </Space>
  );
};

export const QualityTooltip = ({ data, t }) => {
  return (
    <Space direction="vertical">
      <Text style={{ color: '#ffffff' }}>
        {data.qualitySemaphoreNumerator ? data.qualitySemaphoreNumerator : '0'} {t('proggetiPage.semKpiLinea')}
      </Text>
      <Text style={{ color: '#ffffff' }}>
        {data.qualitySemaphoreDenominator ? data.qualitySemaphoreDenominator : '0'} {t('proggetiPage.semKpi')}
      </Text>
    </Space>
  );
};

//semaphores icons
export const TimeSemaphore = (props) => {
  return (
    <Space
      direction="vertical"
      className="semaphoreSpace"
    >
      <ClockCircleOutlined className={checkSemaphoreColor(props.data.timeSemaphore)} />
    </Space>
  );
};

export const BudgetSemaphore = (props) => {
  return (
    <Space
      direction="vertical"
      className="semaphoreSpace"
    >
      <EuroOutlined className={checkSemaphoreColor(props.data.budgetSemaphore)} />
    </Space>
  );
};

export const QualitySemaphore = (props) => {
  return (
    <Space
      direction="vertical"
      className="semaphoreSpace"
    >
      <LineChartOutlined className={checkSemaphoreColor(props.data.qualitySemaphore)} />
    </Space>
  );
};

// project semaphore icons
export const ProjectTimeSemaphore = (props) => {
  return (
    <Space
      direction="vertical"
      className="semaphoreSpace"
    >
      <ClockCircleOutlined />
    </Space>
  );
};

export const ProjectBudgetSemaphore = (props) => {
  return (
    <Space
      direction="vertical"
      className="semaphoreSpace"
    >
      <EuroOutlined />
    </Space>
  );
};

export const ProjectQualitySemaphore = (props) => {
  return (
    <Space
      direction="vertical"
      className="semaphoreSpace"
    >
      <LineChartOutlined />
    </Space>
  );
};
