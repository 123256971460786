import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  type CreateProjectRoutineRequest,
  ProjectRoutineApi,
  type UpdateProjectRoutineRequest,
} from 'src/connectors/backend';

const RoutineService = {
  listProjectRoutines: async (projectId: string) => {
    const api = await authenticatedApi(ProjectRoutineApi);

    return trackPromise(api.projectRoutineListProjectRoutines(projectId));
  },

  // add new project routine
  addProjectRoutine: async (projectId: string, payload: CreateProjectRoutineRequest) => {
    const api = await authenticatedApi(ProjectRoutineApi);

    return trackPromise(api.projectRoutineCreateProjectRoutine(projectId, payload));
  },

  updateProjectRoutine: async (projectId: string, routineId: string, payload: UpdateProjectRoutineRequest) => {
    const api = await authenticatedApi(ProjectRoutineApi);

    return trackPromise(api.projectRoutineUpdateProjectRoutine(projectId, routineId, payload));
  },

  removeRoutine: async (projectId: string, routineId: string) => {
    const api = await authenticatedApi(ProjectRoutineApi);

    return trackPromise(api.projectRoutineDeleteProjectRoutine(projectId, routineId));
  },
};

export default RoutineService;
