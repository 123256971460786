import { Form, Select } from 'antd';
import type { BaseOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonService from '../../../../services/commonService';
import { requiredFields } from '../../utils/notifyMessages';

interface UserSelectDropDownProps {
  disabled?: boolean;
  allowClear?: boolean;
  formName: string;
  required?: boolean;
  initValue?: string;
  fieldLabel?: string;
}

const UserSelectDropDown: React.FC<UserSelectDropDownProps> = ({
  disabled = false,
  allowClear = false,
  formName,
  required = false,
  initValue,
  fieldLabel,
}) => {
  const { t } = useTranslation();
  const [usersSelect, setUsersSelect] = useState<BaseOptionType[] | null>(null);

  useEffect(() => {
    retrieveUsersList();
  }, []);

  const retrieveUsersList = async () => {
    try {
      const response = await CommonService.getUsersPicklist();
      const resp = response.data;

      if (resp.success && resp.responseObject?.value) {
        const respData = resp.responseObject.value;
        const usr = respData.map((item: any) => ({
          value: item.userID,
          label: item.fullName,
          key: item.userID,
          disabled: item.isDeleted,
        }));
        setUsersSelect(usr);
      } else {
        console.warn('API returned success=false or responseObject is undefined');
        setUsersSelect([]);
      }
    } catch (error) {
      console.error('Failed to fetch user list:', error);
      setUsersSelect([]);
    }
  };

  return (
    <>
      <Form.Item
        labelAlign="left"
        label={fieldLabel}
        name={formName}
        rules={required ? [{ required: true, message: requiredFields.required }] : []}
        initialValue={initValue}
      >
        <Select
          style={{ width: '100%' }}
          placeholder={t('general.seleziona')}
          allowClear={allowClear}
          disabled={disabled}
          options={usersSelect || []}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          loading={!usersSelect}
        />
      </Form.Item>
    </>
  );
};

export default UserSelectDropDown;
