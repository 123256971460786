import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  type AnnualGoal,
  AnnualGoalApi,
  CommonApi,
  type InserXMatrixDivisionRequestDto,
  type InsertNextYearXMatrixRequestDto,
  type InsertXMatrixRequestDto,
  type InsertXmatrixCycleRequestDto,
  type KPI,
  type LongTermGoal,
  type Project,
  type UpdateXMatrixByIDRequestDto,
  type UpdateXMatrixCycleRequestDto,
  type UpdateXMatrixRelationsOrderRequest,
  XMatrixApi,
  type XMatrixDto,
  type XMatrixFilters,
  XMatrixRelationApi,
  type XMatrixSecondLevelFilters,
} from 'src/connectors/backend';

type WithOrder<T> = T & { order: number };

export interface XMatrixDtoWithOrder extends XMatrixDto {
  projects: Array<WithOrder<Project>>;
  longTermGoals: Array<WithOrder<LongTermGoal>>;
  annualGoals: Array<WithOrder<AnnualGoal>>;
  kpIs: Array<WithOrder<KPI>>;
}

const MatrixService = {
  getMatrixData: async (xmatrixFilters: XMatrixFilters): Promise<XMatrixDtoWithOrder> => {
    const api = await authenticatedApi(XMatrixApi);
    const api2 = await authenticatedApi(XMatrixRelationApi);

    const [
      {
        data: {
          responseObject: { value: notOrdered } = {},
        },
      },
      { data: ordered },
    ] = await Promise.all([
      trackPromise(api.xMatrixGetXMatrix(xmatrixFilters)),
      trackPromise(api2.xMatrixRelationListXMatrixRelations(xmatrixFilters.xMatrixID!)),
    ]);

    if (!notOrdered || !ordered) {
      throw new Error('Cannot get matrix data');
    }

    const result: XMatrixDtoWithOrder = {
      ...notOrdered,
      projects: notOrdered
        .projects!.map((p) => ({
          ...p,
          order: ordered.find((o) => o.objectId === p.projectID)?.order || 0,
        }))
        .sort((a, b) => a.order - b.order),
      annualGoals: notOrdered
        .annualGoals!.map((p) => ({
          ...p,
          order: ordered.find((o) => o.objectId === p.annualGoalID)?.order || 0,
        }))
        .sort((a, b) => a.order - b.order),
      longTermGoals: notOrdered
        .longTermGoals!.map((p) => ({
          ...p,
          order: ordered.find((o) => o.objectId === p.longTermGoalID)?.order || 0,
        }))
        .sort((a, b) => a.order - b.order),
      kpIs: notOrdered
        .kpIs!.map((p) => ({
          ...p,
          order: ordered.find((o) => o.objectId === p.kpiid)?.order || 0,
        }))
        .sort((a, b) => a.order - b.order),
    };

    return result;
  },

  getUsersXmatrix: async () => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixUsers());
  },

  getLongTermGoals: async () => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixLongTermGoalPicklist());
  },

  getAnnualGoals: async () => {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalGetAnualGoalPicklist());
  },

  getNewXmatrixAnnualGoals: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId));
  },

  getNewXmatrixProjects: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewXmatrixProjectPicklist(xmatrixId));
  },

  getProjectByDivision: async (divisionId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXmatrixDivisionParentProjectPicklist(divisionId));
  },

  getDivisionPicklist: async (projectId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXmatrixDivisionPicklist());
  },

  getXmatrixPicklist: async () => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixPicklist());
  },

  addNewXmatrixNextYear: async (payload: InsertNextYearXMatrixRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertNextYearXMatrix(payload));
  },

  addNewXmatrix: async (payload: InsertXMatrixRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertXMatrix(payload));
  },

  //xmatrix filter apis

  getAnnualGoalsXmatrixFilter: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetAnnualGoalPicklistXMatrix(xmatrixId));
  },

  getLongTermGoalsXmatrixFilter: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetLongTermGoalPicklistXMatrix(xmatrixId));
  },

  getProjectXmatrixFilter: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetProjectPicklistXMatrix(xmatrixId));
  },

  getCommittieXmatrixFilter: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetCommitteePicklistXMatrix(xmatrixId));
  },

  getDivisionXmatrixFilter: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetDivisionPicklistXMatrix(xmatrixId));
  },

  // update xmatrix status
  updateXmatrixStatus: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateXMatrixStatus(xmatrixId));
  },

  deleteXmatrixDraft: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteDraftXMatrix(xmatrixId));
  },

  getXmatrixDivisionRootList: async () => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetRootXMatrixDivisionList());
  },

  getSecondLevelXmatrixData: async (filters: XMatrixSecondLevelFilters, xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetDivisionDataXMatrix(filters));
  },

  getSelectedXmatrixInfo: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixInfo(xmatrixId));
  },

  getNewDivisionXMatrixAnnualGoalPicklist: async (xmatrixId: string, divisionId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionId));
  },

  getNewDivisionXMatrixLongTermGoalPicklist: async (xmatrixId: string, divisionId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionId));
  },

  getNewDivisionXMatrixProjectPicklist: async (xmatrixId: string, divisionId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionId));
  },

  getTeamLaderSecondLevelFilterData: async (divisionId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetNewDivisionTeamLeaderPicklist(divisionId));
  },

  getSponsorSecondLevelFilterData: async (divisionId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetNewDivisionSponsorPicklist(divisionId));
  },

  getSecondaryProjectSecondLevelFilterData: async (xmatrixId: string, divisionId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixId, divisionId));
  },

  // add new xmatrix division
  insertXmatrixDivision: async (payload: InserXMatrixDivisionRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertXMatrixDivision(payload));
  },

  // get matrix cycle
  getXmatrixCycleList: async (companyId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixCycle(companyId));
  },

  insertXmatrixCycle: async (payload: InsertXmatrixCycleRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInserXMatrixCycle(payload));
  },

  updateXmatrixCycle: async (payload: UpdateXMatrixCycleRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateXMatrixCycle(payload));
  },

  removeXmatrixCycle: async (xmatrixCycleId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteXMatrixCycle(xmatrixCycleId));
  },

  getXmatrixCycleById: async (xmatrixCycleId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixCycleByID(xmatrixCycleId));
  },

  getXmatrixOnEditData: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixData(xmatrixId));
  },

  updateXmatrixData: async (payload: UpdateXMatrixByIDRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateXMatrixData(payload));
  },

  getActiveXmatrix: async () => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetActiveXMatrixInfo());
  },

  updateXMatrixRelationsOrder: async (
    xMatrixId: string,
    updateXMatrixRelationsOrderRequest: UpdateXMatrixRelationsOrderRequest,
  ) => {
    const api = await authenticatedApi(XMatrixRelationApi);

    return trackPromise(api.xMatrixRelationUpdateXMatrixRelationsOrder(xMatrixId, updateXMatrixRelationsOrderRequest));
  },
};

export default MatrixService;
