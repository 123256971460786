import { Form } from 'antd';
import React from 'react';
import '../../projects/projectForm/projectActivities/activities.scss';

import { Card, Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import ModalFooterButtons from '../../shared/components/modal/modalFooterButtons';
import { activityStatus, statusPickListCodes } from '../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../shared/utils/functions';
import { requiredFields } from '../../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class NewActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      usersPickList: null,
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrieveResponsibleUsersList();
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveResponsibleUsersList() {
    const { deliverableData } = this.props;
    await CommonService.getResponsibleUserList(deliverableData.projectID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const usr = [];
          const respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ usersPickList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  addData = (values, deliverableId) => {
    this.props.handleSaveActivity(values, deliverableId);
  };

  limitDateSelection(current, deliverableData) {
    // if (deliverableData) {
    //     if (deliverableData.parentDeliverableID) {
    //         let curr = moment(current).endOf('day');
    //         let startDate = moment(deliverableData.parentDeliverableStartDate).endOf('day');
    //         let endDate = moment(deliverableData.parentDeliverableEndDate).endOf('day');
    //         return ((curr < startDate) || (curr > endDate))
    //     }
    //     else {
    //         let curr = moment(current).endOf('day');
    //         let startDate = moment(deliverableData.projectStartDate).endOf('day');
    //         let endDate = moment(deliverableData.projectEndDate).endOf('day');
    //         return ((curr < startDate) || (curr > endDate))
    //     }
    // }
    // else {
    //     let curr = moment(current).endOf('day');
    //     let startDate = moment(deliverableData.projectStartDate).endOf('day');
    //     let endDate = moment(deliverableData.projectEndDate).endOf('day');
    //     return ((curr < startDate) || (curr > endDate))
    // }

    if (deliverableData) {
      const curr = moment(current).endOf('day');
      const startDate = moment(deliverableData.startDate).endOf('day');
      const endDate = moment(deliverableData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else {
    }
  }

  checkValidityBeforeSave = (values, deliverableId) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.addData(values, deliverableId);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  // checkDatesValidity = (values) => {
  //     let t =  this.props.t;
  //     const { deliverableId } = this.props;
  //     if(areDatesValid(values.startDate, values.endDate)){
  //         this.addData(values,deliverableId)
  //     }
  //     else{
  //         showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate)
  //     }
  // }

  render() {
    const {
      showCreateModal,
      handleCreateModalClose,
      loadingButton,
      deliverableId,
      deliverableName,
      deliverableData,
      t,
    } = this.props;
    const { statusList, usersPickList } = this.state;

    const currentDate = moment();

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('leMieAttivitaPage.aggiungiNuovaAttivita')}
        open={showCreateModal}
        destroyOnClose={true}
        onCancel={handleCreateModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleCreateModalClose}
            formId={'newDeliverableActivityForm'}
          />,
        ]}
      >
        <Card>
          <Form
            id="newDeliverableActivityForm"
            layout="vertical"
            {...formItemLayout}
            // onFinish={this.checkDatesValidity}
            onFinish={(values) => this.checkValidityBeforeSave(values, deliverableId)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.deliverable')}
                >
                  <Input
                    disabled={true}
                    value={deliverableName}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('leMieAttivitaPage.soggetto')}
                  name="subject"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  labelAlign="left"
                  label={t('general.responsabile')}
                  name="ownerID"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={deliverableData ? deliverableData.ownerID : null}
                >
                  <Select
                    placeholder={t('general.seleziona')}
                    style={{ width: '100%' }}
                    options={usersPickList}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    loading={!usersPickList}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={activityStatus.active}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {statusList &&
                      statusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}
                        >
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.start')}
                  name="startDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={currentDate}
                >
                  {/* <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY"
                                     disabledDate={deliverableData ? (current) => this.limitDateSelection(current, deliverableData) : null} 
                                    /> */}

                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.end')}
                  name="endDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabledDate={
                      deliverableData ? (current) => this.limitDateSelection(current, deliverableData) : null
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.descrizione')}
                  name="description"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(NewActivity);
