import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { A3PageApi, type UpdateNextStepsSectionA3RequestDto } from 'src/connectors/backend';

const Section4Service = {
  getProjectRisk: async (projectId: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetRiskPrecautionA3(projectId));
  },

  // get project next steps
  getNextSteps: async (id: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetNextStepsSectionA3(id));
  },

  // get project next step table
  getProjectDeliverable: async (projectId: string, daysOfNotice: number) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetProjectDeliverableNextStepA3(projectId, daysOfNotice));
  },

  updateNextSteps: async (payload: UpdateNextStepsSectionA3RequestDto) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageUpdateNextStepsSectionA3(payload));
  },
};

export default Section4Service;
