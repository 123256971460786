import { DownOutlined, FlagOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Tooltip, message } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import Search from 'antd/es/transfer/search';
import classNames from 'classnames';
import { endOfMonth, format, getMonth, getYear, parse, setMonth, setYear, startOfMonth } from 'date-fns';
import { enUS as en, it } from 'date-fns/locale';
import moment from 'moment';

import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCalendar2Line, RiRecordCircleLine, RiSettingsLine, RiUser6Line } from 'react-icons/ri';
import { PrsItemType } from 'src/components/projects/projectForm/deliverablesAndKpi/kanban-view/components/kanban-item';
import type { UpdateDeliverableRequestDto, UserListPageResponseDto } from 'src/connectors/backend';
import { KanBanView, List } from '../../../../../assets/icons';
import ActivityService from '../../../../../services/pages/activitiesServices';
import DeliverablePageService from '../../../../../services/pages/deliverableService';
import UsersService from '../../../../../services/pages/usersService';
import { addQueryParam, getQueryParam, hasQueryParam } from '../../../../../utils/url-utils';
import TableLayout from '../../../../shared/tableLayout';
import { activityStatus, deliverableStatus } from '../../../../shared/utils/constants';
import { updateNotificationBell } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import { DeliverableIcon, PhaseIcon, TaskIcon } from './components/icons';

import KanBan from 'src/components/projects/projectForm/deliverablesAndKpi/kanban-view/components/kanban';
import { useConfirm } from 'src/contexts/ConfirmationContext';
import i18n from 'src/i18n';
import { useAppSelector } from 'src/redux/store';
import PrsKanBanFiltersDrawer from './kanban-filter-drawer';
import {
  GroupBy,
  type KanBanColumn,
  type PrsKanBanItem,
  filterByItemType,
  flatArray,
  getIconFromStatus,
  getMonthsBetween,
  matchItemsWithCompanyUsers,
  toKanBanItems,
} from './kanban-view.utils';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';

interface PrsKanBanViewProps {
  setView: (view: string) => void;
  view: string;
  deliverablesList: any[];
  newBtn: React.ReactNode;
  updateDeliverables: () => void;
}

const PrsKanBanView: React.FC<PrsKanBanViewProps> = ({
  setView,
  view,
  deliverablesList,
  newBtn,
  updateDeliverables,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const project = useAppSelector((state) => state.project?.currentProject);

  const [items, setItems] = useState<PrsKanBanItem[]>([]);
  const [users, setUsers] = useState<UserListPageResponseDto[]>([]);
  const [columns, setColumns] = useState<KanBanColumn[]>([]);
  const [colFn, setColFn] = useState<any>(() => () => undefined);
  const [selectedItemType, setSelectedItemType] = useState<PrsItemType>(PrsItemType.deliverable);
  const [selectedGroupBy, setSelectedGroupBy] = useState<GroupBy>(GroupBy.STATUS);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({ query: '', status: [], period: [], priority: [], owner: [] });

  useEffect(() => {
    if (hasQueryParam('selectedGroupBy')) {
      setSelectedGroupBy(getQueryParam('selectedGroupBy') as GroupBy);
    }

    if (hasQueryParam('selectedItemType')) {
      setSelectedItemType(getQueryParam('selectedItemType') as PrsItemType);
    }

    if (users.length === 0) {
      retrieveResponsibleUsersList(); // get users
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length]);

  useEffect(() => {
    if (!deliverablesList.length) {
      setItems([]);
      return;
    }

    let kanBanItems = toKanBanItems(flatArray(deliverablesList));
    if (!kanBanItems.length) return;

    const updatedItems = (kanBanItems = matchItemsWithCompanyUsers(kanBanItems, users));

    // Reset state to avoid stale values for columns
    // setColFn(() => () => undefined);
    // setColumns([]);

    // Filter items by the selected type
    let filteredItems: PrsKanBanItem[] = filterByItemType(updatedItems, selectedItemType);

    const { query, status, period, priority, owner } = filters;

    // Precompute query filter if provided
    const queryLower = query?.trim().toLowerCase();
    const hasQueryFilter = Boolean(queryLower);
    const statusSet: Set<number> = new Set(status || []);
    const prioritySet: Set<number> = new Set(priority || []);
    const ownerSet: Set<string> = new Set(owner || []);

    // Filter by search query, if provided
    if (hasQueryFilter) {
      filteredItems = filteredItems.filter(
        (item) =>
          item.title.toLowerCase().includes(queryLower) || item.ownerFullName?.toLowerCase().includes(queryLower),
      );
    }

    // Filter by status
    if (statusSet.size) {
      filteredItems = filteredItems.filter((item) => statusSet.has(item.status));
    }

    // Filter by date range
    const [startDate, endDate] = period || [];
    if (startDate && endDate) {
      const minDate = moment(startDate);
      const maxDate = moment(endDate);
      filteredItems = filteredItems.filter((item) => moment(item.startDate).isBetween(minDate, maxDate, null, '[]'));
    }

    // Filter by priority
    if (prioritySet.size) {
      filteredItems = filteredItems.filter((item) => prioritySet.has(item.priority));
    }

    // Filter by owner
    if (ownerSet.size) {
      filteredItems = filteredItems.filter((item) => ownerSet.has(item.ownerId));
    }

    setItems(filteredItems);
  }, [deliverablesList, selectedItemType, selectedGroupBy, filters, users]);

  useEffect(() => {
    if (items?.length === 0) return;

    const setColumnsAndColFn = (columns: KanBanColumn[], colFn: any) => {
      // Cleanup before setting new columns and colFn
      setColumns([]);
      setColFn(() => () => undefined);

      setColumns(columns);
      setColFn(() => colFn);
    };

    switch (selectedGroupBy) {
      case GroupBy.STATUS: {
        const statusMap = selectedItemType === 'task' ? activityStatus : deliverableStatus;
        const columns: KanBanColumn[] = Object.entries(statusMap).map(([key, value]) => ({
          id: value,
          title: t(`general.${key}`),
          icon: getIconFromStatus(value),
          canCollapse: false,
          isDndEnabled: true,
          hideIfEmpty: false,
        }));

        setColumnsAndColFn(columns, (item: PrsKanBanItem) => columns.find(({ id }) => id === item?.status));
        break;
      }
      case GroupBy.MONTH: {
        if (!project?.startDate || !project?.endDate) return;

        const minDate = moment(project.startDate);
        const maxDate = moment(project.endDate);

        const columns = getMonthsBetween(minDate, maxDate).map((label) => ({
          id: label,
          title: label,
          canCollapse: true,
          isDndEnabled: true,
          hideIfEmpty: false,
        }));

        setColumnsAndColFn(columns, (item: PrsKanBanItem) =>
          columns.find(({ id }) => id.toString() === moment(item?.endDate).format('MMM YYYY').toString()),
        );
        break;
      }
      case GroupBy.OWNER: {
        const userIds = users.map((i) => i.userID);
        const itemsOutTheProject = items.filter((u) => !userIds.includes(u.ownerId));
        const columns: KanBanColumn[] = [
          ...users,
          ...(itemsOutTheProject?.length
            ? ([{ userID: 'outOfProject', fullName: 'Utenti fuori progetto' }] as UserListPageResponseDto[])
            : []),
        ].map(({ userID, fullName, profilePhoto }) => ({
          id: userID || '',
          title: fullName || '',
          icon: (
            <Avatar
              size={'small'}
              src={profilePhoto}
              alt={fullName || 'Avatar'}
            >
              {fullName?.at(0)}
            </Avatar>
          ),
          canCollapse: true,
          isDndEnabled: true,
          hideIfEmpty: false,
        }));

        setColumnsAndColFn(
          columns,
          (item: PrsKanBanItem) =>
            columns.find(({ id }) => id === item?.ownerId) ?? columns.find(({ id }) => id === 'outOfProject'),
        );
        break;
      }
      default:
        return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedGroupBy, selectedItemType, users, filters]);

  const retrieveResponsibleUsersList = () => {
    UsersService.getUsersListData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setUsers(resp.responseObject?.value || []);
        }
      })
      .catch(() => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  const updateDeliverableStatus = (deliverable: PrsKanBanItem, newStatus: number) => {
    const values: UpdateDeliverableRequestDto = {
      ...deliverable,
      status: newStatus,
    };

    if (values?.status === deliverableStatus.chiuso) {
      values['actualEndDate'] = null;
    }

    DeliverablePageService.updateDeliverable(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updateTaskStatus = (task: PrsKanBanItem, newStatus: number) => {
    ActivityService.updateActivity({
      projectID: task.projectID,
      activityID: task.activityID,
      ownerID: task.ownerID,
      endDate: task.endDate,
      startDate: task.startDate,
      subject: task.subject,
      description: task.description,
      status: newStatus,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updateDeliverableOwner = (deliverable: PrsKanBanItem, newStatus: string) => {
    DeliverablePageService.updateDeliverable({
      ...deliverable,
      ownerID: newStatus,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updateTaskOwner = (task: PrsKanBanItem, newStatus: string) => {
    ActivityService.updateActivity({
      projectID: task.projectID,
      activityID: task.activityID,
      endDate: task.endDate,
      startDate: task.startDate,
      subject: task.subject,
      description: task.description,
      status: task.status,
      ownerID: newStatus,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updateDeliverableMonth = async (deliverable: PrsKanBanItem, newStatus: string) => {
    if (!deliverable.startDate || !deliverable.endDate || !project?.startDate || !project?.endDate) return;

    const locale = i18n.language === 'it' ? it : en;
    const newStatusDate = parse(newStatus, 'MMM yyyy', new Date(), { locale });
    const newMonth = getMonth(newStatusDate);
    const newYear = getYear(newStatusDate);

    const projectStartDate = new Date(project?.startDate);
    const projectEndDate = new Date(project?.endDate);

    let startDate = new Date(deliverable.startDate);

    let newEndDateDate = setMonth(new Date(deliverable.endDate), newMonth);
    newEndDateDate = setYear(newEndDateDate, newYear);
    newEndDateDate = endOfMonth(newEndDateDate);

    // handle if the startDate is after the endDate
    if (startDate > newEndDateDate) {
      startDate = startOfMonth(newEndDateDate);
    }

    // handle if the startDate and endDate are out of the project range
    if (startDate < projectStartDate || newEndDateDate > projectEndDate) {
      message.error(notifyMessages.updateFailed);
      return;
    }

    // handle the parent deliverable endDate update

    let shouldUpdateParentDeliverableStartDate = false;
    let shouldUpdateParentDeliverableEndDate = false;

    if (deliverable.parentDeliverableID) {
      const res = await DeliverablePageService.getDeliverableById(deliverable.parentDeliverableID);
      deliverable.parentDeliverableEndDate = res.data.responseObject?.value?.endDate;
      deliverable.parentDeliverableStartDate = res.data.responseObject?.value?.startDate;
    }

    if (deliverable.parentDeliverableEndDate && deliverable.parentDeliverableStartDate) {
      // console.log('has parent deliverable', deliverable.parentDeliverableEndDate);
      const parentDeliverableEndDate = new Date(deliverable.parentDeliverableEndDate);
      const parentDeliverableStartDate = new Date(deliverable.parentDeliverableStartDate);

      if (newEndDateDate < parentDeliverableStartDate) {
        const confirmation = await confirm({
          message: t('deliverablePage.startDateGreaterThanWarning'),
          okText: t('general.si'),
          cancelText: t('general.no'),
        });

        if (!confirmation) {
          void message.info(notifyMessages.updateCancelled);

          return;
        }

        shouldUpdateParentDeliverableStartDate = true;
      }

      if (newEndDateDate > parentDeliverableEndDate) {
        // setShowExtendParentDeliverableConfirmation(true);

        const confirmation = await confirm({
          message: t('deliverablePage.endDateGreaterThanWarning'),
          okText: t('deliverablePage.extendDate'),
          cancelText: t('general.no'),
        });

        if (confirmation) {
          shouldUpdateParentDeliverableEndDate = true;
        }
      }
    } else {
      console.log('has no parent deliverable');
    }

    const newStartDateString = format(startDate, 'yyyy-MM-dd');
    const newEndDateString = format(newEndDateDate, 'yyyy-MM-dd');

    DeliverablePageService.updateDeliverable({
      deliverableID: deliverable.deliverableID,
      ownerID: deliverable.ownerId,
      actualEndDate: deliverable.actualEndDate,
      status: deliverable.status,
      parentDeliverableID: deliverable.parentDeliverableID,
      projectID: deliverable.projectID,
      rescheduledEndDate: deliverable.rescheduledEndDate,
      budget: deliverable.budget,
      genericResponsible: deliverable.genericResponsible,
      isGenericResponsabile: deliverable.isGenericResponsabile,
      priority: deliverable.priority,
      name: deliverable.name,

      updateParentStartDate: shouldUpdateParentDeliverableStartDate,
      updateParentEndDate: shouldUpdateParentDeliverableEndDate,
      startDate: newStartDateString,
      endDate: newEndDateString,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updatePhaseMonth = async (phase: PrsKanBanItem, newStatus: string) => {
    if (!phase.startDate || !phase.endDate || !project?.startDate || !project?.endDate) return;

    const locale = i18n.language === 'it' ? it : en;
    const newStatusDate = parse(newStatus, 'MMM yyyy', new Date(), { locale });
    const newMonth = getMonth(newStatusDate);
    const newYear = getYear(newStatusDate);

    const projectStartDate = new Date(project?.startDate);
    const projectEndDate = new Date(project?.endDate);

    let startDate = new Date(phase.startDate);

    let newEndDateDate = setMonth(new Date(phase.endDate), newMonth);
    newEndDateDate = setYear(newEndDateDate, newYear);
    newEndDateDate = endOfMonth(newEndDateDate);

    // handle if the startDate is after the endDate
    if (startDate > newEndDateDate) {
      startDate = startOfMonth(newEndDateDate);
    }

    // handle if the startDate and endDate are out of the project range
    if (startDate < projectStartDate || newEndDateDate > projectEndDate) {
      message.error(notifyMessages.updateFailed);
      return;
    }

    // check if the endDate is greater than the endDate of the children
    let canUpdate = true;
    if (phase.childrenCount && phase.childrenCount > 0) {
      const res = await DeliverablePageService.getDeliverableById(phase.deliverableID);
      phase = toKanBanItems([res.data.responseObject?.value])[0];
      console.log('🚀 ~ updatePhaseMonth ~ phase:', phase);

      if (phase.childs && phase.childs.length > 0) {
        for (const child of phase.childs) {
          if (child.startDate && child.endDate) {
            if (new Date(child.endDate) > newEndDateDate) {
              canUpdate = false;
              break;
            }
          }
        }
      }

      if (!canUpdate) {
        const confirmation = await confirm({
          message: t('deliverablePage.childrenEndDateGreaterThanWarning'),
          okText: t('general.understood'),
          showCancelButton: false,
        });

        if (!confirmation) {
          void message.info(notifyMessages.updateCancelled);

          return;
        }
      }
    }

    if (!canUpdate) {
      return;
    }

    const newStartDateString = format(startDate, 'yyyy-MM-dd');
    const newEndDateString = format(newEndDateDate, 'yyyy-MM-dd');

    DeliverablePageService.updateDeliverable({
      deliverableID: phase.deliverableID,
      ownerID: phase.ownerId,
      actualEndDate: phase.actualEndDate,
      status: phase.status,
      parentDeliverableID: phase.parentDeliverableID,
      projectID: phase.projectID,
      rescheduledEndDate: phase.rescheduledEndDate,
      budget: phase.budget,
      genericResponsible: phase.genericResponsible,
      isGenericResponsabile: phase.isGenericResponsabile,
      priority: phase.priority,
      name: phase.name,
      startDate: newStartDateString,
      endDate: newEndDateString,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch(() => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updateTaskMonth = (task: PrsKanBanItem, newStatus: string) => {
    if (!task.startDate || !task.endDate) return;
    if (!project?.startDate || !project?.endDate) return;

    const locale = i18n.language === 'it' ? it : en;
    const newStatusDate = parse(newStatus, 'MMM yyyy', new Date(), { locale });
    const newMonth = getMonth(newStatusDate);
    const newYear = getYear(newStatusDate);

    const projectStartDate = new Date(project?.startDate);
    const projectEndDate = new Date(project?.endDate);

    let startDate = new Date(task.startDate);

    let newEndDateDate = setMonth(new Date(task.endDate), newMonth);
    newEndDateDate = setYear(newEndDateDate, newYear);
    newEndDateDate = endOfMonth(newEndDateDate);

    if (startDate > newEndDateDate) {
      startDate = startOfMonth(newEndDateDate);
    }

    if (startDate < projectStartDate || newEndDateDate > projectEndDate) {
      message.error(notifyMessages.updateFailed);
      return;
    }

    const newStartDateString = format(startDate, 'yyyy-MM-dd');
    const newEndDateString = format(newEndDateDate, 'yyyy-MM-dd');

    ActivityService.updateActivity({
      projectID: task.projectID,
      activityID: task.activityID,
      endDate: newEndDateString,
      startDate: newStartDateString,
      subject: task.subject,
      description: task.description,
      status: task.status,
      ownerID: task.ownerID,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      });
  };

  const updateCardStatus = (kanbanItem: PrsKanBanItem, newStatus: any) => {
    const isPhaseOrDeliverable = [PrsItemType.phase, PrsItemType.deliverable].includes(
      kanbanItem.itemType as PrsItemType,
    );
    const isPhase = kanbanItem.itemType === PrsItemType.phase;
    const isDeliverable = kanbanItem.itemType === PrsItemType.deliverable;
    const isTask = kanbanItem.itemType === PrsItemType.task;

    switch (selectedGroupBy) {
      case GroupBy.MONTH:
        if (isPhase) {
          updatePhaseMonth(kanbanItem, newStatus);
        } else if (isDeliverable) {
          updateDeliverableMonth(kanbanItem, newStatus);
        } else if (isTask) {
          updateTaskMonth(kanbanItem, newStatus);
        }
        break;

      case GroupBy.STATUS:
        if (isPhaseOrDeliverable) {
          updateDeliverableStatus(kanbanItem, newStatus);
        } else if (kanbanItem.itemType === 'task') {
          updateTaskStatus(kanbanItem, newStatus);
        }
        break;

      case GroupBy.OWNER:
        if (isPhaseOrDeliverable) {
          updateDeliverableOwner(kanbanItem, newStatus);
        } else if (kanbanItem.itemType === 'task') {
          updateTaskOwner(kanbanItem, newStatus);
        }
        break;

      default:
        break;
    }
  };

  const groupByPhase = (arr: PrsKanBanItem[]) => {
    const key: keyof PrsKanBanItem = 'parentDeliverableID';

    return arr.reduce((result: PrsKanBanItem[], item: PrsKanBanItem) => {
      const groupKey = item[key];
      let group: any = result.find((g) => (g as any).id === groupKey);

      if (!group) {
        group = {
          id: groupKey,
          labelContent: (
            <>
              <div className="tw-text-sm tw-font-medium tw-text-violet-700 tw-rounded tw-bg-violet-100 tw-gap-1 tw-border tw-border-violet-700/20 tw-py-0.5 tw-px-1 tw-flex tw-items-center">
                <div className="tw-shrink-0 tw-rounded-md tw-flex tw-items-center tw-justify-center">
                  <FlagOutlined className="!tw-text-violet-700 tw-text-lg" />
                </div>
                {item.parentDeliverableName}
              </div>
            </>
          ),
          items: [],
        };
        result.push(group);
      }

      group.items.push(item);
      return result;
    }, []);
  };

  const onFinish = (values: any) => {
    setFilters(values);
  };

  const itemTypeOptions = () => [
    {
      key: 'phase',
      label: <span className="tw-ml-2">{t('general.phase')}</span>,
      icon: <PhaseIcon />,
    },
    {
      key: 'deliverable',
      label: <span className="tw-ml-2">{t('general.deliverable')}</span>,
      icon: <DeliverableIcon />,
    },
    {
      key: 'task',
      label: <span className="tw-ml-2">{t('general.activity')}</span>,
      icon: <TaskIcon />,
    },
  ];

  const groupByOptions = () => [
    {
      key: 'month',
      label: <span className="tw-ml-2">{t('general.month')}</span>,
      icon: <RiCalendar2Line />,
    },
    {
      key: 'status',
      label: <span className="tw-ml-2">{t('general.status')}</span>,
      icon: <RiRecordCircleLine />,
    },
    {
      key: 'owner',
      label: <span className="tw-ml-2">{t('general.owner')}</span>,
      icon: <RiUser6Line />,
    },
  ];

  return (
    <>
      <TableLayout title={
        <ProjectSectionTitle title={t('proggetiPage.projectReviewSystem')} />
      }>
        <TableLayout.ExtraTitle>
          <Tooltip title={t('general.listView')}>
            <Button
              data-testid="go-to-prs-list"
              className={'tw-fill-black'}
              onClick={() => {
                addQueryParam('view', 'list');
                setView('list');
              }}
              icon={
                <List
                  className={classNames('tw-fill-black', {
                    'tw-fill-primary': view === 'list',
                  })}
                />
              }
            ></Button>
          </Tooltip>
          <ButtonGroup>
            <Button
              className="tw-bg-primary/10"
              icon={<KanBanView className={'tw-fill-primary'} />}
            ></Button>

            {view === 'board' && (
              <>
                <Dropdown
                  placement="bottomLeft"
                  menu={{
                    items: itemTypeOptions(),
                    onClick: (e) => {
                      addQueryParam('selectedItemType', e.key);
                      setSelectedItemType(e.key as PrsItemType);
                    },
                  }}
                >
                  <Button
                    className="tw-capitalize"
                    onClick={(e) => e.preventDefault()}
                  >
                    {t(`general.${selectedItemType}`)}
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  placement="bottomLeft"
                  menu={{
                    items: groupByOptions(),
                    onClick: (e) => {
                      addQueryParam('selectedGroupBy', e.key);
                      setSelectedGroupBy(e.key as GroupBy);
                    },
                  }}
                >
                  <Button
                    className="tw-capitalize"
                    onClick={(e) => e.preventDefault()}
                  >
                    Per {t(`general.${selectedGroupBy}`)}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            )}
          </ButtonGroup>
        </TableLayout.ExtraTitle>
        <TableLayout.Actions>
          <Button
            className={classNames('tw-fill-black', {
              '!tw-bg-primary/10 !tw-text-primary': isFilterDrawerOpen,
            })}
            onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)}
            icon={<RiSettingsLine />}
          />

          <Search
            value={filters.query}
            onChange={(e) => {
              setFilters({ ...filters, query: e.currentTarget.value });
            }}
            placeholder={t('general.search')}
          />
          {newBtn}
        </TableLayout.Actions>
        <TableLayout.Content>
          <div className="tw-flex tw-size-full tw-relative tw-overflow-hidden">
            <div className="tw-flex tw-grow tw-relative tw-gap-4 tw-h-full tw-w-full tw-overflow-x-auto tw-animate-fadeIn">
              <KanBan
                // function for dnd - when a card is dropped
                onDropFn={updateCardStatus}
                // function to group cards into the column
                colGroupFn={selectedItemType === 'deliverable' ? groupByPhase : undefined}
                // function to get the column for a given item
                colFn={colFn}
                // list of items
                items={items}
                // list of columns
                columns={columns}
              />
            </div>

            <PrsKanBanFiltersDrawer
              onFinish={onFinish}
              isOpen={isFilterDrawerOpen}
              onClose={() => setIsFilterDrawerOpen(false)}
              selectedGroupBy={selectedGroupBy}
              projectId={project?.projectID}
            />
          </div>
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default PrsKanBanView;
