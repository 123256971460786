import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  AnnualGoalApi,
  type CreateAnnualGoalRequest,
  type UpdateAnnualGoalXRequestDto,
  XMatrixRelationApi,
} from 'src/connectors/backend';

const ObAnnoService = {
  updateObiettivoAnnoxMartix: async (payload: UpdateAnnualGoalXRequestDto) => {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalUpdateAnnualGoalXMatrix(payload));
  },

  addObiettivoAnnoxMartix: async (payload: CreateAnnualGoalRequest) => {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalCreateAnnualGoal(payload));
  },

  removeObAnno: async (annualGoalId: string, xmatrixCode: string) => {
    const api = await authenticatedApi(XMatrixRelationApi);

    return trackPromise(api.xMatrixRelationDeleteAnnualGoalXMatrixRelation(xmatrixCode, annualGoalId));
  },
};

export default ObAnnoService;
