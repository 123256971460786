import type React from 'react';
import Feed from '../../shared/feed/feed';
import { feedObjectType, feedRegardingObjectType } from '../../shared/utils/constants';

interface LongTermFeedProps {
  longTermId: string | undefined;
  defaultCheckedFeedType: number;
  commentFeedsOnly: boolean;
  fileFeedsOnly: boolean;
  othersFeedsOnly: boolean;
}

const LongTermFeed: React.FC<LongTermFeedProps> = ({
  longTermId,
  defaultCheckedFeedType,
  commentFeedsOnly,
  fileFeedsOnly,
  othersFeedsOnly,
}) => {
  return (
    <Feed
      commentFeedsOnly={commentFeedsOnly}
      fileFeedsOnly={fileFeedsOnly}
      othersFeedsOnly={othersFeedsOnly}
      objectId={longTermId}
      defaultCheckedFeedType={defaultCheckedFeedType}
      objectType={feedObjectType.obLongTermFeed}
      isProject={0}
      feedRegardingObjType={feedRegardingObjectType.longTermGoal}
    />
  );
};

export default LongTermFeed;
