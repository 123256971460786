import { Avatar, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import TableLayout from '../../../shared/tableLayout';

import type { FC } from 'react';
import type { CommitteeDto, CommitteeMemberDto } from 'src/connectors/backend';
import { ParticipantIcon, StarIcon } from '../../../../assets/icons';

const CommitieTeam: FC<{
  committee: CommitteeDto;
}> = ({ committee }) => {
  const { t } = useTranslation();
  const members = committee.members ?? [];

  return (
    <>
      <TableLayout
        title={
          <Typography.Title
            className="!tw-mb-0"
            level={4}
          >
            {t('comitatiPage.team')}
          </Typography.Title>
        }
      >
        <TableLayout.Content>
          {committee && (
            <Table
              size="small"
              dataSource={members}
              rowKey={(obj) => `${obj.committeeId}${obj.userId}`}
              pagination={false}
            >
              <Column
                key="name"
                title={t('general.nome') as string}
                sorter={(a: CommitteeMemberDto, b: CommitteeMemberDto) => {
                  return a.userFullName.localeCompare(b.userFullName);
                }}
                width="110px"
                fixed="left"
                render={(member) => {
                  return (
                    <div className="table-avatar">
                      <Avatar size="small">{member.userInitials.toUpperCase()}</Avatar>
                      <span key={member.userId}>{member.userFullName}</span>
                    </div>
                  );
                }}
              />
              <Column
                key="role"
                title={t('general.ruolo') as string}
                width="110px"
                fixed="left"
                render={(member) => {
                  return (
                    <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-border tw-rounded-md tw-border-zinc-200 tw-w-fit tw-px-2 tw-py-1">
                      {member.isRevisori ? <StarIcon /> : <ParticipantIcon />}
                      <span>{member.isRevisori ? t('comitatiPage.revisore') : t('comitatiPage.participant')}</span>
                    </div>
                  );
                }}
              />
            </Table>
          )}
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default CommitieTeam;
