import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { A3PageApi, KPIApi, type UpdateGoalGroundActA3RequestDto } from 'src/connectors/backend';

const Section1Service = {
  // get goal around to act a3
  getGoal: async (projectId: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGoalGroundtoActA3(projectId));
  },

  updateSection1: async (payload: UpdateGoalGroundActA3RequestDto) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageUpdateGoalGroundtoActA3(payload));
  },

  getKPI: async (projectId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPISectionA3(projectId));
  },
};

export default Section1Service;
