import { Table, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import type React from 'react';
import { useState } from 'react';
import '../../../matrix.scss';

interface UserRecord {
  userID: string;
  title: string;
}

interface UsersLevel2Props {
  usersXmatrix: UserRecord[];
  onRowClick: (userID: string) => void;
  selection: 'primary' | 'secondary';
  selectedIds: string[];
}

const UsersLevel2: React.FC<UsersLevel2Props> = ({ usersXmatrix, onRowClick, selection, selectedIds }) => {
  const [modalState, setModalState] = useState<{ open: boolean; data: UserRecord | null }>({
    open: false,
    data: null,
  });

  const columns = [
    {
      key: 'combinedColumn',
      render: (_text: string, record: UserRecord, index: number) => ({
        children: (
          <div
            onClick={() => onRowClick(record.userID)}
            className={`tw-flex tw-py-1 tw-h-full tw-w-[32px] tw-items-center tw-justify-between ${classNames({
              'tw-bg-primary selected-row': selection === 'primary' && selectedIds?.includes(record.userID),
              'tw-bg-primary/30 selected-row': selection === 'secondary' && selectedIds?.includes(record.userID),
            })}`}
          >
            <div className="tw-flex tw-items-center tw-gap-2">
              <div
                className="xMatrix-square-label tw-rotate-90 !tw-bg-zinc-100"
                key={index}
              >
                {index + 1}
              </div>
              <Tooltip title={record.title}>
                <Typography.Text className="tw-text-base tw-truncate tw-text-black">{record.title}</Typography.Text>
              </Tooltip>
            </div>
          </div>
        ),
      }),
    },
  ];

  return (
    <>
      <Table
        className="[&_table]:tw-h-full"
        size="small"
        dataSource={usersXmatrix}
        pagination={false}
        bordered
        showHeader={false}
        columns={columns}
        rowKey={(record) => record.userID}
      />
    </>
  );
};

export default UsersLevel2;
