import { Component, useState } from 'react';
import './resourceWorkload.scss';

import {
  EditOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  SaveOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ResourceWorkloadService from '../../services/pages/resourceWorkloadServices';
import TableLayout from '../shared/tableLayout';
import { isTeamleaderOrTeamMember, isUserAdminUser } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { projectWorkloadColumNames, workloadColumnNamesValues, workloadTypeValues } from '../shared/utils/constants';
import { capitalizeFirstLetter, numberWithCommas } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';

const { Text } = Typography;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = (
    <InputNumber
      min={0}
      decimalSeparator=","
      precision="2"
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    setSearchText('');
  };

  const isEditableRow = (record) =>
    record.Name === workloadColumnNamesValues.budget && record.Type === workloadTypeValues.user ? true : false;

  const isUser = (record) => (record.Type === workloadTypeValues.user ? true : false);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      // name: '',
      // age: '',
      // address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, record) => {
    try {
      const values = await form.getFieldsValue(null, (meta) => meta.touched);
      props.updateRow(values, record);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const renderedColumns = () => {
    const columns = [];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      const firstRow = tabelData[0];

      for (const col in firstRow) {
        if (col === projectWorkloadColumNames.name) {
          columns.push({
            dataIndex: col,
            title: '',
            editable: false,
            width: '9%',
            ellipsis: {
              showTitle: false,
            },
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === workloadColumnNamesValues.consuntivo ? '2px solid #6f6f6f' : '',
                  },
                },
                // children:   <Tooltip  title={convertCamelCaseToNormal(text)}>
                //   {isEditableRow(record) ? <Text strong>{convertCamelCaseToNormal(text)}</Text> : <Text>{convertCamelCaseToNormal(text)}</Text>}
                children: (
                  <Tooltip title={props.t(`budgetTypes.${text}`)}>
                    {isEditableRow(record) ? (
                      <Text strong>{props.t(`budgetTypes.${text}`)}</Text>
                    ) : (
                      <Text>{props.t(`budgetTypes.${text}`)}</Text>
                    )}
                  </Tooltip>
                ),
              };
            },
          });
        } else if (col === projectWorkloadColumNames.fullName) {
          columns.push({
            // ...getColumnSearchProps(col),
            ...(props.isTotal ? '' : getColumnSearchProps(col)),
            dataIndex: col,
            title: '',
            fixed: 'left',
            width: '9%',
            editable: false,
            ellipsis: {
              showTitle: false,
            },
            render: (text, row, index) => {
              const val = props.isTotal ? (
                <Tooltip title={props.t(`budgetTypes.${text}`)}>{props.t(`budgetTypes.${text}`)}</Tooltip>
              ) : (
                <Tooltip title={text}>
                  {/* {text} */}
                  {props.isRoleAdmin && <Link to={{ pathname: `/profile/${row.UserID}/3` }}>{text}</Link>}
                  {!props.isRoleAdmin && text}
                </Tooltip>
              );
              const obj = {
                children: val,
                props: {},
              };
              if (index % 4 === 0) {
                obj.props.rowSpan = 4;
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            },
          });
        } else if (
          col === projectWorkloadColumNames.userID ||
          col === projectWorkloadColumNames.key ||
          col === projectWorkloadColumNames.type
        ) {
          continue;
        } else if (col === projectWorkloadColumNames.total) {
          columns.push({
            align: 'right',
            width: '6%',
            dataIndex: col,
            // title: col,
            title: `${props.t('general.totale')}`,
            editable: false,
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === workloadColumnNamesValues.consuntivo ? '2px solid #6f6f6f' : '',
                    background: Number.parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text strong>{numberWithCommas(text)}</Text>
                ) : (
                  <Text>{numberWithCommas(text)}</Text>
                ),
              };
            },
          });
        } else {
          const month = moment(col).format('MMM YYYY');
          columns.push({
            align: 'right',
            dataIndex: col,
            title: capitalizeFirstLetter(month),
            editable: true,
            width: '6%',
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === workloadColumnNamesValues.consuntivo ? '2px solid #6f6f6f' : '',
                    background: Number.parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text strong>{numberWithCommas(text)}</Text>
                ) : (
                  <Text>{numberWithCommas(text)}</Text>
                ),
              };
            },

            // render: (text, record) => (isEditableRow(record) ? <Text strong style={{ color: parseInt(text) < 0 ? "#f92f2f" : "" }}>{numberWithCommas(text)}</Text> : <Text style={{ color: parseInt(text) < 0 ? "#f92f2f" : "" }}>{numberWithCommas(text)}</Text>)
          });
        }
      }

      if (!props.isRoleTeamLeaderOrTeamMember) {
        if (isUser) {
          columns.push({
            title: '',
            dataIndex: 'operation',
            width: '10%',

            render: (_, record) => {
              if (isEditableRow(record)) {
                const editable = isEditing(record);
                return editable ? (
                  <span>
                    {props.loadingIcon && (
                      <SyncOutlined
                        style={{ color: '#0f87dc', marginRight: 5 }}
                        spin
                      />
                    )}
                    <Typography.Link onClick={() => save(record.key, record)}>
                      <SaveOutlined style={{ marginRight: 5 }} />
                      {props.t('buttons.salva')}
                    </Typography.Link>
                    <Divider
                      type="vertical"
                      style={{ borderLeft: '1px solid rgb(183 166 166)' }}
                    />
                    <Typography.Link onClick={() => cancel()}>{props.t('buttons.chiudi')}</Typography.Link>
                  </span>
                ) : (
                  <Typography.Link
                    disabled={editingKey !== ''}
                    onClick={() => edit(record)}
                  >
                    <EditOutlined /> {props.t('buttons.modifica')}
                  </Typography.Link>
                );
              }
            },
          });
        }
      }
      return columns;
    }
    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}
    >
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={props.propsData}
        columns={mergedColumns}
        size="small"
        pagination={false}
        rowClassName={(record) => (record.Type === workloadTypeValues.total ? 'table-row-project' : '')}
        scroll={{ x: 'calc(700px + 50%)' }}
        loading={props.loadingData || props.loadingTotal}
      />
    </Form>
  );
};

class ResourceWorkload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      loadingTotal: false,
      resourceWorkloadData: null,
      totalResourceWorkloadData: null,
      selectedYear: null,
      clearFilters: false,
    };
  }

  componentWillMount() {
    this.getResourceWorkload();
    this.totalGetResourceWorkload();
  }

  async getResourceWorkload() {
    const { selectedYear } = this.state;
    const currentYear = moment().format('YYYY');
    const fromatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;
    this.setState({ loadingData: true });
    await ResourceWorkloadService.getResourceWorkloadData(fromatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.map((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectWorkloadColumNames.fullName &&
                col !== projectWorkloadColumNames.name &&
                col !== projectWorkloadColumNames.userID &&
                col !== projectWorkloadColumNames.type
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });

          this.setState({ resourceWorkloadData: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  async totalGetResourceWorkload() {
    const { selectedYear } = this.state;
    var currentYear = moment().format('YYYY');
    const fromatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;
    this.setState({ loadingTotal: true });
    await ResourceWorkloadService.getTotalResourceWorkloadData(fromatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.map((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectWorkloadColumNames.fullName &&
                col !== projectWorkloadColumNames.name &&
                col !== projectWorkloadColumNames.userID &&
                col !== projectWorkloadColumNames.type
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });

          this.setState({ totalResourceWorkloadData: resp.responseObject.value });
          this.setState({ loadingTotal: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingTotal: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingTotal: false });
      });
  }

  updateProjectWorkload = (values, record) => {
    const payload = {};
    let monthsArray = [];

    payload['userID'] = record.UserID;

    if (values) {
      for (const item in values) {
        monthsArray.push({ referencePeriod: item, value: values[item] });
      }
    } else {
      monthsArray = [];
    }

    payload['months'] = monthsArray;

    this.setState({ loadingButton: true });
    ResourceWorkloadService.updateUserPlanning(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.getResourceWorkload();
          this.totalGetResourceWorkload();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.getResourceWorkload();
      this.totalGetResourceWorkload();
    });
  };

  onClearFilter = () => {
    this.setState({ selectedYear: 0, clearFilters: true }, () => {
      this.getResourceWorkload();
      this.totalGetResourceWorkload();
    });
  };

  render() {
    const { loadingData, loadingTotal, loadingButton, resourceWorkloadData, selectedYear, totalResourceWorkloadData } =
      this.state;
    const t = this.props.t;
    const { userData } = this.props;
    const isRoleTLorTM = userData && Object.keys(userData).length > 0 ? isTeamleaderOrTeamMember(userData) : false;
    const isRoleAdmin = userData && Object.keys(userData).length > 0 ? isUserAdminUser(userData) : false;

    return (
      <Card>
        <TableLayout title={t('caricoRisorsePage.caricoRisorse')}>
          <TableLayout.Actions>
            <DatePicker
              placeholder={t('general.anno')}
              picker="year"
              value={selectedYear}
              format="YYYY"
              onChange={(date) => this.onYearChange(date)}
            />
            <Tooltip title={t('general.filter')}>
              <Button
                icon={<FilterOutlined />}
                onClick={() => this.onFilter()}
                disabled={!selectedYear}
              />
            </Tooltip>
            <Tooltip title={t('general.clearFilters')}>
              <Button
                icon={<MinusSquareOutlined />}
                onClick={() => this.onClearFilter()}
                disabled={selectedYear ? false : true}
              />
            </Tooltip>
          </TableLayout.Actions>
          <TableLayout.Content>
            <Typography.Title level={5}>{t('caricoRisorsePage.workloadTotale')}</Typography.Title>
            <EditableTable
              t={t}
              propsData={totalResourceWorkloadData}
              updateRow={this.updateProjectWorkload}
              loadingIcon={loadingButton}
              isTotal={true}
              loadingTotal={loadingTotal}
              isRoleTeamLeaderOrTeamMember={isRoleTLorTM}
            />

            <Typography.Title
              className="tw-mt-8"
              level={5}
            >
              {t('caricoRisorsePage.workloadUtente')}
            </Typography.Title>
            <EditableTable
              t={t}
              userData={userData}
              isRoleAdmin={isRoleAdmin}
              propsData={resourceWorkloadData}
              updateRow={this.updateProjectWorkload}
              loadingIcon={loadingButton}
              isTotal={false}
              loadingData={loadingData}
              isRoleTeamLeaderOrTeamMember={isRoleTLorTM}
            />
          </TableLayout.Content>
        </TableLayout>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ResourceWorkload));
