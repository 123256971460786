import { Avatar, Tag } from 'antd';
import React from 'react';
import { GetProjectMembersResponseDto } from 'src/connectors/backend';
import './userBadge.scss';

interface UserBadgeProps {
  user: GetProjectMembersResponseDto;
  projectId: string;
}

const UserBadge: React.FC<UserBadgeProps> = ({ user, projectId }) => {
  const userAvatarImg = () => {
    return user.profilePhoto ? <Avatar src={user.profilePhoto} /> : <Avatar>{user.initials}</Avatar>;
  };

  return (
    <div className="tw-bg-zinc-100 tw-transition-all tw-border tw-rounded-md tw-w-72 tw-shrink-0 border-b">
      <div className="tw-flex tw-items-start tw-flex-col tw-border-b tw-w-full tw-p-2 tw-pb-6">
        <div className="tw-flex tw-items-center tw-gap-2">
          {userAvatarImg()}
          <div className="tw-flex tw-flex-col tw-items-start">
            <h4 className="tw-text-lg tw-truncate tw-w-56">{user.fullName}</h4>
            <p className="tw-text-sm tw-truncate tw-opacity-50 tw-w-56"> {user.emailAddress}</p>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-text-sm tw-flex-col tw-gap-1 tw-p-2">
        <div className="tw-flex tw-text-rest tw-gap-2 tw-items-center">
          <Tag>{user.role}</Tag>
        </div>
      </div>
    </div>
  );
};

export default UserBadge;
