import { Table, Tabs, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { DeliverableDto } from 'src/connectors/backend';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import { formatOfDate, mapDeliverableStatus } from '../../shared/utils/constants';
import { capitalizeFirstLetter } from '../../shared/utils/functions';
import PerStatusPhasesChart from './perStatusPhasesChart';
import PerTimePhasesChart from './perTimePhasesChart';

const PersonalDashboardPhases: React.FC = () => {
  const { t } = useTranslation();
  const [phases, setPhases] = useState<DeliverableDto[]>([]);

  useEffect(() => {
    fetchPersonalDashboardPhases();
  }, []);

  const phasesColumns = [
    {
      title: t('general.nome'),
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      showSorterTooltip: true,
    },
    {
      title: t('general.stato'),
      dataIndex: 'status',
      render: (text: keyof typeof mapDeliverableStatus) => (
        <Tag>{capitalizeFirstLetter(mapDeliverableStatus[text])}</Tag>
      ),
    },
    {
      title: t('general.end'),
      dataIndex: 'endDate',
      render: (text: string) => (text ? moment(text).format(formatOfDate) : ''),
    },
  ];

  const tabItems = [
    {
      label: t('bachechaPersonalePage.perStatus'),
      key: '1',
      children: <PerStatusPhasesChart phases={phases} />,
    },
    {
      label: t('bachechaPersonalePage.inTempoRitardo'),
      key: '2',
      children: <PerTimePhasesChart phases={phases} />,
    },
  ];

  const fetchPersonalDashboardPhases = async () => {
    try {
      const response = await PersonalDashboardService.getPersonalDashboardPhases();
      if (response?.data.success) {
        setPhases(response.data?.responseObject?.value || []);
      }
    } catch (error) {
      console.error('Error fetching personal dashboard phases', error);
    }
  };

  return (
    <>
      <Tabs
        className="tw-px-2 no-sticky"
        defaultActiveKey="1"
        items={tabItems}
      />
      <Table
        className="table-full-height bottom-paginator"
        columns={phasesColumns}
        dataSource={phases}
        rowKey={(record, index) => record.deliverableID}
        loading={!phases.length}
        size="small"
        pagination={{ defaultPageSize: 5, pageSizeOptions: ['5', '10', '20', '50'] }}
      />
    </>
  );
};

export default PersonalDashboardPhases;
