import { trackPromise } from 'react-promise-tracker';
import { XMatrixRelationApi, authenticatedApi } from 'src/config/connectors';

const ObKpiService = {
  // add kpi in matrix
  addObKpi: async (xMatrixId: string, kpiDetailId: string) => {
    const api = await authenticatedApi(XMatrixRelationApi);

    return trackPromise(api.xMatrixRelationCreateKpiDetailXMatrixRelation(xMatrixId, kpiDetailId));
  },

  // remove kpi xmatrix
  removeKpi: async (xMatrixId: string, kpiId: string) => {
    const api = await authenticatedApi(XMatrixRelationApi);

    return trackPromise(api.xMatrixRelationDeleteKpiXMatrixRelation(xMatrixId, kpiId));
  },
};

export default ObKpiService;
