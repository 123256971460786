import { Form } from 'antd';
import { Component } from 'react';

import { Col, DatePicker, Divider, Modal, Row, Select, Transfer } from 'antd';
import moment from 'moment';
import MatrixService from '../../../services/matrix/matrixService';
import Loader2 from '../../shared/components/loader2/loader2';
import ModalFooterButtons from '../../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../../shared/utils/notifyMessages';
import '../matrix.scss';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class NewSecondLevelXM extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      annualGoalList: [],
      loadingAnnualGoalList: false,
      loadingProjectList: false,
      annualGoalTargetKeys: [],
      projectGoalList: [],
      projectTargetKeys: [],

      longTermGoalList: [],
      loadingLongTermGoalList: false,
      longTermTargetKeys: [],

      divisionList: null,
      loadingDevisions: null,
      showSections: false,
    };
  }

  componentDidMount() {
    this.getDivisions();
  }

  getDivisions() {
    this.setState({ loadingDevisions: true });
    MatrixService.getDivisionPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionList: resp.responseObject.value });
          this.setState({ loadingDevisions: false });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveAnnualGoalsPicklist(divisionId) {
    const { selectedXmatrixData } = this.props;

    this.setState({ loadingAnnualGoalList: true });
    await MatrixService.getNewDivisionXMatrixAnnualGoalPicklist(selectedXmatrixData.xmatrixID, divisionId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const obAnnoData = resp.responseObject.value.map((item) => ({
            key: item.annualGoalID,
            description: item.description,
            //orderingValue: item.orderingValue
          }));

          this.setState({ annualGoalList: obAnnoData });
          this.setState({ loadingAnnualGoalList: false });
        } else {
          this.setState({ loadingAnnualGoalList: false });
        }
      })
      .catch(() => {
        this.setState({ loadingAnnualGoalList: false });
      });
  }

  async retrieveLongTermGoalsPicklist(divisionId) {
    const { selectedXmatrixData } = this.props;
    this.setState({ loadingLongTermGoalList: true });

    await MatrixService.getNewDivisionXMatrixLongTermGoalPicklist(selectedXmatrixData.xmatrixID, divisionId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const obLongData = resp.responseObject.value.map((item) => ({
            key: item.longTermGoalID,
            description: item.title,
            // orderingValue: item.orderingValue
          }));

          this.setState({ longTermGoalList: obLongData });
          this.setState({ loadingLongTermGoalList: false });
        } else {
          this.setState({ loadingLongTermGoalList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingLongTermGoalList: false });
      });
  }

  async retrieveProjects(divisionID) {
    const { selectedXmatrixData } = this.props;

    this.setState({ loadingProjectList: true });
    await MatrixService.getNewDivisionXMatrixProjectPicklist(selectedXmatrixData.xmatrixID, divisionID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const mockData = resp.responseObject.value.map((item) => ({
            key: item.projectID,
            description: item.name,
          }));

          this.setState({ projectGoalList: mockData });
          this.setState({ loadingProjectList: false });
        } else {
          this.setState({ loadingProjectList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingProjectList: false });
      });
  }

  handleChangeAnnualGoal = (targetKeys) => {
    this.setState({ annualGoalTargetKeys: targetKeys });
  };

  handleChangeProject = (targetKeys) => {
    this.setState({ projectTargetKeys: targetKeys });
  };

  handleChangeLongTermGoal = (targetKeys) => {
    this.setState({ longTermTargetKeys: targetKeys });
  };

  disablePreviousYears = (current, lastXmatrixYear) => {
    const curr = moment(current).year();
    const date = moment(lastXmatrixYear).year();
    return curr <= date;
  };

  onDivisionSelect = (divisionId) => {
    this.setState({ showSections: true });
    this.retrieveAnnualGoalsPicklist(divisionId);
    this.retrieveLongTermGoalsPicklist(divisionId);
    this.retrieveProjects(divisionId);
  };

  addData = (values) => {
    const { annualGoalTargetKeys, projectTargetKeys, longTermTargetKeys } = this.state;
    const { selectedXmatrixData } = this.props;

    values.year = moment(values.year).startOf('year').format('YYYY-MM-DD');
    values['parentXMatrixID'] = selectedXmatrixData.xmatrixID;

    values['projectID'] = projectTargetKeys;
    values['annualGoalID'] = annualGoalTargetKeys;
    values['longtermGoalID'] = longTermTargetKeys;

    this.props.handleModalSave(values);
  };

  render() {
    const { showModal, handleModalClose, loadingButton, selectedXmatrixData, t } = this.props;

    const {
      annualGoalList,
      annualGoalTargetKeys,
      projectGoalList,
      projectTargetKeys,
      loadingAnnualGoalList,
      loadingProjectList,
      longTermGoalList,
      loadingLongTermGoalList,
      longTermTargetKeys,
      divisionList,
      showSections,
      loadingDevisions,
    } = this.state;

    return (
      <Modal
        className="mew_xmatrix_modal"
        width={800}
        // title="Inserisci nuovo X-matrix"
        title={`${t('xMatrixPage.nuovaSecondoLiveloXm')}:  ${selectedXmatrixData.hkCode}`}
        open={showModal}
        destroyOnClose={true}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleModalClose}
            formId={'newSecondLevelXmatrix'}
          />,
        ]}
      >
        <Form
          id="newSecondLevelXmatrix"
          name="add_xmatrix"
          layout="vertical"
          {...formItemLayout}
          onFinish={this.addData}
          onValuesChange={() => {
            this.setState({ fieldsChanged: true });
          }}
        >
          <Row gutter={{ lg: 24 }}>
            <Col xs={{ span: 12 }}>
              <Form.Item
                label={t('general.anno')}
                name="year"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={
                  selectedXmatrixData.xMatrixYear
                    ? moment(selectedXmatrixData.xMatrixYear)
                    : selectedXmatrixData.xMatrixYear
                }
              >
                <DatePicker
                  placeholder={t('general.seleziona')}
                  picker="year"
                  style={{ width: '100%' }}
                  format="YYYY"
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 12 }}>
              <Form.Item
                label={t('general.divisione')}
                name="divisionID"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
              >
                <Select
                  showSearch
                  loading={loadingDevisions}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder={t('general.seleziona')}
                  style={{ width: '100%' }}
                  onSelect={(option) => this.onDivisionSelect(option)}
                >
                  {divisionList &&
                    divisionList.map((item) => (
                      <Option
                        value={item.divisionID}
                        key={item.divisionID}
                      >
                        {item.divisionName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={{ lg: 24 }}
            className="content-scroll"
          >
            {showSections && (
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
              >
                <Divider plain>{t('general.obbietiviAnuali')}</Divider>
                {loadingAnnualGoalList && <Loader2 />}

                {!loadingAnnualGoalList && (
                  <Transfer
                    dataSource={annualGoalList}
                    titles={['', `${t('xMatrixPage.nuovoXmatrix')}`]}
                    showSearch
                    listStyle={{
                      width: 350,
                      height: 300,
                    }}
                    targetKeys={annualGoalTargetKeys}
                    onChange={this.handleChangeAnnualGoal}
                    render={(item) => `${item.description}`}
                    oneWay={true}
                    locale={{
                      itemUnit: `${t('general.obbietiviAnuali')}`,
                      itemsUnit: `${t('general.obbietiviAnuali')}`,
                      notFoundContent: `${t('general.elencoVuoto')}`,
                      searchPlaceholder: `${t('buttons.cerca')}`,
                    }}
                  />
                )}
              </Col>
            )}

            {showSections && (
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
              >
                <Divider plain>{t('general.progetti')}</Divider>
                {loadingProjectList && <Loader2 />}

                {!loadingProjectList && (
                  <Transfer
                    dataSource={projectGoalList}
                    titles={['', `${t('xMatrixPage.nuovoXmatrix')}`]}
                    showSearch
                    listStyle={{
                      width: 350,
                      height: 300,
                    }}
                    targetKeys={projectTargetKeys}
                    onChange={this.handleChangeProject}
                    render={(item) => `${item.description}`}
                    oneWay={true}
                    locale={{
                      itemUnit: `${t('general.progetti')}`,
                      itemsUnit: `${t('general.progetti')}`,
                      notFoundContent: `${t('general.elencoVuoto')}`,
                      searchPlaceholder: `${t('buttons.cerca')}`,
                    }}
                  />
                )}
              </Col>
            )}

            {showSections && (
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
              >
                <Divider plain>{t('general.obiettiviLungo')}</Divider>
                {loadingLongTermGoalList && <Loader2 />}
                {!loadingLongTermGoalList && (
                  <Transfer
                    dataSource={longTermGoalList}
                    titles={['', `${t('xMatrixPage.nuovoXmatrix')}`]}
                    showSearch
                    listStyle={{
                      width: 350,
                      height: 300,
                    }}
                    targetKeys={longTermTargetKeys}
                    onChange={this.handleChangeLongTermGoal}
                    render={(item) => `${item.description}`}
                    oneWay={true}
                    locale={{
                      itemUnit: `${t('general.obiettiviLungo')}`,
                      itemsUnit: `${t('general.obiettiviLungo')}`,
                      notFoundContent: `${t('general.elencoVuoto')}`,
                      searchPlaceholder: `${t('buttons.cerca')}`,
                    }}
                  />
                )}
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default NewSecondLevelXM;
