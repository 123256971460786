import { message } from 'antd';
import { notifyMessages } from 'src/components/shared/utils/notifyMessages';
import type { GetMyActivityProjectFormrResponseDto, ProjectDeliverableParent } from 'src/connectors/backend';
import ActivityService from 'src/services/pages/activitiesServices';
import DeliverableService from 'src/services/pages/projectServices/deliverableServices';
import { getRandomId } from 'src/utils/randomId';

export interface PRS extends ProjectDeliverableParent, GetMyActivityProjectFormrResponseDto {
  deliverableID: string;
  key: string;
  children?: PRS[];
}

/**
 * Retrieve the list of activities and deliverables (phases) for the given project ID.
 * It will return an array of PRS objects, which have the structure of a ProjectDeliverableParent
 * and a GetMyActivityProjectFormrResponseDto, but also a key and children property.
 * The key is a random id and the children property is an array of PRS objects, which are the
 * activities under each phase or deliverable. If a phase has only one activity, the children property will be null.
 * @param {string} projectId - The id of the project to retrieve the data for.
 * @returns {Promise<PRS[]>}
 */
export const getProjectReviewSystemList = async ({ projectId }: { projectId: string }): Promise<PRS[] | undefined> => {
  let allActivities: GetMyActivityProjectFormrResponseDto[] = [];

  const res = await ActivityService.getProjectActivities(projectId!, null, null, null);
  allActivities = res.data.responseObject?.value || [];

  try {
    const response = await DeliverableService.getProjectDeliverables(projectId);
    const resp = response.data;

    if (resp.success) {
      if (!resp.responseObject?.value) return;
      const result = resp.responseObject.value.map((currentItem) => {
        const item = { ...currentItem } as PRS;
        item.key = getRandomId();

        // get tasks activities for each phase
        const activities = allActivities.filter(
          (activity: GetMyActivityProjectFormrResponseDto) => activity.deliverableID === item.deliverableID,
        );
        const activitiesUnderPhase =
          activities &&
          activities
            .filter((activity: GetMyActivityProjectFormrResponseDto) => !activity.isGenerated)
            .map((activity: GetMyActivityProjectFormrResponseDto) => {
              const item = { ...activity } as PRS;
              item.key = getRandomId();
              item.activityType = 'activity';
              return item;
            });

        if (activitiesUnderPhase && activitiesUnderPhase.length > 0) {
          item.children = [...item.children!, ...activitiesUnderPhase];
        }

        item.children = item.children!.map((child) => {
          const i = { ...child } as PRS;
          i.key = getRandomId();

          if (child.childrenCount && child.childrenCount > 1) {
            const activities = allActivities;
            child.children = [...(activities as PRS[])]
              .filter((activity) => !activity.isGenerated && activity.deliverableID === child.deliverableID)
              .map((activity) => {
                activity.key = getRandomId();
                return activity;
              });
          } else {
            delete child.children;
          }

          return child;
        });

        if (item.children.length === 0) {
          delete item.children;
        }

        return item;
      });

      return [...result];
    } else {
      message.error(notifyMessages.retrieveFailed);
    }
  } catch (error) {
    message.error(notifyMessages.retrieveFailed);
  }
};
