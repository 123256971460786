import { trackPromise } from 'react-promise-tracker';
import {
  type AddRemoveCommitteeIDProjectRequestDto,
  CommitteeApi,
  CommitteeType,
  CommonApi,
  type CreateCommitteeRequest,
  type GetCommitteeSintesiListRequest,
  type InsertUpdateAgendaRequestDto,
  type InsertUpdateCommitteeSessionRequestDto,
  type UpdateCommitteeCommentRequestDto,
  type UpdateCommitteeRequest,
  authenticatedApi,
} from 'src/config/connectors';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';
import i18n from '../../i18n';

const CommitieService = {
  // get commities
  getCommitiesList: async () => {
    const api = await authenticatedApi(CommitteeApi);

    return trackPromise(api.committeeList());
  },

  getById: async (id: string) => {
    const api = await authenticatedApi(CommitteeApi);

    return trackPromise(api.committeeGet(id));
  },

  update: async (id: string, payload: UpdateCommitteeRequest) => {
    const api = await authenticatedApi(CommitteeApi);

    return trackPromise(api.committeeUpdate(id, payload));
  },

  create: async (payload: CreateCommitteeRequest) => {
    const api = await authenticatedApi(CommitteeApi);

    return trackPromise(api.committeeCreate(payload));
  },

  delete: async (id: string) => {
    const api = await authenticatedApi(CommitteeApi);

    return trackPromise(api.committeeDelete(id));
  },

  getComitieRelatedProjects: async (committeeId: string, xmatrixId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeProjects(committeeId, xmatrixId));
  },

  getComitieRelatedComities: async (committeeId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetChildCommittee(committeeId));
  },

  getNoCommitteeProjects: async (xmatrixId: string, type: CommitteeType) => {
    const api = await authenticatedApi(CommitteeApi);

    return trackPromise(api.committeeListProjectsForCommittee(xmatrixId, type));
  },

  addRemoveProjectToCommitie: async (payload: AddRemoveCommitteeIDProjectRequestDto) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonAddRemoveCommitteeIDProject(payload));
  },

  getAvailableChildCommititesList: async (committeeId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAvailableChildCommittees(committeeId));
  },

  addCommitteeChildRelation: async (payload: { committeeID: string; childID: string }) => {
    const { committeeID: commiteeId, childID: childCommitteeId } = payload;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonAddCommitteeChildRelation(commiteeId, childCommitteeId));
  },

  removeChildComitie: async (childCommitteeId: string, committeeId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonDeleteCommitteeChild(committeeId, childCommitteeId));
  },

  getCommitieSessions: async (committeeId: string, xmatrixId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSessions(committeeId, xmatrixId));
  },

  insertUpdateSession: async (payload: InsertUpdateCommitteeSessionRequestDto) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonInsertUpdateCommitteeSession(payload));
  },

  getAgenda: async (committeeId: string, sessionId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAgenda(committeeId, sessionId));
  },

  insertUpdateAgenda: async (payload: InsertUpdateAgendaRequestDto) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonInsertUpdateAgenda(payload));
  },

  getComitieSummaryData: async (committeeId: string, xmatrixId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSummary(committeeId, xmatrixId));
  },

  getComitieActivities: async (committeeId: string, onlyClosedActivities: boolean) => {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeActivities(committeeId, currentLanguageCode, onlyClosedActivities));
  },

  getComitieActivitiesByDays: async (committeeId: string, onlyClosedActivities: boolean) => {
    const currentLangCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeActivities(committeeId, currentLangCode, onlyClosedActivities));
  },

  addComitieActicity: async (payload: any) => {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'POST',
        url: `api/Common/InsertComitieActivity`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = axiosInstance(optionsVal);
      return apiResponse;
    });
    return data;
  },

  updateComitieActicity: async (payload: any) => {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'POST',
        url: `api/Common/UpdateComitieActivity`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = axiosInstance(optionsVal);
      return apiResponse;
    });
    return data;
  },

  getCommitteeSummaryPerStatus: async (committeeId: string) => {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSummaryPerStatus(committeeId, currentLanguageCode));
  },

  getCommitteeSummaryPerTime: async (committeeId: string) => {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSummaryPerTime(committeeId, currentLanguageCode));
  },

  getCalendarData: async (committeeId: string, month: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAgendaCalendar(committeeId, month));
  },

  getComitieProjectsAndSubProjetcst: async (committeeId: string, sessionId: string, xmatrixId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeAvailableProjects(committeeId, sessionId, xmatrixId));
  },

  deleteAgenda: async (agendaId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonDeleteAgenda(agendaId));
  },

  sendAgendaByMail: async (comitteeId: string, sessionId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonSendEmail(comitteeId, sessionId));
  },

  updateComitieComment: async (payload: UpdateCommitteeCommentRequestDto) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonUpdateCommitteeComment(payload));
  },

  getSintesiListData: async (filters: GetCommitteeSintesiListRequest) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSintesiList(filters));
  },

  deleteSession: async (sessionId: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonDeleteCommitteeSession(sessionId));
  },
};
export default CommitieService;
