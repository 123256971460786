import { Form } from 'antd';
import { useState } from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'src/redux/store';
import MatrixService from '../../services/matrix/matrixService';
import { formItemLayout } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const NewXmatrixCycle = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userData = useAppSelector((state) => state.userData.userData);

  const [loadingButton, setLoadingButton] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);

  const addData = (values: any) => {
    setLoadingButton(true);
    MatrixService.insertXmatrixCycle(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          setLoadingButton(false);
          history.push('/strategicCycle');
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingButton(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingButton(false);
      });
  };

  return (
    <div className="edit-obietivo-anno-wrapper">
      <>
        <div className="site-card-wrapper"> {t('xmatrixCyclePage.aggiungiCycle')} </div>
        <Card>
          <Form
            name="add_matrix_cycle"
            layout="vertical"
            {...formItemLayout}
            onFinish={addData}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}
          >
            <Row gutter={{ lg: 24 }}>
              <Form.Item
                wrapperCol={{ span: 4 }}
                name="companyID"
                initialValue={userData?.lastLoggedInCompanyID}
              >
                <Input type="hidden" />
              </Form.Item>
              <Row
                gutter={{ lg: 24 }}
                style={{ justifyContent: 'space-between', width: '100%' }}
              >
                <Col
                  className="gutter-row"
                  xs={{ span: 4 }}
                  sm={{ span: 4 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  {/* <Button
                    onClick={backFunction}
                    className="backButton">
                    <RollbackOutlined />
                    {t('buttons.indietro')}
                  </Button> */}
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 20 }}
                  sm={{ span: 20 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}
                >
                  <div className="button-wrapper">
                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        htmlType="submit"
                        disabled={!fieldsChanged}
                        className="tw-float-end"
                      >
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Form.Item
                        label={t('xmatrixCyclePage.nomeCycle')}
                        name="cycleName"
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    </div>
  );
};

export default NewXmatrixCycle;
