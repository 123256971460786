import { trackPromise } from 'react-promise-tracker';
import { A3PageApi, type UpdateImplementationSectionA3RequestDto, authenticatedApi } from 'src/config/connectors';

const Section3Service = {
  // get section 3 data
  getImplementationSection: async (projectId: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetImplementationSectionA3(projectId));
  },

  //update implementation section
  updateImplementation: async (payload: UpdateImplementationSectionA3RequestDto) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageUpdateImplementationSectionA3(payload));
  },

  getCompletedActivities: async (projectId: string, implementationDaysOfNotice: number) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetProjectCompletedActivitiesA3(projectId, implementationDaysOfNotice));
  },

  getFollowUpGraph: async (projectId: string, kpiId: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetFollowUpSectionA3(projectId, kpiId));
  },

  getKPIPicklist: async (projectId: string) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetKPIPicklistA3(projectId));
  },
};

export default Section3Service;
