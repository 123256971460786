import { CheckOutlined, CloseOutlined, EditFilled, PlusOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Card, Input, Table, Tag, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { enumCast } from 'src/config/connectors';
import { KpiDetailCalculationType, KpiDetailParentType, KpiDetailType, KpiDto } from 'src/connectors/backend';
import CommitieService from 'src/services/pages/commitieServices';
import KpiService from '../../services/pages/kpiService';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './kpi.scss';

const KpiListPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCommitteeId, setSelectedCommitteeId] = useState<string | undefined>(undefined);
  const [kpiList, setKpiList] = useState<KpiDto[]>([]);
  const [filteredKpiList, setFilteredKpiList] = useState<KpiDto[]>([]);
  const [query, setQuery] = useState('');
  const [committeeList, setCommitteeList] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    getCommitteeList();
  }, []);

  // Init of component after rendering
  useEffect(() => {
    retrieveKpiData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommitteeId]);

  const onNew = () => {
    history.push('/kpi/new');
  };

  // getCommitteeList
  const getCommitteeList = async () => {
    try {
      const response = await CommitieService.getCommitiesList();
      setCommitteeList(
        response.data?.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      );
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const handleSearch = (event: string) => {
    setQuery(event);

    if (event) {
      setFilteredKpiList(kpiList.filter((kpi) => kpi.kpiDetail?.name?.toLowerCase().includes(event.toLowerCase())));
    } else {
      setFilteredKpiList([...kpiList]);
    }
  };

  const retrieveKpiData = async () => {
    setIsLoading(true);
    try {
      const response = await KpiService.getKpiListData(selectedCommitteeId);
      setKpiList(response.data);
      setFilteredKpiList(response.data);
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setIsLoading(false);
    }
  };

  const setKpiAsPreferred = async (kpiDetailID: string) => {
    try {
      const response = await KpiService.setKpiPrefered({
        kpiDetailID: kpiDetailID,
      });

      if (!response.data.success) {
        throw new Error('Update failed');
      }

      message.success(notifyMessages.updateSuccess);
      retrieveKpiData();
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const removeKpiAsPreferred = async (kpiDetailID: string) => {
    try {
      const { data } = await KpiService.removeKpiPrefered(kpiDetailID);

      if (!data.success) {
        throw new Error('Update failed');
      }

      message.success(notifyMessages.updateSuccess);
      retrieveKpiData();
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const columns: ColumnsType<KpiDto> = [
    {
      // title: `${t('kpiPage.preferita')}`,
      dataIndex: ['isFavorite'],
      width: '60px',
      render: (_, record) => {
        const handleClick = () => {
          if (record.kpiDetail) {
            if (record.isFavorite) {
              removeKpiAsPreferred(record.kpiDetail.id);
            } else {
              setKpiAsPreferred(record.kpiDetail.id);
            }
          }
        };

        return (
          <Button
            type="text"
            icon={record.isFavorite ? <StarFilled /> : <StarOutlined />}
            onClick={handleClick}></Button>
        );
      },
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: ['kpiDetail', 'name'],
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.tipo')}`,
      dataIndex: ['kpiDetail', 'type'],
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        return <Tag>{t(`kpi.types.${enumCast(KpiDetailType, value)}`)}</Tag>;
      },
    },
    {
      title: `${t('kpiPage.tipoCalcolo')}`,
      dataIndex: ['kpiDetail', 'calculationType'],
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        return <Tag>{t(`kpi.calculationTypes.${enumCast(KpiDetailCalculationType, value)}`)}</Tag>;
      },
    },
    {
      title: `${t('kpiPage.cumulatoPuntuale')}`,
      dataIndex: ['kpiDetail', 'parentType'],
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        return <Tag>{t(`kpi.parentTypes.${enumCast(KpiDetailParentType, value)}`)}</Tag>;
      },
    },
    {
      title: `${t('general.comitato')}`,
      dataIndex: ['committeeName'],
    },
    {
      title: `${t('kpiPage.gerarchicho')}`,
      dataIndex: ['kpiDetail', 'isRollupKpi'],
      filters: [
        { text: `${t('general.si')}`, value: true },
        { text: `${t('general.no')}`, value: false },
      ],
      render: (text, record, index) => {
        if (record.kpiDetail?.isRollupKpi) {
          return <CheckOutlined style={{ color: '#16a34a' }} />;
        }
      },
    },
    {
      title: `${t('kpiPage.isVisible')}`,
      dataIndex: 'isVisible',
      filters: [
        { text: `${t('general.si')}`, value: true },
        { text: `${t('general.no')}`, value: false },
      ],
      render: (text, record, index) => {
        if (record.kpiDetail?.isVisible) {
          return <CheckOutlined style={{ color: '#16a34a' }} />;
        } else {
          return <CloseOutlined style={{ color: '#7b817c' }} />;
        }
      },
    },
    {
      key: 'action',
      width: '50px',

      render: (text, record) => (
        <Button
          icon={<EditFilled />}
          href={`/kpi/id/${record.kpiDetail?.id}`}></Button>
      ),
    },
  ];

  return (
    <Card>
      <TableLayout title={t('kpiPage.listaKpi')}>
        <TableLayout.Actions>
          {/* filter by committee */}
          {/* <Select
            allowClear
            placeholder={t('general.comitato')}
            className="tw-shrink-0"
            id="committee-filter"
            data-testid="committee-filter"
            optionFilterProp="label"
            options={committeeList}
            maxTagCount={1}
            value={selectedCommitteeId}
            onChange={(value) => setSelectedCommitteeId(value)}
            style={{ width: 250 }}
          /> */}

          <Input.Search
            data-testid="kpiSearchBox"
            placeholder={t('general.search')}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onSearch={handleSearch}
          />
          <Button
            type="primary"
            htmlType="submit"
            onClick={onNew}>
            <PlusOutlined />
            {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            sticky
            size="small"
            columns={columns}
            dataSource={filteredKpiList || kpiList}
            rowKey={(obj) => obj.id}
            loading={isLoading}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default KpiListPage;
