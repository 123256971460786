import { Comment } from '@ant-design/compatible';
import { Badge, Button, Dropdown, Empty, Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import moment from 'moment';
import type React from 'react';
import { useDispatch } from 'react-redux';
import { type RootState, useAppSelector } from 'src/redux/store';
import { NotificationIcon } from '../../../../assets/icons';
import { retrieveNotificationList } from '../../../../redux/slices/notificationsSlice';
import LayoutService from '../../../../services/layoutService';
import { feedRegardingObjectType } from '../../../shared/utils/constants';

const NotificationBell: React.FC = () => {
  const dispatch = useDispatch();
  const notificationsList = useAppSelector((state: RootState) => state.notificationsList.notificationsList);
  const unreadNotifications = notificationsList?.filter((obj) => !obj.isReadNotification).length;

  const updateNotificationsBell = async () => {
    try {
      const response = await LayoutService.updateNotificationBell();
      if (response.data.success) {
        // Handle success
      }
    } catch (error) {
      console.error('Error updating notification bell:', error);
    }
  };

  const onVisible = (visible: boolean) => {
    if (visible && unreadNotifications && unreadNotifications > 0) {
      if (unreadNotifications > 0) {
        void updateNotificationsBell();
      }
    } else {
      if (unreadNotifications && unreadNotifications > 0) {
        dispatch(retrieveNotificationList());
      }
    }
  };

  const generateNotificationItem = (item: any, index: number, path?: string) => ({
    label: (
      <Link
        disabled={!path}
        href={path}
        className="[&_.ant-comment]:tw-px-2 [&_.ant-comment]:tw-py-0 [&_.ant-comment]:tw-rounded-md">
        <Comment
          key={index}
          className={item.isReadNotification ? '' : 'isRead'}
          author={
            <Typography.Title
              className="!tw-my-0"
              level={5}>
              {item.subject}
            </Typography.Title>
          }
          content={
            <div className="tw-flex tw-flex-col">
              <span className="tw-text-xs tw-mb-2 first-letter:tw-uppercase tw-text-zinc-400">
                {item.createdOn ? moment.utc(item.createdOn).fromNow() : ''}
              </span>
              <p className="tw-max-w-[400px] tw-text-pretty">{item.description}</p>
            </div>
          }
        />
      </Link>
    ),
    key: index,
  });

  const generateItems = (notifications: any[]) => {
    if (notifications && notifications.length > 0) {
      return {
        items: notifications.map((item, index) => {
          switch (item.regardinObjectTypeCode) {
            case null:
              return generateNotificationItem(item, index, '/home');
            case feedRegardingObjectType.myActivity:
              return generateNotificationItem(item, index, `/home/${item.regardingObjectID}/?tab=2`);
            case feedRegardingObjectType.project:
              return generateNotificationItem(item, index, `/progetti/id/${item.regardingObjectID}`);
            case feedRegardingObjectType.routine:
              return generateNotificationItem(item, index, `/progetti/id/${item.regardingObjectID}?tab=13`);
            case feedRegardingObjectType.feed:
              return generateNotificationItem(item, index, `/progetti/id/${item.regardingObjectID}?tab=11`);
            case feedRegardingObjectType.meeting:
              return generateNotificationItem(item, index, `/progetti/id/${item.regardingObjectID}?tab=27`);
            case feedRegardingObjectType.file:
              return generateNotificationItem(item, index, `/progetti/id/${item.regardingObjectID}?tab=12`);
            case feedRegardingObjectType.deliverable:
              return generateNotificationItem(item, index, `/deliverables/id/${item.regardingObjectID}/`);
            case feedRegardingObjectType.kpi:
              return generateNotificationItem(item, index, `/kpi/id/${item.regardingObjectID}`);
            case feedRegardingObjectType.annualGoal || 15:
              return generateNotificationItem(item, index, `/obiettiviannuali/details/id/${item.regardingObjectID}/`);
            case feedRegardingObjectType.longTermGoal:
              return generateNotificationItem(
                item,
                index,
                `/obiettivialungoperiodo/details/id/${item.regardingObjectID}/`,
              );
            case feedRegardingObjectType.commitie:
              return generateNotificationItem(item, index, `/committee-dashboard/${item.regardingObjectID}/`);
            default:
              return generateNotificationItem(item, index);
          }
        }),
      };
    } else {
      return { items: [{ label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />, key: 'empty' }] };
    }
  };

  return (
    <div className="navbar-button">
      <Dropdown
        menu={generateItems(notificationsList || [])}
        placement="bottom"
        arrow
        destroyPopupOnHide
        autoAdjustOverflow
        rootClassName="[&_.ant-dropdown-menu]:tw-overflow-auto [&_.ant-dropdown-menu]:tw-max-h-[50vh] [&_.ant-dropdown-menu]:tw-border [&_.ant-dropdown-menu-item]:!tw-p-2 [&_.ant-dropdown-menu-item]:!tw-border-b"
        onOpenChange={onVisible}
        trigger={['click']}>
        <Button
          type="text"
          icon={
            <Badge
              count={unreadNotifications}
              overflowCount={10}>
              <NotificationIcon />
            </Badge>
          }
        />
      </Dropdown>
    </div>
  );
};

export default NotificationBell;
