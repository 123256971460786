import moment, { type Moment } from 'moment';
import type {
  GetMyActivityProjectFormrResponseDto,
  ProjectDeliverableParent,
  UserListPageResponseDto,
} from 'src/connectors/backend';
import { PrsActive, PrsClosed, PrsDraft, PrsPause } from '../../../../../assets/icons';

export type PrsItemType = 'phase' | 'deliverable' | 'task';

export interface PrsKanBanItem extends ProjectDeliverableParent, GetMyActivityProjectFormrResponseDto {
  title: string;
  itemType: PrsItemType;
  childs?: PrsKanBanItem[];
  ownerId: string;
  deliverableID: string;
  ownerFullName: string;
  ownerAvatar: string | null;
  activityType: PrsItemType;
  status: number;
  priority: number;
  parentDeliverableName: string;
}

export interface KanBanColumn {
  id: string | number;
  title: string;
  icon?: React.ReactNode | JSX.Element | null;
  isDndEnabled: boolean;
  canCollapse: boolean;
  hideIfEmpty: boolean;
  items?: PrsKanBanItem[];
}

export enum GroupBy {
  MONTH = 'month',
  STATUS = 'status',
  OWNER = 'owner',
}

// Flattens a nested array structure, optionally adding the parent deliverable name to each item
export function flatArray(
  arr: ProjectDeliverableParent[],
  parentDeliverableName: string | null = null,
): ProjectDeliverableParent[] {
  return arr.reduce((acc: ProjectDeliverableParent[], item) => {
    const { children, name, ...rest } = item;
    const currentItem = { ...rest, name, parentDeliverableName };

    acc.push(currentItem);

    if (children) {
      acc.push(...flatArray(children, name || null));
    }

    return acc;
  }, []);
}

// Determines the type of an item (task, deliverable, or phase) based on its attributes
const getItemType = (item: ProjectDeliverableParent): string => {
  if ((item as any).activityType === 'activity') return 'task';
  if (item?.parentDeliverableID) return 'deliverable';
  return 'phase';
};

// Converts an array of items into KanBan-compatible format
export const toKanBanItems = (items: any[]): PrsKanBanItem[] =>
  items.map((item) => ({
    ...item,
    title: item?.name ?? item?.subject ?? '',
    itemType: getItemType(item),
    ownerId: item.owner ?? item.ownerID ?? '',
    ownerFullName: item.ownerFullName ?? item.fullName ?? '',
    ownerAvatar: `theUrl/${item.id ?? ''}`,
  }));

// Filters items by the specified item type (e.g., task, deliverable, phase)
export const filterByItemType = (items: PrsKanBanItem[], itemType: string): PrsKanBanItem[] => {
  return items.filter((item) => item.itemType === itemType);
};

// Calculates the months between two dates, inclusive, formatted as "MMM YYYY"
export function getMonthsBetween(minDate: Moment, maxDate: Moment): string[] {
  if (!minDate.isValid() || !maxDate.isValid()) {
    throw new Error('Invalid dates provided');
  }

  const start = moment(minDate);
  const end = moment(maxDate);
  const months: string[] = [];

  while (start.isSameOrBefore(end, 'month')) {
    months.push(start.format('MMM YYYY'));
    start.add(1, 'month');
  }

  return months;
}

// Returns an icon component based on the provided status
export const getIconFromStatus = (status: number): JSX.Element | null => {
  switch (status) {
    case 1:
      return <PrsDraft />;
    case 2:
      return <PrsActive />;
    case 3:
      return <PrsPause />;
    case 4:
      return <PrsClosed />;
    default:
      return null;
  }
};

// Matches items with company users to add owner avatars
export const matchItemsWithCompanyUsers = (
  items: PrsKanBanItem[],
  users: UserListPageResponseDto[],
): PrsKanBanItem[] => {
  if (!users?.length || !items?.length) return items;

  const userLookup: Record<string, string | null> = Object.fromEntries(
    users.map((user) => [user.userID, user.profilePhoto || null]),
  );

  return items.map((item) => ({
    ...item,
    ownerAvatar: userLookup[item.ownerId] || null,
  }));
};
