import { EditOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Typography, message } from 'antd';
import Parser from 'html-react-parser';
import React from 'react';
import { withTranslation } from 'react-i18next';
import KpiService from '../../../services/pages/kpiService';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import '../../kpiListPage/kpi.scss';
import Loader2 from '../../shared/components/loader2/loader2';
import TableLayout from '../../shared/tableLayout';
import { kpiTypeRealtion } from '../../shared/utils/constants';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../kpiDashboars.scss';
import EditKpiCommentModal from '../kpiDetailCommentModal';
import KpiBowlingChartGraph from './kpiBowlingChartGraph';
import KpiDetailGraph from './kpiDetailGraph';
import PreviousYearChart from './previousYearChart';

const { Text } = Typography;

const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

class SingelKpiDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      kpiData: null,
      kpiComments: '',
      showCommentModal: false,
      loadingSaveComment: false,
      selectedKpiData: null,
      backPath: null,
      projectInfo: null,
      annualGoalInfo: null,
    };
  }

  componentDidMount() {
    const { kdid, pid, aid, xmatrix } = this.props.match.params;
    this.getKpiData(kdid);
    this.getKpiDetailComment(kdid);

    if (pid && pid !== '0') {
      const url = `/kpiDashboard/1/${pid}`;
      this.setState({ backPath: url });
      this.getProjectData(pid);
    } else if (aid && aid !== '0') {
      const url = `/kpiDashboard/2/0/${aid}`;
      this.setState({ backPath: url });
      this.getObiettiviAnnoData(aid, xmatrix);
    }
  }

  getProjectData = (id) => {
    ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectInfo: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  async getObiettiviAnnoData(id, xmatrix) {
    await ObiettiviAnnoService.getObiettiviAnnoByiD(id, xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ annualGoalInfo: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  getKpiData = (id) => {
    KpiService.getKpiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  getKpiDetailComment = (id) => {
    const { pid, aid, type } = this.props.match.params;
    let objectId = '';
    let kpiType = '';

    if (pid && pid !== '0') {
      objectId = pid;
      kpiType = kpiTypeRealtion.project;
    }
    if (aid && aid !== '0') {
      objectId = aid;
      kpiType = kpiTypeRealtion.annualGoal;
    }

    if (type === 'p') {
      kpiType = kpiTypeRealtion.project;
    }
    if (type === 'a') {
      kpiType = kpiTypeRealtion.annualGoal;
    }

    KpiService.getKpiComments(id, objectId, kpiType)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          this.setState({ kpiComments: respData.comment });
        } else {
        }
      })
      .catch((error) => {});
  };

  onCommentSave = (payload) => {
    const { pid, aid, kdid, type } = this.props.match.params;

    if (pid && pid !== '0') {
      payload['ObjectTypeID'] = pid ? pid : '';
      payload['Type'] = kpiTypeRealtion.project;
    }
    if (aid && aid !== '0') {
      payload['ObjectTypeID'] = aid ? aid : '';
      payload['Type'] = kpiTypeRealtion.annualGoal;
    }

    if (type === 'p') {
      payload['Type'] = kpiTypeRealtion.project;
    }
    if (type === 'a') {
      payload['Type'] = kpiTypeRealtion.annualGoal;
    }

    if (!type) {
      payload['Type'] = '';
    }

    this.setState({ loadingSaveComment: true });
    KpiService.onCommentSave(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.handleKpiCommentClose();
          this.getKpiDetailComment(kdid);
        } else {
          this.setState({ loadingSaveComment: false });
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        this.setState({ loadingSaveComment: false });
        message.error(notifyMessages.updateFailed);
      });
  };

  handleKpiCommentClose = () => {
    this.setState({ showCommentModal: false });
    this.setState({ loadingSaveComment: false });
  };

  toggleKpiComments = (data) => {
    const { kpiComments } = this.state;
    data['kpiName'] = data?.name;
    data['notes'] = kpiComments;
    this.setState({ selectedKpiData: data }, () => {
      this.setState({ showCommentModal: true });
    });
  };

  backFunction = () => {
    const { backPath } = this.state;
    if (backPath) {
      history.push(backPath);
    }
  };

  render() {
    const { kpiData, kpiComments, showCommentModal, selectedKpiData, loadingSaveComment, annualGoalInfo, projectInfo } =
      this.state;
    const { kdid, pid, xmatrix, month, year, aid } = this.props.match.params;
    const t = this.props.t;

    return (
      <>
        {showCommentModal && selectedKpiData && (
          <EditKpiCommentModal
            showCommentModal={showCommentModal}
            handleCommentModalClose={this.handleKpiCommentClose}
            onCommentUpdate={this.onCommentSave}
            loadingCommentSave={loadingSaveComment}
            t={t}
            rowData={selectedKpiData}
          />
        )}

        {!kpiData && <Loader2 />}

        {kpiData && (
          <div className="dash-kpi-wrapper">
            <div className="kpiSintesi">
              <div className="site-card-wrapper">
                <h3 className="objective-item">{kpiData.getKPIDetail?.name}</h3>
                <span>{t('general.kpi')}</span>
              </div>

              <Row
                className="das-header"
                gutter={{ lg: 24 }}
                style={{ alignItems: 'center', padding: '0px 30px 0px 30px' }}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Space direction="horizontal">
                    <Text level={5}> {t('kpiDashboard.kpiDetails')}: </Text>
                    <Divider
                      type="vertical"
                      className="kpiInfoDivider"
                    ></Divider>

                    <Text> {kpiData.getKPIDetail.parentTypeDescription}</Text>
                    <Divider
                      type="vertical"
                      className="kpiInfoDivider"
                    ></Divider>

                    <Text> {kpiData.getKPIDetail.calculationTypeDescription}</Text>
                    <Divider
                      type="vertical"
                      className="kpiInfoDivider"
                    ></Divider>

                    <Text> {kpiData.getKPIDetail.typeDescription}</Text>
                    <Divider
                      type="vertical"
                      className="kpiInfoDivider"
                    ></Divider>

                    {projectInfo && (
                      <>
                        <Text> {t('general.progetto')}: </Text>
                        <Text>
                          {' '}
                          {projectInfo?.name} ({projectInfo.projectCode})
                        </Text>
                      </>
                    )}

                    {annualGoalInfo && annualGoalInfo.getAnnualGoalDto && (
                      <>
                        <Text> {t('general.obiettiviAnno')}: </Text>
                        <Text> {annualGoalInfo.getAnnualGoalDto.description} </Text>
                      </>
                    )}
                  </Space>
                </Col>

                {/* <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Text level={5}> {t('kpiDashboard.kpiDetails')}</Text>
                                        </Col> */}
                {/* <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Text>{t('general.progetto')}: </Text>
                                        </Col> */}

                {/* <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                <Text> {kpiData.getKPIDetail.parentTypeDescription}</Text>
                            </Col>

                            <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                <Text> {kpiData.getKPIDetail.typeDescription}</Text>
                            </Col>

                            <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                <Text> {kpiData.getKPIDetail.calculationTypeDescription}</Text>
                            </Col> */}
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Card
                    className="sub500-delChart-card-body"
                    title={t('kpiDashboard.kpiIndicatori')}
                  >
                    <KpiDetailGraph
                      xmatrix={xmatrix}
                      kpiDetailId={kdid}
                      annualId={aid}
                      projectId={pid}
                      month={month}
                      year={year}
                      t={t}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={{ lg: 24 }}>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 9 }}>
                                <Card size='small' className='sub460-delChart-card-body' title={t('kpiDashboard.kpiGraph')}>
                                    <KpiGraph xmatrix={xmatrix} kpiDetailId={kdid} annualId={aid} projectId={pid} month={month} year={year} t={t} />
                                </Card>
                            </Col> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 18 }}
                  md={{ span: 18 }}
                  lg={{ span: 18 }}
                >
                  <Card
                    className="sub460-delChart-card-body"
                    title={t('kpiDashboard.anniPrecedenti')}
                  >
                    <PreviousYearChart
                      xmatrix={xmatrix}
                      kpiDetailId={kdid}
                      annualId={aid}
                      projectId={pid}
                      month={month}
                      year={year}
                      t={t}
                    />
                  </Card>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 6 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Card
                    className="sub460-delChart-card-body"
                    title={t('general.commenti')}
                    extra={<EditOutlined onClick={() => this.toggleKpiComments(kpiData.getKPIDetail)} />}
                  >
                    {Parser(kpiComments)}
                  </Card>
                </Col>
              </Row>

              <TableLayout title={t('bowlingChartPage.bowlingChart')}>
                <TableLayout.Content>
                  <div className="tw-overflow-x-auto">
                    <KpiBowlingChartGraph
                      xmatrix={xmatrix}
                      kpiDetailId={kdid}
                      annualId={aid}
                      projectId={pid}
                      month={month}
                      year={year}
                      t={t}
                    />
                  </div>
                </TableLayout.Content>
              </TableLayout>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(SingelKpiDetails);
