import { Form } from 'antd';
import { Component } from 'react';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row, Select, Spin, message } from 'antd';
import CommonService from '../../../../services/commonService';
import Riskervice from '../../../../services/pages/projectServices/riskServices';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { objectCodes, statusPickListCodes } from '../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import './riskAndPrecaution.scss';

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class EditCriticModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      severityList: null,
      loadingButton: false,
      loadingDeleteButton: false,
      precautionsList: props.data.precautions,
      deletedIndexes: [],
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrieveSeverity();
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.criticStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveSeverity() {
    const objectCode = objectCodes.criticSeverity;
    await CommonService.getSeverityPickList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ severityList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  editData = (values, riskID) => {
    this.props.handeEditCriticModalSave(values, riskID);
  };

  onRemovePrecaution = (precaution_index) => {
    const { deletedIndexes } = this.state;
    this.setState({ loadingDeleteButton: true });
    const precautionID = this.selectedPrecaution(precaution_index);

    if (precautionID) {
      Riskervice.deletePrecaution(precautionID)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            message.success(notifyMessages.deleteSuccess);
            this.setState({ loadingDeleteButton: false });
            deletedIndexes.push(precaution_index);
            this.setState({ deletedPercautionsList: deletedIndexes });
          } else {
            message.error(notifyMessages.deleteFailed);
            this.setState({ loadingDeleteButton: false });
          }
        })
        .catch((error) => {
          message.error(notifyMessages.deleteFailed);
          this.setState({ loadingDeleteButton: false });
        });
    }
  };

  selectedPrecaution = (precaution_index) => {
    const selectedPrecaution = this.state.precautionsList[precaution_index];
    if (selectedPrecaution) {
      if (selectedPrecaution.precautionID) {
        return selectedPrecaution.precautionID;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render() {
    const { showEditCriticModal, handleEditCriticModalClose, loadingButton, data, t } = this.props;
    const { statusList, loadingDeleteButton, precautionsList, deletedIndexes, severityList } = this.state;

    return (
      <Modal
        className="add-critic-modal"
        width={800}
        title={`${data.riskDescription}`}
        open={showEditCriticModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleEditCriticModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleEditCriticModalClose}
            formId={'editProjectCritic'}
          />,
        ]}
      >
        <Card className="overflow">
          <Form
            id="editProjectCritic"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.editData(values, data.riskID)}
            // initialValues={data }
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <Card
                  title={t('proggetiPage.informazioniCriticita')}
                  className="sub-card-body"
                >
                  <Row
                    gutter={{ lg: 24 }}
                    className="row-margin"
                  >
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Form.Item
                        label={t('proggetiPage.nomeCriticita')}
                        name="riskDescription"
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={data.riskDescription}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        label={t('general.stato')}
                        name="status"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={data.status}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                        >
                          {statusList &&
                            statusList.map((item) => (
                              <Option
                                value={item.statusID}
                                key={item.statusID}
                              >
                                {item.statusDescription}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        label={t('general.priorita')}
                        name="severity"
                        hasFeedback
                        initialValue={data.severity}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                        >
                          {severityList &&
                            severityList.map((item) => (
                              <Option
                                value={item.objectCodeListID}
                                key={item.objectCodeListID}
                              >
                                {item.description}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 16 }}
                      lg={{ span: 24 }}
                    >
                      <Form.Item
                        label={t('general.commenti')}
                        name="riskComment"
                        initialValue={data.riskComment}
                      >
                        <TextArea
                          rows={5}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <Card
                  title={t('proggetiPage.contromisureIdentificate')}
                  className="sub-card-body"
                >
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 16 }}
                    lg={{ span: 24 }}
                  >
                    {loadingDeleteButton && (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 18 }}
                            spin
                          />
                        }
                        className="left-align"
                      />
                    )}
                    <Form.List
                      name="precautions"
                      // initialValue={data.precautions}
                      initialValue={precautionsList}
                      rules={
                        [
                          // {
                          //     validator: async (_, precautions) => {
                          //         if (!precautions || precautions.length < 1) {
                          //             return Promise.reject(new Error('At least 1 contromisure'));
                          //         }
                          //     },
                          // },
                        ]
                      }
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...formItemLayout}
                              label={`${t('proggetiPage.contromisure')} ` + (index + 1)}
                              required={false}
                              key={[field.fieldKey]}
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, 'precautionDescription']}
                                fieldKey={[field.fieldKey, 'precautionID']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: requiredFields.required,
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  placeholder={t('general.nome')}
                                  style={{ width: '80%' }}
                                  disabled={deletedIndexes.indexOf(index) > -1 ? true : false}
                                />
                              </Form.Item>
                              {fields.length > 0 ? (
                                <>
                                  <Button
                                    disabled={deletedIndexes.indexOf(index) > -1 ? true : false}
                                    style={{ marginLeft: 10 }}
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                      this.selectedPrecaution(field.name) === null
                                        ? remove(field.name)
                                        : this.onRemovePrecaution(field.name)
                                    }
                                  />
                                </>
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '60%' }}
                              icon={<PlusOutlined />}
                            >
                              {t('buttons.aggiungiContromisure')}
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default EditCriticModal;
