import { Card, Col, Form, Modal, Row, Select } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { GetKPIChildPicklistResponse } from 'src/connectors/backend';
import KpiService from '../../services/pages/kpiService';
import '../projects/projectForm/deliverablesAndKpi/deliverable.scss';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../shared/utils/notifyMessages';

interface NewChildKpiModalProps {
  showChildKpiModal: boolean;
  handleChildKpiModalClose: () => void;
  handleChildKpiSave: (values: any, parentKpiId: string) => void;
  loadingButton: boolean;
  parentKpiId: string;
}

const NewChildKpiModal: React.FC<NewChildKpiModalProps> = ({
  showChildKpiModal,
  handleChildKpiModalClose,
  handleChildKpiSave,
  loadingButton,
  parentKpiId,
}) => {
  const { t } = useTranslation();

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [kpiList, setKpiList] = useState<GetKPIChildPicklistResponse[] | null>(null);

  useEffect(() => {
    retrieveChildPickList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveChildPickList = async () => {
    try {
      const response = await KpiService.getKpiProjectPicklist(parentKpiId);
      if (response.data.success) {
        setKpiList(response.data.responseObject?.value ?? []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addKpiData = (values: any) => {
    handleChildKpiSave(values, parentKpiId);
  };

  const kpiOptions = kpiList ? kpiList.map((item) => ({ value: item.kpiDetailID, label: item.name })) : [];

  return (
    <Modal
      className="add-kpi-modal"
      width={800}
      title={t('kpiPage.aggiungiKpiFiglio')}
      open={showChildKpiModal}
      destroyOnClose
      onCancel={handleChildKpiModalClose}
      footer={[
        <ModalFooterButtons
          key="footer-buttons"
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleChildKpiModalClose}
          formId="newChildKpiForm"
        />,
      ]}>
      <Card>
        <Form
          id="newChildKpiForm"
          layout="vertical"
          onFinish={addKpiData}
          onValuesChange={() => setFieldsChanged(true)}>
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}>
              <Form.Item
                label={t('kpiPage.listaKpi')}
                name="KPIDetailID"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={kpiOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default NewChildKpiModal;
