import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileZipOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Result, Tooltip, Typography, notification } from 'antd';
import { compareAsc } from 'date-fns';
import moment from 'moment';
import Numeral from 'numeral';
import { validate as isValidUUID, v4 as uuidv4 } from 'uuid';
import i18n from '../../../i18n';
import { retrieveFeedNotificationList } from '../../../redux/slices/feedNotificationsSlice';
import { retrieveNotificationList } from '../../../redux/slices/notificationsSlice';
import { store } from '../../../redux/store';
import { isUserAdmin, userIsProjectTeamLeaderOrSponsor } from './authRolesProvilege/authRolesPrivilege';
import {
  activityStatus,
  bowlingChartRowName,
  deliverableStatus,
  kpiCalculationType,
  kpiPuntualeAcumulato,
  kpiType,
  projectWorkloadColumNames,
  userRolesCodes,
  workloadColumnNamesValues,
  workloadTypeValues,
} from './constants';
import { DeliverableIcon, PhaseIcon, TaskIcon } from 'src/components/projects/projectForm/deliverablesAndKpi/kanban-view/components/icons';

const { Text } = Typography;

export function validateGuid(guid) {
  if (isValidUUID(guid)) {
    return true;
  } else {
    return false;
  }
}

export function generateNewGuid() {
  return uuidv4();
}

export const delayFuncCall = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function statusClass(status) {
  if (status === 1) {
    return 'statusActive';
  } else if (status === 2) {
    return 'statusSospeso';
  } else if (status === 3) {
    return 'statusDraft';
  } else if (status === 4) {
    return 'statusConcluso';
  } else if (status === 5) {
    return 'statusRevisionato';
  }
  return '';
}

export const checkSemaphoreColor = (status) => {
  if (status === 1) {
    return 'greenColor';
  } else if (status === 2) {
    return 'yellowColor';
  } else if (status === 3) {
    return 'redColor';
  } else {
    return 'greyColor';
  }
};

export const checkProjectStatus = (project) => {
  if (project.status === 1) {
    return (
      <p className="active-status">
        <FileDoneOutlined />
        {' ' + project.statusDescription}
      </p>
    );
  } else if (project.status === 2) {
    return (
      <p className="suspended-status">
        <FileZipOutlined />
        {' ' + project.statusDescription}
      </p>
    );
  } else if (project.status === 3) {
    return (
      <p className="draft-status">
        <WarningOutlined />
        {' ' + project.statusDescription}
      </p>
    );
  } else if (project.status === 4) {
    return (
      <p className="completed-status">
        <CheckCircleOutlined />
        {' ' + project.statusDescription}
      </p>
    );
  } else {
  }
};

export const checkRiskSeverity = (critic) => {
  if (critic.severity === 1) {
    return (
      <Tooltip
        placement="top"
        title={critic.riskSeverityDescription}
      >
        <WarningOutlined
          className="tw-text-xl"
          style={{ color: '#ef4444' }}
        />
      </Tooltip>
    );
  } else if (critic.severity === 2) {
    return (
      <Tooltip
        placement="top"
        title={critic.riskSeverityDescription}
      >
        <WarningOutlined
          className="tw-text-xl"
          style={{ color: '#f59e0b' }}
        />
      </Tooltip>
    );
  } else if (critic.severity === 3) {
    return (
      <Tooltip
        placement="top"
        title={critic.riskSeverityDescription}
      >
        <WarningOutlined
          className="tw-text-xl"
          style={{ color: '#22c55e' }}
        />
      </Tooltip>
    );
  } else {
  }
};

export const checkStatus = (critic) => {
  if (critic.status === 1) {
    return (
      <Tooltip
        placement="top"
        title={critic.riskStatusDescription}
      >
        <ExclamationCircleOutlined
          className="tw-text-xl"
          style={{ color: '#f97316' }}
        />
      </Tooltip>
    );
  } else if (critic.status === 2) {
    return (
      <Tooltip
        placement="top"
        title={critic.riskStatusDescription}
      >
        <CheckCircleOutlined
          className="tw-text-xl"
          style={{ color: '#22c55e' }}
        />
      </Tooltip>
    );
  } else {
  }
};

export const returnPreviousMonth = (month) => {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
  const monthIdex = months.indexOf(month);
  const previousMonthIndex = monthIdex - 1;
  const previousMonthName = months[previousMonthIndex];
  return previousMonthName;
};

export const removeTags = (val) => {
  if (val === null || val === '') return false;
  else val = val.toString();
  return val.replace(/(<([^>]+)>)/gi, '');
};

export function kpiTypeColor(type) {
  if (type === kpiType.globale) {
    return 'tw-text-blue-500';
  } else if (type === kpiType.specifico) {
    return 'tw-text-green-500';
  }
  return '';
}

// For deliverable table
export const checkDeliverableStatus = (deliverable, name) => {
  const currentDate = moment();

  if (deliverable.status === deliverableStatus.aperto) {
    if (deliverable.rescheduledEndDate) {
      if (
        deliverable.status === deliverableStatus.aperto &&
        moment(deliverable.rescheduledEndDate) > moment(deliverable.endDate) &&
        moment(deliverable.endDate) > currentDate
      ) {
        return '#eab308'; //yellow
      }

      //added for task 3310
      else if (
        deliverable.status === deliverableStatus.aperto &&
        moment(deliverable.rescheduledEndDate) >= moment(deliverable.endDate) &&
        moment(deliverable.endDate) >= currentDate
      ) {
        return '#22c55e'; //green
      } else if (
        deliverable.status === deliverableStatus.aperto &&
        moment(deliverable.rescheduledEndDate) >= moment(deliverable.endDate) &&
        moment(deliverable.endDate) < currentDate
      ) {
        return '#ef4444'; //red
      } else {
        if (deliverable.status === deliverableStatus.aperto && moment(deliverable.endDate) <= currentDate) {
          return '#22c55e'; //green
        } else if (deliverable.status === deliverableStatus.aperto && moment(deliverable.endDate) > currentDate) {
          return '#ef4444'; //red
        } else {
        }
      }
    } else {
      if (deliverable.status === deliverableStatus.aperto && currentDate <= moment(deliverable.endDate)) {
        return '#86efac'; //light green
      } else if (deliverable.status === deliverableStatus.aperto && currentDate > moment(deliverable.endDate)) {
        return '#ef4444'; //red
      }
    }
  } else if (deliverable.status === deliverableStatus.chiuso) {
    if (name === 'deliverableList') {
      if (deliverable.actualEndDate) {
        if (
          deliverable.status === deliverableStatus.chiuso &&
          moment(deliverable.actualEndDate) <= moment(deliverable.endDate)
        ) {
          return 'white';
        } else if (
          deliverable.status === deliverableStatus.chiuso &&
          moment(deliverable.actualEndDate) > moment(deliverable.endDate)
        ) {
          return 'white';
        }
      } else {
        if (deliverable.status === deliverableStatus.chiuso && currentDate <= moment(deliverable.endDate)) {
          return 'white';
        } else if (deliverable.status === deliverableStatus.chiuso && currentDate > moment(deliverable.endDate)) {
          return 'white';
        }
      }
    } else {
      if (deliverable.actualEndDate) {
        if (
          deliverable.status === deliverableStatus.chiuso &&
          moment(deliverable.actualEndDate) <= moment(deliverable.endDate)
        ) {
          return '#22c55e'; //green
        } else if (
          deliverable.status === deliverableStatus.chiuso &&
          moment(deliverable.actualEndDate) > moment(deliverable.endDate)
        ) {
          return '#fca5a5'; //light red
        }
      } else {
        if (deliverable.status === deliverableStatus.chiuso && currentDate <= moment(deliverable.endDate)) {
          return '#22c55e'; //green
        } else if (deliverable.status === deliverableStatus.chiuso && currentDate > moment(deliverable.endDate)) {
          return '#fca5a5'; //light red
        }
      }
    }
  } else if (deliverable.status === deliverableStatus.draft) {
    return '#ededed'; //white
  } else if (deliverable.status === deliverableStatus.sospeso) {
    return '#eab308'; //yellow
  } else {
    return 'ededed'; //white
  }
};

export const convertCamelCaseToNormal = (str) => {
  if (str !== null && str !== '') {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      .replace(/^./, (str) => str.toUpperCase());
  }
  return '';
};

// export const numberWithCommas = (value) => {
//   if (value) {
//     return Numeral(value).format('0,0.00')
//   }
//   else {
//     return;
//   }

// }

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string[0].toUpperCase() + string.slice(1);
  } else {
    return string;
  }
};

export const capitalizeFirstLetterKpiMonths = (string) => {
  if (string) {
    return string[0].toUpperCase() + string.slice(1);
  } else {
    return null;
  }
};

export const numberWithCommas = (value) => {
  return Numeral(value).format('0,0.00');
};

export const italianNumberFormat = (value) => {
  const valueNumber = Number.parseFloat(value);
  return Numeral(valueNumber).format('0,0.00');
};

export const inputFormatter = (value) => {
  return ` ${value}`.replace(/\./, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const inputParser = (value) => {
  return Number.parseFloat(`${value}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.'));
};

export const getCurrencySymbol = (currency) => {
  if (currency) {
    if (currency.toLowerCase() === 'eur') {
      return '€ ';
    } else if (currency.toLowerCase() === 'usd') {
      return '$ ';
    } else if (currency.toLowerCase() === 'gbp') {
      return '£ ';
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const checkCompanyBudgetAlert = (companyData) => {
  if (companyData?.budgetAlertSelection === 1) {
    // return "gg"
    return i18n.t('general.gg');
  }
  if (companyData?.budgetAlertSelection === 2) {
    return getCurrencySymbol(companyData.currency);
  } else {
    return '';
  }
};

export const numberWithCommasProjectCost = (value, currency) => {
  const formatedValue = Numeral(value).format('0,0.00');
  return getCurrencySymbol(currency) + formatedValue;
};

export const orderUserWorkloadData = (userWorkloadData) => {
  let orderedList = [];

  if (userWorkloadData) {
    userWorkloadData.forEach((item) => {
      if (item[projectWorkloadColumNames.type] === workloadTypeValues.user) {
        if (item[projectWorkloadColumNames.name] === workloadColumnNamesValues.budget) {
          orderedList.unshift(item);
        } else {
          orderedList.splice(1, 0, item);
        }
      } else {
        orderedList.push(item);
      }

      // if (item[projectWorkloadColumNames.name] === workloadColumnNamesValues.budget) {
      //   orderedList.unshift(item)
      // }
      // else if (item[projectWorkloadColumNames.name] === workloadColumnNamesValues.totalBudgetProject) {
      //   orderedList.splice(1, 0, item)
      // }
      // else if (item[projectWorkloadColumNames.name] === workloadColumnNamesValues.disponibile) {
      //   orderedList.splice(2, 0, item)
      // }
      // else if (item[projectWorkloadColumNames.name] === workloadColumnNamesValues.consuntivo) {
      //   orderedList.splice(3, 0, item)
      // }
      // else if (item[projectWorkloadColumNames.type] === workloadTypeValues.project) {
      //   orderedList.splice(1, 0, item)
      // }
      // else {
      //   orderedList.push(item)
      // }
    });
    return orderedList;
  } else {
    return (orderedList = []);
  }
};

export const orderProjectWorkloadData = (projectWorkloadData) => {
  let orderedList = [];
  // return  projectWorkloadData
  if (projectWorkloadData) {
    projectWorkloadData.forEach((item, index) => {
      if (
        item[projectWorkloadColumNames.name] === workloadColumnNamesValues.budgetProject &&
        item[projectWorkloadColumNames.type] === workloadTypeValues.project
      ) {
        orderedList.unshift(item);
      } else if (item[projectWorkloadColumNames.name] === workloadColumnNamesValues.budgetUserProject) {
        orderedList.splice(index - 2, 0, item);
      } else {
        orderedList.push(item);
      }
    });
    return orderedList;
  } else {
    return (orderedList = []);
  }
};

export const LoadingSpinner = () => {
  return (
    <LoadingOutlined
      spin
      style={{ fontSize: 30 }}
    />
  );
};

export const AddSingelSpace = (str, n) => {
  var ret = [];
  var i;
  var len;
  for (i = 0, len = str.length; i < len; i += n) {
    ret.push(str.substr(i, n));
  }
  return ret;
};

export const checkMyActivitiesStatus = (activity) => {
  const currentDate = moment();

  if (activity.status === activityStatus.active && currentDate <= moment(activity.endDate)) {
    return '#86efac'; //light green
  } else if (activity.status === activityStatus.active && currentDate > moment(activity.endDate)) {
    return '#ef4444'; //red
  } else if (activity.status === activityStatus.closed) {
    if (activity.actualCloseDate) {
      if (activity.status === activityStatus.closed && moment(activity.actualCloseDate) <= moment(activity.endDate)) {
        return '#22c55e'; //green
      } else if (
        activity.status === activityStatus.closed &&
        moment(activity.actualCloseDate) > moment(activity.endDate)
      ) {
        return '#fca5a5'; //light red
      }
    } else {
      if (activity.status === activityStatus.closed && currentDate <= moment(activity.endDate)) {
        return '#22c55e'; //green
      } else if (activity.status === activityStatus.closed && currentDate > moment(activity.endDate)) {
        return '#fca5a5'; //light red
      }
    }
  }

  // else if (activity.status === activityStatus.closed && currentDate <= moment(activity.endDate)) {
  //   return (
  //     "#22c55e" //green
  //   )
  // }
  // else if (activity.status === activityStatus.closed && currentDate > moment(activity.endDate)) {
  //   return (
  //     "#fca5a5" //light red
  //   )
  // }
  else if (activity.status === activityStatus.draft) {
    return '#6b7280'; //grey
  } else if (activity.status === activityStatus.cancelled) {
    return '#eab308'; //yellow
  } else {
    return 'ffffff'; //white
  }
};

export const showErrorNotification = (message, description) => {
  return notification.error({
    duration: 8,
    message: message,
    description: description,
    icon: <CloseCircleOutlined style={{ color: '#e75959' }} />,
  });
};

export const areDatesValid = (startDate, endDate) => {
  if (startDate !== null && endDate !== null) {
    if (endDate <= startDate) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

// check user roles

export const isTeamleader = (curentUserData) => {
  if (curentUserData.roleCode === userRolesCodes.teamLeader) {
    return true;
  } else {
    return false;
  }
};

export const isTeamMember = (curentUserData) => {
  if (curentUserData.roleCode === userRolesCodes.teamMember) {
    return true;
  } else {
    return false;
  }
};

export const convertDateToUTC = (date) => {
  if (date) {
    const myDate = new Date(date);
    const utcDate = Date.UTC(
      myDate.getUTCFullYear(),
      myDate.getUTCMonth(),
      myDate.getUTCDate(),
      myDate.getUTCHours(),
      myDate.getUTCMinutes(),
      myDate.getUTCSeconds(),
    );

    return utcDate;
  }
};

export const obAnualSemaphoreColor = (qualitySemaphore) => {
  if (qualitySemaphore === 1) {
    // return "#73cc8b"
    return '#1d913c'; //green
  } else if (qualitySemaphore === 2) {
    return '#FFD81A'; //yellow
  } else if (qualitySemaphore === 3) {
    return '#E60000'; //red
  } else {
    return '#6b7280'; //grey
  }
};

export const handleTimeConvert = (time) => {
  if (time) {
    const formatedTime = moment(time).format('HH:mm');
    return formatedTime;
  }
  return null;
};

export const handleDateConvert = (date) => {
  if (date) {
    const formatedDate = moment(date).format('YYYY-MM-DD');
    return formatedDate;
  }
  return null;
};

export const disableDatesBeforeToday = (current) => {
  return current && current < moment().subtract(1, 'days');
};

export const NoAccessMesssage = () => {
  return (
    <Result
      status="warning"
      title={i18n.t('general.noAccessInArticle')}
      extra={
        <Button
          href="/"
          type="primary"
          key="console"
        >
          {i18n.t('general.tornaHomePage')}
        </Button>
      }
    />
  );
};

export const translatedMonths = (languageCode) => {
  if (languageCode === 'it') {
    return [
      { title: 'Gen', key: 'gen' },
      { title: 'Feb', key: 'feb' },
      { title: 'Mar', key: 'mar' },
      { title: 'Apr', key: 'apr' },
      { title: 'Mag', key: 'mag' },
      { title: 'Giu', key: 'giu' },
      { title: 'Lug', key: 'lug' },
      { title: 'Ago', key: 'ago' },
      { title: 'Set', key: 'set' },
      { title: 'Ott', key: 'ott' },
      { title: 'Nov', key: 'nov' },
      { title: 'Dic', key: 'dic' },
    ];
  } else if (languageCode === 'en') {
    return [
      { title: 'Jan', key: 'gen' },
      { title: 'Feb', key: 'feb' },
      { title: 'Mar', key: 'mar' },
      { title: 'Apr', key: 'apr' },
      { title: 'May', key: 'mag' },
      { title: 'Jun', key: 'giu' },
      { title: 'Jul', key: 'lug' },
      { title: 'Aug', key: 'ago' },
      { title: 'Sep', key: 'set' },
      { title: 'Oct', key: 'ott' },
      { title: 'Nov', key: 'nov' },
      { title: 'Dec', key: 'dic' },
    ];
  } else {
    return [
      { title: 'Jan', key: 'gen' },
      { title: 'Feb', key: 'feb' },
      { title: 'Mar', key: 'mar' },
      { title: 'Apr', key: 'apr' },
      { title: 'May', key: 'mag' },
      { title: 'Jun', key: 'giu' },
      { title: 'Jul', key: 'lug' },
      { title: 'Aug', key: 'ago' },
      { title: 'Sep', key: 'set' },
      { title: 'Oct', key: 'ott' },
      { title: 'Nov', key: 'nov' },
      { title: 'Dec', key: 'dic' },
    ];
  }
};

export const translatedMonths2 = (languageCode) => {
  if (languageCode === 'it') {
    return [
      { title: 'Gen', key: 1, text: 'gen' },
      { title: 'Feb', key: 2, text: 'feb' },
      { title: 'Mar', key: 3, text: 'mar' },
      { title: 'Apr', key: 4, text: 'apr' },
      { title: 'Mag', key: 5, text: 'mag' },
      { title: 'Giu', key: 6, text: 'giu' },
      { title: 'Lug', key: 7, text: 'lug' },
      { title: 'Ago', key: 8, text: 'ago' },
      { title: 'Set', key: 9, text: 'set' },
      { title: 'Ott', key: 10, text: 'ott' },
      { title: 'Nov', key: 11, text: 'nov' },
      { title: 'Dic', key: 12, text: 'dic' },
    ];
  } else if (languageCode === 'en') {
    return [
      { title: 'Jan', key: 1, text: 'gen' },
      { title: 'Feb', key: 2, text: 'feb' },
      { title: 'Mar', key: 3, text: 'mar' },
      { title: 'Apr', key: 4, text: 'apr' },
      { title: 'May', key: 5, text: 'mag' },
      { title: 'Jun', key: 6, text: 'giu' },
      { title: 'Jul', key: 7, text: 'lug' },
      { title: 'Aug', key: 8, text: 'ago' },
      { title: 'Sep', key: 9, text: 'set' },
      { title: 'Oct', key: 10, text: 'ott' },
      { title: 'Nov', key: 11, text: 'nov' },
      { title: 'Dec', key: 12, text: 'dic' },
    ];
  } else {
    return [
      { title: 'Jan', key: 1, text: 'gen' },
      { title: 'Feb', key: 2, text: 'feb' },
      { title: 'Mar', key: 3, text: 'mar' },
      { title: 'Apr', key: 4, text: 'apr' },
      { title: 'May', key: 5, text: 'mag' },
      { title: 'Jun', key: 6, text: 'giu' },
      { title: 'Jul', key: 7, text: 'lug' },
      { title: 'Aug', key: 8, text: 'ago' },
      { title: 'Sep', key: 9, text: 'set' },
      { title: 'Oct', key: 10, text: 'ott' },
      { title: 'Nov', key: 11, text: 'nov' },
      { title: 'Dec', key: 12, text: 'dic' },
    ];
  }
};

export const ResponsiblePopoverContent = ({ data, t }) => {
  return !!data.isGenericResponsabile ? (
    <Text>
      {t('deliverablePage.genericResponsabile')}: <b> {data.genericResponsible} </b>
    </Text>
  ) : (
    ''
  );
};

export const projectDeliverableStatusColor = (status) => {
  if (status === deliverableStatus.draft) {
    return '#8FED8F';
  } else if (status === deliverableStatus.aperto) {
    return '#42b21d';
  } else if (status === deliverableStatus.sospeso) {
    return '#FF0000';
  } else if (status === deliverableStatus.chiuso) {
    return '#FF7276';
  } else if (status === 5) {
    return '#fbd311';
  } else if (status === 6) {
    return '#FFFFFF';
  }
};

export const checkDeliverableStatusColorMaterPlan = (deliverable) => {
  const currentDate = moment();

  // if (deliverable.status === deliverableStatus.aperto && currentDate <= moment(deliverable.endDate)) {
  //   return (
  //     "#86efac" //light green
  //   )
  // }
  // else if (deliverable.status === deliverableStatus.aperto && currentDate > moment(deliverable.endDate)) {
  //   return (
  //     "#ef4444" //red
  //   )
  // }

  if (deliverable.status === deliverableStatus.aperto) {
    if (deliverable.rescheduledEndDate) {
      if (
        deliverable.status === deliverableStatus.aperto &&
        moment(deliverable.rescheduledEndDate) > moment(deliverable.endDate) &&
        moment(deliverable.endDate) > currentDate
      ) {
        return '#eab308'; //yellow
      } else if (
        deliverable.status === deliverableStatus.aperto &&
        moment(deliverable.rescheduledEndDate) > moment(deliverable.endDate) &&
        moment(deliverable.endDate) < currentDate
      ) {
        return '#ef4444'; //red
      } else {
        if (deliverable.status === deliverableStatus.aperto && moment(deliverable.endDate) <= currentDate) {
          return '#22c55e'; //green
        } else if (deliverable.status === deliverableStatus.aperto && moment(deliverable.endDate) > currentDate) {
          return '#ef4444'; //red
        } else {
        }
      }
    } else {
      if (deliverable.status === deliverableStatus.aperto && currentDate <= moment(deliverable.endDate)) {
        return '#86efac'; //light green
      } else if (deliverable.status === deliverableStatus.aperto && currentDate > moment(deliverable.endDate)) {
        return '#ef4444'; //red
      }
    }
  } else if (deliverable.status === deliverableStatus.chiuso) {
    if (deliverable.actualEndDate) {
      if (
        deliverable.status === deliverableStatus.chiuso &&
        moment(deliverable.actualEndDate) <= moment(deliverable.endDate)
      ) {
        return '#22c55e'; //green
      } else if (
        deliverable.status === deliverableStatus.chiuso &&
        moment(deliverable.actualEndDate) > moment(deliverable.endDate)
      ) {
        return '#fca5a5'; //light red
      }
    } else {
      if (deliverable.status === deliverableStatus.chiuso && currentDate <= moment(deliverable.endDate)) {
        return '#22c55e'; //green
      } else if (deliverable.status === deliverableStatus.chiuso && currentDate > moment(deliverable.endDate)) {
        return '#fca5a5'; //light red
      }
    }
  } else if (deliverable.status === deliverableStatus.draft) {
    return '#ededed'; //white
  } else if (deliverable.status === deliverableStatus.sospeso) {
    return '#eab308'; //yellow
  } else {
    return 'ededed'; //white
  }
};

export const updateNotificationBell = () => {
  store.dispatch(retrieveNotificationList());
};

export const updateFeedNotificationBell = () => {
  store.dispatch(retrieveFeedNotificationList());
};

export const checkKpiSemaphoreColor = (record) => {
  const greenColor = '#6ABF6A';
  const redColor = '#dc2626';

  // compare actual and target

  if (record.parentType === kpiPuntualeAcumulato.puntuale) {
    if (record.calculationType === kpiCalculationType.valoreMinimo) {
      if (record.actual < record.target || record.actual === record.target) {
        return greenColor;
      } else if (record.actual > record.target) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    } else {
      if (record.actual > record.target || record.actual === record.target) {
        return greenColor;
      } else if (record.actual < record.target) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    }
  }
  // compare actualYTD and targetYTD
  else if (record.parentType === kpiPuntualeAcumulato.acumulato) {
    if (record.calculationType === kpiCalculationType.valoreMinimo) {
      if (record.updateYTD < record.targetYTD || record.updateYTD === record.targetYTD) {
        return greenColor;
      } else if (record.updateYTD > record.targetYTD) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    } else {
      if (record.updateYTD > record.targetYTD || record.updateYTD === record.targetYTD) {
        return greenColor;
      } else if (record.updateYTD < record.targetYTD) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    }
  } else return '#FFFFFF';
};

export const kpiSemaphoreColor = (color) => {
  const greenColor = '#6abf6a';
  const redColor = '#dc2626';
  const neutral = '#bdbdbd';

  if (color === 'Green') {
    return greenColor;
  } else if (color === 'Red') {
    return redColor;
  } else {
    return neutral;
  }
};

export const checkProjectKpiColor = (record) => {
  const greenColor = '#6ABF6A';
  const redColor = '#dc2626';

  // krahason actual me target
  if (record.parentType === kpiPuntualeAcumulato.puntuale) {
    if (record.calculationType === kpiCalculationType.valoreMinimo) {
      if (record.actual < record.target || record.actual === record.target) {
        return greenColor;
      } else if (record.actual > record.target) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    } else {
      if (record.actual > record.target || record.actual === record.target) {
        return greenColor;
      } else if (record.actual < record.target) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    }
  }
  // krahason actualYTD me targetYTD
  else if (record.parentType === kpiPuntualeAcumulato.acumulato) {
    if (record.calculationType === kpiCalculationType.valoreMinimo) {
      if (record.updateYTD < record.targetYTD || record.updateYTD === record.targetYTD) {
        return greenColor;
      } else if (record.updateYTD > record.targetYTD) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    } else {
      if (record.updateYTD > record.targetYTD || record.updateYTD === record.targetYTD) {
        return greenColor;
      } else if (record.updateYTD < record.targetYTD) {
        return redColor;
      } else {
        return '#FFFFFF';
      }
    }
  } else return '#FFFFFF';
};

export const randomGenKey = () => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 20; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

export const projectTypeColumnValues = (recordData, t) => {
  if (recordData.activityType === 'activity') {
    return t('leMieAttivitaPage.activity');
  }
  if (recordData.activityType === 'macroActivity') {
    return t('leMieAttivitaPage.macroActivity');
  }
  if (recordData.activityType === 'deliverable') {
    return t('leMieAttivitaPage.deliverable');
  } else return;
};

export const getActivityIcon = (activityType, t) => {
  if (activityType === 'activity') {
    return (<Tooltip title={t('leMieAttivitaPage.activity')}><TaskIcon /></Tooltip>);
  }

  if (activityType === 'macroActivity') {
    return  (<Tooltip title={t('leMieAttivitaPage.macroActivity')}><PhaseIcon /></Tooltip>);
  }

  if (activityType === 'deliverable') {
    return (<Tooltip title={t('leMieAttivitaPage.deliverable')}><DeliverableIcon /></Tooltip>);
  }
};

export const alignCurrentMonthCellOnKpiValuesGrid = () => {
  const targetRowCurrentMonth = document.querySelector('.currentMonthBorderLineIndicatorTarget');
  const actualtRowCurrentMonth = document.querySelector('.currentMonthBorderLineIndicatorActual');
  if (actualtRowCurrentMonth) actualtRowCurrentMonth.scrollIntoView();
  if (targetRowCurrentMonth) targetRowCurrentMonth.scrollIntoView();
};

export const alignCurentMonthProjectSintesi = () => {
  const actualMonth = Array.from(document.querySelectorAll('.currentMonthLineIndicatorSintesi'));

  if (actualMonth.length >= 2) actualMonth[1].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
};

export const projectFieldEditorObjectUpdate = (fieldKey, fieldValue, t) => {
  const objData = {};
  if (fieldKey === 'projectGoal') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.risultatoAtteso')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'groundtoAct') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.motivoAgire')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'obtainedBenefit') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.beneficiOtenuti')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'finishedActivitiesNote') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('a3Page.commentiS3')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'hanseiLearnedLessons') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.henseiLezioniImparate')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'nextStepsNote') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('a3Page.commentiS4')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'description') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('general.descrizione')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else {
    return null;
  }
};

export const SegmentTitle = ({ loadOnPresentationSection, title }) => {
  return (
    <Text
      className="upperCase"
      style={{ fontSize: 14 }}
      onClick={loadOnPresentationSection}
    >
      {' '}
      <Tooltip title={title}>{title} </Tooltip>
    </Text>
  );
};

export const generateUserInitials = (firstname, lastname) => {
  const firstName = firstname ? firstname.charAt(0) : '';
  const lastName = lastname ? lastname.charAt(0) : '';
  const initials = firstName + lastName;
  return initials.toUpperCase();
};

export const checkUserIsKpiResponsabile = (responsabileId, currentUser) => {
  if (responsabileId) {
    if (responsabileId === currentUser.userId || isUserAdmin(currentUser)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const checkUserIsKpiResponsabileOrProjectTM = (responsabileId, currentUser, teamLeaderId, sponsorId) => {
  if (responsabileId) {
    if (
      responsabileId === currentUser.userId ||
      isUserAdmin(currentUser) ||
      userIsProjectTeamLeaderOrSponsor(currentUser, teamLeaderId, sponsorId)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

// const calculatedTargetValueTolerance = (monthValue, record, tolerance) => {
//   let monthValueWithTolerance = 0;

//   if (record.type === kpiCalculationType.valoreFatoNonFato) {
//     monthValueWithTolerance = monthValue;
//   }
//   else {
//     if (record.type === kpiCalculationType.valoreMinimo) {
//       monthValueWithTolerance = (monthValue + (monthValue * tolerance))
//     }
//     else {
//       monthValueWithTolerance = (monthValue - (monthValue * tolerance))
//     }
//   }
//   return monthValueWithTolerance;
// }

const calculatedTargetValueTolerance = (monthValue, record, tolerance) => {
  let monthValueWithTolerance = 0;

  if (record.type === kpiCalculationType.valoreFatoNonFato) {
    monthValueWithTolerance = monthValue;
  } else if (record.type === kpiCalculationType.valoreMinimo) {
    monthValueWithTolerance = monthValue + monthValue * tolerance;
  } else {
    monthValueWithTolerance = monthValue - monthValue * tolerance;
  }
  return monthValueWithTolerance;
};

const stopCalculationOnCurrentMonth = (monthValue, targetMonthName, startFromCurrentMonth, targetRowData) => {
  const currentMonth = moment().format('YYYY-MM');
  const kpiMonth = moment(targetMonthName).format('YYYY-MM');

  if (kpiMonth === currentMonth) {
    return null;
  } else {
    return monthValue;
  }
};

export const checkBowlingChartCellColor = (
  record,
  monthName,
  monthValue,
  kpiData,
  tolerance,
  startFromCurrentMonth,
  targetRow,
  targetYtdRow,
  actualYtdRow,
) => {
  const greenColor = '#6ABF6A';
  const redColor = '#dc2626';
  const whiteColor = '#FFFFFF';

  const toleranceInNumber = tolerance ? tolerance / 100 : 0;

  if (record.parentType === kpiPuntualeAcumulato.puntuale) {
    if (record.name === bowlingChartRowName.actual) {
      // let targetRow;
      // if(isChildKpis){
      //   targetRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID && item.projectID === record.projectID })[0];
      // }
      // else {
      //   targetRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];
      // }

      let targetValue = 0;
      let actualValue = 0;

      if (startFromCurrentMonth) {
        targetValue = targetRow.monthsList[monthName];
      } else {
        // targetValue = targetMonthToCalculate(targetRow.monthsList[monthName], monthName, startFromCurrentMonth, targetRow);
        // targetValue = targetRow.monthsList[monthName];

        targetValue = stopCalculationOnCurrentMonth(
          targetRow.monthsList[monthName],
          monthName,
          startFromCurrentMonth,
          targetRow,
        );
      }

      if (startFromCurrentMonth) {
        actualValue = monthValue;
      } else {
        // actualValue = actualMonthToCalculate(monthValue, monthName, startFromCurrentMonth, actualRow);
        // actualValue = monthValue;

        actualValue = stopCalculationOnCurrentMonth(monthValue, monthName, startFromCurrentMonth, targetRow);
      }

      if (record.type === kpiCalculationType.valoreMinimo) {
        // if (monthValue < (targetValue + (targetValue * toleranceInNumber)) || monthValue === (targetValue + (targetValue * toleranceInNumber)) ) {
        if (
          actualValue !== null &&
          (actualValue < calculatedTargetValueTolerance(targetValue, record, toleranceInNumber) ||
            actualValue === calculatedTargetValueTolerance(targetValue, record, toleranceInNumber))
        ) {
          return { cellColor: greenColor, textColor: 'white' };
        } else if (
          actualValue !== null &&
          actualValue > calculatedTargetValueTolerance(targetValue, record, toleranceInNumber)
        ) {
          return { cellColor: redColor, textColor: 'white' };
        } else {
          return { cellColor: whiteColor, textColor: 'black' };
        }
      } else {
        // if (monthValue > (targetValue - (targetValue * toleranceInNumber)) || monthValue === (targetValue - (targetValue * toleranceInNumber))) {
        if (
          actualValue !== null &&
          (actualValue > calculatedTargetValueTolerance(targetValue, record, toleranceInNumber) ||
            actualValue === calculatedTargetValueTolerance(targetValue, record, toleranceInNumber))
        ) {
          return { cellColor: greenColor, textColor: 'black' };
        } else if (
          actualValue !== null &&
          actualValue < calculatedTargetValueTolerance(targetValue, record, toleranceInNumber)
        ) {
          return { cellColor: redColor, textColor: 'white' };
        } else {
          return { cellColor: whiteColor, textColor: 'black' };
        }
      }
    } else {
      return;
    }
  } else if (record.parentType === kpiPuntualeAcumulato.acumulato) {
    if (record.name === bowlingChartRowName.actualYtd) {
      // let targetYtdRow;
      // let actualYtdRow;

      // if(isChildKpis){
      //   targetYtdRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID && item.projectID === record.projectID })[0];
      //   actualYtdRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.actualYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID && item.projectID === record.projectID })[0];
      // }
      // else {
      //   targetYtdRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];
      //   actualYtdRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.actualYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];
      // }

      // let targetYtdRow = data.filter(function (item) { return item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID && item.projectID === record.projectID })[0];

      // let targetYtdValue = targetYtdRow.monthsList[monthName];

      let targetYtdValue = 0;
      let actualYtdValue = 0;

      if (startFromCurrentMonth) {
        targetYtdValue = targetYtdRow.monthsList[monthName];
      } else {
        // targetYtdValue = targetMonthToCalculate(targetYtdRow.monthsList[monthName], monthName, startFromCurrentMonth, targetYtdRow);
        // targetYtdValue = targetYtdRow.monthsList[monthName];

        targetYtdValue = stopCalculationOnCurrentMonth(
          targetYtdRow.monthsList[monthName],
          monthName,
          startFromCurrentMonth,
          targetYtdRow,
        );
      }

      if (startFromCurrentMonth) {
        actualYtdValue = monthValue;
      } else {
        // actualYtdValue = actualMonthToCalculate(monthValue, monthName, startFromCurrentMonth, actualYtdRow);
        // actualYtdValue = monthValue;

        actualYtdValue = stopCalculationOnCurrentMonth(monthValue, monthName, startFromCurrentMonth, actualYtdRow);
      }
      // if(record.kpiDetailName === "202402_SPEC_VMC_PRJ") debugger
      if (record.type === kpiCalculationType.valoreMinimo) {
        // if (monthValue < (targetYtdValue + (targetYtdValue * toleranceInNumber)) || monthValue === (targetYtdValue + (targetYtdValue * toleranceInNumber)) ) {
        if (
          actualYtdValue !== null &&
          (actualYtdValue < calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber) ||
            actualYtdValue === calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber))
        ) {
          return { cellColor: greenColor, textColor: 'white' };
        } else if (
          actualYtdValue !== null &&
          actualYtdValue > calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber)
        ) {
          return { cellColor: redColor, textColor: 'white' };
        } else {
          return { cellColor: whiteColor, textColor: 'black' };
        }
      } else {
        // if (monthValue > (targetYtdValue - (targetYtdValue * toleranceInNumber)) || monthValue === (targetYtdValue - (targetYtdValue * toleranceInNumber))) {
        if (
          actualYtdValue !== null &&
          (actualYtdValue > calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber) ||
            actualYtdValue === calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber))
        ) {
          return { cellColor: greenColor, textColor: 'white' };
        } else if (
          actualYtdValue !== null &&
          actualYtdValue < calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber)
        ) {
          return { cellColor: redColor, textColor: 'white' };
        } else {
          return { cellColor: whiteColor, textColor: 'black' };
        }
      }
    } else {
      return;
    }
  } else return { cellColor: whiteColor, textColor: 'black' };
};

// export const checkBowlingChartCellColor = (record, monthName, monthValue, kpiData, tolerance, startFromCurrentMonth) => {
//   const greenColor = "#6ABF6A";
//   const redColor = "#dc2626";
//   const whiteColor = "#FFFFFF";
// debugger
//   let toleranceInNumber = tolerance ?  (tolerance / 100) : 0;

//   if (record.parentType === kpiPuntualeAcumulato.puntuale) {

//       if (record.name === bowlingChartRowName.actual) {
//           let targetRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];
//           let actualRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.actual && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];

//           // let targetValue = targetRow.monthsList[monthName];

//           let targetValue = 0;
//           let actualValue = 0;

//           if(startFromCurrentMonth){
//             targetValue = targetRow.monthsList[monthName];
//           } else {
//             targetValue = targetMonthToCalculate(targetRow.monthsList[monthName], monthName, startFromCurrentMonth, targetRow);
//           }

//           if(startFromCurrentMonth){
//             actualValue = monthValue;
//           } else {
//             actualValue = actualMonthToCalculate(monthValue, monthName, startFromCurrentMonth, actualRow);
//           }

//           if (record.type === kpiCalculationType.valoreMinimo) {
//               // if (monthValue < (targetValue + (targetValue * toleranceInNumber)) || monthValue === (targetValue + (targetValue * toleranceInNumber)) ) {
//                 if (monthValue < calculatedTargetValueTolerance(targetValue, record, toleranceInNumber) || monthValue === calculatedTargetValueTolerance(targetValue, record, toleranceInNumber) ) {
//                   return greenColor
//               }
//               else if (monthValue > targetValue) {
//                   return redColor
//               }
//               else {
//                   return whiteColor
//               }
//           }

//           else {
//               // if (monthValue > (targetValue - (targetValue * toleranceInNumber)) || monthValue === (targetValue - (targetValue * toleranceInNumber))) {
//                 if (monthValue > calculatedTargetValueTolerance(targetValue, record, toleranceInNumber) || monthValue === calculatedTargetValueTolerance(targetValue, record, toleranceInNumber)) {
//                   return greenColor
//               }
//               else if (monthValue < targetValue) {
//                   return redColor
//               }
//               else {
//                   return whiteColor
//               }
//           }

//       }
//       else {
//           return;
//       }
//   }

//   else if (record.parentType === kpiPuntualeAcumulato.acumulato) {

//       if (record.name === bowlingChartRowName.actualYtd) {
//           let targetYtdRow = kpiData.filter(function (item) { return item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];
//           let targetYtdValue = targetYtdRow.monthsList[monthName];

//           if (record.type === kpiCalculationType.valoreMinimo) {

//               // if (monthValue < (targetYtdValue + (targetYtdValue * toleranceInNumber)) || monthValue === (targetYtdValue + (targetYtdValue * toleranceInNumber)) ) {
//                 if (monthValue < calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber) || monthValue === calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber) ) {
//                   return greenColor
//               }
//               else if (monthValue > targetYtdValue) {
//                   return redColor
//               }
//               else {
//                   return whiteColor
//               }
//           }
//           else {
//               // if (monthValue > (targetYtdValue - (targetYtdValue * toleranceInNumber)) || monthValue === (targetYtdValue - (targetYtdValue * toleranceInNumber))) {
//                 if (monthValue > calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber) || monthValue === calculatedTargetValueTolerance(targetYtdValue, record, toleranceInNumber)) {
//                   return greenColor
//               }
//               else if (monthValue < targetYtdValue) {
//                   return redColor
//               }
//               else {
//                   return whiteColor
//               }
//           }
//       }
//       else {
//           return;
//       }

//   }
//   else return whiteColor
// }

export function isDateBetween(date, beforeDate, afterDate) {
  return compareAsc(date, beforeDate) >= 0 && compareAsc(date, afterDate) <= 0;
}

export function formatMinutesAsHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  let result = '';
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (mins > 0) {
    result += `${mins}m`;
  }

  return result.trim();
}

export function countOccurrences(str, char) {
  if (!str || !char) return 0;

  return str.split(char).length - 1;
}
