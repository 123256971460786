import { Col, Space, Table, Tooltip } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { GetUserProjectResponseDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import { RepositoryLineIcon } from '../../assets/icons';
import CommonService from '../../services/commonService';
import UsersService from '../../services/pages/usersService';
import XmatrixDropDownFilter from '../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import {
  BudgetSemaphore,
  BudgetTooltip,
  QualitySemaphore,
  QualityTooltip,
  TimeSemaphore,
  TimeTooltip,
} from '../shared/semaphores/semaphores';
import { filterRoles, statusPickListCodes } from '../shared/utils/constants';

const getRoleFilter = (option: string): string[] => {
  const roleFilters: Record<string, string[]> = {
    all: ['Team Leader', 'Team Member', 'Sponsor'],
    tmtl: ['Team Leader', 'Team Member'],
    sponsor: ['Sponsor'],
  };
  return roleFilters[option] || [];
};

interface UserProjectsProps {
  toFilter: string;
  isCompact?: boolean;
  userId?: string;
}

const UserProjects: React.FC<UserProjectsProps> = ({ userId, toFilter, isCompact = false }) => {
  const { t } = useTranslation();

  const companyData = useAppSelector((state) => state.companyData.companyData);
  const userData = useAppSelector((state) => state.userData.userData);
  const activeXMatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [loadingProjects, setLoadingProjects] = useState(false);
  const [userProjects, setUserProjects] = useState<GetUserProjectResponseDto[]>([]);
  const [selectedXmatrixId, setSelectedXmatrixId] = useState<string | null>(null);
  const [roleListFilter] = useState(filterRoles);
  const [statusListFilter, setStatusListFilter] = useState<{ text: string; value: string }[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<string[]>(getRoleFilter(toFilter));
  const [filteredStatusInfo, setFilteredStatusInfo] = useState<string[]>([
    `${t('status.draft')}`,
    `${t('status.attivo')}`,
  ]);

  useEffect(() => {
    void retrieveStatusList();

    if (!selectedXmatrixId && activeXMatrix && activeXMatrix?.xMatrixID) {
      setSelectedXmatrixId(activeXMatrix.xMatrixID);
    }
  }, [activeXMatrix]);

  useEffect(() => {
    if ((userId || userData?.userId) && selectedXmatrixId) {
      void retrieveUserProjects();
    }
  }, [userId, selectedXmatrixId, userData, activeXMatrix]);

  const onXmatrixSelect = (selectedXmatrix: { label: string; value: string }) => {
    setSelectedXmatrixId(selectedXmatrix.value);
  };

  const retrieveUserProjects = async () => {
    const matrixId = selectedXmatrixId || activeXMatrix?.xMatrixID;
    const user = userId || userData?.userId;

    if (user && matrixId) {
      setLoadingProjects(true);
      try {
        const response = await UsersService.getUserProjects(matrixId, user);
        if (response.data.success) {
          setUserProjects(response.data.responseObject?.value || []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingProjects(false);
      }
    }
  };

  const retrieveStatusList = async () => {
    try {
      const response = await CommonService.getStatusData(statusPickListCodes.statusProjects);
      if (response.data.success) {
        const statusOptions =
          response.data.responseObject?.value?.map((item) => ({
            text: item.statusDescription || '',
            value: item.statusDescription || '',
          })) || [];
        setStatusListFilter(statusOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (_: any, filters: any) => {
    setFilteredInfo(filters.userRole || []);
    setFilteredStatusInfo(filters.statusDescription || []);
  };

  const userProjectColumns: ColumnType<GetUserProjectResponseDto>[] = [
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      width: '150px',
      sorter: (a, b) => a.projectCode?.localeCompare(b.projectCode || '') || 0,
      defaultSortOrder: 'ascend',
      render: (text: string, record: GetUserProjectResponseDto) => (
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <Link to={{ pathname: `/progetti/id/${record.projectID as string}` }}>{text}</Link>
          {record.projectRepository && (
            <Tooltip title={t('a3Page.repositoriProgetto')}>
              <a
                href={record.projectRepository}
                target="_blank"
                rel="noreferrer"
              >
                <RepositoryLineIcon />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'projectName',
      sorter: (a, b) => a.projectName?.localeCompare(b.projectName || '') || 0,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      width: '100px',
      filters: statusListFilter,
      filteredValue: filteredStatusInfo || null,
      onFilter: (value, record) =>
        (record.statusDescription ? record.statusDescription.includes(value as string) : '') || false,
    },
    {
      title: `${t('general.ruolo')}`,
      dataIndex: 'userRole',
      width: '14ch',
      sorter: (a: GetUserProjectResponseDto, b: GetUserProjectResponseDto) =>
        (a.userRole || '').localeCompare(b.userRole || ''),
      filters: roleListFilter,
      filteredValue: filteredInfo || null,
      onFilter: (value, record) => (record.userRole ? record.userRole.includes(value as string) : false),
    },
    {
      key: 'action1',
      title: `${t('general.indicators')}`,
      width: '22ch',
      render: (_, record) => (
        <Space direction="horizontal">
          <Tooltip
            placement="top"
            title={
              <TimeTooltip
                data={record}
                t={t}
              />
            }
          >
            <Col
              className="semaphore-section"
              xs={8}
              sm={8}
            >
              <TimeSemaphore data={record} />
            </Col>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              <BudgetTooltip
                data={record}
                t={t}
                companyData={companyData}
              />
            }
          >
            <Col
              className="gutter-row semaphore-section"
              xs={8}
              sm={8}
            >
              <BudgetSemaphore data={record} />
            </Col>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              <QualityTooltip
                data={record}
                t={t}
              />
            }
          >
            <Col
              className="gutter-row semaphore-section no-border red-color-bgr"
              xs={8}
              sm={8}
            >
              <QualitySemaphore data={record} />
            </Col>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="xm-filter-wrapper">
        {!isCompact && activeXMatrix && (
          <XmatrixDropDownFilter
            onXmatrixSelect={onXmatrixSelect}
            activeXmatrixInfo={activeXMatrix}
          />
        )}
      </div>

      <Table
        columns={userProjectColumns}
        dataSource={userProjects}
        rowKey={(record) => record.projectID || ''}
        loading={loadingProjects}
        size="small"
        onChange={handleChange}
        pagination={{ defaultPageSize: 5, pageSizeOptions: ['5', '10', '20', '50'] }}
      />
    </>
  );
};

export default UserProjects;
