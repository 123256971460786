import { CloseCircleOutlined, DownloadOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
  message,
} from 'antd';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { withTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import KpiService from '../../services/pages/kpiService';
import { capitalizeFirstLetterKpiMonths } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './kpi.scss';

const { Text, Title } = Typography;

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ErrorList = ({ errorList }) => {
  return (
    <Space direction="vertical">
      {errorList.map((item) => (
        <Text> * {item.error}</Text>
      ))}
    </Space>
  );
};

const ModalHeader = (props) => {
  return (
    <Space direction="vertical">
      <Title
        style={{ marginBottom: 1 }}
        level={5}
      >
        {props.t('general.kpi')}: <span style={{ color: '#1463b5' }}>{props.data.name}</span>
      </Title>
      <span>
        <Text style={{ fontSize: 14 }}>
          {' '}
          <span className="header-text"> {props.t('kpiPage.cumulatoPuntuale')}: </span>{' '}
          <u>{props.data.parentTypeDescription}</u>
        </Text>
        <Divider
          type="vertical"
          style={{ borderColor: '#067af3' }}
        />
        <Text style={{ fontSize: 14 }}>
          {' '}
          <span className="header-text"> {props.t('kpiPage.tipoCalcolo')}: </span>
          <u>{props.data.calculationTypeDescription}</u>
        </Text>
        <Divider
          type="vertical"
          style={{ borderColor: '#067af3' }}
        />
        <Button
          type="primary"
          loading={props.loadingExcelData}
          icon={<DownloadOutlined />}
          onClick={props.onDownload}
        >
          {props.t('buttons.scaricaModello')}
        </Button>

        <Tooltip
          placement="top"
          title={props.t('kpiPage.scaricaModelloKpiInfo')}
        >
          <InfoCircleOutlined style={{ color: '#928e8e', marginLeft: 10 }} />
        </Tooltip>
      </span>
    </Space>
  );
};

class UploadKpiSpecifico extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      loadingExcelData: false,
      cols: [],
      rows: [],
      errorMessage: null,
      kpiApiData: null,

      rowVlidaionErrors: [],
      showErrorsList: false,
    };
  }

  componentDidMount() {
    this.getExcelData();
  }

  async getExcelData() {
    const { kpiRowData, projectId } = this.props;

    this.setState({ loadingExcelData: true });
    await KpiService.getKpiSpecificoExcelData(kpiRowData.kpiProjectRelationShipID, projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiApiData: resp.responseObject.value });
          this.setState({ loadingExcelData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingExcelData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingExcelData: false });
      });
  }

  checkFile(file) {
    const { errorMessage } = this.state;
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === 'application/vnd.ms-excel' ||
      file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      this.setState({ errorMessage: 'Upload only excel files' });
    }
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({ errorMessage: 'File must be less than 2 MB! ' });
    }
    return errorMessage;
  }

  fileHandler = (fileList) => {
    const { rowVlidaionErrors } = this.state;
    const { projectId } = this.props;

    const fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: 'No files uploaded!',
      });
      return false;
    }

    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: 'Upload only excel files',
      });
      return false;
    }

    const isLt2M = fileObj.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({ errorMessage: 'File must be less than 2 MB! ' });
      return false;
    }

    ExcelRenderer(fileObj, (err, resp) => {
      const t = this.props.t;

      if (err) {
        console.log(err);
      } else {
        const newRows = [];
        resp.rows.slice(1).map((row, index) => {
          let rowErrorObj = {};

          if (row[0] !== projectId && index === 0) {
            rowErrorObj['error'] = `${t('kpiPage.kpiAssociatoAltroProgetto')}`;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (isNaN(row[4])) {
            rowErrorObj['error'] = `${t('kpiPage.rigaActual')} ` + (index + 1) + ` ${t('kpiPage.nonValido')}.`;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row[3] === null || row[3] === undefined) {
            rowErrorObj['error'] = `${t('kpiPage.rigaMese')} ` + (index + 1) + ` ${t('kpiPage.nonValido')}.`;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row && row !== 'undefined') {
            newRows.push({
              key: index + 1,
              kpiProjectRelationShipID: row[1],
              name: row[2],
              targetMonth: row[3],
              actual: row[4],
              unitOfMeasure: row[5],
            });
          }
        });

        if (newRows.length === 0) {
          this.setState({
            errorMessage: `${t('utentiPage.noDataOnFile')} `,
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    const { rows } = this.state;

    const payloadArray = [];

    rows.forEach((item, index) => {
      const payloadObj = {};

      payloadObj['actual'] = item.actual;
      payloadObj['unitOfMeasure'] = item.unitOfMeasure;
      payloadObj['kPIProjectRelationShipID'] = item.kpiProjectRelationShipID;
      payloadObj['referencePeriod'] = item.targetMonth;

      payloadArray.push(payloadObj);
    });

    this.setState({ loadingButton: true });
    KpiService.updateSpecificlKpiFromExcel(payloadArray)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ rows: [] });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  exportToCSV = (kpiApiData) => {
    const { kpiRowData, projectName } = this.props;

    const fileName = `KPI_${kpiRowData.name}_${projectName}`;

    if (kpiApiData) {
      const ws = XLSX.utils.json_to_sheet(kpiApiData);

      //column formatter
      const wsColsFormatter = [
        { hidden: true }, // hidde first column
        { hidden: true }, // hidde first column
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
      ];

      ws['!cols'] = wsColsFormatter;

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  cancellUpload = async () => {
    this.setState({ rows: [] });
    this.setState({ rowVlidaionErrors: [] });
  };

  showHideErrorList = () => {
    const { showErrorsList } = this.state;
    this.setState({ showErrorsList: !showErrorsList });
  };

  render() {
    const { showUploadKpiModal, handleUploadKpiModalClose, kpiRowData } = this.props;
    const t = this.props.t;
    const { rows, kpiApiData, loadingExcelData, errorMessage, loadingButton, showErrorsList, rowVlidaionErrors } =
      this.state;

    const columns = [
      {
        title: '',
        dataIndex: 'key',
      },
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        ellipsis: {
          showTitle: true,
        },
      },
      {
        title: `${t('general.mese')}`,
        dataIndex: 'targetMonth',
        render: (text) => capitalizeFirstLetterKpiMonths(text ? moment(text).format('MMM YYYY') : null),
      },
      {
        title: `${t('general.actual')}`,
        dataIndex: 'actual',
      },
      {
        title: `${t('kpiPage.unitaMisura')}`,
        dataIndex: 'unitOfMeasure',
      },
    ];

    return (
      <Modal
        className="edit-kpi-modal"
        width={1100}
        title={
          <ModalHeader
            data={kpiRowData}
            onDownload={() => this.exportToCSV(kpiApiData)}
            loadingExcelData={loadingExcelData}
            t={t}
          />
        }
        open={showUploadKpiModal}
        destroyOnClose={true}
        onCancel={handleUploadKpiModalClose}
        // width={"100%"}
        footer={[
          <div key="close_buttons">
            <Button onClick={handleUploadKpiModalClose}>{t('buttons.chiudi')}</Button>
          </div>,
        ]}
      >
        <Card>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="button-wrapper">
                {rows.length > 0 && (
                  <>
                    <Button
                      onClick={this.handleSubmit}
                      type="primary"
                      style={{ marginBottom: 16, marginLeft: 10 }}
                      loading={loadingButton}
                      disabled={rowVlidaionErrors && rowVlidaionErrors.length > 0}
                      className="tw-float-end"
                    >
                      {t('buttons.salva')}
                    </Button>

                    <Button
                      onClick={this.cancellUpload}
                      type="ghost"
                      style={{ marginBottom: 16, marginLeft: 10 }}
                      icon={<CloseCircleOutlined />}
                      className="long-term-button remove"
                    >
                      {t('buttons.cancella')}
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {rowVlidaionErrors && rowVlidaionErrors.length > 0 && (
            <Alert
              message={t('general.alcuniDatiNonValidi')}
              showIcon
              description={showErrorsList ? <ErrorList errorList={rowVlidaionErrors} /> : ''}
              type="error"
              action={
                <Button
                  danger
                  onClick={this.showHideErrorList}
                >
                  {t('buttons.dettagli')}
                </Button>
              }
            />
          )}

          {rows.length < 1 && (
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Upload
                  name="file"
                  maxCount={1}
                  multiple={false}
                  beforeUpload={this.fileHandler}
                  onRemove={() => this.setState({ rows: [] })}
                  // multiple={false}
                >
                  <Button icon={<UploadOutlined />}>{t('buttons.sleionaExcel')}</Button>
                </Upload>
                <p className="upload-text"> * {t('utentiPage.clickFileExcel')} </p>

                {errorMessage && <p className="upload-text-error"> {errorMessage}</p>}
              </Col>
            </Row>
          )}

          {rows.length > 0 && (
            <Row
              gutter={{ lg: 24 }}
              style={{ marginTop: 10 }}
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Table
                  className="table-height"
                  dataSource={rows}
                  columns={columns}
                  bordered={true}
                  scroll={{ x: 'calc(700px + 50%)' }}
                  size="small"
                  // loading={loadingData}
                />
              </Col>
            </Row>
          )}
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(UploadKpiSpecifico);
