import { type PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { GetXMatrixPicklistResponseDto } from 'src/connectors/backend';
import MatrixService from '../../services/matrix/matrixService';

interface XMatrixState {
  loading: boolean;
  selectedXmatrix: string | null | undefined;
  xmatrixInfo: GetXMatrixPicklistResponseDto[] | null;
  error: string | null;
}

const initialState: XMatrixState = {
  loading: false,
  selectedXmatrix: null,
  xmatrixInfo: null,
  error: null,
};

export const retrieveSelectedXmatrix = createAsyncThunk(
  'xmatrix/retrieveSelectedXmatrix',
  async (_, { rejectWithValue }) => {
    try {
      const response = await MatrixService.getXmatrixPicklist();
      const { success, responseObject } = response.data;

      if (success && responseObject?.value) {
        const defaultXmatrixEntry = responseObject.value.find((x: any) => x.status === 1 && x.parentXMatrixID === null);

        const defaultXmatrix = defaultXmatrixEntry ? defaultXmatrixEntry.xMatrixID : null;
        const xmatrixInfo = defaultXmatrixEntry || null;

        return { selectedXmatrix: defaultXmatrix, xmatrixInfo };
      } else {
        throw new Error('Failed to retrieve xMatrix data');
      }
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  },
);

const xMatrixSlice = createSlice({
  name: 'xmatrix',
  initialState,
  reducers: {
    changeSelectedXmatrix: (
      state,
      action: PayloadAction<{ selectedXmatrix: string | null | undefined; xmatrixInfo: any }>,
    ) => {
      state.selectedXmatrix = action.payload.selectedXmatrix;
      state.xmatrixInfo = action.payload.xmatrixInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveSelectedXmatrix.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        retrieveSelectedXmatrix.fulfilled,
        (state, action: PayloadAction<{ selectedXmatrix: string | null | undefined; xmatrixInfo: any }>) => {
          state.loading = false;
          state.selectedXmatrix = action.payload.selectedXmatrix;
          state.xmatrixInfo = action.payload.xmatrixInfo;
        },
      )
      .addCase(retrieveSelectedXmatrix.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error retrieving xMatrix data';
      });
  },
});

export const { changeSelectedXmatrix } = xMatrixSlice.actions;
export default xMatrixSlice.reducer;
