import { Col, Empty, Row, Switch } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import KpiService from '../../../services/pages/kpiService';
import { capitalizeFirstLetter, convertDateToUTC, italianNumberFormat } from '../../shared/utils/functions';

const ytdColor = '#3ca0d0';
const targetYtdColor = '#000000';

class KpiDetailGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEnabled: true,
      followGraph: [],
      kpiList: [],
      selectedKpiValue: null,
      options: {},
    };
  }

  async componentDidMount() {
    const { kpiList } = this.state;
    const { kpiDetailId, projectId } = this.props;
    await this.getDataGraphAsA3();

    // for (let i = 0; i < kpiList.length; i++) {
    //     if (kpiList.length > 0) {
    //       await this.getDataGraph(id, kpiList[i].kpiDetailID);
    //     }
    //   }
  }

  getDataGraphAsA3 = async () => {
    const { xmatrix, kpiDetailId, projectId, month, year, annualId, t } = this.props;
    const filters = {
      KPIDetailID: kpiDetailId,
      year: year,
      ProjectID: projectId && projectId !== '0' ? projectId : null,
      AnnualGoalID: annualId && annualId !== '0' ? annualId : null,
    };
    await KpiService.getKpiGraphAsInA3(filters)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ followGraph: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  handleCheckBox = () => {
    const { isEnabled } = this.state;
    this.setState({ isEnabled: !isEnabled });
  };

  handleKpiChange = (value) => {
    const { id } = this.props;
    const { kpiList } = this.state;
    for (let i = 0; i < kpiList.length; i++) {
      if (kpiList[i].name === value) {
        this.getDataGraph(id, kpiList[i].kpiDetailID);
      }
    }
    this.setState({ selectedKpiValue: value });
  };

  render() {
    const { t } = this.props;
    const { isEnabled, followGraph, selectedKpiValue } = this.state;
    const ytdData = [];
    const targetYtdData = [];

    for (let i = 0; i < followGraph.length; i++) {
      ytdData.push([convertDateToUTC(followGraph[i].monthReference), followGraph[i].ytd]);
      targetYtdData.push([convertDateToUTC(followGraph[i].monthReference), followGraph[i].targetYTD]);
    }
    let max = 0;

    for (let i = 0; i < followGraph.length; i++) {
      if (followGraph[i].targetYTD > max && followGraph[i].targetYTD >= followGraph[i].ytd) {
        max = followGraph[i].targetYTD;
      } else if (followGraph[i].ytd > max && followGraph[i].ytd >= followGraph[i].targetYTD) {
        max = followGraph[i].ytd;
      } else if (followGraph[i].ytd === 0 && followGraph[i].targetYTD === 0 && max === 0) {
        max = 10;
      }
    }

    const val = max + max * 0.15;
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    var options = {
      title: {
        text: selectedKpiValue ? selectedKpiValue : '',
      },
      credits: {
        enabled: false,
      },
    };

    for (let i = 0; i < followGraph.length; i++) {
      if (followGraph[i].parentType === 1) {
        if (followGraph[i].calculationType === 1) {
          options = {
            chart: {
              type: 'column',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },

            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',
              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                //  format: '{value:%b-%y}'
                formatter: function () {
                  return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                },
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },

              labels: {
                format: '{value}',
              },
              // min: 0,
              max: String(val),
            },
            tooltip: {
              // headerFormat: '{series.name}',
              // pointFormat: '{point.y}',
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors:  colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(currentYear, currentMonth, 2),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        } else if (followGraph[i].calculationType === 2) {
          options = {
            chart: {
              type: 'column',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',
              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                formatter: function () {
                  return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                },
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },
              labels: {
                format: '{value} ',
              },
              // min: 0,
              max: String(val),
            },
            tooltip: {
              //  headerFormat: '{series.name}',
              //  pointFormat: '{point.y}',
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors: colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(2010, 0, 1),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        } else if (followGraph[i].calculationType === 3) {
          var options = {
            chart: {
              type: 'column',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',
              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                format: '{value:%b-%Y}',
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },
              labels: {
                format: '{value} %',
                // formatter: function () { return capitalizeFirstLetter( moment(this.value).format("MMM YYYY") ) }
              },
              // min: 0,
              max: String(val),
              //  categories: ['0', '10', '20', '30', '40', '50', '60', '70','80','90','100']
            },
            tooltip: {
              // headerFormat: '{series.name}',
              // pointFormat: '{point.y}',
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors: colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(2010, 0, 1),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        } else if (followGraph[i].calculationType === 4) {
          options = {
            chart: {
              type: 'column',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',
              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                // format: '{value:%b-%Y}'
                formatter: function () {
                  return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                },
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },
              labels: {
                format: '{value} ',
              },
              min: '0',
              max: '1',
              categories: ['0', '1'],
            },
            tooltip: {
              // headerFormat: '{series.name}',
              // pointFormat: '{point.y}',
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors: colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(2010, 0, 1),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        }

        if (isEnabled) {
          if (followGraph[i].calculationType === 1) {
            options = {
              chart: {
                type: 'column',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value}',
                },
                // min: 0,
                max: String(val),
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              // colors:  ["#3ca0d0", "#000000"],
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          } else if (followGraph[i].calculationType === 2) {
            options = {
              chart: {
                type: 'column',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value}',
                },
                // min: 0,
                max: String(val),
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              // colors: colorPalete,
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          } else if (followGraph[i].calculationType === 3) {
            options = {
              chart: {
                type: 'column',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value} %',
                },
                // min: '0',
                max: String(val),
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              // colors: colorPalete,
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          } else if (followGraph[i].calculationType === 4) {
            options = {
              chart: {
                type: 'column',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value} ',
                },
                min: '0',
                max: '1',
                categories: ['0', '1'],
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              // colors: colorPalete,
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          }
        }
      } else if (followGraph[i].parentType === 2) {
        if (followGraph[i].calculationType === 1) {
          options = {
            chart: {
              type: 'spline',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },

            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',

              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                //  format: '{value:%b-%y}'
                formatter: function () {
                  return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                },
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },

              labels: {
                format: '{value}',
              },
              // min: 0,
              max: String(val),
            },
            tooltip: {
              // headerFormat: '{series.name}',
              // pointFormat: '{point.y}',
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors: colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(currentYear, currentMonth, 2),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        } else if (followGraph[i].calculationType === 2) {
          options = {
            chart: {
              type: 'spline',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',
              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                formatter: function () {
                  return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                },
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },
              labels: {
                format: '{value} ',
              },
              // min: 0,
              max: String(val),
            },
            tooltip: {
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors: colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(2010, 0, 1),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        } else if (followGraph[i].calculationType === 3) {
          var options = {
            chart: {
              type: 'spline',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',
              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                format: '{value:%b-%Y}',
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },
              labels: {
                format: '{value} %',
              },
              // min: 0,
              max: String(val),
            },
            tooltip: {
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors: colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(2010, 0, 1),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        } else if (followGraph[i].calculationType === 4) {
          options = {
            chart: {
              type: 'spline',
            },
            title: {
              text: selectedKpiValue,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              plotBands: [
                {
                  color: 'red',
                  from: Date.UTC(currentYear, currentMonth, 2),
                  to: Date.UTC(currentYear, currentMonth, 2),
                },
              ],
              tickInterval: 28 * 24 * 3600 * 1000,
              type: 'datetime',
              dateTimeLabelFormats: {
                month: '%b',
                year: '%Y',
              },
              labels: {
                // format: '{value:%b-%Y}'
                formatter: function () {
                  return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                },
              },
            },
            yAxis: {
              title: {
                text: `${t('general.valori')}`,
              },
              labels: {
                format: '{value} ',
              },
              min: '0',
              max: '1',
              categories: ['0', '1'],
            },
            tooltip: {
              // headerFormat: '{series.name}',
              // pointFormat: '{point.y}',
              formatter: function () {
                return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
              },
            },
            // colors: colorPalete,
            series: [
              {
                name: 'YTD',
                data: ytdData,
                color: ytdColor,
                pointStart: Date.UTC(2010, 0, 1),
                pointInterval: 28 * 24 * 3600 * 1000,
              },
            ],
          };
        }

        if (isEnabled) {
          if (followGraph[i].calculationType === 1) {
            options = {
              chart: {
                type: 'spline',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value}',
                },
                // min: 0,
                max: String(val),
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          } else if (followGraph[i].calculationType === 2) {
            options = {
              chart: {
                type: 'spline',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value}',
                },
                // min: 0,
                max: String(val),
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          } else if (followGraph[i].calculationType === 3) {
            options = {
              chart: {
                type: 'spline',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value} %',
                },
                // min: '0',
                max: String(val),
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          } else if (followGraph[i].calculationType === 4) {
            options = {
              chart: {
                type: 'spline',
              },
              title: {
                text: selectedKpiValue,
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                plotBands: [
                  {
                    color: 'red',
                    from: Date.UTC(currentYear, currentMonth, 2),
                    to: Date.UTC(currentYear, currentMonth, 2),
                  },
                ],
                tickInterval: 28 * 24 * 3600 * 1000,
                type: 'datetime',
                dateTimeLabelFormats: {
                  month: '%b',
                  year: '%Y',
                },
                labels: {
                  // format: '{value:%b-%Y}'
                  formatter: function () {
                    return capitalizeFirstLetter(moment(this.value).format('MMM YYYY'));
                  },
                },
              },
              yAxis: {
                title: {
                  text: `${t('general.valori')}`,
                },
                labels: {
                  format: '{value} ',
                },
                min: '0',
                max: '1',
                categories: ['0', '1'],
              },
              tooltip: {
                // headerFormat: '{series.name}',
                // pointFormat: '{point.y}',
                formatter: function () {
                  return '<b>' + this.series.name + ': </b>' + italianNumberFormat(this.y);
                },
              },
              series: [
                {
                  name: 'YTD',
                  data: ytdData,
                  color: ytdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
                {
                  name: 'Target YTD',
                  data: targetYtdData,
                  color: targetYtdColor,
                  pointStart: Date.UTC(2010, 0, 1),
                  pointInterval: 28 * 24 * 3600 * 1000,
                },
              ],
            };
          }
        }
      }
    }

    return (
      <div>
        {followGraph && followGraph.length > 0 && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="mt2"
            >
              <Col
                className="gutter-row s1_2"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div style={{ display: 'inline-block', marginTop: '9px', padding: '0px 30px 0px 30px' }}>
                  <label>
                    <span style={{ paddingRight: '10px' }}>{t('a3Page.mostraTargetYtd')}</span>
                    <Switch
                      defaultChecked={isEnabled}
                      onChange={this.handleCheckBox}
                    />
                  </label>
                </div>
                {/* <Select value={selectedKpiValue} className="kpi-select" onChange={this.handleKpiChange}>
                      
                          <Option value={kpi.name} key={kpi.kpiDetailID}>{kpi.name}</Option>
                      
                      </Select> */}

                <div style={{ marginTop: '15px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        {followGraph && followGraph.length < 1 && <Empty />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(KpiDetailGraph));
