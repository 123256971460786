import { Form } from 'antd';
import React from 'react';
import './activities.scss';

import { Card, Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import moment from 'moment';
import CommonService from '../../../../services/commonService';
import DeliverablePageService from '../../../../services/pages/deliverableService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { activityStatus, statusPickListCodes } from '../../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class NewProjectActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      deliverablePickList: null,
      ownerPicklist: null,
      selectedDeliverable: null,
    };
  }

  componentDidMount() {
    const { projectID } = this.props;
    this.retrieveStatus();
    this.retrieveDeliverablePickList(projectID);
    this.retrieveResponsibleUsersList(projectID);
  }

  async retrieveResponsibleUsersList(projectId) {
    await CommonService.getResponsibleUserList(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const usr = [];
          const respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ ownerPicklist: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveDeliverablePickList(projectId) {
    await DeliverableService.getDeliverableProjectPicklist(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverablePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addData = (values, projectId) => {
    this.props.handleSaveProjectActivity(values, projectId);
  };

  getSelectedDeliverableData = (id) => {
    DeliverablePageService.getDeliverableById(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedDeliverable: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  onDeliverableChange = (option) => {
    this.getSelectedDeliverableData(option);
  };

  disabledDate(current, projectStartDate, projectEndDate, selectedDeliverable) {
    if (selectedDeliverable) {
      if (selectedDeliverable.parentDeliverableID) {
        const curr = moment(current).endOf('day');
        const startDate = moment(selectedDeliverable.parentDeliverableStartDate).endOf('day');
        const endDate = moment(selectedDeliverable.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        const curr = moment(current).endOf('day');
        const startDate = moment(projectStartDate).endOf('day');
        const endDate = moment(projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else {
      const curr = moment(current).endOf('day');
      const startDate = moment(projectStartDate).endOf('day');
      const endDate = moment(projectEndDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  }

  checkValidityBeforeSave = (values, projectId) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.addData(values, projectId);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const { showCreateModal, handleCreateModalClose, loadingButton, projectID, projectName, currentProject, t } =
      this.props;
    const { statusList, deliverablePickList, ownerPicklist, selectedDeliverable } = this.state;

    const currentDate = moment();

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('leMieAttivitaPage.aggiungiNuovaAttivita')}
        open={showCreateModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleCreateModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleCreateModalClose}
            formId={'newProjectActivityForm'}
          />,
        ]}
      >
        <Card>
          <Form
            id="newProjectActivityForm"
            layout="vertical"
            {...formItemLayout}
            // onFinish={this.checkDatesValidity}
            onFinish={(values) => this.checkValidityBeforeSave(values, projectID)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.progetto')}
                >
                  <Input
                    disabled={true}
                    value={projectName}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('leMieAttivitaPage.soggetto')}
                  name="subject"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  style={{ marginBottom: 5 }}
                  labelAlign="left"
                  label={t('general.deliverable')}
                  name="deliverableID"
                  hasFeedback
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(option) => this.onDeliverableChange(option)}
                  >
                    {deliverablePickList &&
                      deliverablePickList.map((item) => (
                        <Option
                          value={item.deliverableID}
                          key={item.deliverableID}
                        >
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row mt10"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  labelAlign="left"
                  label={t('general.responsabile')}
                  name="ownerID"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={currentProject.teamLeaderID ? currentProject.teamLeaderID : null}
                >
                  <Select
                    placeholder={t('general.seleziona')}
                    style={{ width: '100%' }}
                    options={ownerPicklist}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    loading={!ownerPicklist}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row mt10"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={activityStatus.active}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {statusList &&
                      statusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}
                        >
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.start')}
                  name="startDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={currentDate}
                >
                  {/* <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="Seleziona" 
                                    disabledDate={(current) => this.disabledDate(current, currentProject.startDate, currentProject.endDate, selectedDeliverable)} /> */}

                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    placeholder="Seleziona"
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.end')}
                  name="endDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    placeholder="Seleziona"
                    disabledDate={(current) =>
                      this.disabledDate(current, currentProject.startDate, currentProject.endDate, selectedDeliverable)
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="mt10 main-container"
                  label={t('general.descrizione')}
                  name="description"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default NewProjectActivity;
