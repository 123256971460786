import { EditOutlined, FolderOpenOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Input, Popover, Table, Tag, Tooltip, message } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { GetDeliverableListPageResponseDto } from 'src/connectors/backend';
import CommonService from '../../../services/commonService';
import DeliverablePageService from '../../../services/pages/deliverableService';
import TableLayout from '../../shared/tableLayout';
import { statusPickListCodes } from '../../shared/utils/constants';
import { ResponsiblePopoverContent } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';

const DeliverableList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [deliverableList, setDeliverableList] = useState<GetDeliverableListPageResponseDto[]>([]);
  const [filteredDeliverableList, setFilteredDeliverableList] = useState<GetDeliverableListPageResponseDto[]>([]);
  const [statusListFilter, setStatusListFilter] = useState<any[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<string[] | null>([
    `${t('status.draft')}`,
    `${t('status.aperto')}`,
    `${t('status.sospeso')}`,
  ]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    retrieveDeliverableList();
    retrieveStatusList();
  }, []);

  useEffect(() => {
    if (searchText) {
      setFilteredDeliverableList(
        deliverableList.filter((item) => item.deliverableName?.toLowerCase().includes(searchText.toLowerCase())),
      );
    } else {
      setFilteredDeliverableList(deliverableList);
    }
  }, [searchText]);

  const retrieveDeliverableList = async () => {
    setLoadingData(true);
    try {
      const response = await DeliverablePageService.getDeliverableList();
      if (response.data?.success) {
        setDeliverableList(response.data?.responseObject?.value || []);
        setFilteredDeliverableList(response.data?.responseObject?.value || []);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingData(false);
    }
  };

  const retrieveStatusList = async () => {
    const objectCode = statusPickListCodes.delivarbleStatus;
    try {
      const response = await CommonService.getStatusData(objectCode);
      if (response.data?.success) {
        const statuses = response.data?.responseObject?.value || [];
        setStatusListFilter(
          statuses.map((item: any) => ({ text: item.statusDescription, value: item.statusDescription })),
        );
      }
    } catch {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const handleChange = (_: any, filters: any) => {
    setFilteredInfo(filters.statusDescription);
  };

  const onNew = () => {
    history.push('/deliverables/new/pid');
  };

  const columns: ColumnType<GetDeliverableListPageResponseDto>[] = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'deliverableName',
      ellipsis: { showTitle: true },
      width: '40%',
      sorter: (a, b) => (a?.deliverableName || '').localeCompare(b?.deliverableName || ''),
      render: (title: string) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.progetto')}`,
      dataIndex: 'projectName',
      sorter: (a, b) => (a.projectName || '').localeCompare(b.projectName || ''),
      ellipsis: { showTitle: false },
      render: (title: string) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: 'ownerName',
      sorter: (a, b) => (a.ownerName || '').localeCompare(b.ownerName || ''),
      render: (title: string, record) => (
        <Popover
          content={
            <ResponsiblePopoverContent
              data={record}
              t={t}
            />
          }
          title={title}
        >
          <div className="table-avatar">
            <Avatar size="small">{title?.charAt(0)}</Avatar>
            <span>{title}</span>
          </div>
        </Popover>
      ),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      sorter: (a: any, b: any) => (a.statusDescription || '').localeCompare(b.statusDescription || ''),
      filters: statusListFilter,
      filterSearch: true,
      onFilter: (value, record) =>
        record.statusDescription ? record.statusDescription.includes(value as string) : false,
      render: (title: string) => <Tag>{title}</Tag>,
    },
    {
      key: 'action',
      width: '50px',
      render: (text: any, record: any) =>
        record.projectID ? (
          <Tooltip title={t('proggetiPage.passaProgetto')}>
            <Button
              type="text"
              icon={<FolderOpenOutlined />}
              href={`/progetti/id/${record.projectID}`}
            />
          </Tooltip>
        ) : null,
    },
    {
      key: 'action',
      width: '50px',
      render: (_: any, record: any) => (
        <Button
          icon={<EditOutlined />}
          href={`/deliverables/id/${record.deliverableID}`}
        />
      ),
    },
  ];

  return (
    <Card>
      <TableLayout title={t('deliverablePage.pageHeader')}>
        <TableLayout.Actions>
          <Input.Search
            placeholder={t('general.search')}
            data-testid="deliverable-search"
            allowClear
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button
            type="primary"
            className="add-new mb10"
            onClick={onNew}
            data-testid="newChildDeliverable"
          >
            <PlusOutlined />
            {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            className="table-height"
            columns={columns}
            size="small"
            dataSource={filteredDeliverableList}
            rowKey={(record) => record.deliverableID}
            loading={loadingData}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            onChange={handleChange}
          />
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default DeliverableList;
