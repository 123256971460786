import moment from 'moment';
import 'moment/min/locales';
import { setDefaultOptions } from 'date-fns';
import { enUS, it } from 'date-fns/locale';
import type React from 'react';
import { type ReactNode, createContext, useLayoutEffect } from 'react';

interface LocaleContextProps {
  switchLocale: (newLocale: string) => void;
}

const LocaleContext = createContext<LocaleContextProps | undefined>(undefined);

interface LocaleProviderProps {
  children: ReactNode;
}

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children }) => {
  useLayoutEffect(() => {
    const locale = localStorage.getItem('i18nextLng') || 'en';
    moment.locale(locale);
    setDefaultOptions({ locale: locale === 'it' ? it : enUS });
  }, []);

  const switchLocale = (newLocale: string) => {
    moment.locale(newLocale);
  };

  return <LocaleContext.Provider value={{ switchLocale }}>{children}</LocaleContext.Provider>;
};

export default LocaleContext;
