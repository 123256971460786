import { Form } from 'antd';
import React from 'react';
import CompanyService from '../../services/pages/companyService';
import './companyProfile.scss';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import noImage from '../../assets/noImage.jpg';
import { retrieveCompanyData } from '../../redux/slices/companySlice';
import { store } from '../../redux/store';
import CommonService from '../../services/commonService';
import Loader from '../shared/components/loader/loader';
import {
  checkUserIsAdminUser,
  checkUserIsNotLenovysAdmin,
} from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { companycurrencies, objectCodes, statusPickListCodes } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const { TextArea } = Input;
const { Option } = Select;

const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;

    this.state = {
      uploadedFile: null,
      fieldsChanged: false,
      companyName: '',
      companyData: null,
      fieldObject: null,
      budgetAlertPicklist: null,
      selectedYear: moment().add(1, 'Y').format('YYYY'),
      userNextYearButton: false,
      statusList: null,
    };
  }

  componentDidMount() {
    this.retrieveCompanyData();
    this.retrieveCompanyBudgetAlert();
    this.retrieveStatus();
  }

  async retrieveCompanyBudgetAlert() {
    const objectCode = objectCodes.budgetAlert;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ budgetAlertPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    this.state.uploadedFile = file;
    return isJpgOrPng && isLt2M;
  }

  handleImageChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  removeUploadedFile = () => {
    this.setState({ uploadedFile: null });
  };

  updateCompany = (values) => {
    // fixme: remove logo from values
    const data = values;
    data.logo = this.state.uploadedFile;

    CompanyService.updateCompanyData(data)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.retrieveCompanyData();
          store.dispatch(retrieveCompanyData());
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  // Retrieve company data
  async retrieveCompanyData() {
    await CompanyService.getCompanyData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ companyData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  backFunction = () => {
    history.goBack();
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
  };

  createUserPlan = () => {
    const { selectedYear } = this.state;
    const currentYear = moment().add(1, 'Y').format('YYYY');
    const formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;

    this.setState({ userNextYearButton: true });

    CompanyService.insertUserNextYearPlan(formatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ userNextYearButton: false });
        } else {
          message.error(notifyMessages.updateSuccess);
          this.setState({ userNextYearButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateSuccess);
        this.setState({ userNextYearButton: false });
      });
  };
  async retrieveStatus() {
    const objectCode = statusPickListCodes.delivarbleStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    const { companyData, budgetAlertPicklist, selectedYear, userNextYearButton, statusList } = this.state;
    const { userData } = this.props;
    const t = this.props.t;
    const currencyList = companycurrencies;

    return (
      <div className="tw-relative">
        <Loader />

        {companyData && (
          <>
            <Form
              name="company_Details"
              layout="vertical"
              onFinish={this.updateCompany}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}
            >
              <div className="tw-bg-white tw-sticky tw-top-0 tw-z-10 tw-shrink-0 tw-gap-8 tw-border-b tw-flex tw-items-center tw-justify-between tw-min-h-6 tw-max-h-14 tw-py-2 tw-px-3">
                <Typography.Text className="tw-text-xl tw-text-black tw-font-semibold">
                  {companyData.companyName}
                </Typography.Text>

                <Form.Item className="tw-mb-0 tw-flex tw-items-center">
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={!this.state.fieldsChanged}
                  >
                    <SaveOutlined /> {t('buttons.salva')}
                  </Button>
                </Form.Item>
              </div>

              <Row
                className="tw-mt-4 tw-gap-y-2"
                gutter={24}
                align={'bottom'}
              >
                <Col
                  md={20}
                  xs={24}
                >
                  <Form.Item
                    className="lg-label tw-mb-0"
                    label={t('societaPage.nomeAzienda')}
                    name="companyName"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.companyName}
                  >
                    <Input disabled={checkUserIsAdminUser(userData)} />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  lg={4}
                >
                  <div className="tw-flex tw-gap-2 tw-items-center">
                    {companyData.logo && (
                      <img
                        className="tw-rounded-md"
                        src={`${companyData.logo}?${+new Date()}`}
                        width="40"
                        height="40"
                        alt="Logo"
                      />
                    )}
                    {!companyData.logo && (
                      <img
                        src={noImage}
                        className="tw-rounded-md"
                        width="40"
                        height="40"
                        alt="Logo"
                      />
                    )}
                    <Form.Item
                      name="logo"
                      className="tw-mb-0"
                      // extra={t('societaPage.caricaFotoAzienda')}
                    >
                      <Upload
                        accept="image/*"
                        maxCount={1}
                        listType="picture"
                        onChange={this.handleImageChange}
                        onRemove={() => this.removeUploadedFile()}
                        beforeUpload={(file) => {
                          this.setState({ uploadedFile: file });
                          return false;
                        }}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={checkUserIsAdminUser(userData)}
                        >
                          {t('buttons.upload')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>
                </Col>

                <Divider />

                <Col
                  className="tw-mt-4"
                  xs={24}
                  lg={8}
                >
                  <Form.Item
                    className="lg-label"
                    label={t('societaPage.nostraMisione')}
                    name="mission"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.mission}
                  >
                    <TextArea
                      rows={4}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  lg={8}
                >
                  <Form.Item
                    className="lg-label"
                    label={t('societaPage.nostraVisione')}
                    name="vision"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.vision}
                  >
                    <TextArea
                      rows={4}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  lg={8}
                >
                  <Form.Item
                    className="lg-label"
                    label={t('societaPage.nostriValori')}
                    name="values"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.values}
                  >
                    <TextArea
                      rows={4}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>

                <Divider />

                <Col span={24}>
                  <Typography.Title level={4}>{t('general.alertThreshold')}</Typography.Title>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.alertTempi')}
                    name="timeAlertEdge"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.timeAlertEdge}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={(value) => `${value} %`}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.alertCosti')}
                    name="budgetAlertEdge"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.budgetAlertEdge}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={(value) => `${value} %`}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.alertRisultati')}
                    name="qualityAlertEdge"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.qualityAlertEdge}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={(value) => `${value} %`}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Typography.Title level={4}>{t('general.configurazioni')}</Typography.Title>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.valuta')}
                    name="currency"
                    hasFeedback
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.currency}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                      disabled={checkUserIsAdminUser(userData)}
                    >
                      {currencyList &&
                        currencyList.map((item) => (
                          <Option
                            value={item.key}
                            key={item.key}
                          >
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.bidgetAlert')}
                    name="budgetAlertSelection"
                    hasFeedback
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData?.budgetAlertSelection}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                      disabled={checkUserIsAdminUser(userData)}
                    >
                      {budgetAlertPicklist &&
                        budgetAlertPicklist.map((item) => (
                          <Option
                            value={item.objectCodeListID}
                            key={item.objectCodeListID}
                          >
                            {item.description}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.ablitaInvioEmail')}
                    name="sendNotification"
                    tooltip={t('societaPage.ablitaInvioEmailMessage')}
                    valuePropName="checked"
                    initialValue={companyData.sendNotification}
                  >
                    <Switch
                      // checkedChildren={t('societaPage.abilitato')}
                      // unCheckedChildren={t('societaPage.disabilitato')}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    style={{ width: '100%' }}
                    rules={[{ required: true, message: requiredFields.required }]}
                    label={t('societaPage.preavvisoImplementazione')}
                    name="implementationDaysOfNotice"
                    help={t('companyNotificationsPage.valorePiuDiUno')}
                    initialValue={companyData.implementationDaysOfNotice}
                  >
                    <InputNumber
                      min={1}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  {/* <Form.Item
                                    style={{ width: "100%" }}
                                    label={t('societaPage.noticeStatus')}
                                    name="noticeStatus"
                                    hasFeedback
                                    rules={[{ required: true, message: requiredFields.required }]}
                                    initialValue={companyData.noticeStatus}
                                    disabled={checkUserIsAdminUser(userData)}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Seleziona"
                                    >
                                        {statusList && statusList.map(item =>
                                            <Option value={item.statusID} key={item.statusID}>{item.statusDescription}</Option>
                                        )}
                                    </Select>
                                </Form.Item> */}

                  <Form.Item
                    style={{ width: '100%' }}
                    rules={[{ required: true, message: requiredFields.required }]}
                    label={t('societaPage.preavvisoProsimiPasi')}
                    name="nextStepsDaysOfNotice"
                    help={t('companyNotificationsPage.valorePiuDiUno')}
                    initialValue={companyData.nextStepsDaysOfNotice}
                  >
                    <InputNumber
                      min={1}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Typography.Title level={4}>{t('societaPage.pianoAttivitaFuturi')}</Typography.Title>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <DatePicker
                    disabled={checkUserIsAdminUser(userData)}
                    allowClear={false}
                    placeholder={t('general.anno')}
                    picker="year"
                    value={moment(selectedYear)}
                    style={{ width: '100%' }}
                    format="YYYY"
                    onChange={(date) => this.onYearChange(date)}
                  />
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Button
                    type="primary"
                    onClick={() => this.createUserPlan()}
                    loading={userNextYearButton}
                    disabled={checkUserIsAdminUser(userData)}
                  >
                    <SaveOutlined /> {t('buttons.creaPiano')}
                  </Button>
                </Col>
              </Row>

              <Divider />

              <Typography.Title level={4}>{t('societaPage.guidaConfigurazione')}</Typography.Title>

              <Row gutter={{ lg: 24 }}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    label={t('societaPage.reindirizzareLink')}
                    name="redirectLink"
                    initialValue={companyData.redirectLink}
                  >
                    <Input disabled={checkUserIsNotLenovysAdmin(userData)} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    label={t('societaPage.postaSupporto')}
                    name="supportMail"
                    initialValue={companyData.supportMail}
                  >
                    <Input disabled={checkUserIsNotLenovysAdmin(userData)} />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Typography.Title level={4}>{t('societaPage.kpi')}</Typography.Title>

              <Row gutter={{ lg: 24 }}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.kpiTolerance')}
                    name="kpiTolerance"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={companyData.kpiTolerance}
                  >
                    <InputNumber
                      min={0}
                      disabled={checkUserIsAdminUser(userData)}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace('%', '')}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.startFromCurrentMonth')}
                    name="startFromCurrentMonth"
                    tooltip={t('societaPage.startFromCurrentMonth')}
                    valuePropName="checked"
                    initialValue={companyData.startFromCurrentMonth}
                  >
                    <Switch disabled={checkUserIsAdminUser(userData)} />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('societaPage.resetGlobalKPI')}
                    name="resetGlobalKpi"
                    tooltip={t('societaPage.resetGlobalKPITooltip')}
                    valuePropName="checked"
                    initialValue={companyData.resetGlobalKPI}
                  >
                    <Switch disabled={checkUserIsAdminUser(userData)} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(CompanyProfile));
