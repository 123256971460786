import { Form } from 'antd';
import React from 'react';
import './obietiviLongTerm.scss';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Select, Switch, message } from 'antd';
import BraftEditor from 'braft-editor';
import { withTranslation } from 'react-i18next';
import CommonService from '../../services/commonService';
import CategoryService from '../../services/pages/categoryService';
import ObiettiviLongService from '../../services/pages/obiettiviLongService';
import CategoryIcon from '../shared/CategoryIcon';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { richTectEditoControlls, statusPickListCodes } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddNewLongTermObjectives extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      goalTypesList: null,
      categoriesList: null,
      statusList: null,
      loadingButton: false,
      selectedCategories: [],
    };
  }

  componentDidMount() {
    this.retrieveTypes();
    this.retrieveCategories();
    this.retrieveStatus();
  }

  //Retrieve list of categories
  async retrieveCategories() {
    await CategoryService.getCategoryList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ categoriesList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of status
  async retrieveStatus() {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of types
  async retrieveTypes() {
    await ObiettiviLongService.getGoalTypes()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ goalTypesList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addData = (values) => {
    this.setState({ loadingButton: true });

    const editorContentToHtml = BraftEditor.createEditorState(values['notes']).toHTML();
    values['notes'] = editorContentToHtml;

    ObiettiviLongService.addLongTermlGoal(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.setState({ loadingButton: false });
          this.props.history.push('/obiettivialungoperiodo');
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  hendelCategoryChange = (value) => {};

  render() {
    const { goalTypesList, categoriesList, statusList, loadingButton } = this.state;
    const t = this.props.t;

    return (
      <div className="edit-obietivo-anno-wrapper">
        <div className="site-card-wrapper"> {t('obiettiviLungoPage.aggiungiObjLungo')}</div>
        <Card>
          <Form
            name="add_ob_lungo"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.addData}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="button-wrapper">
                  <Form.Item
                    wrapperCol={{ span: 24 }}
                    className="main-container"
                  >
                    <Button
                      loading={loadingButton}
                      type="primary"
                      htmlType="submit"
                      className="tw-float-end"
                      disabled={!this.state.fieldsChanged}
                    >
                      <SaveOutlined /> {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
              >
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Form.Item
                        className="main-container"
                        label={t('general.nome')}
                        name="title"
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item
                        className="main-container"
                        label={t('general.stato')}
                        name="status"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                        >
                          {statusList &&
                            statusList.map((item) => (
                              <Option
                                value={item.statusID}
                                key={item.statusID}
                              >
                                {item.statusDescription}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item
                        className="main-container"
                        label={t('obiettiviLungoPage.categoria')}
                        name="goalCategory"
                        hasFeedback
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          mode="multiple"
                          allowClear
                          showSearch={false}
                          maxTagCount="responsive"
                          onChange={(value) => this.hendelCategoryChange(value)}
                        >
                          {categoriesList &&
                            categoriesList.map((item) => (
                              <Option
                                value={item.goalCategoryID}
                                key={item.goalCategoryID}
                              >
                                <div className="tw-flex tw-items-center tw-gap-4">
                                  <CategoryIcon iconName={item.iconSource.trim()} />
                                  {item.name}
                                </div>
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item
                        className="main-container"
                        label={t('general.tipo')}
                        name="goalTypeID"
                        hasFeedback
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                        >
                          {goalTypesList &&
                            goalTypesList.map((item) => (
                              <Option
                                value={item.goalTypeID}
                                key={item.goalTypeID}
                              >
                                {item.description}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      <UserSelectDropDown
                        disabled={false}
                        allowClear={false}
                        fieldLabel={t('general.sponsor')}
                        formName={'sponsorID'}
                        required={false}
                        initValue={null}
                      />
                      {/* <Form.Item className='main-container'
                                                label={t('general.sponsor')}
                                                name="sponsorID"
                                                hasFeedback
                                            >
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder={t('general.seleziona')}
                                                >
                                                    {usersPickList && usersPickList.map(item =>
                                                        <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                    )}
                                                </Select>

                                            </Form.Item> */}
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item
                        className="main-container"
                        label={t('obiettiviLungoPage.coerenzaEnvisioning')}
                        name="isRelatedVMV"
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Form.Item
                        className="main-container"
                        label={t('general.commenti')}
                        name="notes"
                      >
                        <BraftEditor
                          //style={{ height: 350, overflowX: 'auto' }}
                          contentFormat="html"
                          language="en"
                          id="editor-text"
                          controls={richTectEditoControlls}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(AddNewLongTermObjectives);
