import { FilterFilled, MinusSquareOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, Tag, Tooltip, Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PersonalDashboardService from '../../services/pages/personalDashboardService';
import Loader2 from '../shared/components/loader2/loader2';
import TableLayout from '../shared/tableLayout';
import { resurceWorkloadChartOptionsOptions } from './chartsConfig';

const { Option } = Select;

const PersonalDashboardResourceWorklaoad = ({ t, divisionPicklist }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [resourceData, setResourceData] = useState(resurceWorkloadChartOptionsOptions);
  const [userPicklist, setUserPicklist] = useState(null);

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedDivisionIds, setSelectedDivisionIds] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    retrieveUserPickList();
  }, []);

  useEffect(() => {
    if (selectedUserIds.length === 0 && selectedDivisionIds.length === 0 && !selectedYear) {
      getResourceWorkloadData();
    }
  }, [selectedUserIds, selectedDivisionIds, selectedYear]);

  const retrieveUserPickList = async () => {
    await PersonalDashboardService.getUsersPicklistPersonalDashboard()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const usr = [];
          const respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });
          setUserPicklist(usr);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getResourceWorkloadData = async () => {
    const payload = {};

    var currentYear = moment().format('YYYY');
    const fromatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;

    payload['DivisionID'] = selectedDivisionIds;
    payload['UserID'] = selectedUserIds;
    payload['Year'] = fromatedYear;

    setLoadingData(true);
    await PersonalDashboardService.getResourceWorkloadData(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const series = [];
          const xAxisCategories = [];
          const xAxisObject = {};

          const allocatedObject = {};
          const budgetObject = {};
          const consutivoObject = {};

          const allocatedData = [];
          const budgetData = [];
          const consutivoData = [];

          resp.responseObject.value.map((item, index) => {
            //series data
            allocatedData.push(item.allocated);
            budgetData.push(item.budget);
            consutivoData.push(item.consuntivo);

            //xAxis categories data
            xAxisCategories.push(item.fullName);
          });

          allocatedObject['name'] = `${t('general.allocato')}`;
          allocatedObject['data'] = allocatedData;
          series.push(allocatedObject);

          budgetObject['name'] = `${t('general.budget')}`;
          budgetObject['data'] = budgetData;
          series.push(budgetObject);

          consutivoObject['name'] = `${t('general.consutivo')}`;
          consutivoObject['data'] = consutivoData;
          series.push(consutivoObject);

          xAxisObject['crosshair'] = true;
          xAxisObject['categories'] = xAxisCategories;

          const chart = { ...resourceData };
          chart.series = series;
          chart.xAxis = xAxisObject;
          setResourceData(chart);

          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  const onUserSelect = (selectedRowKeys) => {
    setSelectedUserIds(selectedRowKeys);
  };

  const onDivisionSelect = (selectedRowKeys) => {
    setSelectedDivisionIds(selectedRowKeys);
  };

  const onYearChange = (year) => {
    setSelectedYear(year);
  };

  const onFilter = () => {
    getResourceWorkloadData();
  };

  const onClearFilter = async () => {
    setSelectedDivisionIds([]);
    setSelectedUserIds([]);
    setSelectedYear(null);
  };

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    console.log(props);

    return (
      <Tag
        className="tw-max-w-28 tw-h-[30px] tw-flex tw-items-center tw-relative [&_.anticon-close]:tw-shrink-0"
        closable={closable}
        onClose={onClose}
      >
        <Tooltip
          title={label}
          placement="top"
        >
          <span className="tw-shrink tw-max-w-16 tw-truncate">{label?.replace('...', '')}</span>
        </Tooltip>
      </Tag>
    );
  };

  return (
    <TableLayout
      title={
        <Typography.Title
          className="!tw-mb-0"
          level={4}
        >
          {t('bachechaPersonalePage.riepilogoCaricoRisorse')}
        </Typography.Title>
      }
    >
      <TableLayout.Actions>
        <Select
          value={selectedUserIds}
          placeholder={t('bachechaPersonalePage.utenti')}
          mode="multiple"
          allowClear
          maxTagCount={2}
          tagRender={tagRender}
          className="tw-shrink-0 tw-w-[300px]"
          onChange={onUserSelect}
          options={userPicklist}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          loading={!userPicklist}
        />
        <Select
          value={selectedDivisionIds}
          placeholder={t('general.divisione')}
          mode="multiple"
          allowClear
          showSearch
          maxTagCount={2}
          tagRender={tagRender}
          className="tw-shrink-0 tw-w-[300px]"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={onDivisionSelect}
        >
          {divisionPicklist &&
            divisionPicklist.map((item) => (
              <Option
                disabled={item.hasAccess === 1 ? false : true}
                value={item.divisionID}
                key={item.divisionID}
              >
                {item.divisionName}
              </Option>
            ))}
        </Select>
        <DatePicker
          placeholder={t('general.anno')}
          picker="year"
          className="tw-shrink-0 tw-min-w-[150px]"
          value={selectedYear}
          format="YYYY"
          onChange={(date) => onYearChange(date)}
        />
        <Tooltip title={t('general.filter')}>
          <Button
            icon={<FilterFilled />}
            onClick={() => onFilter()}
            disabled={!selectedDivisionIds && !selectedUserIds && !selectedYear}
          />
        </Tooltip>
        <Tooltip title={t('general.clearFilters')}>
          <Button
            icon={<MinusSquareOutlined />}
            onClick={() => onClearFilter()}
            disabled={selectedDivisionIds || selectedUserIds || selectedYear ? false : true}
          />
        </Tooltip>
      </TableLayout.Actions>

      <TableLayout.Content>
        {loadingData && <Loader2 />}
        {resourceData && (
          <HighchartsReact
            highcharts={Highcharts}
            options={resourceData}
          />
        )}
      </TableLayout.Content>
    </TableLayout>
  );
};
export default PersonalDashboardResourceWorklaoad;
