import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Button, Dropdown, Skeleton, Tooltip, Typography, message } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { projectStatusAsNumber } from 'src/components/shared/utils/constants';
import ObAnnoService from '../../../../services/matrix/obAnnoService';
import ObKpiService from '../../../../services/matrix/obKpiService';
import ObLongService from '../../../../services/matrix/obLongService';
import ObProgettiService from '../../../../services/matrix/obProgettiService';
import { handleDateConvert, isTeamleader, updateNotificationBell } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import AttivaModal from './activeObModal';
import AddObietivoAnnoModal from './addObAnnoModal';
import AddKpiModal from './addObKpiModal';
import AddObietivoLungoModal from './addObLungoModal';
import AddProgettiModal from './addObProgettiModal';
import QuickAddProgettiModal from './addQuickProgettiModal';
import './centerSection.scss';
import { enumCast } from 'src/config/connectors';
import { AnnualGoalStatus, ProjectStatus } from 'src/connectors/backend';
import KpiService from 'src/services/pages/kpiService';
import ZoomDataModal from './zoomDataModal';

const { Text } = Typography;

const CenterImage = ({ logo }) => {
  if (logo) {
    return (
      <img
        src={`${logo}?${+new Date()}`}
        width="150px"
        className="tw-rounded-lg"
        alt="Logo"
      />
    );
  } else {
    return <Skeleton.Image />;
  }
};

const optionsMenu = (props) => {
  const items = [
    {
      label: <Text onClick={props.toggleObProgettiModal}>{props.t('xMatrixPage.addProject')}</Text>,
      key: 1,
    },
    {
      label: <Text onClick={props.toggleQuickObProgettiModal}>{props.t('xMatrixPage.quickAddProject')}</Text>,
      key: 2,
    },
  ];
  return { items };
};

const AddOptionsMenu = (props) => (
  <Dropdown
    key="more"
    menu={optionsMenu(props)}
    overlayClassName="test-proejcts-dropdown"
  >
    <Button
      className="tw-text-rest !tw-rounded-t-none"
      data-testid="add-projects-btn"
      type="dashed"
      icon={<PlusOutlined />}
    >
      {props.t('headerMenu.progetti')}
      {props.selectedProjectIds?.length ? (
        <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
          {props.selectedProjectIds?.length}
        </div>
      ) : null}
    </Button>
  </Dropdown>
);

class Center extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showObLungo: false,
      showObAnno: false,
      showZoomModal: false,
      showQuickObProgetti: false,
      showObProgetti: false,
      showObKpi: false,
      showAttivaModal: false,
      loadingButton: false,
      isSecondaryProject: false,
      kpiExpandIcon: false,
      selectedZoomModalData: null,
    };
  }

  addObLungo = async (values) => {
    const { selectedXmatrix } = this.props;
    this.setState({ loadingButton: true });
    try {
      const { data } = await ObLongService.addObiettivoLungoxMartix({
        status: values.status,
        title: values.title,
        xMatrixId: selectedXmatrix,
      });

      if (!data.success) {
        throw new Error('Insert failed');
      }

      void message.success(notifyMessages.addSuccess);
      this.handleObLungoClose();
      this.props.reloadMatrix();
    } catch {
      void message.error(notifyMessages.addFailed);
    }
  };

  toggleObLungoModal = () => {
    this.setState({ showObLungo: !this.state.showObLungo });
  };
  handleObLungoClose = () => {
    this.setState({ showObLungo: false });
    this.setState({ loadingButton: false });
  };

  // Add obittivo lungo anno modal
  addObAnno = (values) => {
    const { selectedXmatrix } = this.props;
    this.setState({ loadingButton: true });
    const payload = values;

    ObAnnoService.addObiettivoAnnoxMartix({
      ...payload,
      status: enumCast(AnnualGoalStatus, values.status) ?? AnnualGoalStatus.Draft,
      xMatrixId: selectedXmatrix,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleObAnnoClose();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleObAnnoModal = () => {
    this.setState({ showObAnno: !this.state.showObAnno });
  };

  handleObAnnoClose = () => {
    this.setState({ showObAnno: false });
    this.setState({ loadingButton: false });
  };

  // Add progetti modal
  addObProgetti = (values) => {
    const { selectedXmatrix } = this.props;
    const { isSecondaryProject } = this.state;

    this.setState({ loadingButton: true });

    ObProgettiService.addObProgetti({
      ...values,
      name: values.name,
      xMatrixId: selectedXmatrix,
      secondaryProject: isSecondaryProject,
      fastCreation: false,
      startDate: handleDateConvert(values.startDate),
      endDate: handleDateConvert(values.endDate),
      code: values.projectCode,

      commiteeId: values.commiteeID,
      divisionId: values.divisionID,
      notes: values.note,
      parentProjectId: values.parentProjectID,
      sponsorId: values.sponsorID,
      teamLeaderId: values.teamLeaderID,
      status: Object.values(ProjectStatus)[values.status] ?? ProjectStatus.Draft,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          updateNotificationBell();
          this.handleObProgettiClose();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch(() => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  addQuickProgetti = (values) => {
    const { selectedXmatrix, selectedXmatrixInfo } = this.props;
    const { isSecondaryProject } = this.state;

    this.setState({ loadingButton: true });

    ObProgettiService.addObProgetti({
      name: values.name,
      xMatrixId: selectedXmatrix,
      secondaryProject: isSecondaryProject,
      fastCreation: true,
      hasExternalReview: false,
      startDate: moment(selectedXmatrixInfo.referencePeriod).startOf('year').format('YYYY-MM-DD'),
      endDate: moment(selectedXmatrixInfo.referencePeriod).endOf('year').format('YYYY-MM-DD'),
      code: values.code ?? values.projectCode,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleQuickObProgettiClose();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleObProgettiModal = () => {
    this.setState({ showObProgetti: !this.state.showObProgetti });
  };
  toggleQuickObProgettiModal = () => {
    this.setState({ showQuickObProgetti: !this.state.showQuickObProgetti });
  };
  handleObProgettiClose = () => {
    this.setState({ showObProgetti: false });
    this.setState({ loadingButton: false });
  };

  handleQuickObProgettiClose = () => {
    this.setState({ showQuickObProgetti: false });
    this.setState({ loadingButton: false });
  };

  // Add KPI modal
  addObKpi = async (values, isNewKpi) => {
    this.setState({ loadingButton: true });
    const { selectedXmatrix } = this.props;

    try {
      await (isNewKpi
        ? KpiService.createKpi({ ...values, xMatrixId: selectedXmatrix })
        : ObKpiService.addObKpi(selectedXmatrix, values.kpiId));

      void message.success(notifyMessages.addSuccess);
      this.setState({ showObKpi: false });
      this.props.reloadMatrix();
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    this.setState({ loadingButton: false });
  };

  toggleObKpiModal = () => {
    this.setState({ showObKpi: !this.state.showObKpi });
  };
  handleObKpiClose = () => {
    this.setState({ showObKpi: false });
  };

  updateAttiva = (values) => {
    const { selectedXmatrix } = this.props;
    values['xMatrixID'] = selectedXmatrix;
    const payload = values;
    this.setState({ loadingButton: true });
    ObLongService.updateAttiva(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.handleAttivaModalClose();
          if (
            values.innovazioneBusiness &&
            values.miglioramentoSignificativo &&
            values.raggiungimentoVision &&
            values.strategiaSostenibile &&
            values.vincoliNormative
          ) {
            this.props.reloadMatrix();
          }
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleAttivaModal = () => {
    this.setState({ showAttivaModal: !this.state.showAttivaModal });
  };
  handleAttivaModalClose = () => {
    this.setState({ showAttivaModal: false });
    this.setState({ loadingButton: false });
  };

  kpiSectionVisibility = () => {
    const { kpiExpandIcon } = this.state;
    this.setState({ kpiExpandIcon: !kpiExpandIcon });
    this.props.toggleKpiSection();
  };

  handleZoomModalClose = () => {
    this.setState({ showZoomModal: false });
  };

  toggleZoomModal = (data, area, label) => {
    this.setState({ showZoomModal: true });
    const dataObj = {};
    dataObj['data'] = data;
    dataObj['area'] = area;
    dataObj['label'] = label;
    this.setState({ selectedZoomModalData: dataObj });
  };

  render() {
    const {
      userData,
      companyData,
      comittieList,
      projectStatusList,
      goalStatusList,
      divisionsList,
      selectedXmatrix,
      selectedXmatrixInfo,
      isSecondLevelXmatrix,
      t,
      annualGoals,
      longTermGoals,
      projects,
    } = this.props;

    const { selectedZoomModalData } = this.state;

    const isRoleTeamLeader = userData && Object.keys(userData).length > 0 ? isTeamleader(userData) : false;

    return (
      <>
        <AddObietivoLungoModal
          showObLungo={this.state.showObLungo}
          handleObLungoClose={this.handleObLungoClose}
          handleObLungoSave={this.addObLungo}
          loadingButton={this.state.loadingButton}
          statusPickList={goalStatusList}
          t={t}
        />

        <AddObietivoAnnoModal
          showObAnno={this.state.showObAnno}
          handleObAnnoClose={this.handleObAnnoClose}
          handleObAnnoSave={this.addObAnno}
          loadingButton={this.state.loadingButton}
          statusPickList={goalStatusList}
          t={t}
          annualGoals={annualGoals}
        />

        <AddProgettiModal
          showObProgetti={this.state.showObProgetti}
          handleObProgettiClose={this.handleObProgettiClose}
          handleObProgettiSave={this.addObProgetti}
          commitiePickList={comittieList}
          divisionsList={divisionsList}
          statusPickList={projectStatusList}
          loadingButton={this.state.loadingButton}
          isSecondLevelXmatrix={isSecondLevelXmatrix}
          selectedXmatrixInfo={selectedXmatrixInfo}
          t={t}
        />

        <QuickAddProgettiModal
          showQuickObProgetti={this.state.showQuickObProgetti}
          handleModalClose={this.handleQuickObProgettiClose}
          handleSave={this.addQuickProgetti}
          loadingButton={this.state.loadingButton}
          isSecondLevelXmatrix={isSecondLevelXmatrix}
          selectedXmatrixInfo={selectedXmatrixInfo}
          t={t}
        />

        {this.state.showObKpi && (
          <AddKpiModal
            showObKpi={this.state.showObKpi}
            handleObKpiClose={this.handleObKpiClose}
            handleObKpiSave={this.addObKpi}
            selectedXmatrix={selectedXmatrix}
            loadingButton={this.state.loadingButton}
            t={t}
            companyData={companyData}
          />
        )}

        {this.state.showAttivaModal && (
          <AttivaModal
            loadingButton={this.state.loadingButton}
            showAttivaModal={this.state.showAttivaModal}
            handleAttivaModalClose={this.handleAttivaModalClose}
            handleAttivaModalSave={this.updateAttiva}
            selectedXmatrix={selectedXmatrix}
            t={t}
          />
        )}

        {this.state.showZoomModal && (
          <ZoomDataModal
            showZoomModal={this.state.showZoomModal}
            handleZoomModalClose={this.handleZoomModalClose}
            modalData={selectedZoomModalData}
            t={t}
            xMatrixCycleName={selectedXmatrixInfo.xMatrixCycleName}
          />
        )}

        <div className="center-section tw-relative">
          <div className="center-grid-container">
            <div className="center-grid-item horizontal-text"></div>

            <div className="tw-flex tw-justify-center tw-gap-2">
              <Tooltip title={t('xMatrixPage.expandList')}>
                <Button
                  className="tw-bg-zinc-100 tw-text-rest !tw-rounded-t-none"
                  icon={<ZoomInOutlined />}
                  onClick={() => this.toggleZoomModal(projects, 'projects', t('headerMenu.progetti'))}
                ></Button>
              </Tooltip>
              <AddOptionsMenu
                selectedProjectIds={this.props.selectedProjectIds}
                toggleObProgettiModal={this.toggleObProgettiModal}
                toggleQuickObProgettiModal={this.toggleQuickObProgettiModal}
                t={t}
              />
            </div>

            <div className="center-grid-item horizontal-text"></div>

            <div className="tw-flex vertical-text tw-gap-2">
              <Tooltip title={t('xMatrixPage.expandList')}>
                <Button
                  icon={<ZoomInOutlined />}
                  className="tw-bg-zinc-100 tw-rotate-180 tw-text-rest !tw-rounded-l-none"
                  onClick={() => this.toggleZoomModal(annualGoals, 'annualGoal', t('xMatrixPage.objAnno'))}
                ></Button>
              </Tooltip>
              {!isRoleTeamLeader && (
                <Button
                  className="tw-h-fit tw-w-10 tw-rounded-r-none tw-bg-zinc-100 tw-text-rest"
                  icon={<PlusOutlined />}
                  onClick={this.toggleObAnnoModal}
                  type="dashed"
                >
                  {t('xMatrixPage.objAnno')}
                  {this.props.selectedAnnualObjectiveIds?.length ? (
                    <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                      {this.props.selectedAnnualObjectiveIds?.length}
                    </div>
                  ) : null}
                </Button>
              )}
            </div>

            <div className="center-grid-item horizontal-text">
              {companyData ? <CenterImage logo={companyData.logo} /> : null}
            </div>

            <div className="tw-flex tw-justify-center tw-gap-2 vertical-text">
              {/* <Text className="menu">{t('xMatrixPage.metriche')}</Text> */}
              <Button
                onClick={this.toggleObKpiModal}
                className="tw-rounded-l-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
                icon={<PlusOutlined />}
                type="dashed"
              >
                {t('xMatrixPage.metriche')}
                <button
                  className="tw-rotate-90 tw-text-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.toggleKpiSection();
                  }}
                >
                  {this.props.isKpiSectionVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </button>
                {this.props.selectedKpiIds?.length ? (
                  <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                    {this.props.selectedKpiIds?.length}
                  </div>
                ) : null}
              </Button>

              <Button
                className="tw-rounded-l-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
                type="dashed"
              >
                {t('xMatrixPage.utenti')}
                <button
                  className="tw-rotate-90 tw-text-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.toggleUsersSection();
                  }}
                >
                  {this.props.isUsersSectionVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </button>
              </Button>
            </div>

            <div className="center-grid-item horizontal-text"></div>

            <div className="tw-flex tw-absolute tw-left-1/2 tw-bottom-[50px] -tw-translate-x-1/2">
              <p>{selectedXmatrixInfo.xMatrixName}</p>
            </div>

            <div className="tw-flex horizontal-text tw-gap-2">
              {!isRoleTeamLeader ? (
                <>
                  <Tooltip title={t('xMatrixPage.expandList')}>
                    <Button
                      icon={<ZoomInOutlined />}
                      className="tw-bg-zinc-100 tw-text-rest !tw-rounded-b-none"
                      onClick={() =>
                        this.toggleZoomModal(longTermGoals, 'longTermGoals', t('xMatrixPage.strategiaLungoPeriodo'))
                      }
                    ></Button>
                  </Tooltip>
                  <Button
                    className="tw-rounded-b-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
                    icon={<PlusOutlined />}
                    onClick={this.toggleObLungoModal}
                  >
                    {t('xMatrixPage.strategiaLungoPeriodo')}
                    {this.props.selectedLongTermObjectiveIds?.length ? (
                      <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                        {this.props.selectedLongTermObjectiveIds?.length}
                      </div>
                    ) : null}
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(Center));
