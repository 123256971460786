import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { Card, Col, Modal, Row, Select } from 'antd';
import CommitieService from '../../../services/pages/commitieServices';
import ModalFooterButtons from '../../shared/components/modal/modalFooterButtons';
import { formItemLayout } from '../../shared/utils/constants';
import { requiredFields } from '../../shared/utils/notifyMessages';
const { Option } = Select;

function AddChildCommititeModal({ showModal, handleModalClose, t, saveChildToComitie, committeeID, loadingSave }) {
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [childCommitites, setChildCommitites] = useState(null);

  useEffect(() => {
    getChildCommitites();
  }, []);

  const getChildCommitites = () => {
    CommitieService.getAvailableChildCommititesList(committeeID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setChildCommitites(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const saveData = (values) => {
    saveChildToComitie(values);
  };

  return (
    <div className="edit-commitie-wrapper">
      <Modal
        width={800}
        title={t('comitatiPage.aggiungiCommitato')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingSave}
            modalClose={handleModalClose}
            formId={'newChildComitie'}
            key="newChildComitie"
          />,
        ]}
      >
        <Card>
          <Form
            id="newChildComitie"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => saveData(values)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('comitatiPage.comitati')}
                  name="childID"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {childCommitites &&
                      childCommitites.map((item) => (
                        <Option
                          value={item.committeeID}
                          key={item.committeeID}
                        >
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </div>
  );
}

export default AddChildCommititeModal;
