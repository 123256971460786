import { CheckOutlined, CloseOutlined, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightCircleFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import TableLayout from 'src/components/shared/tableLayout';
import { enumCast } from 'src/config/connectors';
import {
  CommitteeDto,
  KpiDetailCalculationType,
  KpiDetailParentType,
  KpiDetailType,
  KpiDto,
} from 'src/connectors/backend';
import KpiService from 'src/services/pages/kpiService';

const CommitteeKpiList: FC<{
  committeeDetails: CommitteeDto;
}> = ({ committeeDetails }) => {
  const { t } = useTranslation();
  const [kpiList, setKpiList] = useState<KpiDto[]>([]);
  const [filteredKpiList, setFilteredKpiList] = useState<KpiDto[]>([]);
  const [query, setQuery] = useState('');
  const history = useHistory();

  // get kpi list
  useEffect(() => {
    const fetch = async () => {
      const res = await KpiService.getKpiListData(committeeDetails.id);

      setKpiList(res.data);
      setFilteredKpiList(res.data);
    };

    fetch();
  }, []);

  const columns: ColumnsType<KpiDto> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: ['kpiDetail', 'name'],
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.tipo')}`,
      dataIndex: ['kpiDetail', 'type'],
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        return <Tag>{t(`kpi.types.${enumCast(KpiDetailType, value)}`)}</Tag>;
      },
    },
    {
      title: `${t('kpiPage.tipoCalcolo')}`,
      dataIndex: ['kpiDetail', 'calculationType'],
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        return <Tag>{t(`kpi.calculationTypes.${enumCast(KpiDetailCalculationType, value)}`)}</Tag>;
      },
    },
    {
      title: `${t('kpiPage.cumulatoPuntuale')}`,
      dataIndex: ['kpiDetail', 'parentType'],
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        return <Tag>{t(`kpi.parentTypes.${enumCast(KpiDetailParentType, value)}`)}</Tag>;
      },
    },
    {
      title: `${t('kpiPage.gerarchicho')}`,
      dataIndex: ['kpiDetail', 'isRollupKpi'],
      filters: [
        { text: `${t('general.si')}`, value: true },
        { text: `${t('general.no')}`, value: false },
      ],
      render: (text, record, index) => {
        if (record.kpiDetail?.isRollupKpi) {
          return <CheckOutlined style={{ color: '#16a34a' }} />;
        }
      },
    },
    {
      title: `${t('kpiPage.isVisible')}`,
      dataIndex: 'isVisible',
      filters: [
        { text: `${t('general.si')}`, value: true },
        { text: `${t('general.no')}`, value: false },
      ],
      render: (text, record, index) => {
        if (record.kpiDetail?.isVisible) {
          return <CheckOutlined style={{ color: '#16a34a' }} />;
        } else {
          return <CloseOutlined style={{ color: '#7b817c' }} />;
        }
      },
    },
    {
      key: 'action',
      width: '50px',

      render: (text, record) => (
        <Button
          icon={<EditFilled />}
          href={`/kpi/id/${record.kpiDetail?.id}`}></Button>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setQuery(value);

    if (value) {
      setFilteredKpiList(kpiList.filter((kpi) => kpi.kpiDetail?.name?.toLowerCase().includes(value.toLowerCase())));
    } else {
      setFilteredKpiList([...kpiList]);
    }
  };

  const createNewKpi = () => history.push('/kpi/new?committeeId=' + committeeDetails.id);

  return (
    <TableLayout title={t('kpiPage.listaKpi')}>
      <TableLayout.Actions>
        <Button
          icon={<RiArrowRightCircleFill />}
          href={'kpiDashboard/?tab=3&' + committeeDetails.id}>
          {t('kpiDashboard.kpiDashboard')}
        </Button>
        <Input.Search
          placeholder={t('general.search')}
          onSearch={handleSearch}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          type="primary"
          htmlType="submit"
          onClick={createNewKpi}>
          <PlusOutlined />
          {t('buttons.aggiungiNuovo')}
        </Button>
      </TableLayout.Actions>
      <TableLayout.Content>
        <Table
          sticky
          size="small"
          rowKey={(obj) => obj.kpiDetail?.id!}
          dataSource={filteredKpiList ?? kpiList}
          columns={columns}
          pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default CommitteeKpiList;
