import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { Card, Col, DatePicker, Input, InputNumber, Modal, Row, Select, TimePicker } from 'antd';
import moment from 'moment';
import CommonService from '../../../../services/commonService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { formItemLayout, formatOfDate, formatOfHHMM, statusPickListCodes } from '../../../shared/utils/constants';
import { handleDateConvert, handleTimeConvert } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';

const { Option } = Select;

const UpdateSessionModal = ({
  loadingSave,
  showModal,
  handleModalClose,
  t,
  updateSession,
  sessionData,
  committeeID,
}) => {
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [startTime, setStartTime] = useState(sessionData.startTime);
  const [endTime, setEndTime] = useState(
    sessionData.endTime ? moment(sessionData.endTime, formatOfHHMM) : sessionData.endTime,
  );
  const [duration, setDuration] = useState(sessionData.duration);
  const [sessionStatusList, setSessionStatusList] = useState(null);

  useEffect(() => {
    retrieveStatus();
  }, []);

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.sessionStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setSessionStatusList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const saveData = (values) => {
    values.date = handleDateConvert(values.date);
    values.startTime = handleTimeConvert(values.startTime);
    values['endTime'] = handleTimeConvert(values.endTime) ? handleTimeConvert(values.endTime) : endTime;
    values['committeeID'] = committeeID;
    values['sessionID'] = sessionData.sessionID;
    values.duration = sessionData.duration;
    updateSession(values);
  };

  const onDurationChange = (value, startTime) => {
    if (value && startTime) {
      const endTimePick = moment(startTime, formatOfHHMM).add(value, 'hours');
      setEndTime(endTimePick);
      setStartTime(startTime);
      setDuration(value);
    } else if (value && !startTime) {
      setDuration(value);
    } else {
      return;
    }
  };

  function formatDuration(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);

    if (hours === 0) {
      return `${minutes} min`;
    } else if (minutes === 0) {
      return `${hours} h`;
    } else {
      return `${hours} h ${minutes} min`;
    }
  }

  const onStartTimeChange = (value, durationInMinutes) => {
    if (value && durationInMinutes) {
      const startMoment = moment(value, formatOfHHMM);
      const endMoment = startMoment.clone().add(durationInMinutes, 'minutes');

      setStartTime(value);
      setEndTime(endMoment.format(formatOfHHMM));
      setDuration(durationInMinutes);
    } else if (value && !durationInMinutes) {
      setEndTime(value);
      setStartTime(value);
    } else {
      return;
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={t('comitatiPage.editSession')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingSave}
            modalClose={handleModalClose}
            formId={'updateSession'}
            key="updateSession"
          />,
        ]}
      >
        <Card>
          <Form
            id="updateSession"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => saveData(values)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  label={t('proggetiPage.codice')}
                  name="codice"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={sessionData.codice}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label={t('comitatiPage.date')}
                  name="date"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={sessionData.date ? moment(sessionData.date) : sessionData.date}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={formatOfDate}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  labelAlign="left"
                  label={t('general.stato')}
                  name="state"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={sessionData.stateID}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {sessionStatusList &&
                      sessionStatusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}
                        >
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  label={t('comitatiPage.startTime')}
                  name="startTime"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={
                    sessionData.startTime ? moment(sessionData.startTime, formatOfHHMM) : sessionData.startTime
                  }
                >
                  <TimePicker
                    allowClear={false}
                    placeholder={t('comitatiPage.startTime')}
                    style={{ width: '100%' }}
                    format={formatOfHHMM}
                    onChange={(value) => onStartTimeChange(value, duration)}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  label={t('proggetiPage.durata') + ' (h/min)'}
                  name="duration"
                  // rules={[
                  //     { required: true, message: requiredFields.required }
                  // ]}
                  initialValue={formatDuration(sessionData.duration)}
                  disabled={true}
                >
                  <InputNumber
                    disabled={true}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item label={t('comitatiPage.endTime')}>
                  <TimePicker
                    value={endTime ? moment(endTime, formatOfHHMM) : endTime}
                    placeholder={t('comitatiPage.endTime')}
                    style={{ width: '100%' }}
                    format={formatOfHHMM}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </>
  );
};
export default UpdateSessionModal;
