import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { useTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import UserSelectDropDown from '../../shared/components/userSelect/userSelect';
import XmatrixDropDownFilter from '../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import { formItemLayout, kpiType, statusPickListCodes } from '../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';
import AddKpiObAnnoModal from '../kpi/addKpiModal';
import EditKpiObAnnoModal from '../kpi/editKpiModal';
import UploadKpiAnualGoal from '../kpi/uploadKpiAnualGoal';

const { Text } = Typography;
const { Option } = Select;

const Impostazioni = ({
  annualGoalData,
  annualGoalId,
  isRoleTeamMemberOrTeamLeader,
  reloadData,
  activeXmatrixInfo,
  onXmatrixSelect,
  kpiList,
  reloadObAnnoKpiList,
  routeSelectedXmatrix,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [statusList, setStatusList] = useState(null);
  const [showUploadKpiModal, setShowUploadKpiModal] = useState(false);
  const [showAddKpiModal, setShowAddKpiModal] = useState(false);
  const [showEditKpiModal, setShowEditKpiModal] = useState(false);
  const [kpiRowData, setKpiRowData] = useState(null);
  const [loadingKpiSave, setLoadingKpiSave] = useState(false);
  const [relatedLongTermGoals, setRelatedLongTermGoals] = useState(null);

  const searchInput = useRef(null);

  useEffect(() => {
    retrieveStatus();
  }, []);

  useEffect(() => {
    if (annualGoalData) {
      const relatedObjectives = annualGoalData.longTermGoalByAnnualGoal.filter(
        (el) => el.correlationCoefficient === 1 || el.correlationCoefficient === 2,
      );
      setRelatedLongTermGoals(relatedObjectives);
    }
  }, [annualGoalData]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    handleSearch('', confirm, dataIndex);
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setStatusList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const handleDateChange = (date) => {
    if (date) {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  };

  const removeObjAnno = (id) => {
    ObiettiviAnnoService.deleteAnualGoal(id)
      .then((response) => {
        if (response.data.success) {
          message.success(notifyMessages.deleteSuccess);
          history.push('/obiettiviannuali');
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const updateData = (values, id) => {
    values['annualGoalID'] = id;
    values.referencePeriod = handleDateChange(values.referencePeriod);

    setLoadingSave(true);
    ObiettiviAnnoService.updateAnualGoal(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingSave(false);
          reloadData();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingSave(false);
      });
  };

  const toggleUploadKpiSpecificoModal = (rowData) => {
    setShowUploadKpiModal(true);
    setKpiRowData(rowData);
  };

  const handleUploadKpiModalClose = () => {
    setShowUploadKpiModal(false);
    setLoadingKpiSave(false);
  };

  const toggleAddKpiModal = () => {
    setShowAddKpiModal(true);
  };

  const handleAddKpiModalClose = () => {
    setShowAddKpiModal(false);
    setLoadingKpiSave(false);
  };

  const handleEditKpiModalClose = () => {
    setShowEditKpiModal(false);
    setLoadingKpiSave(false);
  };

  const toggleEditKpiModal = (rowData) => {
    setShowEditKpiModal(true);
    setKpiRowData(rowData);
  };

  const addKpi = (values, annualGoalID) => {
    setLoadingKpiSave(true);
    ObiettiviAnnoService.insertObAnnoKpi(values.KPIDetailID, annualGoalID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingKpiSave(false);
          message.success(notifyMessages.addSuccess);
          handleAddKpiModalClose();
          reloadObAnnoKpiList();
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingKpiSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingKpiSave(false);
      });
  };

  const updateObAnualKpi = (payload) => {
    setLoadingKpiSave(true);
    ObiettiviAnnoService.updateAnnualKpiBowlingChart(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          handleEditKpiModalClose();
          message.success(notifyMessages.updateSuccess);
          reloadObAnnoKpiList();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingKpiSave(true);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingKpiSave(true);
      });
  };

  const removeKpiAnualGoal = (relationId) => {
    ObiettiviAnnoService.deleteKpiObAnual(relationId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          reloadObAnnoKpiList();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const longTermGoalColumns = [
    {
      title: `${t('general.nome')}`,
      width: '60%',
      dataIndex: 'title',
      sorter: (a, b) => {
        a = a.title || '';
        b = b.title || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('description'),
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.sponsor')}`,
      dataIndex: 'sponsorFullName',
      showSorterTooltip: [false],
      ...getColumnSearchProps('sponsorFullName'),
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      width: '10%',
      dataIndex: 'relationship',
      render: (text, record) => {
        if (record.correlationCoefficient === 1) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-items-center tw-gap-2 tw-justify-center">
                <span className="relationFull"></span>
              </div>
            </Tooltip>
          );
        } else if (record.correlationCoefficient === 2) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-items-center tw-gap-2 tw-justify-center">
                <span className="relationPartial"></span>
              </div>
            </Tooltip>
          );
        } else {
        }
      },
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}`,
            }}
          >
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const progettiColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      width: '80%',
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('name'),
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },

    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      sorter: (a, b) => {
        return a.projectCode.localeCompare(b.projectCode);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('projectCode'),
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      width: '10%',
      dataIndex: 'relationship',
      render: (text, record) => {
        if (record.correlationCoefficient === 1) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationFull margin-center"></span>
              </div>
            </Tooltip>
          );
        } else if (record.correlationCoefficient === 2) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationPartial margin-center"></span>
              </div>
            </Tooltip>
          );
        } else {
        }
      },
    },
    {
      key: 'action',
      width: '50px',
      render: (_, record) => (
        <Space>
          <Link
            to={{
              pathname: `/progetti/id/${record.projectID}`,
            }}
          >
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const kpiColumns = [
    {
      dataIndex: 'isFavourite',
      width: '40px',
      showSorterTooltip: [false],
      render: (_, record) => {
        if (record.isFavourite) {
          return (
            <StarFilled
              style={{ color: '#f5a645' }}
              title={t('kpiPage.preferita')}
            />
          );
        } else {
          return <StarOutlined title={t('kpiPage.preferita')} />;
        }
      },
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      width: '40%',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('name'),
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('kpiPage.tipoCalcolo')}`,
      dataIndex: 'calculationTypeDescription',
      sorter: (a, b) => {
        a = a.calculationTypeDescription || '';
        b = b.calculationTypeDescription || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('calculationTypeDescription'),
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.tipo')}`,
      dataIndex: 'kpiTypeDescription',
      sorter: (a, b) => {
        a = a.kpiTypeDescription || '';
        b = b.kpiTypeDescription || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('kpiPage.cumulatoPuntuale')}`,
      dataIndex: 'parentTypeDescription',
      sorter: (a, b) => {
        a = a.parentTypeDescription || '';
        b = b.parentTypeDescription || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },

    {
      key: 'action',
      width: '50px',
      render: (_, record) => <EditOutlined onClick={() => toggleEditKpiModal(record)} />,
    },
    {
      key: 'action1',
      width: '30px',
      render: (_, record) => (
        <Popconfirm
          placement="topRight"
          title={t('obiettiviAnnoPage.eliminareKpi')}
          onConfirm={() => removeKpiAnualGoal(record.annualGoalKPIRelationShipID)}
          okText={t('general.si')}
          cancelText={t('general.no')}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
    {
      key: 'action2',
      width: '30px',
      render: (_, record) =>
        record.kpiType === kpiType.specifico ? (
          <Tooltip
            placement="top"
            title={t('obiettiviAnnoPage.caricaDatiKpi')}
          >
            <UploadOutlined onClick={() => toggleUploadKpiSpecificoModal(record)} />
          </Tooltip>
        ) : (
          ''
        ),
    },
  ];

  return (
    <>
      {showUploadKpiModal && (
        <UploadKpiAnualGoal
          showUploadKpiModal={showUploadKpiModal}
          handleUploadKpiModalClose={handleUploadKpiModalClose}
          loadingButton={loadingKpiSave}
          anualGoalId={annualGoalId}
          kpiRowData={kpiRowData}
          anualGoalName={annualGoalData.getAnnualGoalDto.description}
          t={t}
        />
      )}

      {showAddKpiModal && (
        <AddKpiObAnnoModal
          showAddKpiModal={showAddKpiModal}
          handleAddKpiModalClose={handleAddKpiModalClose}
          handleAddKpiModalSave={addKpi}
          loadingButton={loadingKpiSave}
          annualGoalID={annualGoalId}
          t={t}
        />
      )}

      {showEditKpiModal && (
        <EditKpiObAnnoModal
          showEditKpiModal={showEditKpiModal}
          handleEditKpiModalClose={handleEditKpiModalClose}
          updateObAnualKpi={updateObAnualKpi}
          loadingButton={loadingKpiSave}
          annualGoalID={annualGoalId}
          kpiRowData={kpiRowData}
          t={t}
          annualGoalData={annualGoalData.getAnnualGoalDto}
        />
      )}

      <Form
        name="obietivo_anno_Details"
        layout="vertical"
        {...formItemLayout}
        onFinish={(values) => updateData(values, annualGoalData.getAnnualGoalDto.annualGoalID)}
        onValuesChange={() => {
          setFieldsChanged(true);
        }}
      >
        <Row gutter={{ lg: 24 }}>
          {/* <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                        <Button
                            onClick={this.backFunction}
                            className="backButton"
                        >
                            <ArrowLeftOutlined  />{t('buttons.indietro')}</Button>
                    </Col> */}
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          ></Col>
        </Row>

        <Row gutter={{ lg: 24 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Card
              className="!tw-shadow-none"
              styles={{ header: { padding: 0 }, body: { padding: 0 } }}
              bordered={false}
              extra={
                !isRoleTeamMemberOrTeamLeader && (
                  <div className="tw-flex tw-items-center tw-gap-2">
                    {annualGoalData.getAnnualGoalDto.canBeDeleted ? (
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        className="!tw-mb-0"
                      >
                        <Popconfirm
                          title={t('obiettiviAnnoPage.eliminareObjAnno')}
                          onConfirm={() => removeObjAnno(annualGoalData.getAnnualGoalDto.annualGoalID)}
                          okText={t('general.si')}
                          cancelText={t('general.no')}
                          className="long-term-button remove"
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            type="dashed"
                          >
                            {t('buttons.elimina')}
                          </Button>
                        </Popconfirm>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        className="!tw-mb-0"
                      >
                        <Button
                          type="primary"
                          disabled={true}
                          onClick={() => removeObjAnno(annualGoalData.getAnnualGoalDto.annualGoalID)}
                        >
                          <DeleteOutlined />
                          {t('buttons.elimina')}
                        </Button>
                      </Form.Item>
                    )}
                    <Form.Item
                      wrapperCol={{ span: 24 }}
                      className="!tw-mb-0"
                    >
                      <Button
                        loading={loadingSave}
                        type="primary"
                        htmlType="submit"
                        disabled={!fieldsChanged}
                      >
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                )
              }
              size="small"
              title={t('general.informazioniGenerali')}
            >
              {annualGoalData.getAnnualGoalDto.xMatrixName && (
                <div className="tw-mt-4">
                  <Text>{t('general.xmatrix')}: </Text>
                  <Text strong>{annualGoalData.getAnnualGoalDto.xMatrixName}</Text>
                </div>
              )}

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Form.Item
                    label={t('general.nome')}
                    name="description"
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={annualGoalData.getAnnualGoalDto.description}
                    hidden={true}
                  >
                    <Input disabled={isRoleTeamMemberOrTeamLeader} />
                  </Form.Item>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('general.stato')}
                    name="status"
                    hasFeedback
                    initialValue={annualGoalData.getAnnualGoalDto.status}
                    rules={[{ required: true, message: requiredFields.required }]}
                  >
                    <Select
                      disabled={isRoleTeamMemberOrTeamLeader}
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                    >
                      {statusList &&
                        statusList.map((item) => (
                          <Option
                            value={item.statusID}
                            key={item.statusID}
                          >
                            {item.statusDescription}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <UserSelectDropDown
                    disabled={isRoleTeamMemberOrTeamLeader}
                    allowClear={false}
                    fieldLabel={t('general.responsabile')}
                    formName={'owner'}
                    required={false}
                    initValue={annualGoalData.getAnnualGoalDto.owner}
                  />

                  {/* <Form.Item
                                        label={t('general.responsabile')}
                                        name="owner"
                                        hasFeedback
                                        initialValue={annualGoalData.getAnnualGoalDto.owner}
                                    >
                                        <Select
                                            disabled={isRoleTeamMemberOrTeamLeader}
                                            style={{ width: "100%" }}
                                            placeholder={t('general.seleziona')}
                                        >
                                            {ownerList && ownerList.map(item =>
                                                <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                            )}

                                        </Select>
                                    </Form.Item> */}
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    label={t('obiettiviAnnoPage.periodoRiferimento')}
                    name="referencePeriod"
                    initialValue={
                      annualGoalData.getAnnualGoalDto.referencePeriod
                        ? moment(annualGoalData.getAnnualGoalDto.referencePeriod)
                        : annualGoalData.getAnnualGoalDto.referencePeriod
                    }
                  >
                    <DatePicker
                      format="YYYY"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Card
          className="!tw-shadow-none"
          styles={{ header: { padding: 0 }, body: { padding: 0 } }}
          bordered={false}
          extra={
            <>
              {activeXmatrixInfo && (
                <XmatrixDropDownFilter
                  onXmatrixSelect={onXmatrixSelect}
                  activeXmatrixInfo={activeXmatrixInfo}
                  preSelectedXmatrixId={routeSelectedXmatrix}
                  t={t}
                />
              )}
            </>
          }
        >
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: <>{t('general.obiettiviLungo')}</>,
                    key: '1',
                    children: (
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Table
                            className="table-height"
                            size="small"
                            columns={longTermGoalColumns}
                            dataSource={relatedLongTermGoals}
                            rowKey={(obj) => obj.longTermGoalID}
                          />
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    label: <>{t('general.progetti')}</>,
                    key: '2',
                    children: (
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Table
                            className="table-height"
                            size="small"
                            columns={progettiColumns}
                            dataSource={annualGoalData.projectByAnnualGoal}
                            rowKey={(obj) => obj.projectID}
                          />
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    label: <>{t('general.kpi')}</>,
                    key: '3',
                    children: (
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Card
                            extra={
                              <Button
                                onClick={toggleAddKpiModal}
                                type="dashed"
                                icon={<PlusOutlined />}
                                size="small"
                              />
                            }
                          >
                            <Table
                              size="small"
                              className="table-height"
                              columns={kpiColumns}
                              dataSource={kpiList}
                              rowKey={(obj) => obj.annualGoalKPIRelationShipID}
                            />
                          </Card>
                        </Col>
                      </Row>
                    ),
                  },
                ]}
              ></Tabs>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
};
export default Impostazioni;
