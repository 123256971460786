import { Avatar, Dropdown, type MenuProps } from 'antd';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import msalApp from 'src/config/auth/clientAuthProvider';
import { useAppSelector } from 'src/redux/store';

const UserMenuAvatar: React.FC<{}> = () => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);

  const items: MenuProps['items'] = [
    { label: <a href="/myprofile">{t('headerMenu.mioProfilo')}</a>, key: '2' },
    {
      label: (
        <li
          className="tw-text-red-500"
          onClick={() => {
            msalApp.logoutRedirect();
          }}
        >
          {t('headerMenu.esci')}
        </li>
      ),
      key: '4',
    },
  ];

  return (
    <div className="navbar-button">
      {userData && (
        <Dropdown menu={{ items }}>
          <div className="!tw-flex tw-ml-4 tw-items-center tw-gap-2">
            {userData.fullName && (
              <p className="tw-block tw-leading-none tw-whitespace-nowrap tw-text-white">{userData.fullName}</p>
            )}
            {userData.profilePhoto ? (
              <Avatar
                size={'default'}
                className="tw-bg-white tw-text-black"
                src={userData.profilePhoto}
              />
            ) : (
              <Avatar
                size={'default'}
                className="tw-bg-white tw-text-black"
              >
                {userData.userInitials}
              </Avatar>
            )}
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default UserMenuAvatar;
