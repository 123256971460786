import { Col, Row, Statistic } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { addQueryParam, getQueryParam } from 'src/utils/url-utils';
import MatrixService from '../../../../services/matrix/matrixService';
import CommitieService from '../../../../services/pages/commitieServices';
import '../../../personalDashbard/personalDashboard.scss';
import Loader2 from '../../../shared/components/loader2/loader2';
import XmatrixDropDownFilter from '../../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import { checkUserIsLenovysOrCompanyAdmin } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { formatOfDate } from '../../../shared/utils/constants';
import ComitieProjects from '../comitieProjects';
import CommittieTeam from '../comitieTeam/comitieTeam';
import RelatedComities from '../relatedComities';
import ComitieActivityChart from './activityChart';

const ComitieSummary = ({ comitieId, comitieDetails, getCommitieById, ...props }) => {
  const userData = useAppSelector((state) => state.userData.userData);
  const [comitieSummary, setComitieSummary] = useState(null);
  const [loadingComitieSummary, setLoadingComitieSummary] = useState(false);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [userHasAccess, setUserHasAccess] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getActiveXmatrix();
  }, []);

  useEffect(() => {
    getComitieSummary();
    if (selectedXmatrixValue) {
      getCommitie(comitieId);
    }

    checkIfUserHasAceess(userData);
  }, [activeXmatrixInfo, comitieId, userData, selectedXmatrixValue]);

  useEffect(() => {
    if (selectedXmatrixValue) {
      addQueryParam('xMatrix', selectedXmatrixValue);
    }
  }, [selectedXmatrixValue]);

  const getActiveXmatrix = async () => {
    if (getQueryParam('xMatrix')) {
      MatrixService.getSelectedXmatrixInfo(getQueryParam('xMatrix'))
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            setActiveXmatrixInfo(resp.responseObject.value);
            setSelectedXmatrixValue(getQueryParam('xMatrix'));
          }
        });

      return;
    }

    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;

          if (getQueryParam('xMatrix')) {
            MatrixService.getSelectedXmatrixInfo(getQueryParam('xMatrix'))
              .then((response) => response.data)
              .then((resp) => {
                if (resp.success) {
                  setActiveXmatrixInfo(respData);
                }
              });
          } else {
            setActiveXmatrixInfo(respData);
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  const getComitieSummary = () => {
    if (activeXmatrixInfo) {
      const xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
      setLoadingComitieSummary(true);
      CommitieService.getComitieSummaryData(comitieId, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const respData = resp.responseObject.value;
            setComitieSummary(respData);
            setLoadingComitieSummary(false);
          } else {
            setLoadingComitieSummary(false);
          }
        })
        .catch((error) => {
          setLoadingComitieSummary(false);
        });
    } else {
    }
  };

  const getCommitie = (id) => {
    CommitieService.getById(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          checkIfUserHasAceess(respData.participants);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkIfUserHasAceess = (partecipatUsers) => {
    const accessByRole = checkUserIsLenovysOrCompanyAdmin(userData);

    const isUserPartecipant =
      Array.isArray(partecipatUsers) && partecipatUsers?.filter((user) => user.userID.includes(userData.userId));

    if ((isUserPartecipant && isUserPartecipant.length > 0) || accessByRole) {
      setUserHasAccess(true);
    } else {
      setUserHasAccess(false);
    }
  };

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6">
        {/* Title */}
        <div className="tw-flex tw-flex-row tw-justify-between">
          <h1 className="tw-text-3xl tw-font-medium">{t('comitatiPage.somarioComitati')}</h1>

          <div>
            {activeXmatrixInfo && (
              <XmatrixDropDownFilter
                onXmatrixSelect={onXmatrixSelect}
                activeXmatrixInfo={activeXmatrixInfo}
                t={t}
              />
            )}
          </div>
        </div>

        {loadingComitieSummary && <Loader2 />}

        {comitieSummary && !loadingComitieSummary && (
          <>
            <Row
              gutter={24}
              className="tw-border-b tw-border-zinc-200"
            >
              <Col
                xs={24}
                md={12}
              >
                <Row>
                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic [&_.ant-statistic-content-value]:!tw-text-primary tw-border-b tw-border-r"
                      title={t('comitatiPage.comitatiProgramati')}
                      value={comitieSummary.plannedCommitee ? comitieSummary.plannedCommitee : '-'}
                    />
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic tw-border-b tw-border-r"
                      title={t('comitatiPage.comitatiSvolti')}
                      value={comitieSummary.closedCommittee ? comitieSummary.closedCommittee : '-'}
                    />
                  </Col>

                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic tw-border-r"
                      title={t('comitatiPage.azioniAperte')}
                      value={comitieSummary.openActivities ? comitieSummary.openActivities : '-'}
                    />
                  </Col>

                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic tw-border-r"
                      title={t('comitatiPage.prosimoComitato')}
                      value={
                        comitieSummary.nextCommittee ? moment(comitieSummary.nextCommittee).format(formatOfDate) : '-'
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col
                xs={24}
                md={12}
              >
                <ComitieActivityChart
                  className="tw-grow tw-shrink-0"
                  t={t}
                  comitieId={comitieId}
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24}>
                <ComitieProjects
                  comitieId={comitieId}
                  onlyView={true}
                />
              </Col>

              <Col
                xs={24}
                className="tw-mt-4"
              >
                <RelatedComities
                  comitieId={comitieId}
                  onlyView={true}
                  userHasAccess={userHasAccess}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <CommittieTeam
                  committee={comitieDetails}
                  onlyView={true}
                  userHasAccess={userHasAccess}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ComitieSummary;
