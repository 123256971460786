import { Button, Form, InputNumber, Switch, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AlertConfigurationResponseDto, UpdateAlertConfigurationRequestDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import CompanyService from '../../services/pages/companyService';
import Loader from '../shared/components/loader/loader';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';

const CompanyNotifications: React.FC = () => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const [loadingButton, setLoadingButton] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [notificationsData, setNotificationsData] = useState<AlertConfigurationResponseDto | null>(null);

  useEffect(() => {
    const retrieveCompanyNotifications = async () => {
      if (!userData?.lastLoggedInCompanyID) return;

      try {
        const response = await CompanyService.getCompanyNotifications(userData?.lastLoggedInCompanyID);
        const resp = response.data;
        if (resp.success) {
          setNotificationsData(resp.responseObject?.value || null);
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      } catch (error) {
        message.error(notifyMessages.retrieveFailed);
      }
    };

    retrieveCompanyNotifications();
  }, [userData]);

  const updateCompanyNotifications = async (values: UpdateAlertConfigurationRequestDto) => {
    if (!notificationsData || userData?.lastLoggedInCompanyID) return;

    const currentCompanyId = userData?.lastLoggedInCompanyID;
    const alertID = notificationsData.alertID ?? undefined;

    setLoadingButton(true);

    if (!alertID || !currentCompanyId) {
      message.error(notifyMessages.updateFailed);
      setLoadingButton(false);
      return;
    }

    try {
      const response = await CompanyService.updateCompanyNotificationsAlert({
        ...values,
        alertID: alertID as any,
        companyID: currentCompanyId as any,
      });
      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Form
      className="form-item-space-between"
      onFinish={(values) => updateCompanyNotifications(values)}
      onValuesChange={() => setFieldsChanged(true)}
    >
      <TableLayout title={`${t('companyNotificationsPage.companyNotifications')}`}>
        <TableLayout.Actions>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!fieldsChanged}
            loading={loadingButton}
          >
            {t('buttons.salva')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Typography.Paragraph>
            {t('companyNotificationsPage.pageDescription', {
              companyName: companyData?.companyName,
            })}
          </Typography.Paragraph>
          <Loader />
          {notificationsData && userData && (
            <div className="tw-flex tw-flex-col tw-divide-y">
              <Form.Item
                label={t('companyNotificationsPage.tuttoInUno')}
                name="allInOne"
                valuePropName="checked"
                initialValue={notificationsData.allInOne}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.inviaMail')}
                name="sendViaMail"
                valuePropName="checked"
                initialValue={notificationsData.sendViaMail}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.inviaCamanelloNotifica')}
                name="sendViaNotificationBell"
                valuePropName="checked"
                initialValue={notificationsData.sendViaNotificationBell}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.giorniFrequenza')}
                name="reminderInDaysFrequence"
                initialValue={notificationsData.reminderInDaysFrequence}
              >
                <InputNumber
                  min={1}
                  className="tw-w-40"
                  max={100}
                />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.periodo')}
                name="periodo"
                initialValue={notificationsData.periodo}
              >
                <InputNumber
                  className="tw-w-40"
                  min={1}
                  max={100}
                />
              </Form.Item>
            </div>
          )}
        </TableLayout.Content>
      </TableLayout>
    </Form>
  );
};

export default CompanyNotifications;
