import { trackPromise } from 'react-promise-tracker';
import {
  authenticatedApi,
  CreateProjectRequest,
  CreateProjectRequestDto,
  InsertProjectRepositoryRequestDto,
  LegacyProjectApi,
  ProjectApi,
  ProjectDashboardApi,
  ProjectUserApi,
  SwapTeamMembersActivitiesRequest,
  trackApiPromise,
  UpdateProjectFieldsRequestDto,
  UpdateProjectRequestDto,
  UpdateTeamMembersRequest,
  XMatrixApi,
} from 'src/config/connectors';
import { acquireToken } from '../../../config/auth/clientAuthProvider';
import axiosInstance from '../../../config/axiosConfig/interceptors';
import i18n from '../../../i18n';
import { getParentProjects } from './progettiService.ext';

const ProgettiService = {
  getProgettiList: async (hasProjectRelation: boolean, xMatrixId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectListPage(hasProjectRelation, xMatrixId));
  },

  // get project by id
  getProgettiByiD: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProject(projectId));
  },

  getParentProjectByID: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProject(projectId));
  },

  addNewProject: async (project: CreateProjectRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectCreateProject(project));
  },

  createProject: async (project: CreateProjectRequest) => {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectCreateProject(project));
  },

  getProjectTeamMembers: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectMembers(projectId));
  },

  getProjectObjectives: async (projectId: string, xmatrixId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetGoalByProject(projectId, xmatrixId));
  },

  checkIfProjectCodeExist: async (projectCode: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectCheckDuplicateProject(projectCode));
  },

  updateProject: async (payload: UpdateProjectRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateProject(payload));
  },

  // update project editor fields
  updateEditorField: async (payload: UpdateProjectFieldsRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateProjectFields(payload));
  },

  getParentProjects,

  getDivisionProjects: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetDivisionProjectCreationPicklist(xmatrixId));
  },

  updateTeamMembers: async (payload: UpdateTeamMembersRequest) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackApiPromise(api.legacyProjectUpdateTeamMembers(payload));
  },

  setUserOnCall: async (payload: { projectId: string; userId: string; onCall: boolean }) => {
    const api = await authenticatedApi(ProjectUserApi);

    return trackPromise(api.projectUserOnCall(payload.projectId, payload.userId, payload.onCall));
  },

  // get project deliverables picklist
  getProjectPicklist: async () => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectDeliverablePicklist());
  },

  getKpiProjectPicklist: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetKPIProjectPicklist(projectId));
  },

  deleteProject: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectDeleteProject(projectId));
  },

  getChildProjects: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectChilds(projectId));
  },

  getProjectCostUserAccess: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetUserProjectCostAccess(projectId));
  },

  getProjectLink: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectRepository(projectId));
  },

  updateProjectLink: async (payload: InsertProjectRepositoryRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectInsertProjectRepository(payload));
  },

  getProjectSintesiData: (isChecked: any, xMatrixId: any) => {
    const queryParams = `?HasProjectRelation=${isChecked}&xMatrixID=${xMatrixId}`;
    const data = acquireToken().then((tokenResponse?) => {
      const optionsVal = {
        method: 'GET',
        url: `api/Project/getProjectSintesiData${queryParams}`,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = axiosInstance(optionsVal);
      return apiResponse;
    });
    return data;
  },

  getProjectSintesi: async (
    xmatrixId?: string,
    teamLeader?: string,
    status?: number,
    division?: string,
    sponsor?: string,
    committee?: string,
    projectCode?: string,
    userId?: string,
    selectNotRelatedProjects = true,
  ) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(ProjectDashboardApi);

    return trackPromise(
      api.projectDashboardProjectSummary(
        xmatrixId,
        teamLeader,
        status,
        division,
        sponsor,
        committee,
        projectCode,
        userId,
        selectNotRelatedProjects,
        languageCode,
      ),
    );
  },

  getProjectTeamMebersList: async (hasProjectRelation: boolean, xmatrixId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectListPage(hasProjectRelation, xmatrixId));
  },

  swapProjectTeamMembers: async (payload: SwapTeamMembersActivitiesRequest) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectSwapTeamMembersActivities(payload));
  },
};

export default ProgettiService;
