import { Form } from 'antd';
import React from 'react';
import '../../projects/projectForm/deliverablesAndKpi/deliverable.scss';

import { CheckOutlined, CloseOutlined, FilterOutlined, MinusSquareOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import DeliverableService from '../../../services/pages/projectServices/deliverableServices';
import {
  actualYtdRow,
  currentMonthOfActualYtd,
  currentMonthOfTargetYtd,
  firstMonthOfActualYtd,
  firstMonthOfTargetYtd,
  getTotalTargetMonths,
  lastMonthOfTarget,
  previousMonthOfActualYtd,
  previousMonthOfTargetYtd,
  targetYtdRow,
} from '../../projects/projectForm/deliverablesAndKpi/calculations';
import { bowlingChartRowName, kpiPuntualeAcumulato } from '../../shared/utils/constants';
import {
  AddSingelSpace,
  alignCurrentMonthCellOnKpiValuesGrid,
  capitalizeFirstLetter,
  checkUserIsKpiResponsabile,
} from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
const { Text } = Typography;

class KpiValoreFattoNonFattoGlobale extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parentType: props.kpiRowData.parentType,
      jumpOffPointValue: props.kpiRowData.jumpOffPoint,
      kpiProjectRelationShipID: props.kpiRowData.kpiProjectRelationShipID,
      finalTargetValue: '',
      targetYtdValue: '',
      actualYtdValue: '',
      showInA3: props.kpiRowData.showInA3,
      fieldsChanged: false,
      loadingButton: false,
      definedTarget: props.kpiRowData.freezeTargetValues,
      kpiList: null,
      kpiData: null,
      bowlingChartData: null,
      loadingData: false,

      clearFilters: false,
      // selectedYear: moment().format('YYYY'),
      selectedYear: props.kpiYear ? props.kpiYear : moment().format('YYYY'),
      kpiFieldsChanged: false,
    };
  }

  componentDidMount() {
    this.getGlobalKpiBowlingChart();
  }

  calculateKpiTargetsAndActuals = (bowlingChartData) => {
    if (bowlingChartData) {
      this.calculateFinalTarget(this.state.jumpOffPointValue);
      //this.calculateTargetYtdRow();
    }
  };

  async getGlobalKpiBowlingChart() {
    const { kpiDetailID, kpiRowData } = this.props;
    const { selectedYear } = this.state;

    this.setState({ loadingData: true });
    await DeliverableService.getGlobalKpiBowlingChart(kpiDetailID, selectedYear, kpiRowData.isRollupKPI)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ bowlingChartData: resp.responseObject.value }, () => {
            this.calculateKpiTargetsAndActuals(this.state.bowlingChartData);
            this.calculateTargetYTD(this.state.bowlingChartData);
            this.calculateActualYTD(this.state.bowlingChartData);
          });
          this.setState({ loadingData: false });

          alignCurrentMonthCellOnKpiValuesGrid();
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  onConfirm = () => {
    const { bowlingChartData, jumpOffPointValue, finalTargetValue, showInA3, definedTarget } = this.state;
    const { calculationType } = this.props;
    this.props.onSave(bowlingChartData, jumpOffPointValue, calculationType, finalTargetValue, showInA3, definedTarget);
  };

  renderTargetColumns = (tableData) => {
    const { kpiRowData } = this.props;
    const { definedTarget } = this.state;

    if (tableData && tableData.length > 0) {
      const columns = [];

      const firstRow = tableData[0];

      for (const col in firstRow) {
        if (col === 'Name') {
          columns.push({
            dataIndex: 'Name',
            title: '',
            width: '10%',
            render: (text) => AddSingelSpace(text, 6).join(' '),
          });
        } else {
          const currentMonth = moment().format('YYYY-MM');
          const month = moment(col).format('MMM YYYY');
          columns.push({
            dataIndex: col,
            style: { backgroudColor: '#e66363' },
            // className: col === currentMonth ? "currentMonthBorderLineIndicatorTarget" : "",
            // title: moment(col).format("MMM YYYY"),
            title: capitalizeFirstLetter(month),

            render: (text, record, index) => {
              if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                return record.Name === bowlingChartRowName.target ? (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetChangePuntuale(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                  />
                ) : (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetYtdChangePuntuale(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={true}
                  />
                );
              } else {
                return record.Name === bowlingChartRowName.target ? (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetChange(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                  />
                ) : (
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => this.onTargetYtdChange(col, text, record, checked)}
                    checked={text === 0 || text === null ? false : true}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                  />
                );
              }
            },
          });
        }
      }

      return columns;
    }
  };

  renderActualColumns = (tableData) => {
    const { kpiRowData } = this.props;

    if (tableData) {
      const columns = [];

      if (tableData.length > 0) {
        const firstRow = tableData[0];

        for (const col in firstRow) {
          if (col === 'Name') {
            columns.push({
              dataIndex: 'Name',
              title: '',
              width: '10%',
              render: (text) => AddSingelSpace(text, 6).join(' '),
            });
          } else {
            const currentMonth = moment().format('YYYY-MM');
            const month = moment(col).format('MMM YYYY');
            columns.push({
              className: col === currentMonth ? 'currentMonthBorderLineIndicatorActual' : '',
              dataIndex: col,
              // title: moment(col).format("MMM YYYY"),
              title: capitalizeFirstLetter(month),

              render: (text, record, index) => {
                if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                  return record.Name === bowlingChartRowName.actual ? (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualChangePuntuale(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={kpiRowData.isRollupKPI}
                    />
                  ) : (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualYtdChangePuntuale(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={true}
                    />
                  );
                } else {
                  return record.Name === bowlingChartRowName.actual ? (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualChange(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={kpiRowData.isRollupKPI}
                    />
                  ) : (
                    <Switch
                      size="small"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => this.onActualYtdChange(col, text, record, checked)}
                      checked={text === 0 || text === null ? false : true}
                      disabled={kpiRowData.isRollupKPI}
                    />
                  );
                }
              },
            });
          }
        }
      }

      return columns;
    }
  };

  onTargetChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in targetRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          targetRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          targetRow[key] = newValue ? 1 : 1;
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          targetRow[key] = 0;
        }
      }
    }
    this.setState(bowlingChartData);
  };

  onTargetChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];

    targetRow[selectedMonth] = newValue ? 1 : 0;

    this.setState(bowlingChartData);
  };

  onTargetYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in targetYtdRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          targetYtdRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          targetYtdRow[key] = newValue ? 1 : 1;
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          targetYtdRow[key] = 0;
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateTargetYTD();
    });
  };

  onTargetYtdChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
    targetYtdRow[selectedMonth] = newValue ? 1 : 0;

    this.setState({ bowlingChartData }, () => {
      this.calculateTargetYTD();
    });
  };

  onActualChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in actualRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          actualRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          actualRow[key] = newValue ? 1 : 1;
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          actualRow[key] = 0;
        }
      }
    }
    this.setState(bowlingChartData);
  };

  onActualChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    actualRow[selectedMonth] = newValue ? 1 : 0;

    this.setState(bowlingChartData);
  };

  onActualYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];

    const formatSelectedMonth = moment(selectedMonth, 'YYYY-MM');

    for (const key in actualYtdRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          actualYtdRow[key] = newValue ? 1 : 0;
        }

        if (moment(key, 'YYYY-MM') > formatSelectedMonth) {
          actualYtdRow[key] = newValue ? 1 : 1;
        }

        if (moment(key, 'YYYY-MM') < formatSelectedMonth) {
          actualYtdRow[key] = 0;
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  onActualYtdChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
    actualYtdRow[selectedMonth] = newValue ? 1 : 0;

    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  handleShowInA3Change = (value) => {
    this.setState({ kpiFieldsChanged: true });
    this.setState({ showInA3: value });
  };

  calculateFinalTarget = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      const lastMonth = lastMonthOfTarget(bowlingChartData);
      this.setState({ finalTargetValue: lastMonth.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      const targetTotalMonths = getTotalTargetMonths(bowlingChartData);
      this.setState({ finalTargetValue: jumpOffPointValue + targetTotalMonths });
    } else {
      return;
    }
  };

  calculateTargetYTD = () => {
    const { bowlingChartData } = this.state;
    const currentMonth = moment().format('YYYY-MM');

    const previousMonth = currentMonthOfTargetYtd(bowlingChartData, currentMonth);

    this.setState({ targetYtdValue: previousMonth.monthValue });
  };

  calculateActualYTD = () => {
    const { bowlingChartData } = this.state;
    const currentMonth = moment().format('YYYY-MM');

    const previousMonth = currentMonthOfActualYtd(bowlingChartData, currentMonth);

    this.setState({ actualYtdValue: previousMonth.monthValue });
  };

  calculateTargetYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    const targetYtdFirstMonth = firstMonthOfTargetYtd(bowlingChartData);

    const targetYtdRowData = targetYtdRow(bowlingChartData);

    for (const key in targetYtdRowData) {
      if (key !== 'Name') {
        if (key === targetYtdFirstMonth.monthName) {
          const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
          const targetValue = targetRow[key];

          const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
          targetYtdRow[key] = jumpOffPointValue + targetValue;
        } else {
          const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
          const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];

          const previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
          const previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];

          const targetValue = targetRow[key];

          targetYtdRow[key] = previousTargetYtdMonthValue + targetValue;
        }
      }
    }
  };

  calculateActualYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    const actualYtdFirstMonth = firstMonthOfActualYtd(bowlingChartData);

    const actualYtdRowData = actualYtdRow(bowlingChartData);

    for (const key in actualYtdRowData) {
      if (key !== 'Name') {
        if (key === actualYtdFirstMonth.monthName) {
          const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
          const actualValue = actualRow[key];

          const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
          actualYtdRow[key] = jumpOffPointValue + actualValue;
        } else {
          const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
          const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];

          const previousActualYtdMonth = previousMonthOfActualYtd(bowlingChartData, key);
          const previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];

          const actualValue = actualRow[key];

          actualYtdRow[key] = previousActualYtdMonthValue + actualValue;
        }
      }
    }
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.getGlobalKpiBowlingChart();
    });
  };

  onClearFilter = () => {
    const { kpiYear } = this.props;
    const currentYear = moment().format('YYYY');
    this.setState({ selectedYear: kpiYear ? kpiYear : currentYear, clearFilters: true }, () => {
      this.getGlobalKpiBowlingChart();
    });
  };

  onYearChange = (year) => {
    const formatedYear = moment(year).format('YYYY');
    this.setState({ selectedYear: formatedYear });
  };

  disablePreviousYears = (current) => {
    const { kpiYear } = this.props;
    // return current && (current > moment().add(10, "year") || current < moment());
    if (kpiYear) {
      return current < moment(kpiYear) || moment(kpiYear).add(10, 'year') < current;
    } else {
      return current && (current > moment().add(10, 'year') || current < moment());
    }
  };

  handleDefinedTargetChange = (value) => {
    this.setState({ kpiFieldsChanged: true });
    this.setState({ definedTarget: value });
  };

  render() {
    const { loadingButton, kpiRowData, t, userData, responsabileID } = this.props;
    const { bowlingChartData, loadingData, selectedYear, kpiFieldsChanged, definedTarget } = this.state;

    let targetRow = [];
    let actualRow = [];

    if (bowlingChartData) {
      targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
      actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    }

    return (
      <>
        <Row
          gutter={{ lg: 24 }}
          className="mb10"
        >
          <Col
            className="gutter-row"
            xs={{ span: 8 }}
            sm={{ span: 6 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            {/* <DatePicker allowClear={false} placeholder={t('general.anno')}  picker="year" value={moment(selectedYear)} style={{ width: "100%" }} format="YYYY" onChange={(date) => this.onYearChange(date)} disabledDate={(current) => this.disablePreviousYears(current)} /> */}
            <DatePicker
              allowClear={false}
              placeholder={t('general.anno')}
              picker="year"
              value={moment(selectedYear)}
              style={{ width: '100%' }}
              format="YYYY"
              onChange={(date) => this.onYearChange(date)}
            />
          </Col>
          <Col
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}
          >
            <Tooltip title={t('general.filter')}>
              <Button
                type="dashed"
                icon={<FilterOutlined />}
                onClick={() => this.onFilter()}
                disabled={!selectedYear}
              />
            </Tooltip>
            <Tooltip title={t('general.clearFilters')}>
              <Button
                type="dashed"
                icon={<MinusSquareOutlined />}
                onClick={() => this.onClearFilter()}
                disabled={selectedYear ? false : true}
              />
            </Tooltip>
          </Col>
        </Row>

        {bowlingChartData && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <Space direction="vertical">
                <span>
                  <Text style={{ fontSize: 14 }}>
                    {' '}
                    <span className="header-text"> {t('kpiPage.cumulatoPuntuale')}: </span>{' '}
                    <u>{kpiRowData.parentTypeDescription}</u>
                  </Text>
                  <Divider
                    type="vertical"
                    style={{ borderColor: '#067af3' }}
                  />
                  <Text style={{ fontSize: 14 }}>
                    {' '}
                    <span className="header-text"> {t('kpiPage.tipoCalcolo')}: </span>
                    <u>{kpiRowData.calculationTypeDescription}</u>
                  </Text>

                  {kpiRowData.responsibleID && (
                    <>
                      <Divider
                        type="vertical"
                        style={{ borderColor: '#067af3' }}
                      />
                      <Text style={{ fontSize: 14 }}>
                        {' '}
                        <span className="header-text"> {t('general.responsabile')}: </span>
                        <u>{kpiRowData.responsibleName}</u>
                      </Text>
                    </>
                  )}
                </span>
              </Space>

              {checkUserIsKpiResponsabile(kpiRowData.responsibleID, userData) && (
                <>
                  {!kpiRowData.isRollupKPI && (
                    <div className="button-wrapper">
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                          loading={loadingButton}
                          type="primary"
                          onClick={() => this.onConfirm()}
                          icon={<SaveOutlined />}
                          disabled={!targetRow || !actualRow || !kpiFieldsChanged}
                        >
                          {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        )}

        {kpiRowData.parentType !== kpiPuntualeAcumulato.puntuale && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 5 }}
              >
                <Statistic
                  title={t('kpiPage.finalTarget')}
                  value={this.state.finalTargetValue ? this.state.finalTargetValue : '-'}
                />
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 4 }}
              >
                {this.state.targetYtdValue ? (
                  <Statistic
                    title={t('kpiPage.targetYearDate')}
                    prefix={<CheckOutlined />}
                    value={t('kpiPage.fatto')}
                  />
                ) : (
                  <Statistic
                    title={t('kpiPage.targetYearDate')}
                    prefix={<CloseOutlined />}
                    value={t('kpiPage.nonFatto')}
                  />
                )}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 4 }}
              >
                {this.state.actualYtdValue ? (
                  <Statistic
                    title={t('kpiPage.actualYearDate')}
                    prefix={<CheckOutlined />}
                    value={t('kpiPage.fatto')}
                  />
                ) : (
                  <Statistic
                    title={t('kpiPage.actualYearDate')}
                    prefix={<CloseOutlined />}
                    value={t('kpiPage.nonFatto')}
                  />
                )}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 3 }}
              >
                <Form.Item label={t('kpiPage.definedTarget')}>
                  <Switch
                    defaultChecked={definedTarget}
                    onChange={(value) => this.handleDefinedTargetChange(value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {bowlingChartData && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderTargetColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Target';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Target';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    loading={loadingData}
                    scroll={{ x: 'calc(700px + 50%)' }}
                  />
                </div>
              </Col>
            </Row>

            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderActualColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Actual';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Actual';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    loading={loadingData}
                    scroll={{ x: 'calc(700px + 50%)' }}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default KpiValoreFattoNonFattoGlobale;
