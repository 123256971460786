import { ChangeEvent, useEffect, useState } from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Card, Col, Input, InputNumber, Row, Select, Switch, message } from 'antd';
import { useTranslation } from 'react-i18next';
import CommonService from '../../services/commonService';
import DashboardService from '../../services/pages/dashboardService';
import ProgettiService from '../../services/pages/projectServices/progettiService';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { DatePickerWithDateFns, projectStatusAsNumber, statusPickListCodes } from '../shared/utils/constants';
import {
  areDatesValid,
  handleDateConvert,
  showErrorNotification,
  updateNotificationBell
} from '../shared/utils/functions';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import { useParams } from 'react-router';
import { CreateProjectRequest, GetCommitteePicklistDto, GetDivisionPicklistDto, GetProjectCreationPicklistResponseDto, GetProjectResponseDto, ProjectStatus, ProjectType, StatusPicklistResponseDto, CreateProjectRequestDto as Prova } from 'src/connectors/backend';
import { isBefore, isAfter, endOfDay } from 'date-fns';
import { history } from 'src/App';
import { Rule } from 'antd/es/form';
import { useAppSelector } from 'src/redux/store';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

interface CreateProjectRequestDto {
  name: string;
  projectCode: string;
  parentProjectID: string;
  status: number;
  budget: number;
  teamLeaderID: string;
  sponsorID: string;
  committeeID: string;
  divisionID: string;
  startDate: string;
  endDate: string;
  hasExternalReview: boolean;
  type: boolean;
  projectGoal: string;
}

const MAX_PROJECT_CODE_LENGTH = 10;

const AddNewProjectPage = () => {
  const { t } = useTranslation();
  const { xmid } = useParams();
  const activeXMatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [statusList, setStatusList] = useState<StatusPicklistResponseDto[]>([]);
  const [committeesPicklist, setCommitteesPicklist] = useState<GetCommitteePicklistDto[]>([]);
  const [parentProjectList, setParentProjectList] = useState<GetProjectCreationPicklistResponseDto[]>([]);
  const [divisionsList, setDivisionsList] = useState<GetDivisionPicklistDto[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const [preDefinedProjectCode, setPreDefinedProjectCode] = useState<string>();
  const [projectCodeValue, setProjectCodeValue] = useState<string>();
  const [selectedProjectData, setSelectedProjectData] = useState<GetProjectResponseDto>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    void retrieveStatus();
    void fetchCommittees();
    void getParentProjects();
    retrieveDivisions();
  }, [xmid]);

  const getParentProjects = async () => {
    try {
      const { data } = await ProgettiService.getParentProjects();
      
      if (data.success) {
        setParentProjectList(data.responseObject?.value as GetProjectCreationPicklistResponseDto[]);
      }
    } catch {}
  };

  const retrieveDivisions = async () => {
    try {
      const { data } = await DashboardService.getDivisionFilterData();

      if (data.success) {
        setDivisionsList(data.responseObject?.value as GetDivisionPicklistDto[]);
      }
    } catch {}
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.statusProjects;

    try {
      const { data } = await CommonService.getStatusData(objectCode);

      if (data.success) {
        setStatusList(data.responseObject?.value as StatusPicklistResponseDto[]);
      }
    } catch {}
  };

  const fetchCommittees = async () => {
    try {
      const { data } = await CommonService.getCommitiePicklist();

      if (data.success) {
        setCommitteesPicklist(data.responseObject?.value as GetCommitteePicklistDto[]);
      }
    } catch {}
  };

  const _validateProjectCode = async (rule: Rule, value: string) => {
    try {
      const { data } = await ProgettiService.checkIfProjectCodeExist(value);

      if (data.success && data.responseObject?.value) {
        return requiredFields.validateProjectCode;
      }
    } catch {}
  };

  const onProjectClear = () => {
    setPreDefinedProjectCode(undefined);
    setSelectedProjectData(undefined);
  };

  const disableBefore = (current: Date, start: Date) => {
    return isBefore(endOfDay(current), endOfDay(start));
  };

  const disableAfter = (current: Date, end: Date) => {
    return isAfter(endOfDay(current), endOfDay(end));
  };

  const getProjectData = async (id: string) => {
    try {
      const response = await ProgettiService.getProgettiByiD(id);
      const resp = response.data;

      if (resp.success) {
        setSelectedProjectData(resp.responseObject?.value as GetProjectResponseDto);
      }
    } catch {}
  };

  const onProjectSelect = (selectedProjectId: string) => {
    const selectedProject = parentProjectList.find((project) => project.projectID === selectedProjectId);
    const selectedProjectCode = selectedProject?.projectCode ?? undefined;

    void getProjectData(selectedProjectId);
    setPreDefinedProjectCode(selectedProjectCode);
  };

  const onProjectCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProjectCodeValue(e.target.value);
  };

  const onStatusChange = (value: number, option: any) => {
    setIsMandatory(option.children === 'Attivo');
  };

  const invertObject = <T extends Record<string, string | number>>(obj: T) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [String(value), key])
    );

    /* const addData = async (values: CreateProjectRequestDto) => {
      const PROJECTS_ROUTE = '/progetti';
  
      const payload: Prova = {
        ...values,
        startDate: handleDateConvert(values.startDate) as string,
        endDate: handleDateConvert(values.endDate) as string,
        projectCode: preDefinedProjectCode ? preDefinedProjectCode + '-' + values.projectCode : values.projectCode,
        status: values.status,
      };

      setLoadingButton(true);
  
      try {
        const { data } = await ProgettiService.addNewProject(payload);
  
        if (data.success) {
          updateNotificationBell();
          message.success(notifyMessages.addSuccess);
          history.push(PROJECTS_ROUTE);
        } else {
          message.error(notifyMessages.addFailed);
        }
      } catch {
        message.error(notifyMessages.addFailed);
      } finally {
        setLoadingButton(false);
      }
    }; */

  const addData = async (values: CreateProjectRequestDto) => {
    const PROJECTS_ROUTE = '/progetti';

    const payload: CreateProjectRequest = {
      code: preDefinedProjectCode ? preDefinedProjectCode + '-' + values.projectCode : values.projectCode,
      name: values.name,
      xMatrixId: activeXMatrix?.xMatrixID as string,
      startDate: handleDateConvert(values.startDate) as string,
      endDate: handleDateConvert(values.endDate) as string,
      type: values.type ? ProjectType.Operational : ProjectType.NonOperational,
      status: invertObject(projectStatusAsNumber)[values.status] as ProjectStatus,
      teamLeaderId: values.teamLeaderID,
      sponsorId: values.sponsorID,
      commiteeId: values.committeeID,
      divisionId: values.divisionID,
      parentProjectId: values.parentProjectID,
      hasExternalReview: values.hasExternalReview,
      fastCreation: false,
      secondaryProject: false,
      notes: values.projectGoal,
    };

    setLoadingButton(true);

    try {
      const { data } = await ProgettiService.createProject(payload);

      if (data.success) {
        updateNotificationBell();
        message.success(notifyMessages.addSuccess);
        history.push(PROJECTS_ROUTE);
      } else {
        message.error(notifyMessages.addFailed);
      }
    } catch {
      message.error(notifyMessages.addFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const checkValidityBeforeSave = (values: CreateProjectRequestDto) => {
    if (!areDatesValid(values.startDate, values.endDate)) {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }

    void addData(values);
  };

  return (
    <div className="edit-obietivo-anno-wrapper">
      <>
        <div className="site-card-wrapper">{t('proggetiPage.aggiungiNuovoProgetto')}</div>

        <Card>
          <Form<CreateProjectRequestDto>
            name="add_ob_project"
            layout="vertical"
            {...formItemLayout}
            onFinish={checkValidityBeforeSave}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <div className="button-wrapper">
                  <Form.Item<CreateProjectRequestDto> wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      htmlType="submit"
                      className="tw-float-end"
                      disabled={!fieldsChanged}>
                      <SaveOutlined /> {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}>
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    {/* <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                              <Form.Item<CreateProjectRequestDto>
                                                  label="È progetto secondario?"
                                              >
                                                  <Switch defaultChecked={isSecondaryProject} onChange={(value) => this.handleSecondaryProjectChange(value)} />
                                              </Form.Item>
                                          </Col> */}

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('proggetiPage.nomeProgetto')}
                        name="name"
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item<CreateProjectRequestDto>
                        extra={t('proggetiPage.codiceLungeza')}
                        label={t('proggetiPage.codiceProgetto')}
                        name="projectCode"
                        rules={[
                          { required: true, message: requiredFields.required },
                          { max: MAX_PROJECT_CODE_LENGTH, message: `${t('proggetiPage.codiceLungeza')}` },
                          {
                            validator: _validateProjectCode,
                          },
                        ]}
                        //initialValue={preDefinedProjectCode}
                      >
                        <Input
                          addonBefore={preDefinedProjectCode ? `${preDefinedProjectCode}-` : ''}
                          value={projectCodeValue}
                          onChange={(e) => onProjectCodeChange(e)}
                          maxLength={MAX_PROJECT_CODE_LENGTH}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('proggetiPage.progettoPrincipale')}
                        name="parentProjectID"
                        hasFeedback>
                        <Select
                          allowClear
                          showSearch
                          filterOption={(value, option) => {
                            return option?.label.toLowerCase().includes(value.toLowerCase()) ?? false;
                          }}
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onSelect={(option) => onProjectSelect(option.value)}
                          onClear={() => onProjectClear()}
                          options={parentProjectList.map((p) => {
                            return { 
                              value: p.projectID, 
                              label: `${p.name} ${p.projectCode}` };
                          })}>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('general.stato')}
                        name="status"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={projectStatusAsNumber.draft}>
                        <Select
                          allowClear
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onChange={(value, option) => onStatusChange(value, option)}>
                          {statusList.map((item) => (
                              <Option
                                value={item.statusID}
                                key={item.statusID}>
                                {item.statusDescription}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 4 }}
                      lg={{ span: 4 }}>
                      <Form.Item<CreateProjectRequestDto>
                        labelAlign="left"
                        label={t('general.budget')}
                        name="budget">
                        <InputNumber min={0} />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}>
                      <UserSelectDropDown
                        disabled={false}
                        allowClear={true}
                        fieldLabel={t('general.teamLeader')}
                        formName={'teamLeaderID'}
                        required={isMandatory}
                        initValue={undefined}
                      />
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}>
                      <UserSelectDropDown
                        disabled={false}
                        allowClear={true}
                        fieldLabel={t('general.sponsor')}
                        formName={'sponsorID'}
                        required={isMandatory}
                        initValue={undefined}
                      />
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('general.comitato')}
                        name="committeeID"
                        hasFeedback>
                        <Select
                          showSearch
                          allowClear
                          filterOption={(value, option) => {
                            return option?.label.toLowerCase().includes(value.toLowerCase()) ?? false;
                          }}
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          options={committeesPicklist.map((committee) => {
                            return {
                              disabled: committee.hasAccess !== 1,
                              value: committee.committeeID, 
                              label: `${committee.name}` };
                          })}>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}>
                      <Form.Item<CreateProjectRequestDto>
                        labelAlign="left"
                        label={t('general.divisione')}
                        name="divisionID"
                        hasFeedback>
                        <Select
                          showSearch
                          allowClear
                          filterOption={(value, option) => {
                            return option?.label.toLowerCase().includes(value.toLowerCase()) ?? false;
                          }}
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          options={divisionsList.map((division) => {
                            return {
                              disabled: division.hasAccess !== 1,
                              value: division.divisionID, 
                              label: `${division.divisionName}` };
                          })}>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('general.start')}
                        name="startDate"
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <DatePickerWithDateFns
                          style={{ width: '100%' }}
                          format="DD/MM/YYYY"
                          disabledDate={(current) => !!endDate && disableAfter(current, endDate)}
                          onChange={(current) => setStartDate(current)}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('general.end')}
                        name="endDate"
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <DatePickerWithDateFns
                          style={{ width: '100%' }}
                          format="DD/MM/YYYY"
                          disabledDate={(current) => !startDate || disableBefore(current, startDate)}
                          onChange={(current) => setEndDate(current)}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('proggetiPage.revisioneEsterna')}
                        name="hasExternalReview"
                        initialValue={false}>
                        <Switch />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('proggetiPage.isOperative')}
                        name="type"
                        initialValue={false}>
                        <Switch />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item<CreateProjectRequestDto>
                        label={t('proggetiPage.obiettivoProgetto')}
                        name="projectGoal"
                        initialValue="">
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    </div>
  );
};

export default AddNewProjectPage;
