import { type FC, useEffect, useState } from 'react';

import { EditFilled, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Form, Radio, Row, Select, Table, Typography, message } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type dayjs from 'dayjs';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import type {
  CommitteeDto,
  GetCommitteePicklistDto,
  GetSintesiComitatiResponse,
  GetXMatrixInfoResponseDto,
  ProjectDto,
  StatusPicklistResponseDto,
} from 'src/connectors/backend';
import { addQueryParam, getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import CommonService from '../../../../services/commonService';
import MatrixService from '../../../../services/matrix/matrixService';
import CommitieService from '../../../../services/pages/commitieServices';
import XmatrixDropDownFilter from '../../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../../shared/tableLayout';
import {
  comitieSintesiPeriod,
  formItemLayout,
  formatOfDate,
  sessionStatus,
  statusPickListCodes,
} from '../../../shared/utils/constants';
import { alignCurentMonthProjectSintesi, handleDateConvert } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';

const { Text } = Typography;
const { Option } = Select;

type FilterFormData = {
  committeeIDList: string[];
  statusList: number[];
  dateFrom: dayjs.Dayjs;
  dateTo: dayjs.Dayjs;
  periodType: number;
};

const ProjectRevision: FC<{ committee: CommitteeDto }> = ({ committee }) => {
  const [form] = Form.useForm();
  const defaultStartDate = moment().startOf('year');
  const defaultEndDate = moment().endOf('year');
  const defaultSessionStatus = [sessionStatus.confirmed, sessionStatus.planned];
  const defaultPeriod = comitieSintesiPeriod.month;
  const { t } = useTranslation();

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState<GetXMatrixInfoResponseDto>();
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState<string>();
  const [sintesiData, setSintesiData] = useState<GetSintesiComitatiResponse[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [sessionStatusList, setSessionStatusList] = useState<StatusPicklistResponseDto[]>([]);
  const [commitiePickList, setCommitiePickList] = useState<GetCommitteePicklistDto[]>([]);
  const [sintesiTableColums, setSintesiTableColums] = useState<ColumnsType<GetSintesiComitatiResponse>>([]);
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [noComitiesProjects, setNoComitiesProjects] = useState<ProjectDto[]>([]);
  const [expandCollapseRows, setExpandCollapseRows] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getActiveXmatrix();
    retrieveStatus();
    retrieveCommities();
  }, []);

  useEffect(() => {
    getSintesiData(form.getFieldsValue());
    getNoComitieProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeXmatrixInfo]);

  useEffect(() => {
    if (selectedXmatrixValue) {
      addQueryParam('xMatrix', selectedXmatrixValue);
    }
  }, [selectedXmatrixValue]);

  const getNoComitieProjects = async () => {
    const xMatrixId = selectedXmatrixValue ?? activeXmatrixInfo?.xMatrixID ?? undefined;

    if (!xMatrixId) {
      return;
    }

    try {
      const { data } = await CommitieService.getNoCommitteeProjects(xMatrixId, committee.type);
      setNoComitiesProjects(data);
    } catch {}
  };

  const noComitiesProjectsColumns: ColumnType<ProjectDto>[] = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'projectName',
      width: '20%',
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
      fixed: 'left',
      showSorterTooltip: false,
    },
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      sorter: (a, b) => a.code.localeCompare(b.code),
      showSorterTooltip: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: `${t('general.teamLeader')}`,
      dataIndex: 'TeamLeaderName',
      sorter: (a, b) => (a.teamLeader?.fullName ?? '').localeCompare(b.teamLeader?.fullName ?? ''),
      showSorterTooltip: true,
    },
    {
      key: 'action',
      width: '60px',
      render: (_, record) => (
        <Button
          icon={<EditFilled />}
          href={`/progetti/id/${record.id}`}
        ></Button>
      ),
    },
  ];

  const getSintesiData = async (values: FilterFormData) => {
    const xMatrixId = selectedXmatrixValue ?? activeXmatrixInfo?.xMatrixID ?? undefined;

    if (!xMatrixId) {
      return;
    }

    setLoadingData(true);

    try {
      const { data } = await CommitieService.getSintesiListData({
        dateFrom: handleDateConvert(values.dateFrom)!,
        dateTo: handleDateConvert(values.dateTo)!,
        xmatrixID: xMatrixId,
        periodType: values.periodType,
        committeeIDList: values.committeeIDList,
        statusList: values.statusList.map((s) => `${s}`),
      });

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Retrieve failed');
      }

      const records = data.responseObject.value.map((item) => ({
        ...item,
        projectSessions: JSON.parse(item.projectSessions!),
        children: JSON.parse(item.children!),
      }));

      setSintesiData(records);

      const tableColumns = generateTableColumns(records);
      setSintesiTableColums(tableColumns);
      alignCurentMonthProjectSintesi();
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    setLoadingData(false);
  };

  const getActiveXmatrix = async () => {
    if (hasQueryParam('xMatrix')) {
      MatrixService.getSelectedXmatrixInfo(getQueryParam('xMatrix'))
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            setActiveXmatrixInfo(resp.responseObject?.value);
            setSelectedXmatrixValue(getQueryParam('xMatrix'));
          }
        });

      return;
    }

    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject?.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.sessionStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success && resp.responseObject?.value) {
          setSessionStatusList(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveCommities = async () => {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success && resp.responseObject?.value) {
          setCommitiePickList(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  };

  const handleClear = () => {
    setSelectedPeriod(defaultPeriod);
    form.setFieldsValue({
      statusList: defaultSessionStatus,
      dateFrom: defaultStartDate,
      dateTo: defaultEndDate,
      periodType: defaultPeriod,
      committeeIDList: [],
    });
    setActiveXmatrixInfo(undefined);
    setSelectedXmatrixValue(undefined);
    getActiveXmatrix();
  };

  const periodOptions = [
    {
      label: t('comitatiPage.year'),
      value: comitieSintesiPeriod.year,
    },
    {
      label: t('comitatiPage.month'),
      value: comitieSintesiPeriod.month,
    },
    {
      label: t('comitatiPage.week'),
      value: comitieSintesiPeriod.week,
    },
  ];

  const convertDateBasedOnPeriod = (date: string) => {
    if (date) {
      //selected period year
      if (selectedPeriod === comitieSintesiPeriod.year) {
        return moment(date).format('YYYY');
      }
      //selected period month
      else if (selectedPeriod === comitieSintesiPeriod.month) {
        return moment(date).format('MMM YYYY');
      }
      //selected period week
      else if (selectedPeriod === comitieSintesiPeriod.week) {
        return moment(date).week();
      }
    }
  };

  const statusCellColor = (sesStatus: string) => {
    const status = sesStatus ? Number.parseInt(sesStatus) : 100;

    if (status && status === sessionStatus.confirmed) {
      return 'status status-fatto';
    } else if (status && status === sessionStatus.notDiscussed) {
      return 'status status-sospeso';
    } else if (status && status === sessionStatus.planned) {
      return 'status status-panificato';
    } else if (status && status === sessionStatus.toBeConfirmed) {
      return 'status status-draft';
    } else if (status && status === 10) {
      return 'status status-grey';
    } else if (status && status === 11) {
      return 'status status-orange';
    } else {
      return 'status status-empty';
    }
  };

  const generateTableColumns = (data: GetSintesiComitatiResponse[]) => {
    if (!data?.length) {
      return [];
    }

    // TODO not even the backend know the type of GetSintesiComitatiResponse['projectSessions']
    type SessionDataElement = any;

    const cols: ColumnsType<GetSintesiComitatiResponse> = [
      {
        title: `${t('general.comitato')}`,
        dataIndex: 'committeeName',
        fixed: 'left',
        width: '200px',
        sorter: (a: GetSintesiComitatiResponse, b: GetSintesiComitatiResponse) =>
          (a.committeeName ?? '').localeCompare(b.committeeName ?? ''),
        showSorterTooltip: false,
      },
      {
        title: `${t('proggetiPage.codice')}`,
        dataIndex: 'projectCode',
        fixed: 'left',
        width: '110px',
      },
      {
        title: `${t('general.nome')}`,
        dataIndex: 'projectName',
        fixed: 'left',
        width: '300px',
      },
      {
        title: `${t('general.teamLeader')}`,
        dataIndex: 'teamLeaderName',
        fixed: 'left',
        width: '150px',
        render: (title: string, record: GetSintesiComitatiResponse) => {
          if (title === 'total') {
            return (
              <Text
                className={record.projectID ? '' : 'totalRow'}
                strong
              >
                Σ
              </Text>
            );
          } else {
            return <Text>{title}</Text>;
          }
        },
      },
      ...((data.find((e) => e.projectSessions?.length)?.projectSessions as unknown as SessionDataElement[]) ?? []).map(
        (col) => {
          const colDate = col.date;

          const currentDate = moment();
          const colDateRec = moment(col.date);
          let className = 'currentMonthLineIndicatorSintesi';

          if (selectedPeriod === comitieSintesiPeriod.year) {
            if (currentDate.year() !== colDateRec.year()) {
              className = '';
            }
          } else if (selectedPeriod === comitieSintesiPeriod.month) {
            if (currentDate.format('MMM YYYY') !== colDateRec.format('MMM YYYY')) {
              className = '';
            }
          } else if (selectedPeriod === comitieSintesiPeriod.week) {
            if (currentDate.week() !== colDateRec.week()) {
              className = '';
            }
          }

          return {
            dataIndex: colDate,
            align: 'center',
            className,
            title: convertDateBasedOnPeriod(colDate),
            render: (text: string, record: GetSintesiComitatiResponse) => {
              const recordDays = record.projectSessions as unknown as SessionDataElement[];
              const recordDay = recordDays.find((x) => x.date === colDate);
              if (recordDay && recordDay.count) {
                return {
                  props: {
                    className: statusCellColor(recordDay.status),
                  },
                  children: (
                    <>
                      <Text>{recordDay.count}</Text>
                    </>
                  ),
                };
              } else {
                return <Text className="dot dot-empty"></Text>;
              }
            },
          } as ColumnType<SessionDataElement>;
        },
      ),
      {
        title: `Σ`,
        dataIndex: 'projectSessionCount',
        className: 'totalCol',
        render: (title: string) => <Text strong>{title}</Text>,
      },
    ];

    return cols;
  };

  const onRow = ({ id }: GetSintesiComitatiResponse) => (expandedKeys.includes(id) && { className: 'parentRow' }) || {};

  const onExpand = (expanded: boolean, { id }: GetSintesiComitatiResponse) => {
    const keys = expandedKeys;
    const expandedKeysOn = expanded ? keys.concat(id) : keys.filter((k) => k !== id);
    setExpandedKeys(expandedKeysOn);
  };

  const expandCollapsRows = (expandAction: boolean) => {
    if (!expandAction && expandedKeys.length < 1) {
      setExpandedKeys(sintesiData.map((a) => a.id));
      setExpandCollapseRows(true);
    } else {
      setExpandedKeys([]);
      setExpandCollapseRows(false);
    }
  };

  return (
    <>
      <Form<FilterFormData>
        name="sintesiFilters"
        layout="vertical"
        form={form}
        {...formItemLayout}
        onFinish={getSintesiData}
      >
        <TableLayout title={t('comitatiPage.projectSintesi')}>
          <div></div>
        </TableLayout>
        <Row gutter={{ lg: 24 }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
          >
            <Row gutter={{ lg: 24 }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 10 }}
              >
                {activeXmatrixInfo && (
                  <Form.Item label={t('general.xmatrix')}>
                    <XmatrixDropDownFilter
                      onXmatrixSelect={({ value }) => setSelectedXmatrixValue(value)}
                      activeXmatrixInfo={activeXmatrixInfo}
                    />
                  </Form.Item>
                )}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 7 }}
              >
                <Form.Item<FilterFormData>
                  label={t('general.comitato')}
                  name="committeeIDList"
                  initialValue={[]}
                >
                  <Select
                    placeholder={t('general.seleziona')}
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option?.children && typeof option.children === 'string') {
                        const value = option.children as string;
                        return value.toLowerCase().includes(input.trim().toLowerCase());
                      }
                      return false;
                    }}
                  >
                    {commitiePickList.map((item) => (
                      <Option
                        disabled={item.hasAccess === 1 ? false : true}
                        value={item.committeeID}
                        key={item.committeeID}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 7 }}
              >
                <Form.Item<FilterFormData>
                  label={t('general.stato')}
                  name="statusList"
                  initialValue={defaultSessionStatus}
                >
                  <Select
                    placeholder={t('general.seleziona')}
                    maxTagCount="responsive"
                    mode="multiple"
                  >
                    {sessionStatusList.map((item) => (
                      <Option
                        value={item.statusID}
                        key={item.statusID}
                      >
                        {item.statusDescription}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
          >
            <Row gutter={{ lg: 24 }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
              >
                <Form.Item<FilterFormData>
                  label={t('general.start')}
                  name="dateFrom"
                  initialValue={defaultStartDate}
                >
                  <DatePicker
                    placeholder={t('general.seleziona')}
                    format={formatOfDate}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
              >
                <Form.Item<FilterFormData>
                  label={t('general.end')}
                  name="dateTo"
                  initialValue={defaultEndDate}
                >
                  <DatePicker
                    placeholder={t('general.seleziona')}
                    format={formatOfDate}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 10 }}
              >
                <Form.Item<FilterFormData>
                  label={t('comitatiPage.aggregazione')}
                  name="periodType"
                  initialValue={defaultPeriod}
                >
                  <Radio.Group
                    options={periodOptions}
                    onChange={(event) => setSelectedPeriod(event.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={{ lg: 24 }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item>
              <div className="tw-flex tw-gap-2 tw-items-center tw-justify-end">
                <Button onClick={() => handleClear()}>{t('general.clearFilters')}</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  {t('general.filter')}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Row gutter={{ lg: 24 }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            className="tw-mb-2"
            icon={!expandCollapseRows ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
            onClick={() => expandCollapsRows(expandCollapseRows)}
          >
            {t('xMatrixPage.expandCollapse')}
          </Button>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <Table
            size="small"
            className="sintesiTable"
            loading={loadingData}
            columns={sintesiTableColums}
            dataSource={sintesiData}
            rowKey={(record) => record.id!}
            pagination={false}
            scroll={{ x: 'max-content' }}
            rowClassName={(obj) => (obj.total ? 'totalRow' : '')}
            bordered
            expandable={{
              expandRowByClick: true,
              onExpand: onExpand,
              expandedRowKeys: expandedKeys,
            }}
            onRow={onRow}
          />
        </Col>
      </Row>

      <Typography.Title
        className="tw-mt-8 tw-mb-4"
        level={4}
      >
        {t('comitatiPage.notRelatedComitieProjects')}
      </Typography.Title>

      <Row gutter={{ lg: 24 }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <Table
            size="small"
            loading={!noComitiesProjects}
            columns={noComitiesProjectsColumns}
            dataSource={noComitiesProjects}
            rowKey={(obj) => obj.id}
            scroll={{ x: 'calc(600px + 50%)' }}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ProjectRevision;
