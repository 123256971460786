import { Button, Divider, Space, Tabs, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader from '../../../shared/components/loader/loader';
import { formatOfDate } from '../../../shared/utils/constants';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import UserBadge from 'src/components/shared/userBadge/userBadge';
import RoutineService from 'src/services/pages/projectServices/routineServices';
import { Calendar2Fill, CalendarScheduleLine, Group2Line } from 'src/assets/icons';
import { PlusOutlined, SwapOutlined } from '@ant-design/icons';
import RoutineFormModal from '../mainInfo/projectRoutine/routineFormModal';
import { updateNotificationBell } from 'src/components/shared/utils/functions';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import AddMemberModal from '../mainInfo/teamMembers/addMemberModal';
import ManageTeamMembers from '../mainInfo/teamMembers/manageTeamMembersModal';
import { userIsAdminOrProjectTeam } from 'src/components/shared/utils/authRolesProvilege/authRolesPrivilege';
import { useAppSelector } from 'src/redux/store';

const ProjectTeamAndRoutines = ({projectId, currentProject, hasUserAccess, isPresentationMode}) => {
  const { t } = useTranslation();

  const userData = useAppSelector((state) => state.userData.userData);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState(null);
  const [routinesList, setRoutinesList] = useState([]);
  const [selectedRoutine, setSelectedRoutine] = useState();
  const [showEditRoutineModal, setShowEditRoutineModal] = useState(false);
  const [showNewRoutineModal, setShowNewRoutineModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
    const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
    const [showSwapTeamMemberModal, setShowSwapTeamMemberModal] = useState(false);

  useEffect(() => {
    void Promise.all([
      fetchProjectMembers(projectId),
      fetchProjectRoutines(),
    ]);
  }, []);

  const fetchProjectMembers = async (id) => {
    setIsDataLoading(true);

    try {
      const response = await ProgettiService.getProjectTeamMembers(id);
      const resp = response.data;

      if (resp.success) {
        setTeamMembers(resp.responseObject.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setIsDataLoading(false);
    }
  };

    const fetchProjectRoutines = async () => {
    try {
      const { data } = await RoutineService.listProjectRoutines(projectId);

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Fetch failed');
      }

      setRoutinesList(data.responseObject.value);
    } catch {}
  };

    const handleNewRoutineModalClose = async (routine) => {
      if (routine) {
        fetchProjectRoutines();
      }
  
      setShowNewRoutineModal(false);
    };

    const handleUpdateRoutineModalClose = async (routine) => {
      if (routine) {
        fetchProjectRoutines();
        updateNotificationBell();
      }
  
      setShowEditRoutineModal(false);
    };
    

    const reloadUsers = () => {
      fetchProjectMembers(projectId);
    };

    const handleTeamMemberModalClose = () => {
      setShowTeamMemberModal(false);
      setShowSwapTeamMemberModal(false);
    };

    const addTeamMembers = async (values) => {
      setLoadingButton(true);
  
      try {
        const { status, data } = await ProgettiService.updateTeamMembers({
          userIds: values,
          projectID: projectId,
        });
  
        if (status >= 400 && status <= 499) {
          message.error(t(`projectMembers.errors.${(data).errorDescription}`));
          setLoadingButton(false);
          return;
        }
  
        message.success(notifyMessages.updateSuccess);
        updateNotificationBell();
        handleTeamMemberModalClose();
        reloadUsers();
      } catch {
        message.error(notifyMessages.updateFailed);
      } finally {
        setLoadingButton(false);
      }
    };

    const manageSwapTeamMembers = async (values) => {
      values['ProjectID'] = projectId;
  
      setLoadingButton(true);
  
      try {
        const response = await ProgettiService.swapProjectTeamMembers(values);
        if (response.data.success) {
          message.success(notifyMessages.updateSuccess);
          handleTeamMemberModalClose();
          reloadUsers();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      } catch {
        message.error(notifyMessages.updateFailed);
      } finally {
        setLoadingButton(false);
      }
    };

    const toggleTeamMemberModal = () => setShowTeamMemberModal(true);
    const toggleManageTeamMemberModal = () => setShowSwapTeamMemberModal(true);

    const isAdminOrProjectTeam = userIsAdminOrProjectTeam(userData, currentProject.teamLeaderId, currentProject.sponsorId);

  return (
    <>
      <Loader />

      {showNewRoutineModal && (
          <RoutineFormModal
            endDateProject={currentProject.endDate}
            handleClose={handleNewRoutineModalClose}
            loadingButton={loadingButton}
            projectId={currentProject.projectID}
            setLoading={setLoadingButton}
            showModal={showNewRoutineModal}
            startDateProject={currentProject.startDate}
            users={teamMembers}
          />
        )}
        {showEditRoutineModal && (
          <RoutineFormModal
            routine={selectedRoutine}
            endDateProject={currentProject.endDate}
            handleClose={handleUpdateRoutineModalClose}
            loadingButton={loadingButton}
            projectId={projectId}
            setLoading={setLoadingButton}
            showModal={showEditRoutineModal}
            startDateProject={currentProject.startDate}
            users={teamMembers}
          />
        )}

        {!isPresentationMode && showTeamMemberModal && (
          <AddMemberModal
            showTeamMemberModal={showTeamMemberModal}
            handleTeamMemberModalClose={handleTeamMemberModalClose}
            handleTeamMemberModalSave={addTeamMembers}
            projectId={projectId}
            teamMembers={teamMembers.filter((x) => !x.isDeleted)}
            loadingButton={loadingButton}
          />
        )}

        {!isPresentationMode && showSwapTeamMemberModal && (
          <ManageTeamMembers
            showModal={showSwapTeamMemberModal}
            handleModalClose={handleTeamMemberModalClose}
            handleModalSave={manageSwapTeamMembers}
            projectTeam={teamMembers}
            loadingSave={loadingButton}
            t={t}
          />
        )}

      {currentProject && teamMembers && (
        <>
          <ProjectSectionTitle
            title={t('proggetiPage.team')}
            isPresentationMode={true}
          />

          <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: '1',
                  label: t('proggetiPage.teamMembers'),
                  children: (
                    <>
                    {(hasUserAccess || isAdminOrProjectTeam) && !isPresentationMode && (
                      <div className='tw-flex tw-justify-end tw-gap-x-2'>
                        <Tooltip title={t('proggetiPage.changeTeamMembers')}>
                          <Button
                            onClick={toggleManageTeamMemberModal}
                            type="dashed"
                            icon={<SwapOutlined />}
                          />
                        </Tooltip>

                        <Tooltip
                          placement="topRight"
                          title={t('proggetiPage.gestireMembriProgetto')}>
                          <Button
                            onClick={toggleTeamMemberModal}
                            type="primary"
                            data-testid="addMemberBtn"
                            icon={<PlusOutlined />}>
                            {t('buttons.gestisci')}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                      <div className='tw-flex tw-gap-x-3 tw-flex-wrap' data-testid="teamMembers">
                        {teamMembers.map((member) => (
                          <div className='tw-w-[200px'>
                            <UserBadge
                              user={member}
                              projectId={projectId}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  ),
                },
                {
                  key: '2',
                  label: t('proggetiPage.routneGestioneProgetto'),
                  children: (
                    <div>
                      {!isPresentationMode && (
                        <>
                          <div className='tw-flex tw-justify-end'>
                            <Button
                              data-testid="openAddRoutineModal"
                              onClick={() => setShowNewRoutineModal(true)}
                              type="primary"
                              icon={<PlusOutlined />}>
                              {t('buttons.aggiungiNuovo')}
                            </Button>
                          </div>
    
                          <Divider className='tw-my-3' />
                        </>
                      )}

                      <div className='tw-flex tw-gap-4'>
                          {(routinesList.map((routine, index) =>
                            (<button
                              key={index}
                              onClick={() => {
                                setSelectedRoutine(routine);
                                setShowEditRoutineModal(true);
                              }}
                              className="tw-bg-zinc-100 hover:tw-bg-zinc-200/50 tw-transition-all hover:tw-shadow-md tw-border tw-rounded-md tw-w-64 tw-shrink-0 border-b">
                              <div className="tw-flex tw-justify-between tw-gap-2 tw-border-b tw-w-full tw-p-2 tw-items-center">
                                <h4 className="tw-text-lg tw-truncate">{routine?.name}</h4>
                                <span className="tw-text-lg tw-bg-white tw-text-zinc-500 tw-rounded-md tw-py-0.5 tw-px-2">
                                  {routine?.duration}h
                                </span>
                              </div>

                              <div className="tw-flex tw-text-sm tw-flex-col tw-gap-1 tw-p-2">
                                <div className="tw-flex tw-gap-2 tw-items-center">
                                  <Group2Line />
                                  {routine.typeDescription}
                                </div>
                                <div className="tw-flex tw-text-rest tw-gap-2 tw-items-center">
                                  <CalendarScheduleLine />
                                  <div>
                                    {routine?.schedule} - {routine?.scheduleDescription}
                                  </div>
                                </div>
                                <div className="tw-flex tw-text-rest tw-gap-2 tw-items-center">
                                  <Calendar2Fill />
                                  <div>
                                    {moment(routine.startDate).format(formatOfDate)} {`<->`} {moment(routine.endDate).format(formatOfDate)}
                                  </div>
                                </div>
                              </div>
                            </button>)
                          )
                        )}
                      </div>
                    </div>
                  ),
                },
              ]}
            />
        </>
      )}
    </>
  );
};

export default ProjectTeamAndRoutines;
