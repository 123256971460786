import { DesktopOutlined, FullscreenExitOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Tabs, Typography, message } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AnnualGoalFeed from 'src/components/obietiviAnnoListPage/details/feed';
import type { GetXMatrixInfoResponseDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import CommonService from '../../../services/commonService';
import MatrixService from '../../../services/matrix/matrixService';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import ObiettiviLongService from '../../../services/pages/obiettiviLongService';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils';
import { isTeamleaderOrTeamMemberOrObAnnoResponsible } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { longOrAnnualGoalFieldUpdate, objectTypeUserSecurity } from '../../shared/utils/constants';
import { NoAccessMesssage } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import Impostazioni from './impostazioni';
import Workshop from './workshop';

import { BorderOuterOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

const IndexDetailsObAnno: React.FC = () => {
  const { t } = useTranslation();
  const handleFullscreen = useFullScreenHandle();
  const { id: annualGoalId, xmatrix: routeSelectedXmatrix } = useParams<{ id: string; xmatrix?: string }>();
  const userData = useAppSelector((state) => state.userData.userData);

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState<GetXMatrixInfoResponseDto | null>(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState<string | null>(null);
  const [annualGoalData, setAnnualGoalData] = useState<any>(null);
  const [checkUserAccessOnObject, setCheckUserAccessOnObject] = useState(false);
  const [activeKeyTab, setActiveKeyTab] = useState(getQueryParam('tab') || '2');
  const [editableTitle, setEditableTitle] = useState('');
  const [kpiList, setKpiList] = useState<any[]>([]);
  const [presentationMode, setPresentationMode] = useState(false);

  useEffect(() => {
    getActiveXmatrix();

    if (annualGoalId) {
      checkIfUserHasAccess(annualGoalId);
    }
  }, [annualGoalId]);

  useEffect(() => {
    getAnnualGoalData();
    getObjAnnoKPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const getActiveXmatrix = async () => {
    try {
      const response = await MatrixService.getActiveXmatrix();
      const data = response.data;
      if (data.success) {
        setActiveXmatrixInfo(data.responseObject?.value || null);
      }
    } catch (error) {
      console.error('Error fetching active Xmatrix:', error);
    }
  };

  const checkIfUserHasAccess = async (objectId: string) => {
    const payload = {
      type: objectTypeUserSecurity.annualGoal,
      objectID: objectId,
    };
    try {
      const response = await CommonService.chaeckUserAccessOnObject(payload);
      const data = response.data;
      setCheckUserAccessOnObject(data.responseObject?.value?.hasPrivilege === 1);
    } catch (error) {
      console.error('Error checking user access:', error);
    }
  };

  const getAnnualGoalData = async () => {
    if (!annualGoalId) return;

    const xmatrix = selectedXmatrixValue || activeXmatrixInfo?.xMatrixID;
    if (xmatrix) {
      try {
        const response = await ObiettiviAnnoService.getObiettiviAnnoByiD(annualGoalId, xmatrix);
        const data = response.data;
        if (data.success) {
          setAnnualGoalData(data.responseObject?.value);
          setEditableTitle(data.responseObject?.value?.getAnnualGoalDto?.description || '');
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      } catch (error) {
        message.error(notifyMessages.retrieveFailed);
      }
    }
  };

  const onTitleSave = async (value: string) => {
    setEditableTitle(value);
    const payload = {
      itemID: annualGoalId,
      nameOrNote: value,
      updateCase: longOrAnnualGoalFieldUpdate.anualGoalName,
    };
    updateCommentOrName(payload);
  };

  const updateCommentOrName = async (payload: any) => {
    try {
      const response = await ObiettiviLongService.updateCommentOrNameField(payload);
      const data = response.data;
      if (data.success) {
        getAnnualGoalData();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const getObjAnnoKPI = async () => {
    if (!annualGoalId) return;

    const xmatrix = selectedXmatrixValue || activeXmatrixInfo?.xMatrixID;
    if (xmatrix) {
      try {
        const response = await ObiettiviAnnoService.getObjAnnoKpi(annualGoalId, xmatrix);
        const data = response.data;
        if (data.success) {
          setKpiList(data.responseObject?.value || []);
        }
      } catch (error) {
        console.error('Error fetching KPI list:', error);
      }
    }
  };

  const handlePresentationMode = () => {
    handleFullscreen.enter();
  };

  const isRoleTeamMemberOrTeamLeader = isTeamleaderOrTeamMemberOrObAnnoResponsible(userData, annualGoalData);

  const tabsItems = [
    {
      label: t('obiettiviLungoPage.workShopComments'),
      key: '1',
      children: (
        <Workshop
          isPresentationMode={presentationMode}
          routeSelectedXmatrix={routeSelectedXmatrix}
          updateAnnualGoalData={getAnnualGoalData}
          annualGoalData={annualGoalData}
          annualGoalId={annualGoalId}
          isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
          updateFields={updateCommentOrName}
        />
      ),
    },
    {
      label: t('obiettiviLungoPage.impostazioni'),
      key: '2',
      disabled: presentationMode,
      children: (
        <Impostazioni
          routeSelectedXmatrix={routeSelectedXmatrix}
          annualGoalData={annualGoalData}
          annualGoalId={annualGoalId}
          isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
          reloadData={getAnnualGoalData}
          activeXmatrixInfo={activeXmatrixInfo}
          onXmatrixSelect={setSelectedXmatrixValue}
          kpiList={kpiList}
          reloadObAnnoKpiList={getObjAnnoKPI}
        />
      ),
    },
    {
      label: t('general.commenti'),
      key: '3',
      disabled: presentationMode,
      children: (
        <AnnualGoalFeed
          defaultCheckedFeedType={2}
          annualGoalId={annualGoalId}
          commentFeedsOnly
          fileFeedsOnly={false}
          othersFeedsOnly={false}
        />
      ),
    },
    {
      label: t('proggetiPage.file'),
      key: '4',
      disabled: presentationMode,
      children: (
        <AnnualGoalFeed
          defaultCheckedFeedType={3}
          annualGoalId={annualGoalId}
          commentFeedsOnly={false}
          fileFeedsOnly
          othersFeedsOnly={false}
        />
      ),
    },
    {
      label: t('proggetiPage.notificeRiunioni'),
      key: '5',
      disabled: presentationMode,
      children: (
        <AnnualGoalFeed
          defaultCheckedFeedType={4}
          annualGoalId={annualGoalId}
          commentFeedsOnly={false}
          fileFeedsOnly={false}
          othersFeedsOnly
        />
      ),
    },
  ];

  return (
    <div className="tw-bg-white">
      {annualGoalData && (
        <FullScreen
          handle={handleFullscreen}
          className="tw-bg-white tw-h-full"
        >
          <ObHeader
            editableTitle={editableTitle}
            isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
            onTitleSave={onTitleSave}
          />
          {!checkUserAccessOnObject ? (
            <NoAccessMesssage />
          ) : (
            <div className="tw-bg-white">
              <Tabs
                destroyInactiveTabPane
                defaultActiveKey="1"
                className="tw-px-4"
                activeKey={activeKeyTab}
                onChange={(key) => {
                  setActiveKeyTab(key);
                  addQueryParam('tab', key);
                }}
                items={tabsItems}
                tabBarExtraContent={{
                  right: activeKeyTab === '1' && (
                    <div className="tw-flex tw-items-center tw-gap-2">
                      {presentationMode && (
                        <Tooltip title={t('a3Page.fullscreenPage')}>
                          <Button
                            icon={<BorderOuterOutlined />}
                            onClick={handlePresentationMode}
                          />
                        </Tooltip>
                      )}

                      <Button
                        type="primary"
                        icon={presentationMode ? <FullscreenExitOutlined /> : <DesktopOutlined />}
                        onClick={() => setPresentationMode(!presentationMode)}
                      >
                        {presentationMode ? t('general.exit') : t('a3Page.presenta')}
                      </Button>
                    </div>
                  ),
                }}
              />
            </div>
          )}
        </FullScreen>
      )}
    </div>
  );
};

export const ObHeader: React.FC<{
  editableTitle: string;
  isRoleTeamMemberOrTeamLeader: boolean;
  endContent?: React.ReactNode;
  onTitleSave: (title: string) => void;
}> = ({ editableTitle, isRoleTeamMemberOrTeamLeader, onTitleSave, endContent }) => {
  const history = useHistory();

  return (
    <div className="tw-bg-white tw-gap-2 tw-border-b tw-h-14 tw-flex tw-items-center tw-box-border">
      <Button
        onClick={() => history.goBack()}
        type="text"
        icon={<LeftOutlined />}
      />
      <Typography.Title
        level={4}
        className="!tw-mb-0 tw-w-full"
        editable={!isRoleTeamMemberOrTeamLeader ? { onChange: onTitleSave } : undefined}
      >
        {editableTitle}
      </Typography.Title>
      <span className="tw-w-fit tw-shrink-0 tw-mr-4">{endContent}</span>
    </div>
  );
};

export default IndexDetailsObAnno;
