import { Form } from 'antd';
import React from 'react';
import './listCategoryPage.scss';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Select, message } from 'antd';
import { withTranslation } from 'react-i18next';
import CategoryService from '../../services/pages/categoryService';
import CategoryIcon from '../shared/CategoryIcon';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddNewCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      iconList: null,
    };
  }

  componentDidMount() {
    this.retrieveIcons();
  }

  // Retrieve list of icons
  async retrieveIcons() {
    await CategoryService.getIconList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ iconList: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  addData = (values) => {
    CategoryService.addCategory(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.props.history.push('/categorie');
        } else {
          message.error(notifyMessages.addFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
      });
  };

  render() {
    const { iconList } = this.state;
    const t = this.props.t;

    return (
      <div className="edit-obietivo-anno-wrapper">
        <div className="site-card-wrapper">{t('categoriePage.aggiungiCategoria')}</div>

        <Card>
          <Form
            name="add_ob_anno"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.addData}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="button-wrapper">
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="tw-float-end"
                      disabled={!this.state.fieldsChanged}
                    >
                      <SaveOutlined /> {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Form.Item
                        label={t('general.nome')}
                        name="name"
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        label={t('categoriePage.icona')}
                        name="goalCategoryIconID"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                        >
                          {iconList &&
                            iconList.map((item) => (
                              <Option
                                value={item.goalCategoryIconID}
                                key={item.goalCategoryIconID}
                              >
                                <CategoryIcon iconName={item.iconSource} />
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(AddNewCategory);
