import { Form } from 'antd';
import { type FC, useEffect, useState } from 'react';

import { Card, Col, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { CommitteeDto, ProjectDto } from 'src/config/connectors';
import CommitieService from '../../../services/pages/commitieServices';
import ModalFooterButtons from '../../shared/components/modal/modalFooterButtons';
import { formItemLayout } from '../../shared/utils/constants';
import { requiredFields } from '../../shared/utils/notifyMessages';
import { useAppSelector } from 'src/redux/store';
const { Option } = Select;

type FormData = {
  projectId: string;
};

const AddProjectToComitieModal: FC<{
  addProjectToCommittee: (projectId: string) => Promise<void>;
  handleModalClose: () => void;
  loadingSave: boolean;
  selectedXmatrixId: string;
  showModal: boolean;
  committee: CommitteeDto;
}> = ({ addProjectToCommittee, handleModalClose, showModal, loadingSave, selectedXmatrixId, committee }) => {
  const { t } = useTranslation();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  const activeMatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  useEffect(() => {
    void fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProjects = async () => {

    try {
      const { data } = await CommitieService.getNoCommitteeProjects(activeMatrix?.xMatrixID as string, committee.type);
      
      setProjects(data);
    } catch {}
  };

  const saveData = async ({ projectId }: FormData) => {
    void addProjectToCommittee(projectId);
  };

  return (
    <div className="edit-commitie-wrapper">
      <Modal
        width={800}
        title={t('comitatiPage.aggiungiProgetto')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingSave}
            modalClose={handleModalClose}
            formId={'newProjectComitie'}
            key="newProjectComitie"
          />,
        ]}
      >
        <Card>
          <Form<FormData>
            id="newProjectComitie"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => saveData(values)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item<FormData>
                  className="main-container"
                  label={t('general.progetti')}
                  name="projectId"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    loading={!projects}
                    placeholder={t('general.seleziona')}
                    optionFilterProp="children"
                    filterOption={(input: string, option) => {
                      if (option?.children && typeof option.children === 'string') {
                        const value = option?.children as unknown as string;
                        return value.toLowerCase().includes(input.trim().toLowerCase());
                      }

                      return false;
                    }}
                  >
                    {projects.map((item) => (
                      <Option
                        value={item.id}
                        key={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default AddProjectToComitieModal;
