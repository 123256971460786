import { Card, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MatrixService from '../../services/matrix/matrixService';
import { addQueryParam, getQueryParam, hasQueryParam } from '../../utils/url-utils';
import '../projects/projectListViewPage/projectList.scss';
import TableLayout from '../shared/tableLayout';
import './kpiDashboars.scss';
import AnualGoalKpiSintesi from './kpiForAnnualGoal/anualGoalKpiSintesi';
import ProjectKpiSintesi from './kpiForProjects/projectsKpiSintesi';
import ProjectSectionTitle from '../shared/components/ProjectSectionTitle/ProjectSectionTitle';

function KpiDashboard(props) {
  const { isPresentationMode, presentationProjectId, presentationAnnualId } = props;

  const urlParams = useParams();
  const { t } = useTranslation();

  const { projectId, annualId, tab } = urlParams;

  const [loadingRootList, setLoadingRootList] = useState(false);
  const [xmatrixRootList, setXmatrixRootList] = useState(null);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    getActiveXmatrix();

    if (hasQueryParam('dashboardTab')) {
      setActiveTab(getQueryParam('dashboardTab') ?? '1');
    } else {
      setActiveTab('1');
    }
  }, []);

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const onTabChange = (activeKey) => {
    setActiveTab(activeKey);
    addQueryParam('dashboardTab', activeKey);
  };

  const tabItems = [
    {
      label: <>{t('general.progetti')}</>,
      key: '1',
      children: (
        <ProjectKpiSintesi
          projectId={projectId ?? presentationProjectId}
          t={t}
          xmatrixRootList={xmatrixRootList}
          loadingRootList={loadingRootList}
          activeXmatrixInfo={activeXmatrixInfo}
        />
      ),
    },
    {
      label: <>{t('general.obiettiviAnno')}</>,
      key: '2',
      children: (
        <AnualGoalKpiSintesi
          annualId={annualId ?? presentationAnnualId}
          t={t}
          xmatrixRootList={xmatrixRootList}
          loadingRootList={loadingRootList}
          activeXmatrixInfo={activeXmatrixInfo}
        />
      ),
    },
  ];

  return (
    <Card
      className={'!tw-shadow-none' + (isPresentationMode ? '[&_.ant-card-body]:!tw-p-0' : '')}
      bordered={false}
    >
      <TableLayout
        title={
          isPresentationMode ? (
            <ProjectSectionTitle title={t('proggetiPage.kpiStatus')} isPresentationMode={isPresentationMode} />
          ) : (
            <ProjectSectionTitle title={t('kpiDashboard.kpiDashboard')} isPresentationMode={isPresentationMode} />
          )
        }
      >
        <TableLayout.Content>
          {activeXmatrixInfo && (
            <Tabs
              items={tabItems}
              defaultActiveKey="1"
              activeKey={activeTab}
              onChange={(activeKey) => onTabChange(activeKey)}
            ></Tabs>
          )}
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
}

export default KpiDashboard;
