import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Modal, Popconfirm, Row, Statistic, Typography } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../../matrix.scss';

const { Text } = Typography;

class KpiDetails extends Component {
  deleteKpi = (id) => {
    this.props.deleteKpi(id);
  };

  render() {
    const { show, handleModalCancel, data, loadingButton, t } = this.props;

    return (
      <Modal
        className="add_ob_modal"
        width={800}
        title={`${t('general.detagli')}: ${data.title}`}
        open={show}
        destroyOnClose={true}
        onCancel={handleModalCancel}
        footer={[
          <div key="close_buttons">
            <Button onClick={handleModalCancel}>{t('buttons.chiudi')}</Button>
          </div>,
        ]}
      >
        <Card>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="button-wrapper">
                <Popconfirm
                  title={t('xMatrixPage.rimuoviIndicatoriKpi')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => this.deleteKpi(data.kpiid)}
                >
                  <Button
                    className="tw-float-end"
                    style={{ marginBottom: 10 }}
                    loading={loadingButton}
                    icon={<DeleteOutlined />}
                    type="primary"
                  >
                    {t('buttons.elimina')}
                  </Button>
                </Popconfirm>
              </div>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
              >
                <Text>{t('general.stato')}: </Text>
                <Text strong>{data.statusDescription}</Text>
              </Col>
            </Col>

            <Divider />

            <Col
              className="gutter-row"
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Statistic
                title={t('general.jumpOffPoint')}
                value={data.jumpOffPoint || data.jumpOffPoint === 0 ? data.jumpOffPoint : '-'}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Statistic
                title={t('general.target')}
                value={data.target || data.target === 0 ? data.target : '-'}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Statistic
                title={t('general.actualYtd')}
                value={data.actualYTD || data.actualYTD === 0 ? data.actualYTD : '-'}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Statistic
                title={t('general.targetYtd')}
                value={data.targetYTD || data.targetYTD === 0 ? data.targetYTD : '-'}
              />
            </Col>
          </Row>
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(KpiDetails);
