import { Col, Form, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMinutesAsHoursAndMinutes } from 'src/components/shared/utils/functions';
import { requiredFields } from 'src/components/shared/utils/notifyMessages';

const QUARTERS_IN_A_DAY = [
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
];

interface TimeRangePickerTime {
  defaultValue?: string;
  name: string;
  required?: boolean;
}

interface TimeRangePickerProps {
  required?: boolean;
  startTime: TimeRangePickerTime;
  endTime: TimeRangePickerTime;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({ required, startTime, endTime }) => {
  const { t } = useTranslation();
  const [selectedStartTime, setSelectedStartTime] = useState<string>();
  const [enabledEndTimes, setEnabledEndTimes] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    const index = selectedStartTime ? QUARTERS_IN_A_DAY.indexOf(selectedStartTime.slice(0, -3)) + 1 : 0;
    const values = QUARTERS_IN_A_DAY.slice(index);
    setEnabledEndTimes(
      values.map((v, i) => ({
        value: `${v}:00`,
        label: `${v} (${formatMinutesAsHoursAndMinutes((i + 1) * 15)})`,
      })),
    );
  }, [selectedStartTime]);

  return (
    <Row
      gutter={{ lg: 12 }}
      className="row-margin"
    >
      <Col
        className="gutter-row"
        xs={{ span: 12 }}
      >
        <Form.Item
          className="-tw-ml-1.5"
          label={t('proggetiPage.oraInizio')}
          name={startTime.name ?? 'startTime'}
          rules={required || startTime.required ? [{ required: true, message: (requiredFields as any).required }] : []}
        >
          <Select
            style={{ width: '100%' }}
            placeholder={t('proggetiPage.oraInizio')}
            defaultValue={startTime.defaultValue}
            onChange={setSelectedStartTime}
            options={QUARTERS_IN_A_DAY.map((v) => ({ value: `${v}:00`, label: v }))}
            showSearch
          />
        </Form.Item>
      </Col>
      <Col
        className="gutter-row"
        xs={{ span: 12 }}
      >
        <Form.Item
          className="-tw-mr-1.5"
          label={t('proggetiPage.oraFine')}
          name={endTime.name ?? 'endTime'}
          rules={required || startTime.required ? [{ required: true, message: (requiredFields as any).required }] : []}
        >
          <Select
            style={{ width: '100%' }}
            placeholder={t('proggetiPage.oraFine')}
            defaultValue={endTime.defaultValue}
            options={enabledEndTimes}
            showSearch
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TimeRangePicker;
