import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  type CreateProjectRequest,
  ProjectApi,
  type UpdateProjectXMatrixRequestDto,
  XMatrixApi,
  XMatrixRelationApi,
} from 'src/connectors/backend';

const ObProgettiService = {
  // update ob projects in matrix
  updateObProgetti: async (payload: UpdateProjectXMatrixRequestDto) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateProjectXMatrix(payload));
  },

  // add projects in matrix
  addObProgetti: async (payload: CreateProjectRequest) => {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectCreateProject(payload));
  },

  removeProjectFromXMatrix: async (projectId: string, xMatrixId: string, alsoDeleteProject: boolean) => {
    const api = await authenticatedApi(XMatrixRelationApi);

    return trackPromise(api.xMatrixRelationDeleteProjectXMatrixRelation(xMatrixId, projectId, alsoDeleteProject));
  },

  removeProjectDraftXmatrix: async (projectId: string, xmatrixCode: string, deleteProject: boolean) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteProjectXMatrixDraftRelation(projectId, xmatrixCode, deleteProject));
  },
};

export default ObProgettiService;
