import { Form } from 'antd';
import type { DataIndex } from 'rc-table/lib/interface';
import { type FC, useEffect, useState } from 'react';

import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row, Select, Space, Switch, Table, Tooltip, message } from 'antd';

import type { ColumnType } from 'antd/lib/table';
import type { FilterDropdownProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/components/shared/utils/functions';
import CommonService from '../../../../../services/commonService';
import ModalFooterButtons from '../../../../shared/components/modal/modalFooterButtons';
import { userRolesCodes } from '../../../../shared/utils/constants';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

type ProjectTeamMember = {
  FullName: string | null;
  Disponibility: number;
  JobTitle: string | null;
  UserID: string;
  OnCall: string | null;
  Key: string | null;
  HasOpenActivities: boolean | null;
  IsDeleted: boolean | null;
};

const AddMemberModal: FC<{
  showTeamMemberModal: boolean;
  handleTeamMemberModalClose: () => void;
  loadingButton: boolean;
  handleTeamMemberModalSave: (values: React.Key[]) => {};
  projectId: string;
  teamMembers: any[];
}> = ({
  showTeamMemberModal,
  handleTeamMemberModalClose,
  loadingButton,
  handleTeamMemberModalSave,
  projectId,
  teamMembers,
}) => {
  const { t } = useTranslation();

  const [columns, setColumns] = useState<ColumnType<ProjectTeamMember>[]>([]);
  const [loadingUsersAvailability, setLoadingUsersAvailability] = useState(false);
  const [userAvailabilityList, setUserAvailabilityList] = useState<ProjectTeamMember[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(teamMembers.map((a) => a.userID as React.Key));
  const [searchText, setSearchText] = useState<React.Key>();

  useEffect(() => {
    retrieveUsersAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const availabilities = Object.keys(userAvailabilityList[0] || {}).filter((k) => /\d/.test(k[0]));

    setColumns([
      {
        title: `${t('general.nome')}`,
        dataIndex: 'FullName',
        key: 'FullName',
        width: 170,
        fixed: 'left',
        sorter: (a: ProjectTeamMember, b: ProjectTeamMember) => {
          return (a.FullName || '').localeCompare(b.FullName || '');
        },
        showSorterTooltip: false,
        ellipsis: {
          showTitle: false,
        },
        render: (_, { FullName }) => <Tooltip title={FullName}>{FullName}</Tooltip>,
        ...getColumnSearchProps('FullName'),
      },
      {
        title: `${t('utentiPage.titoloLavoro')}`,
        dataIndex: 'JobTitle',
        key: 'JobTitle',
        width: 170,
        sorter: (a: ProjectTeamMember, b: ProjectTeamMember) => {
          return (a.JobTitle || '').localeCompare(b.JobTitle || '');
        },
        showSorterTooltip: false,
        ellipsis: {
          showTitle: false,
        },
        render: (_, { JobTitle }) => <Tooltip title={JobTitle}>{JobTitle}</Tooltip>,
      },
      {
        title: `${t('utentiPage.disponibilita')}`,
        dataIndex: 'Disponibility',
      },
      ...availabilities.map((availability) => ({
        dataIndex: availability,
        title: capitalizeFirstLetter(moment(availabilities).format('MMM YYYY')),
      })),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAvailabilityList]);

  const getColumnSearchProps = (
    dataIndex: DataIndex<ProjectTeamMember>,
  ): Pick<ColumnType<ProjectTeamMember>, 'filterDropdown' | 'filterIcon' | 'onFilter' | 'render'> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,

    onFilter: (value: React.Key | boolean, record: ProjectTeamMember) => {
      const path = typeof dataIndex === 'string' ? [dataIndex] : (dataIndex as string[]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let target: any = record;
      for (const elem of path) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (target && elem in target && target[elem]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
          target = target[elem];

          continue;
        }

        return false;
      }

      if (typeof target !== 'string') {
        return false;
      }

      return target.toLowerCase().includes(value.toString().toLowerCase());
    },
  });

  const handleSearch = ([selectedKeys]: React.Key[], confirm: FilterDropdownProps['confirm']) => {
    confirm();
    setSearchText(selectedKeys);
  };

  const handleReset = (clearFilters: (() => void) | undefined, confirm: FilterDropdownProps['confirm']) => {
    clearFilters && clearFilters();
    handleSearch([], confirm);
  };

  // Retrieve list of users availability
  const retrieveUsersAvailability = async () => {
    if (!projectId) {
      return;
    }

    setLoadingUsersAvailability(true);
    try {
      const { data } = await CommonService.getUsersAvailability(projectId);

      if (!data.success || !data.responseObject) {
        throw new Error('Failed loading content');
      }

      const list = (data.responseObject.value as ProjectTeamMember[]).map((elem) => {
        return {
          ...elem,
          Disponibility: Object.entries(elem)
            .filter(([k]) => /\d/.test(k[0]))
            .map((kv) => kv as [string, number])
            .reduce((total, [, v]) => v + total, 0),
        };
      });

      setUserAvailabilityList(list);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    setLoadingUsersAvailability(false);
  };

  const updateMembers = (values: React.Key[]) => {
    handleTeamMemberModalSave(values);
  };

  const checkUsersDisponibility = (checked: boolean, sponsorAndTeamLider: string[]) => {
    if (checked) {
      const filteredData = userAvailabilityList.filter((item) => {
        return (item.Disponibility ?? 0) > 0 || sponsorAndTeamLider.indexOf(item.UserID) !== -1;
      });
      setUserAvailabilityList(filteredData);
    } else {
      retrieveUsersAvailability();
    }
  };

  const sponsorAndTeamLider = teamMembers
    .filter((obj) => obj.roleCode === userRolesCodes.sponsor || obj.roleCode === userRolesCodes.teamLeader)
    .map((u) => u.userID);

  const users = userAvailabilityList.map((item) => {
    return {
      value: item.UserID,
      disabled: sponsorAndTeamLider.indexOf(item.UserID) !== -1 || item.HasOpenActivities || item.IsDeleted,
      key: item.UserID,
      username: item.UserID,
      label: item.FullName,
      title:
        sponsorAndTeamLider.indexOf(item.UserID) !== -1 && item.HasOpenActivities
          ? item.FullName
          : item.HasOpenActivities
            ? t('proggetiPage.hasActivity')
            : item.FullName,
    };
  });

  return (
    <Modal
      className="team_modal"
      width={1100}
      title={t('proggetiPage.gestireMembriProgetto')}
      open={showTeamMemberModal}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleTeamMemberModalClose}
      footer={[
        <ModalFooterButtons
          disabled={selectedRowKeys && selectedRowKeys.length > 0 ? false : true}
          loadingButton={loadingButton}
          modalClose={handleTeamMemberModalClose}
          formId={'projectMembersForm'}
        />,
      ]}
    >
      <Card className="sub450-card-body">
        {userAvailabilityList && (
          <Form
            id="projectMembersForm"
            name="add_ob_lungo"
            layout="vertical"
            {...formItemLayout}
            onFinish={() => updateMembers(selectedRowKeys)}
          >
            <Row
              gutter={{ lg: 24 }}
              className="no-padding"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-col-24 ant-form-item-label">
                    <label title={t('proggetiPage.membriProgetto')}>{t('proggetiPage.membriProgetto')}</label>
                  </div>
                  <div className="ant-col ant-col-24 ant-form-item-control">
                    <Select
                      value={loadingUsersAvailability ? [] : selectedRowKeys}
                      onChange={setSelectedRowKeys}
                      options={users.map((user) => ({
                        value: user.value,
                        disabled: false,
                        label: user.label,
                      }))}
                      showSearch
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.label?.toLowerCase()?.includes(input.toLowerCase()) || false
                      }
                      loading={loadingUsersAvailability}
                    ></Select>
                  </div>
                </div>
              </Col>
            </Row>

            <Row
              gutter={{ lg: 24 }}
              className="no-padding"
            >
              <Col
                className="gutter-row"
                style={{ marginBottom: 10 }}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <span>{t('proggetiPage.soloDisponibili')} </span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={false}
                  onChange={(checked) => checkUsersDisponibility(checked, sponsorAndTeamLider)}
                />
              </Col>
            </Row>

            <Row
              gutter={{ lg: 24 }}
              className="no-padding"
            >
              {userAvailabilityList && (
                <Col
                  className="gutter-row overflow-user-list"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Table
                    rowSelection={{
                      selectedRowKeys,
                      onChange: (keys: React.Key[]) => setSelectedRowKeys(keys),
                      hideSelectAll: true,
                    }}
                    dataSource={userAvailabilityList}
                    columns={columns}
                    bordered={true}
                    pagination={false}
                    scroll={{ x: 'auto' }}
                    size="small"
                    rowKey={(obj) => obj.UserID}
                    loading={loadingUsersAvailability}
                  />
                </Col>
              )}
            </Row>
          </Form>
        )}
      </Card>
    </Modal>
  );
};

export default withTranslation()(AddMemberModal);
