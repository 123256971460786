import { Form } from 'antd';
import { Component } from 'react';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Modal, Row, Switch, message } from 'antd';
import ObLongService from '../../../../services/matrix/obLongService';
import Loader from '../../../shared/components/loader/loader';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { formItemLayout } from '../../../shared/utils/constants';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import './centerSection.scss';

class AttivaModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      loadingButton: false,
      activeObLungoList: null,
    };
  }

  componentDidMount() {
    this.getAcctiveObLungoList();
  }

  async getAcctiveObLungoList() {
    const { selectedXmatrix } = this.props;

    await ObLongService.getAttiva(selectedXmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ activeObLungoList: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  addData = (values) => {
    this.props.handleAttivaModalSave(values);
  };

  render() {
    const { showAttivaModal, handleAttivaModalClose, loadingButton, t } = this.props;
    const { activeObLungoList } = this.state;

    return (
      <Modal
        className="active_obLungo_modal"
        width={800}
        title={t('xMatrixPage.verificaObjannuali')}
        open={showAttivaModal}
        destroyOnClose={true}
        onCancel={handleAttivaModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleAttivaModalClose}
            formId={'activeObLungoXmatrix'}
          />,
        ]}
      >
        <Loader />
        {activeObLungoList && (
          <Card>
            <Form
              id="activeObLungoXmatrix"
              name="add_ob_lungo_attiva"
              layout="vertical"
              {...formItemLayout}
              onFinish={this.addData}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}
            >
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 20 }}
                >
                  <Form.Item
                    label={t('xMatrixPage.miglioramentoSignificativo')}
                    name="miglioramentoSignificativo"
                    valuePropName="checked"
                    initialValue={activeObLungoList.miglioramentoSignificativo}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      style={{ left: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Divider style={{ margin: '5px 0' }} />

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 20 }}
                >
                  <Form.Item
                    label={t('xMatrixPage.raggiungimentoVision')}
                    name="raggiungimentoVision"
                    valuePropName="checked"
                    initialValue={activeObLungoList.raggiungimentoVision}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      style={{ left: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Divider style={{ margin: '5px 0' }} />

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 20 }}
                >
                  <Form.Item
                    label={t('xMatrixPage.strategiaSostenibile')}
                    name="strategiaSostenibile"
                    valuePropName="checked"
                    initialValue={activeObLungoList.strategiaSostenibile}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      style={{ left: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Divider style={{ margin: '5px 0' }} />

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 20 }}
                >
                  <Form.Item
                    label={t('xMatrixPage.innovazioneBusiness')}
                    name="innovazioneBusiness"
                    valuePropName="checked"
                    initialValue={activeObLungoList.innovazioneBusiness}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      style={{ left: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Divider style={{ margin: '5px 0' }} />

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 20 }}
                >
                  <Form.Item
                    label={t('xMatrixPage.vincoliNormative')}
                    name="vincoliNormative"
                    valuePropName="checked"
                    initialValue={activeObLungoList.vincoliNormative}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      style={{ left: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </Modal>
    );
  }
}

export default AttivaModal;
