import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { GeneralApi, LegacyProjectApi } from 'src/connectors/backend';
import i18n from '../i18n';

const LayoutService = {
  getMenuItem: async () => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(GeneralApi);

    return trackPromise(api.generalGetMenuItem(languageCode));
  },

  getNotificationBellItems: async () => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetMonthlyAlertByUser());
  },

  updateNotificationBell: async () => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateMonthlyAlert());
  },
};

export default LayoutService;
